import React, {useState, useEffect} from 'react';
import './GridWithText.css';

const GridWithText = () => {

  const [localData,  setlocalData] = useState([]);
  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicpart = '/events-filter';
  const fullUrl = baseUrl + dynamicpart;

  useEffect(() => {
    
        const fetchContents = async () => {
          await fetch(fullUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify()
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents writetest",data)
              if (data.length > 0) {
                
                if (data.length > 0) {
                  setlocalData(data);
                }else {
                  fetchContents();
                }
                
                 
              }   
          });
          
        };
    
        fetchContents();
        
    
  }, []);

  if (localData !== null) {

    const eventscard = localData.map((post) =>
        <div className="events-cardB1" key={post._id}>
          <a href="#">
            <div className="grid-itemss">
              <h2>{post.title}</h2>
              <p>{post.message}</p>
            </div>
          </a>
        </div>
    )

    /*
        <a href="#">
        <div className="grid-itemss">
          <h2>TJ Academic Frequently Asked Questions Available Now!</h2>
          <p>If you've ever had questions about TJ's curriculum, this one-stop shop answers many of the most frequently asked questions. Click here for more information. The link can also be found under Full Menu - Academics</p>
        </div>
        </a>
        <a href="#">
        <div className="grid-itemss">
          <h2>TJHSST News You Choose 2022-23</h2>
          <p>Access all of the News You Choose bulletins sent during the 2022-23 School Year.</p>
        </div>
        </a>
        <a href="#">
        <div className="grid-items">
          <h2>2023 Summer Program Information Now Available</h2>
          <p>
            Information for TJ's 2023 Summer Programming is now available at this link.
            <br></br>
            <br></br>
            Academic Summer School will be held June 26 to July 28 for full credit courses.
            <br></br>
            Full-year courses: June 26 - July 28 - no class July 3 or July 4
            <br></br>
            Semester courses: June 26 - July 14 - no class July 3 or July 4
            <br></br>
            <br></br>
            Middle School Technology Institute dates are TBA
            <br></br>
            Academic summer school is open to TJ students only.
            <br></br>
            The Middle School Technology Institute is open to all FCPS students.

          </p>
        </div>
        </a>
        <a href="#">
        <div className="grid-items">
          <h2>Schoology Tutorial for Parents</h2>
          <p>Do you have questions on how to use Schoology here at TJ? Parents/Guardians should click here for a tutorial on how to use the learning management system to view information about their child's classes.</p>
        </div>
        </a>




    */



  return (
    <>
      <div className='titleh'><h2>Events & Calender</h2></div>
      <div className="grid-containers">

      {eventscard}
        
      </div>
    </>
  );
  }
};

export default GridWithText;
