import React from "react";
import './clubsimgintro.css';

const Clubsintroimg = () => {
  return (
    <>
        <div className="clubsinimgcont">
            <div className="clubsinimg">
                <img src="https://optsirvacc.sirv.com/masiyephambili/sports/chessclub.jpg" alt="placeholder" />
            </div>
        </div>
         
    </>
  );
};

export default Clubsintroimg;