import React from "react";
import "./admission.css";

/*
<div className="pictitle">
                    <h1>Admissions</h1>
                    <img src="https://ucarecdn.com/a56cfeab-0f48-4d60-be64-03f09dde4e90/-/preview/1000x374/" alt="Admissions" />
                </div>
*/ 


function Admission() {
    return (
        <>
            <div className="wholepage">
                
                <div className="gridwhole">
                    <div className="gridelig">
                        <h2>Eligibility</h2>
                        <p>All parents desiring to enroll their child at Masiyephambili Junior School must complete an application form which is obtained from the bursar's office and submit it, together with the required documentation to the bursar. Parents or legal guardian of the prospective student are invited for interviews of which successful candidates will then be required to write an entrance examination to ascertain whether the applicant has achieved the minimum standard for admission to a particular grade. The results of this examination do not necessarily bar the child from getting a place or exclude children with learning problems but assist determining necessary help that might be needed to be given to the child.</p>
                        <p>Attach a copy of the child's birth certificate, current or former school report and transfer letter</p>
                        <p>US$50.00 registration fee (This is non-refundable)</p>
                    </div>
                    <div className="appliwhole">
                        <div className="applicationd">
                            <h3>Application Forms</h3>
                            <ul> 
                                <li><a href="https://drive.google.com/uc?export=download&id=1JGiZ2nm0nQLQ9_oOR6TMvdCTRuKW1nD7">2024 Enrollment Form</a></li>
                                <li><a href="https://drive.google.com/uc?export=download&id=1tMzx3ZUfFVJthjaWChO-crQBhLG21Ab_">2025 Enrollment Form</a></li>
                            </ul>
                        </div>
                        <div className="applideadline">
                            <h3>ECD and Grade 1 Entry</h3>
                            <p>In the case of entry into Grade 1, the school gives first preference to pupils who have gone through the Early Childhood Development, that is, ECD B being offered by the school. Thus, parents with pupils who have gone through the ECD programme offered at school are exempted from attending grade one interviews. In the case of external students, a "Pre-learning readiness” examination is administered. </p>
                            <p>Please attach the immunisation record, proof of attendace of nursery school</p>
                        </div>
                    </div>

                </div>

            </div>
            
        </>
    );
  }
  
  export default Admission;
  