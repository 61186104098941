import React from "react";
import './sportsstate.css';

const Statement = () => {
  return (
    <>
        <div className="sportsmnstatementcont">
            <div className="sportsthestatement">
                <h2>Sports are like a superhero suit for kids. They build strong bodies and minds, teaching teamwork and goal-setting. </h2>
            </div>
        </div>
         
    </>
  );
};

export default Statement;