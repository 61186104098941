import React, { useState } from 'react';
import './gallerymenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Gallerymenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

  
  return (
    <>
       <div className="gallerymenu"> 
            <div className="gallerymenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Gallery Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Gallery Menu  &#9776;</h2>
                }
                <div className={`gallerymenugerneblock ${showGerne ? 'gallerymenushow-gerne' : ''}`}>
                    <div className="gallerymenuactualgerne">
                        <Link 
                            to="/gallery" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { gallery: "all" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>All</p> 
                        </Link>
                        <Link 
                            to="/gallery" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { gallery: "campus" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Campus</p> 
                        </Link>
                        <Link 
                            to="/gallery" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { gallery: "campus life" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Campus Life</p>
                        </Link>
                        
                    </div>

                </div>
            </div>
        </div>
    </>
  );
    
}

export default Gallerymenu;