import React from 'react';
import '../pagecomponents/phome/navbarp.css';
import Navbar from '../pagecomponents/phome/navbarp';
import '../pagecomponents/phome/sidebar.css';
import Sidebar from '../pagecomponents/phome/sidebar';
//import '../pagecomponents/phome/subject.css';
//import Subject from '../pagecomponents/phome/subject';

//intial build then rome to correct place
import '../pagecomponents/contents/content.css';
import Content from '../pagecomponents/contents/content';

function Contentp() {
    return (
        <>
            <Navbar />

            <Sidebar />

            <Content />

        </>
    )
}

export default Contentp;