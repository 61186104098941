import React, {useState, useEffect} from "react";
import './clubsgrid.css';
import { useLocation, Link } from "react-router-dom";

const Clubsimggrid = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sportlength, setSportlength] = useState(0);

    const images = [
        { id: 0, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/chess1.jpg", club: "chess", spid: 0 },
        { id: 1, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/chess2.jpg", club: "chess", spid: 1 },
        { id: 2, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/chessclub.jpg", club: "chess", spid: 2 },
        { id: 3, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/chess3.jpg", club: "chess", spid: 3 },
        { id: 4, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/scouts.JPG", club: "scouts", spid: 0 },
        { id: 5, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/scouts2.JPG", club: "scouts", spid: 1 }
        
    ];

    const location = useLocation();

    const sportstype = location.state.data.club;

    useEffect(() => {
      
        const filteredImages = images.filter(
          (image) => image.club === sportstype
        );
        setSportlength(filteredImages.length);
  
    }, [sportstype, images]);

    const handleImageClick = (index) => {
      setCurrentIndex(index);
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };
  
    const handlePrev = () => {
      const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNext = () => {
      const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };

    const handlePrevSport = () => {
      const newIndex = currentIndex === 0 ? sportlength - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNextSport = () => {
      const newIndex = currentIndex === sportlength - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };


    return (
      <>
          <div className="clubsimggridcont">
              {isOpen && (
                <div className="popupclubs">
                  {images.map((image, index) => (
                    <div className="clubsimgbuffer" key={image.id}>
                      {(image.club === sportstype && image.spid === currentIndex) ?
                      <>
                        <img src={image.src} alt={`clubs ${currentIndex}`} />
                        <button className="clubsarrow prev" onClick={handlePrevSport}>
                          &#10094;
                        </button>
                        <button className="clubsarrow next" onClick={handleNextSport}>
                          &#10095;
                        </button>
                        <button className="clubsclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      :(sportstype === "all" && index === currentIndex) &&
                      <>
                        <img src={image.src} alt={`clubs ${currentIndex}`} />
                        <button className="clubsarrow prev" onClick={handlePrev}>
                          &#10094;
                        </button>
                        <button className="clubsarrow next" onClick={handleNext}>
                          &#10095;
                        </button>
                        <button className="clubsclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      }
                    </div>
                  ))}
                </div>
              )}
              <div className="clubsimggridsize">
                <div className="clubsimggrid">
                  {images.map((image, index) => (
                          <div className="clubsimgcont" key={image.id}>
                              {image.club === sportstype ?
                                <img src={image.src} alt={`Grid ${image.spid + 1}`}  onClick={() => handleImageClick(image.spid)}/>
                                : sportstype === "all" &&
                                <img src={image.src} alt={`Grid ${image.id + 1}`} onClick={() => handleImageClick(image.id)} />
                              }
                          </div>
                  ))} 
                </div>
              </div>
          </div>
           
      </>
    );
  };
  
export default Clubsimggrid;