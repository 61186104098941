import React, { useState } from "react";
import "./liblandingimg.css";

const Liblandingimg = () => {
  return (
    <>
       <div className="mainhomepicture">
            <div className="homepicture">
                <img src="https://www.carnegielibrary.org/wp-content/uploads/2023/11/WinterGuide2024_1500x500-1.jpg" alt="libraryimage"/>
                <h2>Masiyephambili space to grow</h2>
            </div>
        </div>
    </>
  );
};

export default Liblandingimg;