import React from "react";
import './culturestate.css';

const Culturestatement = () => {
  return (
    <>
        <div className="culturemnstatementcont">
            <div className="culturethestatement">
                <h2>Culture is a child's compass, guiding their identity, empathy, and understanding of the world's rich diversity.</h2>
            </div>
        </div>
         
    </>
  );
};

export default Culturestatement;