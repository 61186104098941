import React, {useState, useEffect} from "react";
import './majoreventsgrid.css';
import { useLocation, Link } from "react-router-dom";

const Majoreventsimggrid = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sportlength, setSportlength] = useState(0);

    const images = [
        { id: 0, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/business1.jpg", majorevents: "business indaba", spid: 0 },
        { id: 1, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/business2.jpg", majorevents: "business indaba", spid: 1 },
        { id: 2, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/business3.jpg", majorevents: "business indaba", spid: 2},
        { id: 3, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun11.jpg", majorevents: "family fun day", spid: 0 },
        { id: 4, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun3.jpg", majorevents: "family fun day", spid: 1 },
        { id: 5, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun4.jpg", majorevents: "family fun day", spid: 2 },
        { id: 6, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun5.jpg", majorevents: "family fun day", spid: 3 },
        { id: 7, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun6.jpg", majorevents: "family fun day", spid: 4 },
        { id: 8, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun7.jpg", majorevents: "family fun day", spid: 5 },
        { id: 9, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun8.jpg", majorevents: "family fun day", spid: 6 },
        { id: 10, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun2.jpg", majorevents: "family fun day", spid: 7 },
        { id: 11, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun12.jpg", majorevents: "family fun day", spid: 8 },
        { id: 12, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun14.jpg", majorevents: "family fun day", spid: 9 },
        { id: 13, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/familyfun16.jpg", majorevents: "family fun day", spid: 10 },
        { id: 14, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/headm2.jpg", majorevents: "family fun day", spid: 11 },
        { id: 15, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/business4.jpg", majorevents: "business indaba", spid: 3 },
        { id: 16, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/business5.jpg", majorevents: "business indaba", spid: 4 },
        { id: 17, src: "https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/business6.jpg", majorevents: "business indaba", spid: 5},
        
    ];

    const location = useLocation();
    //location.state.data.sporttype;

    const sportstype = location.state.data.majorevents;
    const aboutevent = location.state.data.abt;

    useEffect(() => {
      
        const filteredImages = images.filter(
          (image) => image.majorevents === sportstype
        );
        setSportlength(filteredImages.length);
  
    }, [sportstype, images]);

    const handleImageClick = (index) => {
      setCurrentIndex(index);
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };
  
    const handlePrev = () => {
      const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNext = () => {
      const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };

    const handlePrevSport = () => {
      const newIndex = currentIndex === 0 ? sportlength - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNextSport = () => {
      const newIndex = currentIndex === sportlength - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };


    return (
      <>
          <div className="majoreventsimggridcont">
              <p className="majoreventsabout">{aboutevent}</p>
              {isOpen && (
                <div className="popupmajorevents">
                  {images.map((image, index) => (
                    <div className="majoreventsimgbuffer" key={image.id}>
                      {(image.majorevents === sportstype && image.spid === currentIndex) ?
                      <>
                        <img src={image.src} alt={`majorevents ${currentIndex}`} />
                        <button className="majoreventsarrow prev" onClick={handlePrevSport}>
                          &#10094;
                        </button>
                        <button className="majoreventsarrow next" onClick={handleNextSport}>
                          &#10095;
                        </button>
                        <button className="majoreventsclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      :(sportstype === "all" && index === currentIndex) &&
                      <>
                        <img src={image.src} alt={`majorevents ${currentIndex}`} />
                        <button className="majoreventsarrow prev" onClick={handlePrev}>
                          &#10094;
                        </button>
                        <button className="majoreventsarrow next" onClick={handleNext}>
                          &#10095;
                        </button>
                        <button className="majoreventsclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      }
                    </div>
                  ))}
                </div>
              )}
              <div className="majoreventsimggridsize">
                <div className="majoreventsimggrid">
                  {images.map((image, index) => (
                          <div className="majoreventsimgcont" key={image.id}>
                              {image.majorevents === sportstype ?
                                <img src={image.src} alt={`Grid ${image.spid + 1}`}  onClick={() => handleImageClick(image.spid)}/>
                                : sportstype === "all" &&
                                <img src={image.src} alt={`Grid ${image.id + 1}`} onClick={() => handleImageClick(image.id)} />
                              }
                          </div>
                  ))} 
                </div>
              </div>
          </div>
           
      </>
    );
  };
  
export default Majoreventsimggrid;