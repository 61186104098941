import React from "react";
import './agriculturepara.css';

const Agriculturepara = () => {
    const homeabtimg = "https://ucarecdn.com/8dc62c32-addc-49bd-9036-f297394dcaaf/-/preview/1000x750/";

    return (
        <>
            <div className="mainagricultureparacont">
                <div className="agricultureparaimgcont">
                    <p className="agricultureparathree">Imagine you plant a tiny seed in a pot with some soil. You water it every day and leave it in the sunshine. After a while, what do you see? A small green sprout! That's the magic of agriculture – turning tiny seeds into the delicious fruits and vegetables you love to eat. Learning about agriculture helps you understand this amazing process, from planting those first seeds to harvesting all sorts of yummy things for your plate.</p>

                    <p className="agricultureparafour">But agriculture isn't just about yummy food! It's like a giant game of hide-and-seek. Can you guess where all the different colors on your plate come from? Each bright color – like the orange of a carrot or the green of a bean – means special superpowers for your body! Learning about agriculture helps you discover these hidden superpowers in your food. It teaches you how fruits and vegetables grow strong and healthy, just like you want to be!</p>
                </div>

                <div className="agricultureparatextcont">
                    <div className="agricultureparaparacont">
                        <p className="agricultureparaone">Think of all the cool things you learn in school – science, math, even taking care of your pet. Agriculture uses all of these things! Farmers need to be like scientists, understanding how weather affects plants and how to keep the soil healthy. They use math to measure things and plan their crops. Learning about agriculture helps you connect what you learn in school to the real world, making it even more exciting!</p>

                        <p className="agricultureparatwo">So next time you bite into a juicy apple or a crunchy carrot, remember the amazing journey it took to get there. Learning about agriculture is like having a secret decoder ring for your food. It helps you appreciate the hard work of farmers, understand where your food comes from, and discover the hidden superpowers that keep you healthy and strong!</p>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

export default Agriculturepara;