import React, {useEffect} from 'react';
import '../editwebpagecomp/edithomep/Carasoule.css';
import Carasoule from '../editwebpagecomp/edithomep/Carasoule';

import '../editwebpagecomp/edithomep/ImageGrid.css';
import ImageGrid from '../editwebpagecomp/edithomep/ImageGrid';

import '../editwebpagecomp/edithomep/GridWithText.css';
import GridWithText from '../editwebpagecomp/edithomep/GridWithText';

import '../editwebpagecomp/edithomep/Navbar.css';
import Navbar from '../editwebpagecomp/edithomep/Navbar';

import '../editwebpagecomp/edithomep/Footer.css';
import Footer from '../editwebpagecomp/edithomep/Footer';

import { useDispatch } from 'react-redux';

import { getPosts } from "../../actions/posts";


function Ehome() {

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getPosts());
    }, [dispatch]);


    return (
        <>
            <Navbar />

            <Carasoule />

            <ImageGrid />

            <GridWithText />

            <Footer />
        </>
    )
}

export default Ehome;