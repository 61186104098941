import React, { useState, useEffect } from 'react';
import './addmember.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Addmemberpage() {
    const [localData,  setlocalData] = useState([]);
    const[checkrole, setCheckrole] = useState(false);
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [addPopup, setaddPopup] = useState(false);
    const [deletePopup, setdeletePopup] = useState(false);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicsearchmember = '/searchmembers';
    const searchmembersurl = baseUrl + dynamicsearchmember;

    const dynamicgetmember = '/getmember';
    const getmemberurl = baseUrl + dynamicgetmember;

    const dynamiceditmember = '/editmember';
    const editmemberurl = baseUrl + dynamiceditmember;

    const dynamicaddmember = '/addmember';
    const addmemberurl = baseUrl + dynamicaddmember;

    const dynamicchangepassword = '/changepassword';
    const changepasswordurl = baseUrl + dynamicchangepassword;

    const dynamicdeletemember = '/deletemember';
    const deletememberurl = baseUrl + dynamicdeletemember;



    //const[editcheck, seteditcheck] = useState("off"); /changepassword
    

    const [memberDetails, setmemberDetails] = useState({
        userole: "",
        username: "",
        names: "",
        surname: "",
        dob: "",
        Grade: "",
        GradeClass: "",
        term: "",
        GName: "",
        GPhoneNum: "",
        Email : "",
        Department: "",
        Position: "",
        Address: "",
        PhoneNum: "",
        memberid: "",
        password: "",
        editcheck: "off"
    });

    const [getmembers, setgetmembers] = useState({
        userole: "student"
    });

    const [filterby, setfilterby] = useState("");


    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getmemberurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ getmembers })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    console.log("localdata after db out",localData)

    

    


    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("grade")

    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("username")
    
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("role")
    
    }

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchmembersurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    const clear = () => {

        setmemberDetails({ ...memberDetails, userole: "",
        username: "",
        names: "",
        surname: "",
        dob: "",
        Grade: "",
        GradeClass: "",
        term: "",
        GName: "",
        GPhoneNum: "",
        Email : "",
        Department: "",
        Position: "",
        Address: "",
        PhoneNum: "",
        memberid: "",
        password: "",
        editcheck: "off"});
        
        //setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    function edit (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(editmemberurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ memberDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();
       
    }

    function handleAddSubmit(e) {
        e.preventDefault();
       console.log(memberDetails)
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(addmemberurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ memberDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data.var2)
                setlocalData(data.var2);
                setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            setaddPopup(true);
            
            
        };
    
        Addquestion();
    }

    function openChangepassClick (e) {
        e.preventDefault();
        setShowPopup(true);
    };

    function changepasswordyes () {
        
        const changepyes = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(changepasswordurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ memberDetails }),
            
            });
            /*.then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data.var2)
                setlocalData(data.var2);
                setmemberDetails({ ...memberDetails, password: data.var1});
                setmemberDetails({ ...memberDetails, memberid: data.var3});
      
                //setContentData(data);
                
              
            });*/

            clear();
            closeChangepassClick();
            
            
        };

        changepyes();

    }
    
    function closeChangepassClick () {
        setShowPopup(false);

    };

    function deletememberyes (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(deletememberurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ memberDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();

        setdeletePopup(false);
       
    }

    
    function closeaddpop () {
        setaddPopup(false);
        clear();

    };

    function opendeleteuserClick (e) {
        e.preventDefault();
        setdeletePopup(true);
    };

    function closedeletepop () {
        setdeletePopup(false);
        clear();

    };

    



    if (localData !== null) {

        const userscard = localData.map((post) =>
            <Link
                            
            onClick={(e) => setmemberDetails({...memberDetails, userole: post.role,
            username: post.username,
            names: post.names,
            surname: post.surname,
            dob: post.dob,
            Grade: post.form,
            GradeClass: post.gradeclass,
            term: post.term,
            GName: post.guardianName,
            GPhoneNum: post.guardianPhone,
            Email : post.email,
            Department: post.department,
            Position: post.position,
            Address: post.address,
            PhoneNum: post.phonenum,
            memberid: post.memberId,
            password: post.password,
            editcheck: "on"})}
            
            style={{ textDecoration: 'none', color: 'black' }}

            key={post._id}
            
            >
            <div className="libuserdettableaddmbr" >
                <div className={memberDetails.memberid === post.memberId ? "libuserbktableaddmbrcontblue" : "libuserbktableaddmbrcont"}>
                    <div className='libdetnameaddmbr'>
                        <p>{post.names}</p>
                    </div>
                    <div className='libdetsurnameaddmbr'>
                        <p>{post.surname}</p>
                    </div>
                    <div className='libdetgradeaddmbr'>
                        <p>{post.gradeclass}</p>
                    </div>
                    <div className='libdetuseridaddmbr'>
                        <p>{post.memberId}</p>
                    </div>
                    <div className='libdetpasswordaddmbr'>
                        <p>{post.role}</p>
                    </div>
                </div>
            </div>
            </Link>
        )




    return (
        <>
        <div className="addlibmemberpageaddmbr">
            <div className="addlibmembersectaddmbr">
                <div className="addlibmemberboxaddmbr">
                    {showPopup && (
                        <div className="popup">
                        <div className="popup-content">
                            <p>Are you sure you want to change password!</p>
                            <button className="yesbutton" onClick={changepasswordyes}>Yes</button>
                            <button className="nobutton" onClick={closeChangepassClick}>No</button>
                        </div>
                        </div>
                    )}
                    {addPopup && (
                        <div className="popup">
                        <div className="popup-content">
                            <p>Please take note of the password ONE TIME VIEW ONLY!</p>
                            <p>username: {memberDetails.memberid}</p>
                            <p>password: {memberDetails.password}</p>
                            <button className="yesbutton" onClick={closeaddpop}>Done</button>
                        </div>
                        </div>
                    )}
                    {deletePopup && (
                        <div className="popup">
                        <div className="popup-content">
                            <p>Are sure you want to delete this user!</p>
                            <button className="yesbutton" onClick={deletememberyes}>Yes</button>
                            <button className="nobutton" onClick={closedeletepop}>No</button>
                        </div>
                        </div>
                    )}
                    <h2>Add Member</h2>
                    <div className='addlibmembertextboxaddmbr'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetails">
                                <select className="selectrolesaddmbr" name="selectroles" value={memberDetails.userole} onChange={(e) => setmemberDetails({...memberDetails, userole: e.target.value})} required>
                                    <option value="">Role</option>
                                    <option value="student">Student</option>
                                    <option value="library">Librarian</option>
                                    <option value="staff">Staff</option>
                                    <option value="admin">Admin</option>
                                    <option value="adminh1">AdminH1</option>
                                    <option value="pta">PTA</option>
                                </select>
                                <input type="text" className="libusernamesaddmbr" name="libusernames" placeholder='UserEmail' value={memberDetails.username} onChange={(e) => setmemberDetails({...memberDetails, username: e.target.value})} required/>
                                <input type="text" className="libusersurnamesaddmbr" name="libusersurname" placeholder='Enter Names' value={memberDetails.names} onChange={(e) => setmemberDetails({...memberDetails, names: e.target.value})} required/>
                                <input type="text" className="libusersurnamesaddmbr" name="libusersurname" placeholder='Surname' value={memberDetails.surname} onChange={(e) => setmemberDetails({...memberDetails, surname: e.target.value})} required/>
                                <input type="text" className="libusersurnamesaddmbr" name="libusersurname" placeholder='D.O.B DD/MM/YY' value={memberDetails.dob} onChange={(e) => setmemberDetails({...memberDetails, dob: e.target.value})} />
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Address' value={memberDetails.Address} onChange={(e) => setmemberDetails({...memberDetails, Address: e.target.value})} />
                                

                                {memberDetails.userole === "student" &&
                                <>
                                <input type="number" className="libusergradeaddmbr" name="libusergrade" placeholder='Grade' value={memberDetails.Grade} onChange={(e) => setmemberDetails({...memberDetails, Grade: e.target.value})} required/>
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Grade + Class eg 5 Red' value={memberDetails.GradeClass} onChange={(e) => setmemberDetails({...memberDetails, GradeClass: e.target.value})} required/>
                                <input type="number" className="libusergradeaddmbr" name="libusergrade" placeholder='Term' value={memberDetails.term} onChange={(e) => setmemberDetails({...memberDetails, term: e.target.value})} required/>
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Guardian Name' value={memberDetails.GName} onChange={(e) => setmemberDetails({...memberDetails, GName: e.target.value})} />
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Guardian Phone Number' value={memberDetails.GPhoneNum} onChange={(e) => setmemberDetails({...memberDetails, GPhoneNum: e.target.value})} />
                                <p className="labeluserid">Student ID</p>
                                <input type="text" className="libuseridaddmbr" name="libuserid" placeholder='UserId Generator' value={memberDetails.memberid} readOnly/>
                                <p className="labeluserpassword">Password</p>
                                <input type="text" className="libuserpasswordaddmbr" name="libuserpassword" placeholder='Enter Password' value={memberDetails.password} onChange={(e) => setmemberDetails({...memberDetails, password: e.target.value})}/>
                                </>
                                }

                                {memberDetails.userole !== "student" && memberDetails.userole !== "" &&
                                <>
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Email' value={memberDetails.Email} onChange={(e) => setmemberDetails({...memberDetails, Email: e.target.value})} />
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Department' value={memberDetails.Department} onChange={(e) => setmemberDetails({...memberDetails, Department: e.target.value})} />
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Position/Title' value={memberDetails.Position} onChange={(e) => setmemberDetails({...memberDetails, Position: e.target.value})} />
                                <input type="text" className="libusergradeaddmbr" name="libusergrade" placeholder='Phone Number' value={memberDetails.PhoneNum} onChange={(e) => setmemberDetails({...memberDetails, PhoneNum: e.target.value})} />
                                <p className="labeluserid">Staff ID</p>
                                <input type="text" className="libuseridaddmbr" name="libuserid" placeholder='UserId Generator' value={memberDetails.memberid} readOnly/>
                                <p className="labeluserpassword">Password</p>
                                <input type="text" className="libuserpasswordaddmbr" name="libuserpassword" placeholder='Enter Password' value={memberDetails.password} onChange={(e) => setmemberDetails({...memberDetails, password: e.target.value})}/>
                                
                                </>
                                }

                            
                                {memberDetails.editcheck === "on" ?
                                <> 
                                <p className="labeluserpassword">Change Password</p>
                                <input type="text" className="libuserpasswordaddmbr" name="libuserpassword" placeholder='Password' value={memberDetails.password} onChange={(e) => setmemberDetails({...memberDetails, password: e.target.value})} />
                                </>
                                :
                                <></>
                                }


                                {memberDetails.editcheck === "off" ?
                                <button className='addbtnaddmbr'>Add</button>
                                :
                                <>
                                <button className='clearbtnaddmbr' onClick={clear}>Clear</button>
                                <button className='editbtnaddmbr' onClick={edit}>Edit</button>
                                <button className='passbtnaddmbr' onClick={openChangepassClick}>Change Password</button>
                                <button className='deletebtnaddmbr' onClick={opendeleteuserClick}>Delete</button>
                                </>
                                }

                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectaddmbr">
                <div className="viewlibmemberboxaddmbr">
                    <h2>Library Members</h2>
                    <div className="libusertableaddmbr">
                        <div className="libuserbktableaddmbrcont">
                            <div className='liblblnameaddmbr'>
                                <p>Names</p>
                            </div>
                            <div className='liblblsurnameaddmbr'>
                                <p>Surname</p>
                            </div>
                            <div className='liblblgradeaddmbr'>
                                <p>Grade</p>
                            </div>
                            <div className='liblbluseridaddmbr'>
                                <p>UserId</p>
                            </div>
                            <div className='liblblpasswordaddmbr'>
                                <p>Role</p>
                            </div>
                        </div>
                        
                        {userscard}
                        
                    </div>  
                    <div className="filterosectaddmbr">
                        <h2>Filter by:</h2>
                        <div className="filterboxaddmbr">
                        <div className='form-groupaddmbr'>
                                <span>Role:</span>
                                <input className='byrole' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div> 
                            <div className='form-groupaddmbr'>
                                <span>Grade:</span> 
                                <input className='bygrade' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-groupaddmbr'>
                                <span>Name:</span>
                                <input className='byname' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>   
                            <div className='searchboxaddmbr'>
                                <input type="text" name="search" onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );
    }
}
        
        
export default Addmemberpage;
        