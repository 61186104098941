import React from 'react';

import Galleryintro from '../pagecomp/gallery/galleryintro';
import '../pagecomp/gallery/galleryintro.css';

import Galleryimggrid from '../pagecomp/gallery/gallerygrid';
import '../pagecomp/gallery/gallerygrid.css';

import Gallerymenu from '../pagecomp/gallery/gallerymenu';
import '../pagecomp/gallery/gallerymenu.css';


import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Gallerypage() {
    return (
        <>
            <NavbarMs />

            <Galleryintro />

            <Gallerymenu />

            <Galleryimggrid />

            <Footer />
        </>
    )
}

export default Gallerypage;