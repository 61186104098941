import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
//import { Button } from './Button';
import { NavLink } from "react-router-dom";

  const Navbar = () => {
    const [admissionsMenuOpen, setAdmissionsMenuOpen] = useState(false);
    const [academicsMenuOpen, setAcademicsMenuOpen] = useState(false);
  
    const toggleAdmissionsMenu = () => {
      setAdmissionsMenuOpen(!admissionsMenuOpen);
      setAcademicsMenuOpen(false);
    };
  
    const toggleAcademicsMenu = () => {
      setAcademicsMenuOpen(!academicsMenuOpen);
      setAdmissionsMenuOpen(false);
    };
  
    return (
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a href="/">Home</a>
          </li>
          <li className="nav-item">
            <a href="/about-us">About Us</a>
          </li>
          <li className="nav-item dropdown">
            <a href="/admissions" onClick={toggleAdmissionsMenu}>
              Admissions &#9662;
            </a>
            {admissionsMenuOpen && (
              <ul className="dropdown-menu">
                <li>
                  <a href="#form-1">Form 1</a>
                </li>
                <li>
                  <a href="#lower-six">Lower Six</a>
                </li>
                <li>
                  <a href="#transfers">Transfers</a>
                </li>
              </ul>
            )}
          </li>
          <li className="nav-item dropdown">
            <a href="/academics" onClick={toggleAcademicsMenu}>
              Academics &#9662;
            </a>
            {academicsMenuOpen && (
              <ul className="dropdown-menu">
                <li>
                  <a href="#schedule">Schedule</a>
                </li>
                <li>
                  <a href="#subjects-1-4">Subjects 1-4</a>
                </li>
                <li>
                  <a href="#subjects-5-6">Subjects 5-6</a>
                </li>
                <li>
                  <a href="#practical-subjects">Practical subjects</a>
                </li>
              </ul>
            )}
          </li>
          <li className="nav-item">
            <a href="#sports">Sports</a>
          </li>
          <li className="nav-item">
            <a href="/studentportal">Library</a>
          </li>
          <li className="nav-item">
            <a href="/studentportal">Student Portal</a>
          </li>
          <li className="nav-item">
            <a href="#parents-space">Parents Space</a>
          </li>
          <li className="nav-item">
            <a href="#google-workspace">Google Workspace</a>
          </li>
          <li className="nav-item">
            <a href="/contact-us">Contact Us</a>
          </li>
        </ul>
      </nav>
    );
  };
  
  export default Navbar;
  