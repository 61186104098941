import * as api from '../api';

//Action creators
export const getEvents = () => async (dispatch) => {
    try {
        const { data } = await api.fetchEvents();

        dispatch({ type: 'FETCH_ALL', payload: data });
        
    } catch (error) {
        console.log(error);
        
    }
}

export const createEvent = (post) => async (dispatch) => {
    try {
        const { data } = await api.createEvent(post);

        dispatch({ type: 'CREATE', payload: data})
    } catch (error) {
        console.log(error);
        
    }
}

export const updateEvent = (id, post) => async (dispatch) => {
    try {
        const { data } =await api.updateEvent(id, post);

        dispatch({ type: 'UPDATE', payload: data });
    } catch (error) {

        console.log(error);
        
    }
}
