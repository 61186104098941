import React, { useState, useEffect } from "react";
import "./navbargu.css";
import { Link } from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBars } from '@fortawesome/free-solid-svg-icons';


function Navbargu() {

  const [menuOpen, setMenuOpen] = useState(false);

  const [userfData,  setuserfData] = useState([]);
  const [usertermData,  setusertermData] = useState([]);

  const username = localStorage.getItem('theusername');

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {

    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/user-det', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      })
        .then(response => response.json())
        .then(data => {
          //console.log("data contents userform db",data.form)
          setuserfData(data.form)
          setusertermData(data.term)
    
      });
      
    };

    fetchContents();

  }, []);

 //console.log("localdata after userform db out",window.location.href)
  if (userfData !== null){

  return (
    <>
    <div className="Navbarcont">
        <div className="logoitem"> <p href="#">Logo</p></div>
        <div className={`otherlinks ${menuOpen ? 'open' : ''}`}>
          <div className="dropdown">
          <a href="/phome">Perfomance</a>
              <div className="dropdown-content">
                  <div className="dropdown-center">
                    <Link
                      to = "/Outline"

                      onClick={() => {if (window.location.href === 'http://localhost:3000/Outline') {window.location.reload()} }}
                      
                      state = {{
                      
                        data: {form: userfData, filterf: 'Form ' + userfData + ' Mathematics'} // your data array of objects type.title 
                      }}

                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                    <p>Maths</p>
                    </Link>
                     <Link
                      to = "/Outline"

                      onClick={() => {if (window.location.href === 'http://localhost:3000/Outline') {window.location.reload()} }}
                      
                      state = {{
                      
                        data: {form: userfData, filterf: 'Form ' + userfData + ' Physics'} // your data array of objects type.title 
                      }}

                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                    <p>Physics</p>
                    </Link>
                    <Link
                      to = "/Outline"

                      onClick={() => {if (window.location.href === 'http://localhost:3000/Outline') {window.location.reload()} }}
                      
                      state = {{
                      
                        data: {form: userfData, filterf: 'Form ' + userfData + ' Biology'} // your data array of objects type.title 
                      }}

                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                    <p>Biology</p>
                    </Link>
                    <Link
                      to = "/Outline"

                      onClick={() => {if (window.location.href === 'http://localhost:3000/Outline') {window.location.reload()} }}
                      
                      state = {{
                      
                        data: {form: userfData, filterf: 'Form ' + userfData + ' English'} // your data array of objects type.title 
                      }}

                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                    <p>English</p>
                    </Link>
                    <Link
                      to = "/Outline"

                      onClick={() => {if (window.location.href === 'http://localhost:3000/Outline') {window.location.reload()} }}
                      
                      state = {{
                      
                        data: {form: userfData, filterf: 'Form ' + userfData + ' Geography'} // your data array of objects type.title 
                      }}

                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                    <p href="#">Geography</p>
                    </Link>
                  </div>
              </div>
          </div>
          
          
          <p href="#">Transcripts &amp; Reports</p>
          <p href="#">Payment History</p>
          <p href="#">Timetables &amp; Activity</p>
          <p href="#">Announcements</p>
          <p href="#">Log Out</p>
        </div>
        <div className="navbar-menu-icon" onClick={handleMenuClick}>
            <i className="fa fa-bars"></i>
        </div>
    </div>
    </>
  );
  }
}

export default Navbargu;
