import React, { useState } from "react";
import "./phybcategories.css";
import { Link } from 'react-router-dom';

const PhysicalLibrarycat = () => {

    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

    



  return (
    <>
       <div className="physicallibrarylibmain">
            <div className="physicallibgerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Categories  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Categories  &#9776;</h2>
                }
                <div className={`physicalgerneblock ${showGerne ? 'physhow-gerne' : ''}`}>
                    <div className="phyactualgerne">
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "arts" ,catname: "Arts", nxtpage: 1, searchactive: "off"  }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Arts</p> 
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "music" ,catname: "Music", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Music</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "atlases" ,catname: "Atlases", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Atlases</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "dinosaurs" ,catname: "Dinosaurs", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Dinosaurs</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "plant" ,catname: "Plant", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Plant</p> 
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "natural sciences" ,catname: "Natural Sciences", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Natural Sciences</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "space" ,catname: "Space", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Space</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "magazine and journals" ,catname: "Magazine and Journals", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Magazine and Journals</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "mammals" ,catname: "Mammals", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Mammals</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "reptiles" ,catname: "Reptiles", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Reptiles</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "biography" ,catname: "Biography", nxtpage: 1, searchactive: "off"  }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Biography</p> 
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "insects" ,catname: "Insects", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Insects</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "fish" ,catname: "Fish", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Fish</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "geography" ,catname: "Geography", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Geography</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "isindebele" ,catname: "Isindebele", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Isindebele</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "math" ,catname: "Mathematics", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Mathematics</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "science" ,catname: "Science", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Science</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "history" ,catname: "History", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>History</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "home economics" ,catname: "Home Economics", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Home Economics</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "english" ,catname: "English", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>English</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "general knowledge" ,catname: "General Knowledge", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>General Knowledge</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "miscellaneous" ,catname: "Miscellaneous", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Miscellaneous</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "historical fantasy" ,catname: "Historical Fantasy", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Historical Fantasy</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "mystery" ,catname: "Mystery", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Mystery</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "science fiction" ,catname: "Science Fiction", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Science Fiction</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "horror" ,catname: "Horror", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Horror</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "fiction" ,catname: "Fiction", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Fiction</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "adventure fiction" ,catname: "Adventure Fiction", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Adventure Fiction</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "classic" ,catname: "Classics", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Classics</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "humor" ,catname: "Humor", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Humor</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "poetry" ,catname: "Poetry", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Poetry</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "fairy tale" ,catname: "Fairy Tale", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Fairy Tale</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "mythology" ,catname: "Mythology", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Mythology</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "adult fiction" ,catname: "Adult Fiction", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Adult Fiction</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "picture book" ,catname: "Picture Book", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Picture Book</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "fantasy" ,catname: "Fantasy", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Fantasy</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "sports" ,catname: "Sports", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Sports</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "realistic fiction" ,catname: "Realistic Fiction", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Realistic Fiction</p>
                        </Link>
                        <Link 
                            to="/phycategory" 
                            
                            state = {{
                                data: { categoryview: "easy to read" ,catname: "easy to read", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Easy to read</p>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    </>
  );
};

export default PhysicalLibrarycat;