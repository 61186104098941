import axios from 'axios';

const url = 'http://localhost:5000/posts';
const urlusers = 'http://localhost:5000/users';
const urlsubjects = 'http://localhost:5000/subjects';
const urloutlines = 'http://localhost:5000/outlines';
const urlevents = 'http://localhost:5000/events';
const urlnotes = 'http://localhost:5000/notes';


export const fetchPosts = () => axios.get(url);
export const createPost = (newPost) => axios.post(url, newPost);
export const updatePost = (id, updatedPost) => axios.patch(`${url}/${id}`, updatedPost);

export const fetchUser = (finduser) => axios.post(urlusers, finduser);
export const createUser = (newUser) => axios.post(urlusers, newUser);

export const fetchSubjects = () => axios.get(urlsubjects);
export const createSubject = (newSubject) => axios.post(urlsubjects, newSubject);
export const updateSubject = (id, updatedSubject) => axios.patch(`${urlsubjects}/${id}`, updatedSubject);

export const fetchOutlines = (findf) => axios.post(urloutlines, findf);
export const createOutline = (newOutline) => axios.post(urloutlines, newOutline);
export const updateOutline = (id, updatedOutline) => axios.patch(`${urloutlines}/${id}`, updatedOutline);

export const fetchEvents = () => axios.get(urlevents);
export const createEvent = (newEvent) => axios.post(urlevents, newEvent);
export const updateEvent = (id, updatedEvent) => axios.patch(`${urlevents}/${id}`, updatedEvent);

export const fetchNotes = (findNote) => axios.post(urlnotes, findNote);
export const createNote = (newNote) => axios.post(urlnotes, newNote);
export const updateNote = (id, updatedNote) => axios.patch(`${urlnotes}/${id}`, updatedNote);


