import React from "react";
import './sportsintro.css';

const Sportsintro = () => {
  return (
    <>
        <div className="sportsintrocont">
            <div className="sportsintrostatement">
                <h2>Sports</h2>
            </div>
        </div>
         
    </>
  );
};

export default Sportsintro;