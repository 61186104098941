import React from 'react';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';

import '../pagecomp/portals/portalscomb.css';
import Portalscomb from '../pagecomp/portals/portalscomb';



function Portals() {
    return (
        <>
            <NavbarMs />

            <Portalscomb />

            <Footer />
        </>
    )
}

export default Portals;