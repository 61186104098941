import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./addtestassign.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


function Addtestassign(props) {

    const location = useLocation();

    const formnum = location.state.data.formnum;
    const termnum = location.state.data.termnum;
    const subject = location.state.data.subject;

    const [selectedOption, setSelectedOption] = useState("enter");

    const [editOption, setEditOption] = useState("off");

    const [testidDetails, settestidDetails] = useState({
        tstName: "",
        tstid: "",
        numque: 0
    });

    const [searcDetails, setsearcDetails] = useState({
        formnum: formnum,
        termnum: termnum,
        subject: subject
    });

    const [questionDetails, setquestionDetails] = useState({
        question: "",
        optA: "",
        optB: "",
        optC: "",
        optD: "",
        optE: "",
        answer: ""
    });

    const [editqueDetails, seteditqueDetails] = useState({
        question: "",
        optA: "",
        optB: "",
        optC: "",
        optD: "",
        optE: "",
        answer: "",
        typeans: "",
        id : ""
    });

    const [localData,  setlocalData] = useState([]);

    const editteachque = (e, teachque, teachans, teachOptA, teachOptB, teachOptC, teachOptD, teachOptE, typeans, id) => {

        e.preventDefault()
        
        console.log("get edit detailts", teachque + teachans + teachOptA+ teachOptB)

        

        /*if (teachOptA !== "") {
            seteditqueDetails({ ...editqueDetails, question: teachque, typeans: typeans, answer: teachans})     
        } else {
            seteditqueDetails({ ...editqueDetails, question: teachque, typeans: typeans, answer: teachans, optA: teachOptA, optB: teachOptB, optC: teachOptC, optD: teachOptD, optE: teachOptE})     
        }*/



        seteditqueDetails({ ...editqueDetails, question: teachque, typeans: typeans, optA: teachOptA, optB: teachOptB, optC: teachOptC, optD: teachOptD, optE: teachOptE, id : id, answer: teachans})
        setSelectedOption(typeans)

        console.log(editqueDetails)
        setEditOption("editing")
        setquestionDetails({...questionDetails, question: "", optA: "", optB: "", optC: "", optD : "", optE : "", answer: ""});


    }

    console.log('add test section from',formnum);


    const handleTestdetails = (e) => {
        e.preventDefault();

    
        //dispatch(createEvent(eventData));
 
        console.log(testidDetails.tstName);
        console.log(testidDetails.tstid);
        console.log(testidDetails.numque);
        console.log(questionDetails.question);
        console.log(questionDetails.answer);
      
        const updatecarasole = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/addnewtest', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ testidDetails, searcDetails })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                
            });
            
        };
      
        updatecarasole();
          
     
        
    }

    const handleAddquestion = (e) => {
        e.preventDefault();

    
        //dispatch(createEvent(eventData));

        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/addnewquestion', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ questionDetails, searcDetails, testidDetails, selectedOption })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                if (data !== null) {
                    //console.log("logging data", data[0].subtopics);
                    const contentcheck = data.subtest.map((post) => post.subject)
                    //console.log("mapping",contentcheck);
                    //console.log('data', data)
                    if (contentcheck !== null) {
                      setlocalData(data.subtest);
                    }
                    
                      //localStorage.setItem('array', JSON.stringify(data));
                  }
                
            });
            
        };
      
        Addquestion();
          
        clear();
        
    }

    const editqueDb = (e) => {
        e.preventDefault();

    
        //dispatch(createEvent(eventData));

        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/addnewquestionedit', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ editqueDetails, searcDetails, testidDetails, selectedOption })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                if (data.subtest.length > 0) {
                    //console.log("logging data", data[0].subtopics);
                    const contentcheck = data.subtest.map((post) => post.subject)
                    //console.log("mapping",contentcheck);
                    //console.log('data', data)
                    if (contentcheck !== null) {
                      setlocalData(data.subtest);
                    }
                    
                      //localStorage.setItem('array', JSON.stringify(data));
                  }
                
            });
            
        };
      
        Addquestion();

        setEditOption("off")
          
        clear();
        
    }

    const deletequeDb = (e) => {
        e.preventDefault();

    
        //dispatch(createEvent(eventData));

        const Deletequestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/teachdeletequestion', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ editqueDetails, searcDetails, testidDetails, selectedOption })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                if (data.subtest.length > 0) {
                    //console.log("logging data", data[0].subtopics);
                    const contentcheck = data.subtest.map((post) => post.subject)
                    //console.log("mapping",contentcheck);
                    //console.log('data', data)
                    if (contentcheck !== null) {
                      setlocalData(data.subtest);
                    }
                    
                      //localStorage.setItem('array', JSON.stringify(data));
                  }
                
            });
            
        };
      
        Deletequestion();

        setEditOption("off")
          
        clear();
        
    }

    const clear = () => {

        seteditqueDetails({ ...editqueDetails, question: "", typeans: "", answer: "", optA: "", optB: "", optC: "", optD: "", optE: "", id : ""});
        
        setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    

    /*
            <div className="addtestque">
                
                <p className="addtestname">Test Questions</p>
                <div className="addingtestcont">
                    <p className="addquestionnumber">Question 1</p>
                    <div className="addacttestquestion">
                        <p>What is the value of 6h when h = 5</p>
                    </div>
                    <div className="addtestanswer">
                        <label>6h <input type="text" /></label>

                        <div className="addtestoptionabc">
                                <div className="addcheckoptionr">
                                    <input type="radio"  name="question" id='radio1' value="A"  />
                                    <label htmlFor='radio1' >A</label>
                                    <p>ans = 2</p>   
                                </div>
                                <div className="addcheckoptionr">
                                    <input type="radio"  name="question" id='radio2' value="B" />
                                    <label htmlFor='radio2'>B</label> 
                                    <p>ans = 2</p>
                                </div>
                                <div className="addcheckoptionr">
                                    <input type="radio"  name="question" id='radio3' value="C" />
                                    <label htmlFor='radio3'>C</label>
                                    <p>ans = 2</p>
                                </div>
                                <div className="addcheckoptionr">
                                    <input type="radio"  name="question" id='radio4' value="D" />
                                    <label htmlFor='radio4'>D</label> 
                                    <p>ans = 2</p>
                                </div>
                                <div className="addcheckoptionr">
                                    <input type="radio"  name="question" id='radio5' value="E" />
                                    <label htmlFor='radio5'>E</label>
                                    <p>ans = 2</p>
                                </div>

                            </div>
                    </div>
                </div>
            </div>


    */

    

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/addingquestions-filter', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ searcDetails })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents writetest",data)
                if (data !== null) {
                  //console.log("logging data", data[0].subtopics);
                  const contentcheck = data.subtest.map((post) => post.subject)
                  //console.log("mapping",contentcheck);
                  //console.log('data', data)
                  if (contentcheck !== null) {
                    setlocalData(data.subtest);
                  }else {
                    fetchContents();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
           
      
    }, [searcDetails]);

    /*useEffect(() => {

        console.log("onclick", location.state.data.teachque )


        if (location.state.data.teachOptA !== "") {
            seteditqueDetails({ ...editqueDetails, question: location.state.data.teachque, typeans: location.state.data.typeans, answer: location.state.data.teachans})     
        } else {
            seteditqueDetails({ ...editqueDetails, question: location.state.data.teachque, typeans: location.state.data.typeans, answer: location.state.data.teachans, optA: location.state.data.teachOptA, optB: location.state.data.teachOptB, optC: location.state.data.teachOptC, optD: location.state.data.teachOptD, optE: location.state.data.teachOptE})     
        }

        state = {{
               
                          data: {teachque: next.question, teachans: next.answer, teachOptA: next.options[0], teachOptB: next.options[1], teachOptC: next.options[2], teachOptD: next.options[3], teachOptE: next.options[4], typeans: next.anstype } // your data array of objects type.title 
                        }}

        
      
    }, []);*/

    console.log("adding question local data", localData)

    if (localData !== null) {

        const questionscard = localData.map((post) =>
            <div className="addquestions-cardB1" key={post._id}>
            {post.testcont.map((type)=>
                <div className="addquestions-cardb2" key={type._id}>
                    {type.testid === testidDetails.tstid &&
                    <div className="addtestque">
                        <p className="addtestname">{type.testname}</p>
                        {type.testques.map((next, index)=>
                        <Link
                        

                        onClick={e => editteachque(e, next.question, next.answer, next.options[0], next.options[1], next.options[2], next.options[3], next.options[4], next.anstype , next._id )}
                        
                        style={{ textDecoration: 'none', color: 'black' }}

                        key={next._id}
                        
                        >
                        <div className="addingtestcont">
                            <p className="questionnumber">Question {index + 1}</p>
                            <div className="acttestquestion">
                                <p>{next.question}</p>
                            </div>
                            <div className="addtestanswer">
                                {next.anstype === "enter" ?
                                <label>{next.finditem} <input type="text" name={"question" + (index+1)}  value={next.answer} readOnly/></label>
                                :
                                <div className="addtestoptionabc">
                                    <div className="addcheckoptionr">
                                        <input type="radio"  name={"question" + (index+1)} id={index + 'radio1'} value="A" checked={(next.answer=== "A") } readOnly />
                                        <label htmlFor={index + 'radio1' }>A</label>
                                        <p>{next.options.map((newopt, index)=> index === 0 && newopt)}</p>   
                                    </div>
                                    <div className="addcheckoptionr">
                                        <input type="radio"  name={"question" + (index+1)} id={index + 'radio2'} value="B" checked={(next.answer=== "B") } readOnly/>
                                        <label htmlFor={index + 'radio2'}>B</label> 
                                        <p>{next.options.map((newopt, index)=> index === 1 && newopt)}</p>
                                    </div>
                                    <div className="addcheckoptionr">
                                        <input type="radio"  name={"question" + (index+1)} id={index + 'radio3'} value="C" checked={(next.answer=== "C") } readOnly/>
                                        <label htmlFor={index + 'radio3'}>C</label>
                                        <p>{next.options.map((newopt, index)=> index === 2 && newopt)}</p>
                                    </div>
                                    <div className="addcheckoptionr">
                                        <input type="radio"  name={"question" + (index+1)} id={index + 'radio4'} value="D" checked={(next.answer=== "D") } readOnly/>
                                        <label htmlFor={index + 'radio4'}>D</label> 
                                        <p>{next.options.map((newopt, index)=> index === 3 && newopt)}</p>
                                    </div>
                                    <div className="addcheckoptionr">
                                        <input type="radio"  name={"question" + (index+1)} id={index + 'radio5'} value="E" checked={(next.answer=== "E") } readOnly />
                                        <label htmlFor={index + 'radio5'}>E</label>
                                        <p>{next.options.map((newopt, index)=> index === 4 && newopt)}</p>
                                    </div>

                                </div>
                                }
                            </div>
                        </div>
                        </Link>
                        )}
                        
                    </div>
                    }    
                </div>
            )}
            </div>
        )

    
    return (
        <>
        <div className="startaddtest-main">
            <div className="addquestioncont">
                <div className="addquestion">
                    <p>Test Details</p>
                    <form onSubmit={handleTestdetails}>
                        <div className='testname'> 
                            <input type="text" name="testname" value={searcDetails.subject} readOnly />
                        </div>
                        <div className='testname'> 
                            <input type="text" name="testname" placeholder="Test Name" onChange={(e) => settestidDetails({ ...testidDetails, tstName: e.target.value})} />
                        </div>
                        <div className='testname'> 
                            <input type="text" name="testname" placeholder="Test ID e.g mathTst01/01/23" onChange={(e) => settestidDetails({ ...testidDetails, tstid: e.target.value})} />
                        </div>
                        <div className='testname'> 
                            <input type="number" name="testname" placeholder="Number of questions" onChange={(e) => settestidDetails({ ...testidDetails, numque: e.target.value})} />
                        </div>
                        <button className="submitbuttontestname" type="submit">Submit</button>
                    </form>

                </div>

                <div className="startaddquestion">
                    <p>Add Question</p>
                    <form onSubmit={handleAddquestion}> 
                        <div className='testname'> 
                            <input type="text" name="testname" placeholder="Question" value={(editOption === "editing") ? editqueDetails.question : questionDetails.question}  onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, question: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, question: e.target.value})} required />
                        </div>
                        <div className='testoption'>
                            <p>Answer type:</p>
                            <select value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                                <option value="enter">Enter</option>
                                <option value="option">Option</option>
                            </select>
                        </div>
                        { selectedOption === "option" &&
                        <>
                        <div className='testoptabc'> 
                            <label>A
                            {console.log("Check hereeeeeeee"+ "edit"+editqueDetails.optA + "que"+questionDetails.optA)}
                            <input type="text" name="testname" placeholder="Options" value={(editOption === "editing") ? editqueDetails.optA : questionDetails.optA} onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, optA: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, optA: e.target.value})} required/>
                            </label>
                        </div>
                        <div className='testoptabc'> 
                            <label>B
                            <input type="text" name="testname" placeholder="Options" value={(editOption === "editing") ? editqueDetails.optB : questionDetails.optB} onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, optB: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, optB: e.target.value})} required/>
                            </label>
                        </div>
                        <div className='testoptabc'> 
                            <label>C
                            <input type="text" name="testname" placeholder="Options" value={(editOption === "editing") ? editqueDetails.optC : questionDetails.optC} onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, optC: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, optC: e.target.value})} required/>
                            </label>
                        </div>
                        <div className='testoptabc'> 
                            <label>D
                            <input type="text" name="testname" placeholder="Options" value={(editOption === "editing") ? editqueDetails.optD : questionDetails.optD} onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, optD: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, optD: e.target.value})} required/>
                            </label>
                        </div>
                        <div className='testoptabc'> 
                            <label>E
                            <input type="text" name="testname" placeholder="Options" value={(editOption === "editing") ? editqueDetails.optE : questionDetails.optE} onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, optE: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, optE: e.target.value})} required/>
                            </label>
                        </div>
                        </>
                        }
                        <div className='testname'> 
                            <input type="text" name="testname" placeholder="Answers" value={(editOption === "editing") ? editqueDetails.answer : questionDetails.answer} onChange={(editOption === "editing") ? (e) => seteditqueDetails({ ...editqueDetails, answer: e.target.value}) : (e) => setquestionDetails({ ...questionDetails, answer: e.target.value})} required/>
                        </div>
                        { (editOption === "editing") ?
                        <>
                        <button className="submitbuttontestname" onClick={(e) => editqueDb(e)}>Edit</button>
                        <button className="submitbuttontestname" onClick={(e) => deletequeDb(e)}>Delete</button>
                        </>
                        :
                        <button className="submitbuttontestname" type="submit">Add</button>
                        }
                    </form>

                </div>

                
            </div>

            {questionscard}
   
        </div>
        </>
      
    );
    }
    
    
}


  
  export default Addtestassign;