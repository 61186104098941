import React, { useState, useEffect } from 'react';
import './clubsmenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Clubsmenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

  return (
    <>
       <div className="clubsmenu"> 
            <div className="clubsmenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Clubs Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Clubs Menu  &#9776;</h2>
                }
                <div className={`clubsmenugerneblock ${showGerne ? 'clubsmenushow-gerne' : ''}`}>
                    <div className="clubsmenuactualgerne">
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "all" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>All</p> 
                        </Link>
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "brownies" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Brownies</p> 
                        </Link>
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "chess" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Chess</p>
                        </Link>
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "spelling" } 
                            }}
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Spelling Bee</p>
                        </Link>
                        
                        <>
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "media club" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Media Club</p>
                        </Link>
                        <Link 
                             to="/clubs" 
                             //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                             state = {{
                                 data: { club: "time table rockstars" } 
                             }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Time Table Rockstars</p> 
                        </Link>
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "public speaking" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Public Speaking</p>
                        </Link>
                        <Link 
                            to="/clubs" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { club: "scouts" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Scouts</p>
                        </Link>
                        
                        </>
                                                
                    </div>

                </div>
            </div>
        </div>
    </>
  );
  
}

export default Clubsmenu;