import React from "react";
import './whoarewe.css';

const Whoarewe = () => {
    const homeabtimg = "https://ucarecdn.com/6a78916a-82e6-4bc9-be66-ee748e09f152/-/preview/1000x750/";

    return (
        <>
            <div className="whoarewehomemaincont">
                <div className="whoarewehomeimgcont">
                    <img src={homeabtimg} alt="who are we" />
                </div>

                <div className="whoarewehometextcont">
                    <div className="whoarewehomeparacont">
                        <h2>Who are we</h2>
                        
                        <p className="whoarewehomeone">Masiyephambili Junior School is Firmly committed to providing the best educational experience for its kindergarten and primary school going age students. The school aims at adequately preparing its students for secondary education by maximizing individual potential in the curricular, co-curricular and personal development spheres.</p>

                        <p className="whoarewehometwo">The educational programme at Masiyephambili Junior School is based on the belief that we were all created equal and should have equal opportunities and that this is our school and we should all be proud to be part of it. Let us all have love for one another and the love of God and love for life itself.</p>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

export default Whoarewe;