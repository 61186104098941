import React from 'react';
import '../pagecomponents/phome/navbarp.css';
import Navbar from '../pagecomponents/phome/navbarp';
import '../pagecomponents/testassignment/testassignment.css';
import TestAssignsec from '../pagecomponents/testassignment/testassignment';

//intial build then rome to correct place

function TestAssignmentp() {
    return (
        <>
            <Navbar />

            <TestAssignsec />

            

        </>
    )
}

export default TestAssignmentp;