import React, { useState, useEffect } from "react";
import './GridWithText.css';
import { updateEvent } from '../../../actions/events';
import { useDispatch,  useSelector } from 'react-redux';

const GridWithText = () => {
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState({ creator: '', title:'', message:'' });

  const [ElocalData, setElocalData] = useState([]);

  const [Efound, setEfound] = useState({ creator: '', title:'', message:''});

  useEffect(() => {
    const fetchEvents = async () => {
      const Eresponse = await fetch('http://localhost:5000/events');
      const Edata = await Eresponse.json();
      
      if (Edata.length > 0) {
        setEventData(Edata);
        localStorage.setItem('earray', JSON.stringify(Edata));
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const storedEData = localStorage.getItem('earray');
    if (storedEData) {
      setElocalData(JSON.parse(storedEData));
      
    }
  }, []);

  

  const handleSubmit = (e) => {
    e.preventDefault();

    //dispatch(createEvent(eventData));

    console.log("inside button id", Efound._id)
    
    if (Efound._id) {
      dispatch(updateEvent(Efound._id, Efound))
      const updatedElocaldata= ElocalData.map((ldata) => {
        if (ldata._id === Efound._id){  
          return { ...ldata, ...Efound };
        }else {
          return ldata;
        }
        
      })
      localStorage.setItem("earray", JSON.stringify(updatedElocaldata));
      setElocalData(updatedElocaldata);

    }
    clear();


    

    

  }


  const clear = () => {
    Efound._id = null;
    setEfound({ creator: '', title:'', message:''});

  }

  const edittextonlyhomep = (event, id) => {
    event.preventDefault();
    console.log("receiving id", ElocalData);
   
    const Efoundi = ElocalData.find(obj => {
      return obj._id === id;
    });

    if (Efoundi) {
      setEfound(Efoundi);
    }
  }

  if (ElocalData.length > 0) {

  return (
    <>
      <div className="editwholegridwithtext">
        <div className="eventsgridedit"> 
          <div className='titlehI'><h2>Events & Calender</h2></div>
          <div className="grid-containerG">
            <a href="/" onClick={(event) => edittextonlyhomep(event, ElocalData[0]._id) }>
            <div className="grid-itemG">
              <h2>{ ElocalData[0].title }</h2>
              <p>{ ElocalData[0].message }</p>
            </div>
            </a>
            <a href="/" onClick={(event) => edittextonlyhomep(event, ElocalData[1]._id) } >
            <div className="grid-itemG">
              <h2>{ ElocalData[1].title }</h2>
              <p>{ ElocalData[1].message }</p>
            </div>
            </a>
            <a href="/" onClick={(event) => edittextonlyhomep(event, ElocalData[2]._id) }>
            <div className="grid-itemG">
              <h2>{ ElocalData[2].title }</h2>
              <p>
              { ElocalData[2].message }

              </p>
            </div>
            </a>
            <a href="/" onClick={(event) => edittextonlyhomep(event, ElocalData[3]._id) }>
            <div className="grid-itemG">
              <h2>{ ElocalData[3].title }</h2>
              <p>{ ElocalData[3].message }</p>
            </div>
            </a>
          </div>
        </div>
        <div className="editgridthome">
            <form onSubmit={handleSubmit}>
              <div className="editformhome2">
                <h2>Click on element to edit</h2>
                <div className='creatoredithome2'>
                  <input type="text" name="creator"  placeholder="Creator" value={Efound ? Efound.creator : "Creator"} onChange={(e) => setEfound({ ...Efound, creator: e.target.value})} />
                </div>
                <div className='titledithome2'>
                  
                  <input type="text" name="title" placeholder="Title" value={Efound ? Efound.title : "Title"} onChange={(e) => setEfound({ ...Efound, title: e.target.value})} />
                </div>
                <div className='detediithome2'>
                  
                  <textarea name="message" placeholder="Article" value={Efound ? Efound.message : "Article"} onChange={(e) => setEfound({ ...Efound, message: e.target.value})}></textarea>
                </div>
                
                <button className="submitbuttonhomep2" type="submit">Submit</button>
              
                <button className="clearbuttonhomep2" onClick={clear}>Clear</button>


              </div>
            </form>
          </div>
        </div>
    </>
  );
  }
};

export default GridWithText;
