import React from "react";
import './learnmore.css';

const Learnmore = () => {
  return (
    <>
        <div className="learnmoremaincont">
            <div className="learnmoremission">
                <p>At Masiyephambili junior we strive to develop a school that will remain a leader in the region in the provision of quality education</p>
            </div>
        </div>
        
        
    </>
  );
};

export default Learnmore;