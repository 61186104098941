import React, { useState, useEffect } from "react";
import "./videoviewer.css";
import { useLocation } from "react-router-dom";



function Videoviewer(props) {


  const location = useLocation();

  const filterf = location.state.data.clicked;
  const subjectnm = location.state.data.topic;
  const outltopic = location.state.data.outltopic;
  const sub1t = location.state.data.sub1t;
  const sub2lessindex = location.state.data.sub2lessindex;
  const lessopt = location.state.data.lessopt;

  const [contentData, setContentData] = useState({
      subjectnm: subjectnm,
      outltopic: outltopic
  });

  const [localData,  setlocalData] = useState([]);

  console.log("data to be sent to next page", contentData.filterf)

  useEffect(() => {
      const fetchContents = async () => {
        //await dispatch(getOutlines(parsedObj));
        await fetch('http://localhost:5000/lesson-filter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ contentData })
        })
          .then(response => response.json())
          .then(data => {
            console.log("data contents db",data)
            if (data.outline.length > 0) {
              //console.log("logging data", data[0].subtopics);
              const contentcheck = data.outline.map((post) => post.title)
              console.log("mapping",contentcheck);
              if (contentcheck !== null) {
                setlocalData(data.outline);
              }else {
                fetchContents();
              }
              
                //localStorage.setItem('array', JSON.stringify(data));
            }

            //setContentData(data);
            
          
        });
        
      };

      fetchContents();

    
      

    }, []);

    console.log("localdata after db out",localData)

    if (localData !== null) {
      const lessonvideol = localData.map((post) =>
      <div className="mainvideoB1" key={post._id}>
        {post.subtopic.map((type)=> 
        <div className="mainvideoB2" key={type._id}>
          {type.subtopic2.map((enter, index)=> 
          <div className="mainvideoB3" key={enter._id}>
            {filterf === enter.sub2lesstitle && index === sub2lessindex ?
            <div className="mainvidoB4" key={enter._id}>
              {enter.lessonotes.map((close, index)=>
              <div className="video-contain" key={index}>
                {index  === 0 && lessopt === undefined ?
                <iframe title="videonotes" src={close.videoviewlink}></iframe>
                :
                null
                }
                {lessopt  === close.sublesstitle ?
                <iframe title="videonotes" src={close.videoviewlink}></iframe>
                :
                null
                }
              </div>
              )}
            </div>
            :
            null
            }       
          </div>
          )} 
        </div>
        )}
      </div> 

    )

   
  
    return (
      
      <div className="video-container">
        <h2>Video Lesson</h2>
        {lessonvideol}  
      </div>
      
    );
  }
}


  
export default Videoviewer;
