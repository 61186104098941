import React, { useState } from "react";
import "./qrgenerate.css";
import { Link} from "react-router-dom";

const Qrgenerate = () => {
  const [bookDetails, setbookDetails] = useState({
    title: "",
    bookid: ""
});



  return (
    <>
        <div className="qrgeneratemain">
            <div className="qrgeneratebox">
                <h2>Generate QR Code</h2>
                <input type="text" className="qrcodetitle" name="libuserbknames" placeholder='Book Title'  value={bookDetails.title} onChange={(e) => setbookDetails({...bookDetails, title: e.target.value})} />
                <input type="text" className="qrcodebookid" name="libuserbknames" placeholder='Book ID'  value={bookDetails.title} onChange={(e) => setbookDetails({...bookDetails, title: e.target.value})} />
                <button>Generate</button>
            </div> 
        </div>
    </>
  );
};

export default Qrgenerate;