import React from 'react';

import Academic from '../pagecomp/academicsp/academic';
import '../pagecomp/academicsp/academic.css';

import Academicintro from '../pagecomp/academicsp/academicintro';
import '../pagecomp/academicsp/academicintro.css';

import Academicintroimg from '../pagecomp/academicsp/academicintroimg';
import '../pagecomp/academicsp/academicintroimg.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Academics() {
    return (
        <>
            <NavbarMs />

            <Academicintro />

            <Academicintroimg />

            <Academic />

            <Footer />
        </>
    )
}

export default Academics;