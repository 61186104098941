import React, { useState } from "react";
import "./recommsect.css";

const Recommendedsectp = () => {

  //https://www.carnegielibrary.org/wp-content/uploads/2022/07/Readers-Advisory-Recommendations.jpg
  return (
    <>
        <div className="recommendedsection">
            <div className="homeworksection">
                <div className="homeworkimgsection">
                  <img src="https://optsirvacc.sirv.com/masiyephambili/lifeatmasiye/tab2.jpg" alt="recommendationpic" />
                </div>
                
                <div className="homeworkpara">
                    <p>Meet Your Match</p>
                    <h2>PERSONALIZED RECOMMENDATIONS JUST FOR YOU</h2>
                    <p>Looking for a good book, album, movie, TV show, or something new entirely? Fill out this brief form and we’re happy to recommend them to you!</p>
                    <i className="fas fa-arrow-right"></i>
                </div>
            </div>  
        </div>
    
        
    </>
  );
};

export default Recommendedsectp;