const notes = (notes= {notes:null, loading: false}, action) => {
    //console.log("logging data in posts.js",posts)

    switch (action.type){
        case 'UPDATE':
            return notes.map((post)=> post._id === action.payload._id ? action.payload : post)
            
        case 'FETCH_ALL':
            return {loading: false, notes: action.payload};
        case 'CREATE':
            return [...notes, action.payload];
        default:
            return notes;
    }
    
}

export default notes;