import React from 'react';
//import '../pagecomponents/addbooks/navbarlibrian.css';
//import Navbar from '../pagecomponents/addbooks/navbarlibrian';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';

import Navbarpta from '../pagecomponents/addptaproducts/navbar';
import '../pagecomponents/addptaproducts/navbar.css';

import '../pagecomponents/addptaproducts/addptaproduct.css';
import Addptaproduct from '../pagecomponents/addptaproducts/addptaproduct';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Addptaproductp() {
    return (
        <>

            <Navbarpta />          

            <Addptaproduct />




        </>
    )
}

export default Addptaproductp;