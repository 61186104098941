import React, { useState, useEffect } from 'react';
import './addonlinebook.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Addonlinebookp() {
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkrole, setCheckrole] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [localData,  setlocalData] = useState([]);
    const [filterby, setfilterby] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetonlinebooks = '/getonlinebooks';
    const getonlinebooksurl = baseUrl + dynamicgetonlinebooks;

    const dynamicsearchonlinebooks = '/searchonlinebooks';
    const searchonlinebooksurl = baseUrl + dynamicsearchonlinebooks;

    const dynamicaddonlinebooks = '/addonlinebooks';
    const addonlinebooksurl = baseUrl + dynamicaddonlinebooks;

    const dynamiceditonlinebooks = '/editonlinebooks';
    const editonlinebooksurl = baseUrl + dynamiceditonlinebooks;

    const dynamicdeleteonlbooks = '/deleteonlinebooks';
    const deleteonlbooksurl = baseUrl + dynamicdeleteonlbooks;



    ///////--------------/searchonlinebooks



    const [bookDetails, setbookDetails] = useState({
        title: "",
        authorname: "",
        bioinfor: "",
        bioinfor2: "",
        bioinfor3: "",
        imglink: "",
        pdflink: "",
        category: "",
        typebook: "",
        year: "",
        grade: 0,
        bookid: "",
        editcheck: "off",
    });

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getonlinebooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookDetails })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("author")
    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("name")
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("category")
    
    }

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchonlinebooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleAddSubmit(e) {
        e.preventDefault();
        console.log(bookDetails)
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(addonlinebooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            //setaddPopup(true);
            clear();
            
            
        };
    
        Addquestion();
    
    }

    const clear = () => {

        setbookDetails({ ...bookDetails, 
        title: "",
        authorname: "",
        bioinfor: "",
        bioinfor2: "",
        bioinfor3: "",
        imglink: "",
        pdflink: "",
        category: "",
        typebook: "",
        year: "",
        grade: 0,
        bookid: "",
        editcheck: "off"});
        
        //setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    function edit (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(editonlinebooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();
       
    }

    function deleteonlbookyes (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(deleteonlbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();

        setShowPopup(false);
       
    }

    function openonlinedeletebkClick (e) {
        e.preventDefault();
        setShowPopup(true);
    };

    function closedeleteonlbkClick () {
        setShowPopup(false);

    };



    if (localData !== null) {

        const getbookscard = localData.map((post) =>
            <Link
            
            onClick={(e) => setbookDetails({...bookDetails,
                title: post.title,
                authorname: post.authorname,
                bioinfor: post.authorbio,
                bioinfor2: post.authorbio2,
                bioinfor3: post.authorbio3,
                imglink: post.imglink,
                pdflink: post.pdflink,
                category: post.category,
                typebook: post.type,
                year: post.year,
                bookid: post.bookid,
                editcheck: "on"
            })}
            
            style={{ textDecoration: 'none', color: 'black' }}

            key={post._id}
            
            >
            <div className="libuserdetbktableonladd" >
                <div className={bookDetails.bookid === post.bookid ? "libuserdetbktableonladdcontblue" : "libuserdetbktableonladdcont"}>
                    <div className='libdetbknameonladd'>
                        <p>{post.bookid}</p>
                    </div>
                    <div className='libdetbksurnameonladd'>
                        <p>{post.authorname}</p>
                    </div>
                    <div className='libdetbkgradeonladd'>
                        <p>{post.title}</p>
                    </div>
                    <div className='libdetbkuseridonladd'>
                        <p>{post.category}</p>
                    </div>
                    <div className='libdetbkpasswordonladd'>
                        <p>{post.type}</p>
                    </div>
                </div>
            </div>
            </Link>
        )





    return (
        <>
        <div className="addlibmemberpageonladd">
            <div className="addlibmembersectonladd">
                <div className="addlibmemberboxonladd">
                    {showPopup && (
                        <div className="popuponlbk">
                        <div className="popup-contentonlbk">
                            <p>Are you sure you want delete this book!</p>
                            <button className="deletebuttononlbk" onClick={deleteonlbookyes}>Yes</button>
                            <button className="nodeletebtnonlbk" onClick={closedeleteonlbkClick}>No</button>
                        </div>
                        </div>
                    )}
                    <h2>Add Online Books</h2>
                    <div className='addlibmembertextboxonladd'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetails">
                                <input type="text" className="libuserbknamesonladd" name="libuserbknames" placeholder='Title'  value={bookDetails.title} onChange={(e) => setbookDetails({...bookDetails, title: e.target.value})} />
                                <input type="text" className="libuserbksurnamesonladd" name="libuserbksurname" placeholder='Author Name' value={bookDetails.authorname} onChange={(e) => setbookDetails({...bookDetails, authorname: e.target.value})} />
                                <textarea className="libdetbktextarearonladd" name="libuserbkgrade" placeholder='Biographical Information' maxLength="600" value={bookDetails.bioinfor} onChange={(e) => setbookDetails({...bookDetails, bioinfor: e.target.value})} > </textarea>
                                <textarea className="libdetbktextarearonladd" name="libuserbkgrade" placeholder='Paragraph 2 Biographical Information' maxLength="600" value={bookDetails.bioinfor2} onChange={(e) => setbookDetails({...bookDetails, bioinfor2: e.target.value})} > </textarea>
                                <textarea className="libdetbktextarearonladd" name="libuserbkgrade" placeholder='Paragraph 3 Biographical Information' maxLength="600" value={bookDetails.bioinfor3} onChange={(e) => setbookDetails({...bookDetails, bioinfor3: e.target.value})} > </textarea>
                                <input type="text" className="libuserbkgradeonladd" name="libuserbkgrade" placeholder='Cover Page Link' value={bookDetails.imglink} onChange={(e) => setbookDetails({...bookDetails, imglink: e.target.value})} />
                                <input type="text" className="libuserbkgradeonladd" name="libuserbkgrade" placeholder='Book Pdf Link' value={bookDetails.pdflink} onChange={(e) => setbookDetails({...bookDetails, pdflink: e.target.value})} />
                                <select className="libuserbkgradeonladd" name="selectroles" value={bookDetails.category} onChange={(e) => setbookDetails({...bookDetails, category: e.target.value})}>
                                    <option defaultValue>Category/Genre</option>
                                    <option value="picture book">Picture Book</option>
                                    <option value="early readers">Early Readers</option>
                                    <option value="chapter books">Chapter Books</option>
                                    <option value="fairy tale">Fairy Tale</option>
                                    <option value="adventure">Adventure</option>
                                    <option value="mystery">Mystery</option>
                                    <option value="fantasy">Fantasy</option>
                                    <option value="science fiction">Science Fiction</option>
                                    <option value="historical">Historical</option>
                                    <option value="horror">Horror</option>
                                    <option value="biography">Biography</option>
                                    <option value="poetry">Poetry</option>
                                    <option value="animal story">Animal Story</option>
                                    <option value="friendship">Friendship</option>
                                    <option value="mathematics">Mathematics</option>
                                    <option value="isndebele">IsNdebele</option>
                                    <option value="heritage">Heritage</option>
                                </select>

                                <select className="libuserbkgradeonladd" name="selectbooktype" value={bookDetails.typebook} onChange={(e) => setbookDetails({...bookDetails, typebook: e.target.value})}>
                                    <option defaultValue>Book Type</option>
                                    <option value="PDF">PDF</option>
                                    <option value="Audio">Audio</option>
                                    <option value="Video">Video</option>
    
                                </select>
                                
                                <input type="text" className="libuserbkgradeonladd" name="libuserbkgrade" placeholder='Publication Year' value={bookDetails.year} onChange={(e) => setbookDetails({...bookDetails, year: e.target.value})} />
                               
                                { bookDetails.editcheck === "off" ? 
                                <button className='addbtnonladd'>Add</button>
                                :
                                <>
                                <button className='clearbkbtnonladd' onClick={clear}>Clear</button>
                                <button className='editbkbtnonladd' onClick={edit}>Edit</button>
                                <button className='editbkbtnonladd' onClick={openonlinedeletebkClick}>Delete</button>
                                </>
                                }
                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectonladd">
                <div className="viewlibmemberboxonladd">
                    <h2>Library Books</h2>
                    <div className="libuserbktableonladd">
                        <div className="libuserbktableonladdcont">
                            <div className='liblblbknameonladd'>
                                <p>ID</p>
                            </div>
                            <div className='liblblbksurnameonladd'>
                                <p>Author</p>
                            </div>
                            <div className='liblblbkgradeonladd'>
                                <p>Title</p>
                            </div>
                            <div className='liblblbkuseridonladd'>
                                <p>Category</p>
                            </div>
                            <div className='liblblbkpasswordonladd'>
                                <p>Type</p>
                            </div>
                        </div>
                        {getbookscard}
                        
                    </div>  
                    <div className="filterosectonladd">
                        <h2>Filter by:</h2>
                        <div className="filterboxonladd">
                            <div className='form-grouponladd'>
                                <span>Author:</span> 
                                <input className='bybkgrade' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-grouponladd'>
                                <span>Title:</span>
                                <input className='bybkname' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>  
                            <div className='form-grouponladd'>
                                <span>Genre:</span>
                                <input className='bybkrole' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div>  
                            <div className='searchbkboxonladd'>
                                <input type="text" name="search" onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

    }
}
        
        
export default Addonlinebookp;
        