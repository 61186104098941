import React from "react";
import './agricultureimgintro.css';

const Agricultureintroimg = () => {
  return (
    <>
        <div className="agricultureinimgcont">
            <div className="agricultureinimg">
                <img src="https://optsirvacc.sirv.com/masiyephambili/gallery/greenhouse.JPG" alt="placeholder" />
            </div>
        </div>
         
    </>
  );
};

export default Agricultureintroimg;