import React, { useState, useEffect } from 'react';
import './dashboard.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Dashboardp() {

    const [localData,  setlocalData] = useState([]);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcirculationstats = '/getcirculationstats';
    const getcirculationstatsurl = baseUrl + dynamicgetcirculationstats;


    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getcirculationstatsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);
   
    if (localData !== null) {
    return (
        <>
        <div className="dashboardlibadminds">
        <div className="dashboardlibadmindsgerne">
                <h2>Library Dashboard</h2>
                
                <div className="dashboardlibadmindsgerneblock">
                    <div className="dashboardlibadmindsactualgerne">
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormal'>Circulation
                                <br></br>
                                <span className="libadmindsnumber">{localData.circnum}</span> 
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(localData.notapprovednum/localData.circnum) < 0.1 ? "dashboardlibadmindsrobotgreen" :(localData.notapprovednum/localData.circnum) < 0.2 ? "dashboardlibadmindsrobotorange" : "dashboardlibadmindsrobotred" }>Not Approved
                                <br></br>
                                <span className="libadmindsnumber">{localData.notapprovednum}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(localData.approvednum/localData.circnum) > 0.9 ? "dashboardlibadmindsrobotgreen" :(localData.approvednum/localData.circnum) > 0.8 ? "dashboardlibadmindsrobotorange" : "dashboardlibadmindsrobotred" }>Approved
                                <br></br>
                                <span className="libadmindsnumber">{localData.approvednum}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(localData.overduenum) > 5 ? "dashboardlibadmindsrobotred" :(localData.overduenum) > 0 ? "dashboardlibadmindsrobotorange" : "dashboardlibadmindsrobotred" }>Overdue
                                <br></br>
                                <span className="libadmindsnumber">{localData.overduenum}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormal'>Reserved
                                <br></br>
                                <span className="libadmindsnumber">100</span>
                            </p> 
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(localData.lostnum) > 5 ? "dashboardlibadmindsrobotgreen" :(localData.lostnum) > 0 ? "dashboardlibadmindsrobotorange" : "dashboardlibadmindsrobotred" }>Lost Books
                                <br></br>
                                <span className="libadmindsnumber">{localData.lostnum}</span>
                            </p>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
        </>
    );
    };

    
}
        
        
export default Dashboardp;