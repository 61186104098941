import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/borrowbooks/searchphybook.css';
import Searchphybooks from '../pagecomponents/borrowbooks/searchphybook';

import '../pagecomponents/borrowbooks/phybcategories.css';
import PhyBookcategories from '../pagecomponents/borrowbooks/phybcategories';

import '../pagecomponents/borrowbooks/phycatblock.css';
import Physicalcatblock from '../pagecomponents/borrowbooks/phycatblock';

import '../pagecomponents/borrowbooks/phypages.css';
import Physicalpagesblock from '../pagecomponents/borrowbooks/phypages';





//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Physicalcategoryp() {
    return (
        <>
            <Libnavbar />

            <Searchphybooks />

            <PhyBookcategories />

            <Physicalcatblock />

            <Physicalpagesblock />

        </>
    )
}

export default Physicalcategoryp;