import React, { useState, useEffect } from 'react';
import './studentstatus.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'; // Importing date picker component
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import {Bar} from "react-chartjs-2";

//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function StudentStatuslp() {
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkrole, setCheckrole] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [localData,  setlocalData] = useState([]);
    const [memberData,  setMemberData] = useState([]);
    const [filterby, setfilterby] = useState("");

    const[checkgradestud, setCheckgradestud] = useState(false);
    const[checkrolestud, setCheckrolestud] = useState(false);
    const[checknamestud, setChecknamestud] = useState(false);
    const[searchtermstud, setSearchtermstud] = useState("");
    const [filterbystud, setfilterbystud] = useState("");

    //const[bookId, setBookId] = useState(""); showPopupnobook
    const currentdate = moment().tz('Africa/Harare').toDate();
    console.log("today is", currentdate)


    // Function to handle date selection
    const handleDateChange = (date) => {
      //setSelectedDate(date);
      setClbookDetails({...clbookDetails, startDate: date})
    };

    const handlereturnDateChange = (date) => {
        //setSelectedDate(date);
        setClbookDetails({...clbookDetails, endDate: date})
    };
  

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcirculatinghist = '/getstudentstatshistory';
    const getcirculationhistsurl = baseUrl + dynamicgetcirculatinghist;

    const dynamicgetmember = '/getmemberstudents';
    const getmemberurl = baseUrl + dynamicgetmember;

    ///////--------------/searchonlinebooks  findcirculatingbooks

    const start = moment().subtract(31, 'days').startOf('day').toDate();
    const end = moment().endOf('day').toDate();

    const formattedStartDate = moment().subtract(31, 'days').format('DD MMMM YYYY');
    const formattedEndDate = moment().format('DD MMMM YYYY');
    
    const [bookDetails, setbookDetails] = useState({
        startDate: start,
        endDate: end,
        writeStartDate: formattedStartDate,
        writeEndDate: formattedEndDate,
        userid: "",
        editcheck: "off",
    });

    const [clbookDetails, setClbookDetails] = useState({
        startDate: "",
        endDate: "",
    });

    const [memberDetails, setmemberDetails] = useState({
        userole: "",
        username: "",
        names: "",
        surname: "",
        dob: "",
        Grade: "",
        GradeClass: "",
        term: "",
        GName: "",
        GPhoneNum: "",
        Email : "",
        Department: "",
        Position: "",
        Address: "",
        PhoneNum: "",
        memberid: "",
        password: "",
        editcheck: "off"
    });

    const [getmembers, setgetmembers] = useState({
        userole: "student"
    });

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getmemberurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ getmembers })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setMemberData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            const reqtype = "firstload";
            const newuser = "";
            await fetch(getcirculationhistsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({newuser, bookDetails, reqtype })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data.var2);
                
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    /*function handlescanedit() {
        //e.preventDefault();
        console.log("find book section running", bookId);
        

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(findcirculatingbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookId })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data to manipulate db",data)
                //setlocalData(data);
      
                //setContentData(data);
                
                closepopup();
                
              
            });
            
          };
      
          fetchContents();

          
        
    }*/
  
  

    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("author")
    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("name")
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("category")
    
    }

    /*--------------Student check box--------------------*/

    function handlegradechangestud(e) {
        setCheckgradestud(!checkgradestud)
        setChecknamestud(false)
        setCheckrolestud(false)
        setfilterbystud("grade")
    }

    function handlenamechangestud(e) {
        setChecknamestud(!checknamestud)
        setCheckgradestud(false)
        setCheckrolestud(false)
        setfilterbystud("username")
    }

    function handlerolechangestud(e) {
        setCheckrolestud(!checkrolestud)
        setCheckgradestud(false)
        setChecknamestud(false)
        setfilterbystud("role")
    
    }


    function handlestudentclick(user) {
        
        setCheckrole(false)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("")
        setSearchterm("")
        setbookDetails({...bookDetails, 
            writeStartDate: formattedStartDate,
            writeEndDate: formattedEndDate,
            userid: user
        })
        setClbookDetails({clbookDetails,
            startDate: "",
            endDate: ""
        })

        console.log("data contents user id here",user)
        const newuser = user;

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            const reqtype = "firstload"
            await fetch(getcirculationhistsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ newuser,bookDetails, reqtype })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents click data db",data)
                setlocalData(data.var2);
                
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
    
    }





    /*--------------Student check box--------------------*/

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            var reqtype = "";
            var newuser = bookDetails.userid;
            if (clbookDetails.startDate === ""){
                reqtype = "firstload";
            }else{
                reqtype = "adddate";
            }
            
            await fetch(getcirculationhistsurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({newuser, reqtype, bookDetails, clbookDetails, filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data.var2);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleSearchStudentSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            
            await fetch(getmemberurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ getmembers, filterbystud, searchtermstud })
            })
              .then(response => response.json())
              .then(data => {
                //console.log("data memberdata db",data)
                setMemberData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleAddSubmit(e) {
        e.preventDefault();
        setCheckrole(false)
        setCheckgrade(false)
        setCheckname(false)
        setSearchterm("");
        setfilterby("");
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            const reqtype = "adddate"
            const newuser = bookDetails.userid;
            await fetch(getcirculationhistsurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({newuser, clbookDetails, bookDetails, reqtype }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data.var2);
                setbookDetails({ ...bookDetails, 
                    writeStartDate: data.var1,
                    writeEndDate: data.var3,
                    
                });
                //setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            //setaddPopup(true);
            //clear();
            
            
        };
    
        Addquestion();

       

    }


    if (localData !== null) {

        const categoryCounts = localData.reduce((acc, item) => {
            acc[item.category] = (acc[item.category] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);
      
        // Take the first 10 categories just changed categories
        const topCategories = sortedCategories.slice(0, 1);
        const countCategories = sortedCategories.length;
        const circulationnumber = localData.length;
        console.log("the circulation number", circulationnumber)
        //console.log("topCategories",categoryCounts) 

        const titleCounts = localData.reduce((acc, item) => {
            acc[item.title] = (acc[item.title] || 0) + 1;
            return acc;
        }, {});

        const sortedTitle = Object.keys(titleCounts).sort((a, b) => titleCounts[b] - titleCounts[a]);
        const topTitle = sortedTitle.slice(0, 1);

        const userCounts = localData.reduce((acc, item) => {
            acc[item.userid] = (acc[item.userid] || 0) + 1;
            return acc;
        }, {});

        const sortedUser = Object.keys(userCounts).sort((a, b) => userCounts[b] - userCounts[a]);
        const countUser = sortedUser.length;
       


        const classgradeCounts = localData.reduce((acc, item) => {
            acc[item.classgrade] = (acc[item.classgrade] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedClassgrade = Object.keys(classgradeCounts).sort((a, b) => classgradeCounts[b] - classgradeCounts[a]);

        const booksborrCounts = localData.reduce((acc, item) => {
            acc[item.title] = (acc[item.title] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedBooksborr = Object.keys(booksborrCounts).sort((a, b) => booksborrCounts[b] - booksborrCounts[a]);


        const userscard = memberData.map((post) =>
            <Link
                            
            onClick={(e) => handlestudentclick(post.memberId)}
            
            style={{ textDecoration: 'none', color: 'black' }}

            key={post._id}
            
            >
            <div className="libuserdettableaddmbrstudstatus" >
                <div className={bookDetails.userid === post.memberId ? "libuserbktableaddmbrstudstatuscontblue" : "libuserbktableaddmbrstudstatuscont"}>
                    <div className='libdetnameaddmbrstudstatus'>
                        <p>{post.names}</p>
                    </div>
                    <div className='libdetsurnameaddmbrstudstatus'>
                        <p>{post.surname}</p>
                    </div>
                    <div className='libdetgradeaddmbrstudstatus'>
                        <p>{post.gradeclass}</p>
                    </div>
                    <div className={post.activepage === "true" ? 'libdetuseridaddmbrstudstatusgreen' : 'libdetuseridaddmbrstudstatusred'}>
                        <p>{post.currentpage}{post.activepage}</p>
                    </div>
                    <div className={(post.currentpage === "/onlinedisplay" && post.activepage === "true") ? 'libdetpasswordaddmbrstudstatusgreen' : 'libdetpasswordaddmbrstudstatusred' }>
                        <p>{post.openedbook}</p>
                    </div>
                </div>
            </div>
            </Link>
        )



        const getbookscard = localData.map((post) => 
            <div className="libuserdetbktablestudstatus" key={post._id}>
                <div className="libuserdetbktablestudstatuscont">
                    <div className='libdetbknamestudstatus'>
                        <p>{post.title}</p>
                    </div>
                    <div className='libdetbksurnamestudstatus'>
                        <p>{post.names}</p>
                    </div>
                    <div className='libdetbkgradestudstatus'>
                        <p>{post.classgrade}</p>
                    </div>
                    <div className='libdetbkuseridstudstatus'>
                        <p>{(post.outdate).split("T")[0]}</p>
                    </div>
                    <div className='libdetbkpasswordstudstatus'>
                        <p>{(post.returndate).split("T")[0]}</p>
                    </div>
                    <div className='libdetbkpassword2studstatus'>
                        <p>{post.status}</p>
                    </div>
                </div>
            </div>
        )





    return (
        <>

            <div className="viewlibmembersectaddmbrstudstatus">
                <div className="viewlibmemberboxaddmbrstudstatus">
                    <h2>Student Status</h2>
                    <div className="libusertableaddmbrstudstatus">
                        <div className="libuserbktableaddmbrstudstatuscont">
                            <div className='liblblnameaddmbrstudstatus'>
                                <p>Names</p>
                            </div>
                            <div className='liblblsurnameaddmbrstudstatus'>
                                <p>Surname</p>
                            </div>
                            <div className='liblblgradeaddmbrstudstatus'>
                                <p>Grade</p>
                            </div>
                            <div className='liblbluseridaddmbrstudstatus'>
                                <p>Current Page</p>
                            </div>
                            <div className='liblblpasswordaddmbrstudstatus'>
                                <p>Opened book</p>
                            </div>
                        </div>
                        
                        {userscard}
                        
                    </div>  
                    <div className="filterosectaddmbrstudstatus">
                        <h2>Filter by:</h2>
                        <div className="filterboxaddmbrstudstatus">
                        <div className='form-groupaddmbrstudstatus'>
                                <span>Role:</span>
                                <input className='byrole' name="byrole" checked={checkrolestud} type="checkbox" onChange={handlerolechangestud} />
                            </div> 
                            <div className='form-groupaddmbrstudstatus'>
                                <span>Grade:</span> 
                                <input className='bygrade' name="bygrade" checked={checkgradestud} type="checkbox" onChange={handlegradechangestud} />
                            </div>
                            <div className='form-groupaddmbrstudstatus'>
                                <span>Name:</span>
                                <input className='byname' name="byname" checked={checknamestud} type="checkbox" onChange={handlenamechangestud} />
                            </div>   
                            <div className='searchboxaddmbrstudstatus'>
                                <input type="text" name="search" onChange={(e) => setSearchtermstud(e.target.value)} />
                                <button onClick={handleSearchStudentSubmit}  className = {(!checkgradestud && !checknamestud && !checkrolestud) ? "searchbkboxaddmbrstudstatusbtn" : "searchbkboxaddmbrstudstatusbtnactive"} disabled={(!checkgradestud && !checknamestud && !checkrolestud)}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

















        <div className="addlibmemberpagestudstatus">
            <div className="addlibmembersectstudstatus">
                <div className="addlibmemberboxstudstatus">
                   
                    <h2>Search Period</h2>
            
                    <div className='addlibmembertextboxstudstatus'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetailsstudstatus">
                             
                                <DatePicker
                                    selected={clbookDetails.startDate} 
                                    onChange={handleDateChange}
                                    className="libuserbkgradestudstatus"
                                    name="libuserbkgrade"
                                    placeholderText="Start Date"
                                    dateFormat="yyyy-MM-dd" // Date format
                                    showYearDropdown // Show year dropdown in the date picker
                                    showMonthDropdown // Show month dropdown in the date picker
                                    dropdownMode="select" // Dropdown mode for year and month dropdowns
                                    autoComplete="off"
                                />

                                <DatePicker
                                    selected={clbookDetails.endDate}
                                    onChange={handlereturnDateChange}
                                    className="libuserbkgradestudstatus"
                                    name="libuserbkgrade"
                                    placeholderText="End Date"
                                    dateFormat="yyyy-MM-dd" // Date format yyyy-MM-dd'T'HH:mm:ss.SSSxxx
                                    showYearDropdown // Show year dropdown in the date picker
                                    showMonthDropdown // Show month dropdown in the date picker
                                    dropdownMode="select" // Dropdown mode for year and month dropdowns
                                    autoComplete="off"
                                />
                               
                                { bookDetails.editcheck === "off" &&
                                <>
                                <button className='addbtnstudstatus'>Search</button>
                                </> 
                               
                                }
                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectstudstatus">
                <div className="viewlibmemberboxstudstatus">
                    <h2>Borrowing History: <span className="viewlibmemberboxstudstatusspan">{bookDetails.writeStartDate} - {bookDetails.writeEndDate}</span></h2>
                    <div className="libuserbktablestudstatus">
                        <div className="libuserbktablestudstatuscont">
                            <div className='liblblbknamestudstatus'>
                                <p>Title</p>
                            </div>
                            <div className='liblblbksurnamestudstatus'>
                                <p>Name</p>
                            </div>
                            <div className='liblblbkgradestudstatus'>
                                <p>Class</p>
                            </div>
                            <div className='liblblbkuseridstudstatus'>
                                <p>OutDate</p>
                            </div>
                            <div className='liblblbkpasswordstudstatus'>
                                <p>ReturnDate</p>
                            </div>
                            <div className='liblblbkpassword2studstatus'>
                                <p>Status</p>
                            </div>
                        </div>
                        {getbookscard}
                        
                    </div>  
                    <div className="filterosectstudstatus">
                        <h2>Filter by:</h2>
                        <div className="filterboxstudstatus">
                            <div className='form-groupstudstatus'>
                                <span>Title:</span> 
                                <input className='bybkgrade' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-groupstudstatus'>
                                <span>Name:</span>
                                <input className='bybkname' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>  
                            <div className='form-groupstudstatus'>
                                <span>Class:</span>
                                <input className='bybkrole' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div>  
                            <div className='searchbkboxstudstatus'>
                                <input type="text" name="search"  value={searchterm} onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}  className = {(!checkgrade && !checkname && !checkrole) ? "searchbkboxstudstatusbtn" : "searchbkboxstudstatusbtnactive"} disabled={(!checkgrade && !checkname && !checkrole)}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>



        <div className="dashboardlibadmindscircstudstatus">
        <div className="dashboardlibadmindsgernecircstudstatus">
                <h2>Dashboard</h2>
                
                <div className="dashboardlibadmindsgerneblockcircstudstatus">
                    <div className="dashboardlibadmindsactualgernecircstudstatus">
                        <Link 
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcircstudstatus'>Borrowed Books
                                <br></br>
                                <span className="libadmindsnumbercircstudstatus">{circulationnumber}</span> 
                            </p>
                        </Link>
                        <Link 
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcircstudstatus'>Gernes
                                <br></br>
                                <span className="libadmindsnumbercircstudstatus">{countCategories}</span> 
                            </p>
                        </Link>
                        <Link 
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcircstudstatus' >Popular Book
                                <br></br>
                                <span className="libadmindsbookcirchitsdb">{topTitle}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcircstudstatus'>Popular Gerne
                                <br></br>
                                <span className="libadmindsbookcircstudstatus">{topCategories}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcircstudstatus'>Students
                                <br></br>
                                <span className="libadmindsnumbercircstudstatus">{countUser}</span> 
                            </p>
                        </Link>
                       
                    </div>

                </div>
            </div>
        </div>


        <div className="detailsgraphadminstudstatus">
            <h2>Gernes Borrowed</h2> 
            <div className="customerCardstudstatus">
                <Bar
                    data={{
                        labels: sortedCategories,
                        datasets: [
                            {
                                label: "Gerne",
                                data: sortedCategories.map(category => categoryCounts[category]),
                                backgroundColor: [
                                    "rgba(160, 32, 240, 0.5)"
                                ],
                                borderRadius: 5,
                            },
                        ]
                    }}
                />  
            </div>  
        </div>


        <div className="detailsgraphadminstudstatus">
            <h2>Books Borrowed</h2> 
            <div className="customerCardstudstatus">
                <Bar
                    data={{
                        labels: sortedBooksborr,
                        datasets: [
                            {
                                label: "Class",
                                data: sortedBooksborr.map(title => booksborrCounts[title]),
                                backgroundColor: [
                                  "rgba(160, 32, 240, 0.5)"
                                ],
                                borderRadius: 5,
                            },
                        ]
                    }}
                />  
            </div>  
        </div>






        </>
    );

    }
}
        
        
export default StudentStatuslp;
        
        