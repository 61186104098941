import React, { useState, useEffect } from "react";
import "./onlinedisplay.css";
import { useLocation } from "react-router-dom";


const Onlinedisplaybook = () => {
  const [userRole, setUserRole] = useState(null);

  const location = useLocation();

  const pdfUrl = location.state.data.pdflink;
  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicrequireAuth = '/requireAuth';
  const requireAuthurl = baseUrl + dynamicrequireAuth;  

  const dynamicopenedbook = '/updateopenedbook';
  const openedbookurl = baseUrl + dynamicopenedbook;

  useEffect(() => {
    const fetchRoles = async () => {
      //await dispatch(getOutlines(parsedObj));
      /*await fetch(requireAuthurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        
      })
        .then(response => response.json())
        .then(data => {
          console.log("data contents db",data)  
          setUserRole(data)
      });

      console.log(fetchRoles)*/

      const response = await fetch(requireAuthurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        
      });
      const data = await response.json();
      const userRole = data; // Assign the data to a constant
      console.log(userRole.userid);

      const update = await fetch(openedbookurl,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ pdfUrl, userRole })
      })

    }
    fetchRoles();
 
  }, [pdfUrl]);

  /*useEffect(() => {

    if (userRole !== null) {
      
    console.log(`your opened book url is ${pdfUrl} and the user role is ${userRole.userid}`)

    const fetchContents = async () => {
    //await dispatch(getOutlines(parsedObj));
    await fetch(openedbookurl,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ pdfUrl, userRole })
    })
      .then(response => response.json())
      .then(data => {
        //console.log("data contents fetched books db",data)
        //setlocalData(data);

        //setContentData(data);
        
      
    });
    
  };

  fetchContents();
}
 
    
 
 }, [pdfUrl]);*/
   

  return (
    <>
       <div className="mainonlinebookdisplay">
            <div className="onlinepdfcontain">
                <iframe src={pdfUrl}></iframe>
            </div>  
        </div>
    </>
  );
};

export default Onlinedisplaybook;
