import React from "react";
//import { Link } from 'react-router-dom';

import Sidebarportals from "./psidebar";
import Portalscontrol from "./portalcontrol";
import "./portalscomb.css";


function Portalscomb() {
    
    return (
        <>
        <div className="Portalcombmain">

            <Sidebarportals />

            <Portalscontrol />
            
        </div>
        </>
      
    );
    
    
}


  
export default Portalscomb;