import React from 'react';
import '../pagecomponents/phome/navbarp.css';
import Navbar from '../pagecomponents/phome/navbarp';
import '../pagecomponents/phome/sidebar.css';
import Sidebar from '../pagecomponents/phome/sidebar';
import '../pagecomponents/phome/subject.css';
import Subject from '../pagecomponents/phome/subject';

//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Homep() {
    return (
        <>
            <Navbar />

            <Sidebar />

            <Subject />

        </>
    )
}

export default Homep;