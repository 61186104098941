import React, { useState } from "react";
import "./onlinelibrary.css";
import { useLocation, Link } from 'react-router-dom';

const OnlineLibrary = () => {

    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    const location = useLocation();

    const booktype = location.state.data.typebook;

    



  return (
    <>
       <div className="onlinelibrarylibmain">
            <div className="onlinelibgerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Categories  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Categories  &#9776;</h2>
                }
                <div className={`onlinegerneblock ${showGerne ? 'show-gerne' : ''}`}>
                    <div className="actualgerne">
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "picture book" ,catname: "Picture Book", nxtpage: 1, searchactive: "off"  }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Picture Book</p> 
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "early readers" ,catname: "Early Readers", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Early Readers</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "chapter books" ,catname: "Chapter Books", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Chapter Books</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "fairy tale" ,catname: "Fairy Tale", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Fairy Tale</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "adventure" ,catname: "Adventure", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Adventure</p> 
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "mystery" ,catname: "Mystery", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Mystery</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "fantasy" ,catname: "Fantasy", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Fantasy</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "science fiction" ,catname: "Science Fiction", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Science Fiction</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "historical" ,catname: "Historical", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Historical</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: {typebook: booktype, categoryview: "horror" ,catname: "Horror", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Horror</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "biography" ,catname: "Biography", nxtpage: 1, searchactive: "off"  }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Biography</p> 
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "poetry" ,catname: "Poetry", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Poetry</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "animal story" ,catname: "Animal Story", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Animal Story</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "humor" ,catname: "Humor", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Humor</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "friendship" ,catname: "Friendship", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Friendship</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "math" ,catname: "Mathematics", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Math</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "ndebele" ,catname: "Ndebele", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Ndebele</p>
                        </Link>
                        <Link 
                            to="/onlinecategory" 
                            
                            state = {{
                                data: {typebook: booktype, categoryview: "heritage" ,catname: "Heritage", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Heritage</p>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    </>
  );
};

export default OnlineLibrary;