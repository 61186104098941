import React, { useState, useEffect } from "react";
import "./scanqrcode.css";
import { Html5QrcodeScanner } from 'html5-qrcode';
//import { Link} from "react-router-dom";

const Scanqrcodephybk = () => {

  const [showScanner, setShowScanner] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [html5QrCode, setHtml5QrCode] = useState(null);

    useEffect(() => {
      if (showScanner) {
        const html5QrCode = new Html5QrcodeScanner(
          'qr-reader', // element id for scanning
          { fps: 10, qrbox: 250, cameraId: { facingMode: 'environment' } } // optional configuration
        );
    
        html5QrCode.render(onScanSuccess);
        setHtml5QrCode(html5QrCode);
      }
       
      }, [showScanner]);

    function deletebookyes (e) {
        e.preventDefault();
        setShowPopup(true);
        setShowScanner(true);

        /*const html5QrCode = new Html5QrcodeScanner(
          'qr-reader', // element id for scanning
          { fps: 10, qrbox: 250 } // optional configuration
        );
    
        html5QrCode.render(onScanSuccess);*/
      
       
    }

    const onScanSuccess = (decodedText, decodedResult) => {
      // Handle the decoded result, for example, you can set it in state
      console.log("check url here",decodedText);
      console.log(decodedResult);
      //window.location.href = JSON.stringify(decodedText); //JSON.stringify(decodedResult);    //codedResult;

      /*const urlnew = decodedText;

      // Parse the URL to get the query parameters
      const queryString = urlnew.split('?')[1];

      // Parse the query string to get the variable after the '=' sign
      const queryParams = new URLSearchParams(queryString);
      const bookId = queryParams.get('bookId');
      console.log("view book id", bookId)

      const firstNineLetters = bookId.substring(0, 9); 
      const firsturl = decodedText.substring(1, 54); 
      console.log("check book id 2", firsturl);*/

      const removedurl = decodedText.substring(1, decodedText.length - 1);
      console.log("final url", removedurl)



      window.location.href = removedurl; 


      setShowScanner(false);
    };

    function closepopup (e) {
      e.preventDefault();
      setShowPopup(false);
      setShowScanner(false);

      if (html5QrCode) {
        html5QrCode.clear(); // Turn off the camera
      }
    
     
  }

   

    /*const handleScan = (data) => {
        if (data) {
          setQRCodeResult(data);
          console.log("running error", data);
        }
        console.log("running error", data);
       

      };
    
      const handleError = (error) => {
        console.error(error);
        console.log("running error");
      };*/
  

  return (
    <>
       <div className="scanqrcodemain">
          {showPopup && (
            <div className="popupaddscan">
              <div className="popup-contentaddscan">
                  <div id="qr-reader" style={{ width: '100%', height: '100%' }}></div>
                  <button className="deletebuttonaddbk" onClick={closepopup}>Close</button>
              </div>
            </div>
          )}  
            
            <h2 onClick={deletebookyes}>Scan Qrcode</h2>
        </div>
    </>
  );
};

export default Scanqrcodephybk;