const subjects = (subjects= {subjects:null, loading: false}, action) => {
    //console.log("logging data in posts.js",posts)

    switch (action.type){
        case 'UPDATE':
            return subjects.map((post)=> post._id === action.payload._id ? action.payload : post)
            
        case 'FETCH_ALL':
            return {loading: false, subjects:action.payload};
        case 'CREATE':
            return [...subjects, action.payload];
        default:
            return subjects;
    }
    
}

export default subjects;