import React from "react";
import './clubsintro.css';

const Clubsintro = () => {
  return (
    <>
        <div className="clubsintrocont">
            <div className="clubsintrostatement">
                <h2>Clubs</h2>
            </div>
        </div>
         
    </>
  );
};

export default Clubsintro;