import React from 'react';
import '../pagecomponents/addbooks/navbarlibrian.css';
import Navbarlibrian from '../pagecomponents/addbooks/navbarlibrian.js';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';
import '../pagecomponents/circulation/circulation.css';
import CirculatingBooks from '../pagecomponents/circulation/circulation.js';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Circulatingbooksp() {
    return (
        <>
            <Navbarlibrian />

            <CirculatingBooks />




        </>
    )
}

export default Circulatingbooksp;