import React from 'react';

import PTAproductinfo from '../pagecomp/PTA/ptaprodinfo/ptaprodinfo';
import '../pagecomp/PTA/ptaprodinfo/ptaprodinfo.css';


import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function PTAproductpage() {
    return (
        <>
            <NavbarMs />

            <PTAproductinfo />

            <Footer />
        </>
    )
}

export default PTAproductpage;