import React from "react";
import './academicintro.css';

const Academicintro = () => {
  return (
    <>
        <div className="academicintrocont">
            <div className="academicintrostatement">
                <h2>Curriculum</h2>
            </div>
        </div>
         
    </>
  );
};

export default Academicintro;