import React from 'react';
import '../pagecomponents/thome/navbarte.css';
import Navbar from '../pagecomponents/thome/navbarte';
import Assignaddsec from '../pagecomponents/adtest/addtestassign';



//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Addstart() {
    return (
        <>
            <Navbar />

            <Assignaddsec />
            
        </>
    )
}

export default Addstart;