import React, { useState, useEffect } from 'react';
import './phybookstats.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function PhyBookStatsp() {

    const [booksData,  setbooksData] = useState([]);
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };


    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetphybookstats = '/getbooks';
    const getphybookstatsstatsurl = baseUrl + dynamicgetphybookstats;


    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getphybookstatsstatsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setbooksData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);
   
    if (booksData !== null) {
        const numbooks = booksData.length;

        const categoryCounts = booksData.reduce((acc, item) => {
            acc[item.category] = (acc[item.category] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);
      
        // Take the first 10 categories just changed categories
        const topCategories = sortedCategories.slice(0, 1);
        const countCategories = sortedCategories.length;
    return (
        <> 
        <div className="dashboardphybkstats">
        <div className="dashboardphybkstatsgerne">
                <h2>Library Books Dashboard</h2>
                
                <div className="dashboardphybkstatsonegerneblock">
                    <div className="dashboardphybkstatsactualgerne">
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardphybkstatsrobotnormal'>Total Books
                                <br></br>
                                <span className="phybkstatsnumber">{numbooks}</span> 
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardphybkstatsrobotnormal'>Gernes with books
                                <br></br>
                                <span className="phybkstatsnumber">{countCategories}</span>
                            </p>
                        </Link>
                       
                    </div>

                </div>
            </div>
        </div>


        <div className="dashboardphybkstats">
        <div className="dashboardphybkstatsgerne">
            {showGerne ?
                <h2 onClick={toggleMenu}>Books in Genre  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Books in Genre  &#9776;</h2>
                }
               
                <div className={`dashboardphybkstatsgerneblock ${showGerne ? 'dashboardphybkstatsshow-gerne' : ''}`}>
                    <div className="dashboardphybkstatsactualgerne">
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'arts').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'arts').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Arts
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'arts').length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'music').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'music').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Music
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'music').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'atlases').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'atlases').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Atlases
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'atlases').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'dinosaurs').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'dinosaurs').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Dinosaurs
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'dinosaurs').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'plants').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'plants').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Plants
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'plants').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'natural sciences').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'natural sciences').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Natural Sciences
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'natural sciences').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'space').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'space').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Space
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'space').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'magazine and journals').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'magazine and journals').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Magazine and Journals
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'magazine and journals').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'mammals').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'mammals').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Mammals
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'mammals').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'biography').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'biography').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Biography
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'biography').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'reptiles').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'reptiles').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Reptiles
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'reptiles').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'insects').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'insects').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Insects
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'insects').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'fish').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'fish').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Fish
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'fish').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'geography').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'geography').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Geography
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'geography').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'isndebele').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'isndebele').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Isndebele
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'isndebele').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'science').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'science').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Science
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'science').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'mathematics').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'mathematics').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Mathematics
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'mathematics').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'history').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'history').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>History
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'history').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'home economics').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'home economics').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Home Economics
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'home economics').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'english').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'english').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>English
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'english').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'general knowledge').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'general knowledge').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>General Knowledge
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'general knowledge').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'miscellaneous').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'miscellaneous').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Miscellaneous
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'miscellaneous').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'historical fantasy').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'historical fantasy').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Historical Fantasy
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'historical fantasy').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'mystery').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'mystery').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Mystery
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'mystery').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'science fiction').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'science fiction').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Science Fiction
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'science fiction').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'horror').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'horror').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Horror
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'horror').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'fiction').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'fiction').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Fiction
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'fiction').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'adventure fiction').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'adventure fiction').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Adventure Fiction
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'adventure fiction').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'classics').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'classics').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Classics
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'classics').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'humor').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'humor').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Humor
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'humor').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'poetry').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'poetry').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Poetry
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'poetry').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'fairy tale').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'fairy tale').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Fairy Tale
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'fairy tale').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'mythology').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'mythology').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Mythology
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'mythology').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'adult fiction').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'adult fiction').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Adult Fiction
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'adult fiction').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'picture book').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'picture book').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Picture Book
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'picture book').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'fantasy').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'fantasy').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Fantasy
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'fantasy').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'sports').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'sports').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Sports
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'sports').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'realistic fiction').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'realistic fiction').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Realistic Fiction
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'realistic fiction').length}</span>
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => item.category === 'easy to read').length) < 5 ? "dashboardphybkstatsrobotred" :(booksData.filter(item => item.category === 'easy to read').length) < 10 ? "dashboardphybkstatsrobotorange" : "dashboardphybkstatsrobotnormal" }>Easy to Read
                                <br></br>
                                <span className="phybkstatsnumber">{booksData.filter(item => item.category === 'easy to read').length}</span>
                            </p>
                        </Link>
                       
                        
                        
                       
                    </div>

                </div>
            </div>
        </div>
        </>
    );
    };

    
}
        
        
export default PhyBookStatsp;