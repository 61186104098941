import React, { useState } from "react";
import "./gotobutton.css";
import { Link } from 'react-router-dom';

const Gotobuttonp = () => {
  return (
    <>
        <div className="maingotobutton">
            <Link
              to = "/onlinelibcat"
              state = {{
                data: {typebook: "Audio" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
              }}
              style={{ textDecoration: 'none', color: 'black', padding: 0}}
            >
              <div className="gotoaudioing">
                <h2>Listen to audio books<i className="fas fa-arrow-right"></i></h2>
              </div>
            </Link>

            <Link
              to = "/onlinelibcat"
              state = {{
                data: {typebook: "Video" }  //borrowbook your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
              }}
              style={{ textDecoration: 'none', color: 'black', padding: 0}}
            >
              <div className="gotovisualling">
                <h2>Watch Audio Visual Book<i className="fas fa-arrow-right"></i></h2> 
              </div>
            </Link>  
        </div>
    
        
    </>
  );
};

export default Gotobuttonp;