import React, { useState, useEffect } from "react";
import "./phypages.css";
import { Link, useLocation } from "react-router-dom";

const Phypagesp = () => {
    const [localData,  setlocalData] = useState([]);
    const [totalbooks,  setTotalbooks] = useState([]);


    const location = useLocation();

   
    const categoryview =  location.state.data.categoryview;
    var pagen = location.state.data.nxtpage;
    const numboksperpage = 24;
    const searchactive = location.state.data.searchactive;
    const searchterm = location.state.data.searchterm;
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetphycatgridbooks = '/getphycatgridbooks';
    const getphycatgridbooksurl = baseUrl + dynamicgetphycatgridbooks;

    const dynamicsearchphycatgridbooks = '/searchphycatgridbooks';
    const searchphycatgridbooksurl = baseUrl + dynamicsearchphycatgridbooks;

    if (pagen < 1){
        pagen = 1;
    }

    const [currentPage,  setcurrentPage] = useState({
        pagen: pagen
    })

    

    
    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        // <a href="/onlinelibinfo">
        if (searchactive === "off") {
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getphycatgridbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ categoryview })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setTotalbooks(data.length);
                setlocalData(data);

      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
        }else if (searchactive === "on") {
            const fetchContents = async () => {
                //await dispatch(getOutlines(parsedObj));
               
                await fetch(searchphycatgridbooksurl, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ searchterm })
                })
                  .then(response => response.json())
                  .then(data => {
                    console.log("data contents db",data.length)
                    setTotalbooks(data.length);
                    setlocalData(data);
          
                    //setContentData(data);
                    
                  
                });
                
              };
          
              fetchContents();

        }
      
         
           
      
    }, [categoryview, searchterm]);

    useEffect(() => {

       
        setcurrentPage({...currentPage, pagen : pagen});
        
    
       
    }, [pagen]);





if (totalbooks !== null) {
    const numofpage = totalbooks/numboksperpage;
    console.log("number of pages is",numofpage)
    var fnumofpage = Math.ceil(numofpage);
    var upperpagelimit = currentPage.pagen + 3;
    var lowerpagelimit = currentPage.pagen - 3;


    const pagescard = localData.map((post, index) =>
            <div className="books-cardB12" key={post._id}> 
                <div className="phyactualnumbers">
                    { (index > lowerpagelimit && index < upperpagelimit && index < fnumofpage) &&
                    <Link
                    to = "/phycategory"

                    onClick={() => window.location.reload() }

                    state = {{
            
                        data: { categoryview: categoryview, nxtpage: index+1, searchactive: searchactive, searchterm: searchterm} }}
                    
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p className={(currentPage.pagen === index + 1) ? 'phyclickedpage': 'phythepage'}>{index+1}</p>
                    </Link>
                    }
                </div>
                
            </div>
        )


        const pagebuttonscard = localData.map((post, index) =>
            <div className="books-cardB1" key={post._id}> 
                {index === currentPage.pagen &&
                <div className="phynextprevbtns">
                    <Link
                    to = "/phycategory"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: { categoryview: categoryview, nxtpage: 1, searchactive: searchactive, searchterm: searchterm} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>First |</p>
                    </Link>
                    {index !== 1 ?
                    <Link
                    to = "/phycategory"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: { categoryview: categoryview, nxtpage: index-1, searchactive: searchactive, searchterm: searchterm} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>Prev |</p>
                    </Link>
                    :
                    ""
                    }
                    {index !== fnumofpage ?
                    <Link
                    to = "/phycategory"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: { categoryview: categoryview, nxtpage: index+1, searchactive: searchactive, searchterm: searchterm} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>Next |</p>
                    </Link>
                    :
                    ""
                    }
                    <Link
                    to = "/phycategory"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: { categoryview: categoryview, nxtpage: fnumofpage, searchactive: searchactive, searchterm: searchterm} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>Last |</p>
                    </Link>
                </div> 
                }    
            </div>
        )

   

   

  return (
    <>
        <div className="phypagesmain">
            
            
            <div className="phynumbers">
                <p>Pages:</p>
                {pagescard}       
            </div>  
            {pagebuttonscard}
        </div>
    
        
    </>
  );
}
};

export default Phypagesp;