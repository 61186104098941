import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
import './Carasoule.css';
//import { Button } from './Button';
//import { NavLink } from "react-router-dom";

/*
  <div className="editcarasoulehome">
        <form onSubmit={handleSubmit}>
          <div className='changecarasoule'>
            <h2>Change carousel image</h2>
            <div className='ecarasoule1'> 
              <label>Image 1</label>
              <input type="text" name="carasoule1" defaultValue={localData[0].imagelink} onChange={(e) => setCarosuelimData({ ...carosuelimData, carousel1: e.target.value})} />
            </div>
            <div className='ecarasoule2'> 
              <label>Image 2</label>
              <input type="text" name="carasoule2" defaultValue={localData[1].imagelink } onChange={(e) => setCarosuelimData({ ...carosuelimData, carousel2: e.target.value})}/>
            </div>
            <div className='ecarasoule3'> 
             <label>Image 3</label>
              <input type="text" name="carasolue3" defaultValue={localData[2].imagelink } onChange={(e) => setCarosuelimData({ ...carosuelimData, carousel3: e.target.value})}/>
            </div>
            <button className="submitbuttoncarasoulep" type="submit">Submit</button>
          </div>
        </form>
      </div>

*/



const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [localData,  setlocalData] = useState([]);

  const [carosuelData, setCarosuelData] = useState({
      pagename: "Home"
  });

  const [carosuelimData, setCarosuelimData] = useState({
    carousel1: "",
    carousel2: "",
    carousel3: ""
  });

  const handleLchange = (e) => {
    const linkurl = e.target.value;
    const linkname = e.target.name;
    console.log("userabc", linkname+linkurl )

    if (linkname = "carousel1"){

    }else if (linkname = "carousel2"){

    }else if (linkname = "carousel3"){

    }
     
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    //dispatch(createEvent(eventData));

   
    if (carosuelimData.carousel1 === ""){
      setCarosuelimData({ ...carosuelimData, carousel1: localData[0].imagelink})
    }
    if (carosuelimData.carousel2 === ""){
      setCarosuelimData({ ...carosuelimData, carousel2: localData[1].imagelink})
    }
    if (carosuelimData.carousel3 === ""){
      setCarosuelimData({ ...carosuelimData, carousel3: localData[2].imagelink})
    }

    const updatecarasole = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/updatehomepagedet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ carosuelData, carosuelimData })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data contents db",data)
          
      });
      
    };

    updatecarasole();
    
    
    
  }

  useEffect(() => {
    
    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/gethomepagedet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ carosuelData })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data contents db",data)
          if (data.section.length > 0) {
            //console.log("logging data", data[0].subtopics);
            const contentcheck = data.section.map((post) => post.title)
            console.log("mapping",contentcheck);
            if (contentcheck !== null) {
              setlocalData(data.section);
            }else {
              fetchContents();
            }
            
              //localStorage.setItem('array', JSON.stringify(data));
          }

          //setContentData(data);
          
        
      });
      
    };

    fetchContents();

   
     

}, []);

console.log("localdata after db out",localData)

useEffect(() => {
  const interval = setInterval(() => {
    setActiveIndex((activeIndex + 1) % 3);
  }, 5000);

  return () => clearInterval(interval);
}, [activeIndex]);

const goToPrevSlide = () => {
  setActiveIndex(activeIndex === 0 ? 3 - 1 : activeIndex - 1);
};

const goToNextSlide = () => {
  setActiveIndex((activeIndex + 1) % 3);
};

if (localData.length !== 0){

  console.log("localdata after db in",localData)


const images = [
  { id: 1, src: localData[0].imagelink },
  { id: 2, src: localData[1].imagelink },
  { id: 3, src: localData[2].imagelink }
];


  return (
    <>
    <div className='carouselmain'>
      <div className="carousels">
        {images.map((image, index) => (
          <div
            key={image.id}
            className={`slides${index === activeIndex ? ' active' : ''}`}
            style={{ transform: `translateX(${(index - activeIndex) * 100}%)` }}
          >
            <img src={image.src} alt={`Slide ${index + 1}`} />
            <p className='caranumberid'>{image.id}</p>
          </div>
        ))}
       
        <button className="prevs" onClick={goToPrevSlide}>
          &#10094;
        </button>
        <button className="nexts" onClick={goToNextSlide}>
          &#10095;
        </button>
      </div>

      <div className="editcarasoulehome">
          <form onSubmit={handleSubmit}>
            <div className='changecarasoule'>
              <h2>Change carousel image</h2>
              <div className='ecarasoule1'> 
                <label>Image 1</label>
                <input type="text" name="carasoule1" defaultValue={localData[0].imagelink} onChange={(e) => setCarosuelimData({ ...carosuelimData, carousel1: e.target.value})} />
              </div>
              <button className="submitbuttoncarasoulep" type="submit">Submit</button>
            </div>
          </form>
          <form onSubmit={handleSubmit}>
            <div className='changecarasoule'>
              <div className='ecarasoule2'> 
                <label>Image 2</label>
                <input type="text" name="carasoule2" defaultValue={localData[1].imagelink } onChange={(e) => setCarosuelimData({ ...carosuelimData, carousel2: e.target.value})}/>
              </div>
              <button className="submitbuttoncarasoulep" type="submit">Submit</button>
            </div>
          </form>
          <form onSubmit={handleSubmit}>
            <div className='changecarasoule'>
              <div className='ecarasoule3'> 
              <label>Image 3</label>
                <input type="text" name="carasolue3" defaultValue={localData[2].imagelink } onChange={(e) => setCarosuelimData({ ...carosuelimData, carousel3: e.target.value})}/>
              </div>
              <button className="submitbuttoncarasoulep" type="submit">Submit</button>
            </div>
          </form>
      </div>
      
    </div>
    
    
    </>
  );

  }
};
  
export default Carousel;
  
  