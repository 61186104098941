import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/borrowbooks/phybookinfo.css';
import Borrowlibinfor from '../pagecomponents/borrowbooks/phybookinfo';

//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Borrowbookinfo() {
    return (
        <>
            <Libnavbar />

            <Borrowlibinfor />

        
        </>
    )
}

export default Borrowbookinfo;