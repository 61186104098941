import React from "react";
import "./psidebar.css";
import { Link } from 'react-router-dom';

function Psidebar() {

   // StuffTopic StuffOption

   /*
    <Link
            to = "/portals"

            state = {{
                data: {portalname: "Library" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
            }}
                                    
            style={{ textDecoration: 'none', color: 'black' }}
            >
           <div className="adminp">
                <p>Library</p>
           </div>
           </Link>


           <Link
            to = "/portals"

            state = {{
                data: {portalname: "Library" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
            }}
                                    
            style={{ textDecoration: 'none', color: 'black' }}
            >
           <div className="adminp">
                <p>Library</p>
           </div>
           </Link>



   */
  
    return (
      <>
      <div className="PSidebar-main">
        <div className="PAccountTopic">
            <p>Portals</p>
        </div>
        <div className="PAccountOption">
            <Link
            to = "/portals"

            state = {{
                data: {portalname: "Admin" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
            }}
                                    
            style={{ textDecoration: 'none', color: 'black' }}
            >
           <div className="adminp">
                <p>Admin</p>
           </div>
           </Link>
           <Link
            to = "/portals"

            state = {{
                data: {portalname: "Staff" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
            }}
                                    
            style={{ textDecoration: 'none', color: 'black' }}
            >
           <div className="adminp">
                <p>Staff</p>
           </div>
           </Link>
           <Link
            to = "/portals"

            state = {{
                data: {portalname: "Library" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
            }}
                                    
            style={{ textDecoration: 'none', color: 'black' }}
            >
           <div className="adminp">
                <p>Library</p>
           </div>
           </Link>
           <Link
            to = "/portals"

            state = {{
                data: {portalname: "PTA" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
            }}
                                    
            style={{ textDecoration: 'none', color: 'black' }}
            >
           <div className="adminp">
                <p>PTA</p>
           </div>
           </Link>
            
        </div>
          
      </div>
      </>
    );
  }
  
  export default Psidebar;