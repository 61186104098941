import React, { useState, useEffect } from 'react';
import './circhistory.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'; // Importing date picker component
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import {Bar} from "react-chartjs-2";

//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function CirculatingbookHistoryp() {
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkrole, setCheckrole] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [localData,  setlocalData] = useState([]);
    const [filterby, setfilterby] = useState("");

    //const[bookId, setBookId] = useState(""); showPopupnobook
    const currentdate = moment().tz('Africa/Harare').toDate();
    console.log("today is", currentdate)


    // Function to handle date selection
    const handleDateChange = (date) => {
      //setSelectedDate(date);
      setClbookDetails({...clbookDetails, startDate: date})
    };

    const handlereturnDateChange = (date) => {
        //setSelectedDate(date);
        setClbookDetails({...clbookDetails, endDate: date})
    };
  

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcirculatinghist = '/getcirculationhistory';
    const getcirculationhistsurl = baseUrl + dynamicgetcirculatinghist;

    ///////--------------/searchonlinebooks  findcirculatingbooks

    const end = moment().add(7, 'days').startOf('day').toDate();
    const start = moment().endOf('day').toDate();

    const formattedStartDate = moment().subtract(31, 'days').format('DD MMMM YYYY');
    const formattedEndDate = moment().format('DD MMMM YYYY');
    
    const [bookDetails, setbookDetails] = useState({
        startDate: start,
        endDate: end,
        writeStartDate: formattedStartDate,
        writeEndDate: formattedEndDate,
        editcheck: "off",
    });

    const [clbookDetails, setClbookDetails] = useState({
        startDate: "",
        endDate: "",
    });

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            const reqtype = "firstload"
            await fetch(getcirculationhistsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookDetails, reqtype })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data.var2);
                
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    /*function handlescanedit() {
        //e.preventDefault();
        console.log("find book section running", bookId);
        

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(findcirculatingbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookId })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data to manipulate db",data)
                //setlocalData(data);
      
                //setContentData(data);
                
                closepopup();
                
              
            });
            
          };
      
          fetchContents();

          
        
    }*/
  
  

    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("author")
    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("name")
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("category")
    
    }

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            var reqtype = "";
            if (clbookDetails.startDate === ""){
                reqtype = "firstload";
            }else{
                reqtype = "adddate";
            }
            
            await fetch(getcirculationhistsurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ reqtype, bookDetails, clbookDetails, filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data.var2);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleAddSubmit(e) {
        e.preventDefault();
        setCheckrole(false)
        setCheckgrade(false)
        setCheckname(false)
        setSearchterm("");
        setfilterby("");
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            const reqtype = "adddate"
            await fetch(getcirculationhistsurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ clbookDetails, reqtype }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data.var2);
                setbookDetails({ ...bookDetails, 
                    writeStartDate: data.var1,
                    writeEndDate: data.var3,
                    
                });
                //setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            //setaddPopup(true);
            //clear();
            
            
        };
    
        Addquestion();

       

    }

    /*const clear = () => {

        setClbookDetails({ ...clbookDetails, 
            bookid: "",
            title: "",
            authorname: "",
            category: "",
            userid: "",
            names: "",
            surname: "",
            classgrade: "",
            outdate: "",
            returndate: "",
            status: ""});

        setShowPopupnobook(false);
        
        //setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    function deleteonlbookyes (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(deletecirculatingbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ clbookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();

        setShowPopup(false);
       
    }

    function openonlinedeletebkClick (e) {
        e.preventDefault();
        setShowPopup(true);
    };

    function closedeleteonlbkClick () {
        setShowPopup(false);

    };

    
    
     {showPopup && (
                        <div className="popupclhis">
                        <div className="popup-contentclhis">
                            <p>Are you sure you want delete this book!</p>
                            <button className="deletebuttonclhis" onClick={deleteonlbookyes}>Yes</button>
                            <button className="nodeletebtnclhis" onClick={closedeleteonlbkClick}>No</button>
                        </div>
                        </div>
                    )}
    
    
    */



    if (localData !== null) {

        const categoryCounts = localData.reduce((acc, item) => {
            acc[item.category] = (acc[item.category] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);
      
        // Take the first 10 categories just changed categories
        const topCategories = sortedCategories.slice(0, 1);
        const countCategories = sortedCategories.length;
        const circulationnumber = localData.length;
        console.log("the circulation number", circulationnumber)
        //console.log("topCategories",categoryCounts) 

        const titleCounts = localData.reduce((acc, item) => {
            acc[item.title] = (acc[item.title] || 0) + 1;
            return acc;
        }, {});

        const sortedTitle = Object.keys(titleCounts).sort((a, b) => titleCounts[b] - titleCounts[a]);
        const topTitle = sortedTitle.slice(0, 1);

        const userCounts = localData.reduce((acc, item) => {
            acc[item.userid] = (acc[item.userid] || 0) + 1;
            return acc;
        }, {});

        const sortedUser = Object.keys(userCounts).sort((a, b) => userCounts[b] - userCounts[a]);
        const countUser = sortedUser.length;
       
        const classCounts = localData.reduce((acc, item) => {
            acc[item.classgrade] = (acc[item.classgrade] || 0) + 1;
            return acc;
        }, {});

        const sortedclass = Object.keys(classCounts).sort((a, b) => classCounts[b] - classCounts[a]);
        const topclass = sortedclass.slice(0, 1);
        const countclass = sortedclass.length;


        const classgradeCounts = localData.reduce((acc, item) => {
            acc[item.classgrade] = (acc[item.classgrade] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedClassgrade = Object.keys(classgradeCounts).sort((a, b) => classgradeCounts[b] - classgradeCounts[a]);



        const getbookscard = localData.map((post) =>
            <div className="libuserdetbktableclhis" key={post._id}>
                <div className="libuserdetbktableclhiscont">
                    <div className='libdetbknameclhis'>
                        <p>{post.title}</p>
                    </div>
                    <div className='libdetbksurnameclhis'>
                        <p>{post.names}</p>
                    </div>
                    <div className='libdetbkgradeclhis'>
                        <p>{post.classgrade}</p>
                    </div>
                    <div className='libdetbkuseridclhis'>
                        <p>{(post.outdate).split("T")[0]}</p>
                    </div>
                    <div className='libdetbkpasswordclhis'>
                        <p>{(post.returndate).split("T")[0]}</p>
                    </div>
                    <div className='libdetbkpassword2clhis'>
                        <p>{post.status}</p>
                    </div>
                </div>
            </div>
        )





    return (
        <>
        <div className="addlibmemberpageclhis">
            <div className="addlibmembersectclhis">
                <div className="addlibmemberboxclhis">
                   
                    <h2>Search Period</h2>
            
                    <div className='addlibmembertextboxclhis'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetailsclhis">
                             
                                <DatePicker
                                    selected={clbookDetails.startDate} 
                                    onChange={handleDateChange}
                                    className="libuserbkgradeclbmdate"
                                    name="libuserbkgrade"
                                    placeholderText="Start Date"
                                    dateFormat="yyyy-MM-dd" // Date format
                                    showYearDropdown // Show year dropdown in the date picker
                                    showMonthDropdown // Show month dropdown in the date picker
                                    dropdownMode="select" // Dropdown mode for year and month dropdowns
                                    autoComplete="off"
                                />

                                <DatePicker
                                    selected={clbookDetails.endDate}
                                    onChange={handlereturnDateChange}
                                    className="libuserbkgradeclhis"
                                    name="libuserbkgrade"
                                    placeholderText="End Date"
                                    dateFormat="yyyy-MM-dd" // Date format yyyy-MM-dd'T'HH:mm:ss.SSSxxx
                                    showYearDropdown // Show year dropdown in the date picker
                                    showMonthDropdown // Show month dropdown in the date picker
                                    dropdownMode="select" // Dropdown mode for year and month dropdowns
                                    autoComplete="off"
                                />
                               
                                { bookDetails.editcheck === "off" &&
                                <>
                                <button className='addbtnclhis'>Search</button>
                                </> 
                               
                                }
                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectclhis">
                <div className="viewlibmemberboxclhis">
                    <h2>Borrowing History: <span className="viewlibmemberboxclhisspan">{bookDetails.writeStartDate} - {bookDetails.writeEndDate}</span></h2>
                    <div className="libuserbktableclhis">
                        <div className="libuserbktableclhiscont">
                            <div className='liblblbknameclhis'>
                                <p>Title</p>
                            </div>
                            <div className='liblblbksurnameclhis'>
                                <p>Name</p>
                            </div>
                            <div className='liblblbkgradeclhis'>
                                <p>Class</p>
                            </div>
                            <div className='liblblbkuseridclhis'>
                                <p>OutDate</p>
                            </div>
                            <div className='liblblbkpasswordclhis'>
                                <p>ReturnDate</p>
                            </div>
                            <div className='liblblbkpassword2clhis'>
                                <p>Status</p>
                            </div>
                        </div>
                        {getbookscard}
                        
                    </div>  
                    <div className="filterosectclhis">
                        <h2>Filter by:</h2>
                        <div className="filterboxclhis">
                            <div className='form-groupclhis'>
                                <span>Title:</span> 
                                <input className='bybkgrade' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-groupclhis'>
                                <span>Name:</span>
                                <input className='bybkname' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>  
                            <div className='form-groupclhis'>
                                <span>Class:</span>
                                <input className='bybkrole' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div>  
                            <div className='searchbkboxclhis'>
                                <input type="text" name="search"  value={searchterm} onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}  className = {(!checkgrade && !checkname && !checkname) ? "searchbkboxclhisbtn" : "searchbkboxclhisbtnactive"} disabled={(!checkgrade && !checkname && !checkname)}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>



        <div className="dashboardlibadmindscirchitsdb">
        <div className="dashboardlibadmindsgernecirchitsdb">
                <h2>Dashboard</h2>
                
                <div className="dashboardlibadmindsgerneblockcirchitsdb">
                    <div className="dashboardlibadmindsactualgernecirchitsdb">
                        <Link 
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcirchitsdb'>Borrowed Books
                                <br></br>
                                <span className="libadmindsnumbercirchitsdb">{circulationnumber}</span> 
                            </p>
                        </Link>
                        <Link 
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcirchitsdb'>Gernes
                                <br></br>
                                <span className="libadmindsnumbercirchitsdb">{countCategories}</span> 
                            </p>
                        </Link>
                        <Link 
                           
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcirchitsdb' >Popular Book
                                <br></br>
                                <span className="libadmindsbookcirchitsdb">{topTitle}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcirchitsdb'>Popular Gerne
                                <br></br>
                                <span className="libadmindsbookcirchitsdb">{topCategories}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcirchitsdb'>Students
                                <br></br>
                                <span className="libadmindsnumbercirchitsdb">{countUser}</span> 
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardlibadmindsrobotnormalcirchitsdb'>Most Active Class
                                <br></br>
                                <span className="libadmindsbookcirchitsdb">{topclass}</span>
                            </p> 
                        </Link>
                    </div>

                </div>
            </div>
        </div>


        <div className="detailsgraphadminhitsdb">
            <h2>Gernes Borrowed</h2> 
            <div className="customerCardhitsdb">
                <Bar
                    data={{
                        labels: sortedCategories,
                        datasets: [
                            {
                                label: "Gerne",
                                data: sortedCategories.map(category => categoryCounts[category]),
                                backgroundColor: [
                                    "rgba(160, 32, 240, 0.5)"
                                ],
                                borderRadius: 5,
                            },
                        ]
                    }}
                />  
            </div>  
        </div>


        <div className="detailsgraphadminhitsdb">
            <h2>Class Stats</h2> 
            <div className="customerCardhitsdb">
                <Bar
                    data={{
                        labels: sortedClassgrade,
                        datasets: [
                            {
                                label: "Class",
                                data: sortedClassgrade.map(classgrade => classgradeCounts[classgrade]),
                                backgroundColor: [
                                  "rgba(160, 32, 240, 0.5)"
                                ],
                                borderRadius: 5,
                            },
                        ]
                    }}
                />  
            </div>  
        </div>






        </>
    );

    }
}
        
        
export default CirculatingbookHistoryp;
        