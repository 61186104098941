import React, { useState } from 'react';
import "./slogin.css";
//import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
//import { createUser } from '../../../actions/users';
import { getUser } from '../../../actions/users';

import * as api from '../../../api';

function Slogin() {
  // get data from database thru app.js
  /*const users = useSelector ((state) => state.users);
  console.log("check token here",users);*/

 
  

  const [userData, setUserData] = useState({
    username: '',
    password: ''

  });

  
  
  // user dispatch to send data to the database thru the handlesubmit

  const dispatch = useDispatch();

    //const [username, setUsername] = useState('');
    //const [password, setPassword] = useState('');
  
    /*const handleUsernameChange = (event) => {
      setUsername(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };*/
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      //console.log(`Username: ${userData.username}, Password: ${userData.password}`);
      //console.log(dispatch(getUser()));
      /*dispatch(getUser(userData))
      .then(data => {
        //localStorage.setItem('token', data.token);
        console.log("check token here",data)
        console.log("check token",data.token)
      });*/


      //dispatch(getUser(userData));

      fetch('http://localhost:5000/login-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userData })
      })
        .then(response => response.json())
        .then(data => {
          localStorage.setItem('token', data.token);
          localStorage.setItem('theusername', userData.username);

          //console.log("check token here", data);
          window.location.href = '/phome  ';
        
      });
    
  

      
  
      
    };
  
    return (
        <div className="formpagestp">
            <h2>Student Portal</h2>
            <form onSubmit={handleSubmit}>
                <div>
                <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder='Username'
                    value={userData.username}
                    onChange={(e) => setUserData({ ...userData, username: e.target.value})}
                />
                </div>
                <div>
                <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder='Password'
                    value={userData.password}
                    onChange={(e) => setUserData({ ...userData, password: e.target.value})}
                />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
  }
  
  export default Slogin;
  
