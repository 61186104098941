import React, { useState } from "react";
import "./onlinelibinfor.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


const Onlinelibinfor = () => {

  const [showMore, setShowMore] = useState(false);

  const toggleDescription = () => {
    setShowMore(!showMore);
  };

  const location = useLocation();

  const imglink = location.state.data.imglink;
  const pdflink = location.state.data.pdflink;
  const bio = location.state.data.bio;
  const bio2 = location.state.data.bio2;
  const bio3 = location.state.data.bio3;
  const authorname = location.state.data.authorname;
  const title = location.state.data.title;
  const booktype = location.state.data.typebook;

  console.log("your book type is", booktype)


  return (
    <>
       <div className="mainonlinelibinfo">         
            <div className="onlinebookdet" >
                <div className="onlinebookinfobox">
                    <img src={imglink} alt="onlinebookinfo" />
                </div>
                <div className="onlinebookinfodetails">
                    <h2>{title}</h2>
                    <p className="authornametag">{authorname}</p>
                    {(booktype === "PDF") ?
                    <Link 
                    to="/onlinedisplay"
                    state = {{
                      data: {pdflink: pdflink }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                      }}
                      style={{ textDecoration: 'none', color: 'black', padding: 0}}
                    >
                      <button className="onlinebreadbtn">READ</button>
                    </Link>
                    : (booktype === "Audio") ?
                    <Link 
                    to="/onlinedisplay"
                    state = {{
                      data: {pdflink: pdflink }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                      }}
                      style={{ textDecoration: 'none', color: 'black', padding: 0}}
                    >
                      <button className="onlinebreadbtn">Audio</button>
                    </Link>
                    : (booktype === "Video") &&
                    <Link 
                    to="/onlinedisplay"
                    state = {{
                      data: {pdflink: pdflink }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                      }}
                      style={{ textDecoration: 'none', color: 'black', padding: 0}}
                    >
                      <button className="onlinebreadbtn">Video</button>
                    </Link>


                    }
                    <p className="onlinebifordesc">{bio}</p>
                    <div className={`onlinereadmoreparablock ${(showMore && bio2 !=="") ? 'show-descrip' : ''}`}>
                      <p className="onlinereadmorepara1">{bio2}</p>
                      <p className="onlinereadmorepara2">{bio3}</p>
                      <p className="onlinebreadless" onClick={toggleDescription}>Read Less</p>
                    </div>
                    {(showMore) ?
                    ""
                    : (bio2 !== "") ?
                    <p className="onlinebreadmore" onClick={toggleDescription}>Read More</p>
                    :
                    ""
                    }
                </div>
            </div>
        </div>
    </>
  );
};

export default Onlinelibinfor;