import React from 'react';

import Majoreventsintro from '../pagecomp/majorevents/majoreventsintro';
import '../pagecomp/majorevents/majoreventsintro.css';

import Majoreventsmenu from '../pagecomp/majorevents/majoreventsmenu';
import '../pagecomp/majorevents/majoreventsmenu.css';

import Majoreventsimggrid from '../pagecomp/majorevents/majoreventsgrid';
import '../pagecomp/majorevents/majoreventsgrid.css';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Majoreventspage() {
    return (
        <>
            <NavbarMs />

            <Majoreventsintro />

            <Majoreventsmenu />

            <Majoreventsimggrid />

            <Footer />
        </>
    )
}

export default Majoreventspage;