import React from 'react';
import '../pagecomponents/thome/navbarte.css';
import Navbar from '../pagecomponents/thome/navbarte';
import Editsubjects from '../pagecomponents/subjects/edsubject';



//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Edsubjects() {
    return (
        <>
            <Navbar />

            <Editsubjects />
            
        </>
    )
}

export default Edsubjects;