import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./subject.css";
import { useDispatch, useSelector } from 'react-redux';
import { getSubjects } from "../../../../actions/subjects";

function Subject() {

  const [localData, setlocalData] = useState([]);

  const [userfData,  setuserfData] = useState([]);

  const username = localStorage.getItem('theusername');


  /*const dispatch = useDispatch();
    
  useEffect(() => {
      dispatch(getSubjects());
  }, [dispatch]);*/

  const dbsubjects = useSelector((state) => state.subjects);
  const mpsubjects = dbsubjects.subjects;

  //console.log("receving from db", mpsubjects);
  useEffect(() => {

    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/user-det', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      })
        .then(response => response.json())
        .then(data => {
          //console.log("data contents userform db",data.form)
          setuserfData(data.form)
          
        
      });
      
    };

    fetchContents();

  }, []);

  useEffect(() => {
    const fetchSubjects = async () => {
      /*const response = await fetch('http://localhost:5000/subjects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userfData })
      });
      const data = await response.json();
        
      if (data.length > 0) {
        //console.log("logging data", data[0].subtopics);
        const subjectcheck = data.map((post) => post.subjects)
        console.log(subjectcheck);
        if (subjectcheck !== null) {
          setlocalData(data);
        }else {
          fetchSubjects();
        }
        
          //localStorage.setItem('array', JSON.stringify(data));
      }
      
    };*/
    
      //await dispatch(getOutlines(parsedObj));
      fetch('http://localhost:5000/displaysubject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data from db",typeof(data))
          if (data !== null) {
            //console.log("logging data", data[0].subtopics); data.subjects.length > 0
          
            const subjectcheck = data.subjects.map((post) => post.title)
            console.log(subjectcheck);
            if (subjectcheck !== null) {
              setlocalData(data.subjects);
            }else {
              fetchSubjects();
            }
          
          }else {
            fetchSubjects();

          }

          //setlocalData(data);
          
        
      });
      
    };

    fetchSubjects();
   }, []);


  /*useEffect(() => {
    const fetchSubjects = async () => {
      const response = await fetch('http://localhost:5000/subjects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      }));
      const data = await response.json();
      
      if (data.length > 0) {
        //setPostData(data);
        localStorage.setItem('array', JSON.stringify(data));
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setlocalData(JSON.parse(storedData));
      
    }
  }, []);

  console.log("see localdata", localData)*/

  /*const gotooutline = (event, title) => {
    event.preventDefault();
    console.log("receiving id", title);

    <Link
      to={{
        pathname: "/spoutline",
        state: title // your data array of objects onClick={(event) => gotooutline(event, type.title) }
      }}
    />
   
    
  }*/
 


  

  

  //<h2>Form 1 Mathematics</h2>
  //              <p>Form one mathematics is an introduction to basic algebra, graphs, equations, pathagarus theorem </p>


  if (localData !== null) { 
    console.log("see",localData);

    const subjectcard = localData.map((post) =>
      <div className="testcont" key={post._id}> 
      <Link
      to = "/Outline"
      
      state = {{
       
        data: {form: userfData, filterf: post.title} // your data array of objects type.title 
      }}

      style={{ textDecoration: 'none', color: 'black' }}
      >
      <div className="Subject-card" >
        <div className="Subject-name" >
          <p>Course{console.log("receiving id", post._id)}</p>
        </div>
        <div className="Subject-descrip" >
          <h2>{post.title}</h2>
          <p>{post.descrip}</p>
        </div>
        <div className="Lesson-number" >
        <p>{post.numlesson}</p>
        </div>
      </div> 
      </Link>
  
      </div>
    );






  
    return (
      
      <div className="Subject-main">
        {subjectcard} 
      </div>
      
    );
  }

}
  
  export default Subject;