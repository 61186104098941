import React, { useState, useEffect } from "react";
import "./bookres.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";




function Examres(props) {



    const location = useLocation();

    const papert = location.state.data.papertype;
    const booksub = location.state.data.booksubject;
    const formnumber = location.state.data.formnum;
    const resperpage = 2;
    const carpage  = location.state.data.nxtpage;
    const searchword = location.state.data.keywordserch;

    

    const [contentData, setContentData] = useState({
        papert: papert,
        booksub: booksub,
        formnumber: formnumber,
        searchword: searchword
    });

    const [localData,  setlocalData] = useState([]);

    const [numlocalData,  setnumlocalData] = useState([]);

    const [currentPage,  setcurrentPage] = useState({
        pagen: carpage
    });

    const [lowerlimit,  setlowerlimit] = useState([]);
    const [upperlimit,  setupperlimit] = useState([]);

   


    useEffect(() => {
        
        const fetchBooks = async () => {
            
        await fetch('http://localhost:5000/book-filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentData })
        })
        .then(response => response.json())
        .then(data => {
            console.log("data contents db",data.length)
            if (data.length > 0) {
                //console.log("logging data", data[0].subtopics);
                const contentcheck = data.map((post) => post.title)
                console.log("mapping",contentcheck);
                if (contentcheck !== null) {
                    setlocalData(data);
                    setnumlocalData(data.length);
                }else {
                    fetchBooks();
                }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
            }
      
                //setContentData(data);
                
              
        });
            
        };
      
        fetchBooks();
        
       
    }, []);


    useEffect(() => {


        var pgupperlimit = currentPage.pagen * resperpage;

        var pglowerlimit = ((currentPage.pagen - 1) * resperpage) - 1

        if (currentPage.pagen === 1){
            setupperlimit(pgupperlimit)
            setlowerlimit(-1)
        }else{
            setupperlimit(pgupperlimit)
            setlowerlimit(pglowerlimit)

        }
        
        
    }, [currentPage.pagen, resperpage]);



    if (localData !== null){
        
        const bookscard = localData.map((post, index) =>
        <div className="books-cardB1" key={post._id}> 
            {(index > lowerlimit && index < upperlimit) &&
            <div className="resultsblock">
                <div className="authorres">
                    <p className="othertopicsres">{post.author} </p>
                </div>
                <div className="titleres">
                    <Link
                        to = "/Exampapers"

                        onClick={() => window.location.reload() }

                        state = {{
                
                        data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: searchword, bookview: "yes", nxtpage: currentPage.pagen, bookurl: post.pdflink} }}
                                            
                        style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p className="titletopicres">{post.title}</p>
                    </Link>
                </div>
                <div className="publisherres">
                    <p className="othertopicsres">{post.publisher}</p>
                </div>
                <div className="yearres">
                    <p className="othertopicsres">{post.year}</p>
                </div>
                <div className="pagesres">
                    <p className="othertopicsres">{post.pages}</p>
                </div>
            </div>
            }
        </div>
      )

    
    return (
        <>
        <div className="bookresults-main">
            <div className="bookresults-cont">
                <p>Book results: {booksub === undefined ? searchword : booksub}</p>
                <div className="bookrestable">
                    <div className="authorsect">
                        <p>Author </p>
                    </div>
                    <div className="titlesect">
                        <p>Title </p>
                    </div>
                    <div className="publishersect">
                        <p>Month </p>
                    </div>
                    <div className="yearsect">
                        <p>Year </p>
                    </div>
                    <div className="pagessect">
                        <p>Pages </p>
                    </div>
                    { bookscard }
                </div>
            </div>
        </div>
        </> 
      
    );
    }   
    
}


  
export default Examres;