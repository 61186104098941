import React from "react";
import './ptaintroimg.css';

const Ptaintroimg = () => {
  return (
    <>
        <div className="abtusintroimgcont">
            <div className="abtusintroimg">
                <img src="https://ucarecdn.com/8dc62c32-addc-49bd-9036-f297394dcaaf/-/preview/1000x750/" alt="placeholder" />
            </div>
        </div>
         
    </>
  );
};

export default Ptaintroimg;