import React, { useEffect, useState } from "react";
import "./edoutline.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getOutlines } from "../../../../actions/outlines";

function Edoutline(props) {

  const dispatch = useDispatch();
  const location = useLocation();


  const form = location.state.data.form;
  const filterf = location.state.data.filterf;
  
 
  //const { state } = props.location;
  console.log("sent data", props )
  console.log("sent location", location.state.data.form )

  const [outlineData, setOutlineData] = useState({
    form: form,
    filterf: filterf


  });

  const dboutlines = useSelector((state) => state.outlines);

  useEffect(() => {
      dispatch(getOutlines());
  }, [dispatch]);

  const mpoutlines = dboutlines.outlines;

  const [localData, setlocalData] = useState([]);

  const [ediOutline, setediOutline] = useState({
    topic: "",
    numbersbtop: "",
    subtop1: "",
    subtop2: "",
    subtop3: "",
    subtop4: "",
    subtop5: "",
    idsb: "",
    idot: ""
  });

  const editoutline = (e, topic, numsbtop, sub1, sub2, sub3, sub4, sub5, id, ids) => {

    e.preventDefault()
    setediOutline({...ediOutline, topic: topic, numbersbtop:numsbtop, subtop1:sub1, subtop2:sub2, subtop3:sub3, subtop4:sub4, subtop5:sub5, idot:id, idsb:ids  });
    //console.log(ediOutline)

  }

  const handleEditOutline = (e) => {
    e.preventDefault();


    //dispatch(createEvent(eventData));

    const EditsubjectOutline = async () => {
        //await dispatch(getOutlines(parsedObj));
        await fetch('http://localhost:5000/editsubjectoutlines', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ediOutline })
        })
          .then(response => response.json())
          .then(data => {
            console.log("data contents db",data)
            if (data.subjects.length > 0) {
                //console.log("logging data", data[0].subtopics);
                const contentcheck = data.subjects.map((post) => post.subjects)
                //console.log("mapping",contentcheck);
                //console.log('data', data)
                if (contentcheck !== null) {
                  setlocalData(data.subjects);
                }else {
                  EditsubjectOutline();
                }
                
                  //localStorage.setItem('array', JSON.stringify(data));
              }
            
        });
        
    };
  
    EditsubjectOutline();
      
    clear();
    
}

const clear = () => {

    setediOutline({...ediOutline, topic: "", numbersbtop: "", subtop1: "", subtop2:"", subtop3:"", subtop4:"", subtop5:"", id: "" });
}

  

   useEffect(() => {
    const fetchOutliness = async () => {
      //await dispatch(getOutlines(parsedObj));
      fetch('http://localhost:5000/outline-filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ outlineData })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data from db",data)
          if (data.subjects.length > 0) {
            //console.log("logging data", data[0].subtopics);
            const subjectcheck = data.subjects.map((post) => post.title)
            console.log("mapping",subjectcheck);
            if (subjectcheck !== null) {
              setlocalData(data.subjects);
            }else {
              fetchOutliness();
            }
            
              //localStorage.setItem('array', JSON.stringify(data));
          }

          //setlocalData(data);
          
        
      });
      
    };

    fetchOutliness();

     console.log("localdata after db in",localData)

   }, []);
  
  
   console.log("localdata after db out",localData) //{console.log("thepostinfor", post)}
  


  if (localData !== null) { 

    const outlinecard = localData.map((post)=> 
      <div className="Outline-cards" key={post._id}> 
         {post.outline.map((type)=>
          <div className="edOutline-card" key={type._id}>
            <div className="edoutline-name">
                <h2>{type.topic}</h2>
                <Link
                to = "/Content"

                state = {{
       
                  data: {filterf: post.title, topic: type.topic } // your data array of objects type.title 
                }}
                
                style={{ textDecoration: 'none', color: 'blue' }}
                
                >
                <p>{type.numsubtop} </p>
                </Link>
                
            </div>

            <Link

            onClick={e => editoutline(e, type.topic, type.numsubtop, type.subtopics[0], type.subtopics[1], type.subtopics[2], type.subtopics[3], type.subtopics[4], type._id, post._id )}
                
            style={{ textDecoration: 'none', color: 'black' }}
                
            >
            <div className="edcontent-list">
              <ul>
                  {type.subtopics.map((enter, index)=>
                  <li key={index}>{enter}</li>
                  )}
              </ul>
            </div>
            </Link>
          </div>
          )} 
      </div>
    );
  
    return (
      <>
      <div className="edOutline-main">
        <div className="edCourse-title">
            <h1>My Course Outline</h1>
        </div>
        {outlinecard}
      </div>

      <div className="addoutline-main">
        <div className="addoutline-box">
            <p className="addoutline-boxpara">Course Outline</p>
            <form>
                <div className='outlinename' onSubmit={handleEditOutline}> 
                    <input type="text" name="outlinename" placeholder="Topic Name" value={ediOutline.topic} onChange={(e) => setediOutline({ ...ediOutline, topic: e.target.value})} />
                </div>
                <div className='outlinename'> 
                    <input type="text" name="outlinename" placeholder="Number of Subtopics" value={ediOutline.numbersbtop} onChange={(e) => setediOutline({ ...ediOutline, numbersbtop: e.target.value})} />
                </div>
                <div className='outlinename'> 
                    <input type="text" name="outlinename" placeholder="Topic 1" value={ediOutline.subtop1} onChange={(e) => setediOutline({ ...ediOutline, subtop1: e.target.value})} />
                </div>
                <div className='outlinename'> 
                    <input type="text" name="outlinename" placeholder="Topic 2" value={ediOutline.subtop2} onChange={(e) => setediOutline({ ...ediOutline, subtop2: e.target.value})} />
                </div>
                <div className='outlinename'> 
                    <input type="text" name="outlinename" placeholder="Topic 3" value={ediOutline.subtop3} onChange={(e) => setediOutline({ ...ediOutline, subtop3: e.target.value})} />
                </div>
                <div className='outlinename'> 
                    <input type="text" name="outlinename" placeholder="Topic 4" value={ediOutline.subtop4} onChange={(e) => setediOutline({ ...ediOutline, subtop4: e.target.value})} />
                </div>
                <div className='outlinename'> 
                    <input type="text" name="outlinename" placeholder="Topic 5" value={ediOutline.subtop5} onChange={(e) => setediOutline({ ...ediOutline, subtop5: e.target.value})} />
                </div>
                <button className="submitbuttonoutlinename" type="submit">Edit</button>
            </form>

        </div>
      </div>
      </>
    );
  }

}
  
  export default Edoutline;