import React, { useState, useEffect } from 'react';
import './circulation.css';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker'; // Importing date picker component
import 'react-datepicker/dist/react-datepicker.css';
import { Html5QrcodeScanner } from 'html5-qrcode';
import moment from "moment-timezone";

//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Circulatingbookp() {
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkrole, setCheckrole] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [localData,  setlocalData] = useState([]);
    const [filterby, setfilterby] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupscan, setShowPopupscan] = useState(false);
    const [showScanner, setShowScanner] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const [showPopupnobook, setShowPopupnobook] = useState(false);
    //const[bookId, setBookId] = useState(""); showPopupnobook
    const currentdate = moment().tz('Africa/Harare').toDate();
    console.log("today is", currentdate)


    // Function to handle date selection
    const handleDateChange = (date) => {
      //setSelectedDate(date);
      setClbookDetails({...clbookDetails, outdate: date})
    };

    const handlereturnDateChange = (date) => {
        //setSelectedDate(date);
        setClbookDetails({...clbookDetails, returndate: date})
      };
  

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcirculatingbooks = '/circulatingbooks';
    const getcirculatingbooksurl = baseUrl + dynamicgetcirculatingbooks;

    const dynamiceditcirculatingbooks = '/editCirculationbooks';
    const editcirculatingbooksurl = baseUrl + dynamiceditcirculatingbooks;

    const dynamicsearchcirculatingbooks = '/searchcirculatingbooks';
    const searchcirculatingbooksurl = baseUrl + dynamicsearchcirculatingbooks;

    const dynamicdeletecirculatingbooks = '/deletecirculatingbooks';
    const deletecirculatingbooksurl = baseUrl + dynamicdeletecirculatingbooks;  

    const dynamicfindcirculatingbooks = '/findcirculatingbooks';
    const findcirculatingbooksurl = baseUrl + dynamicfindcirculatingbooks;

    



    ///////--------------/searchonlinebooks  findcirculatingbooks



    const [bookDetails, setbookDetails] = useState({
        title: "",
        authorname: "",
        bioinfor: "",
        bioinfor2: "",
        bioinfor3: "",
        imglink: "",
        pdflink: "",
        category: "",
        typebook: "",
        year: "",
        grade: 0,
        bookid: "",
        editcheck: "off",
    });

    const [clbookDetails, setClbookDetails] = useState({
        bookid: "",
        title: "",
        authorname: "",
        category: "",
        userid: "",
        names: "",
        surname: "",
        classgrade: "",
        outdate: "",
        returndate: "",
        status: ""
    });

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getcirculatingbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookDetails })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    useEffect(() => {
        if (showScanner) {
          const html5QrCode = new Html5QrcodeScanner(
            'qr-reader', // element id for scanning
            { fps: 10, qrbox: 250, cameraId: { facingMode: 'environment' } } // optional configuration
          );
      
          html5QrCode.render(onScanSuccess);
          setHtml5QrCode(html5QrCode);
        }else {
           
            if (html5QrCode) {
                html5QrCode.clear(); // Turn off the camera
            }

            setShowPopupscan(false)

        }
         
    }, [showScanner]);

    function startscan (e) {
        e.preventDefault();
        setShowPopupscan(true);
        setShowScanner(true);

        /*const html5QrCode = new Html5QrcodeScanner(
          'qr-reader', // element id for scanning
          { fps: 10, qrbox: 250 } // optional configuration
        );
    
        html5QrCode.render(onScanSuccess);*/
      
       
    }


    const onScanSuccess = (decodedText, decodedResult) => {
        // Handle the decoded result, for example, you can set it in state
        console.log("check url here",decodedText);
        console.log(decodedResult);
        //window.location.href = JSON.stringify(decodedText); //JSON.stringify(decodedResult);    //codedResult;
  
        const urlnew = decodedText;
  
        // Parse the URL to get the query parameters
        const queryString = urlnew.split('?')[1];
  
        // Parse the query string to get the variable after the '=' sign
        const queryParams = new URLSearchParams(queryString);
        const bookIDD = queryParams.get('bookId');
        const bookId = bookIDD.substring(0, bookIDD.length - 1 );
        console.log("view book id", bookId)

        //setBookId(bookID);

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(findcirculatingbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookId })
            })
              .then(response => response.json())
              .then(data => {

                if (data !== null ) {
            
                console.log("data to manipulate db",data)
                //setlocalData(data);
      
                //setContentData(data);

                setClbookDetails({...clbookDetails,
                    bookid: data.bookid,
                    title: data.title,
                    authorname: data.authorname,
                    category: data.category,
                    userid: data.userid,
                    names: data.names,
                    surname: data.surname,
                    classgrade: data.classgrade,
                    outdate: data.outdate,
                    returndate: data.returndate,
                    status: data.status
                })
                
                
                /*console.log(html5QrCode)
                if (html5QrCode) {
                    html5QrCode.clear(); // Turn off the camera
                }*/
                //setShowPopupscan(false);
                setShowScanner(false);
                setShowPopupnobook(false);

                } else {
                    setShowPopupnobook(true);
                    setShowScanner(false);
                }
                
              
            });
            
          };
      
          fetchContents();

        //handlescanedit();
      };

    /*function handlescanedit() {
        //e.preventDefault();
        console.log("find book section running", bookId);
        

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(findcirculatingbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookId })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data to manipulate db",data)
                //setlocalData(data);
      
                //setContentData(data);
                
                closepopup();
                
              
            });
            
          };
      
          fetchContents();

          
        
    }*/
  
  

    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("author")
    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("name")
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("category")
    
    }

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchcirculatingbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleAddSubmit(e) {
        e.preventDefault();
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(editcirculatingbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ clbookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            //setaddPopup(true);
            clear();
            
            
        };
    
        Addquestion();
    
    }

    const clear = () => {

        setClbookDetails({ ...clbookDetails, 
            bookid: "",
            title: "",
            authorname: "",
            category: "",
            userid: "",
            names: "",
            surname: "",
            classgrade: "",
            outdate: "",
            returndate: "",
            status: ""});

        setShowPopupnobook(false);
        
        //setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    function deleteonlbookyes (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(deletecirculatingbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ clbookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();

        setShowPopup(false);
       
    }

    function openonlinedeletebkClick (e) {
        e.preventDefault();
        setShowPopup(true);
    };

    function closedeleteonlbkClick () {
        setShowPopup(false);

    };

    function closepopup (e) {
        e.preventDefault()

        setShowPopupscan(false);
        setShowScanner(false);

        if (html5QrCode) {
            html5QrCode.clear(); // Turn off the camera
        }
  
       
      
       
    }



    if (localData !== null) {

        const getbookscard = localData.map((post) =>
            <Link

            onClick={(e) => setClbookDetails({...clbookDetails,
                bookid: post.bookid,
                title: post.title,
                authorname: post.authorname,
                category: post.category,
                userid: post.userid,
                names: post.names,
                surname: post.surname,
                classgrade: post.classgrade,
                outdate: post.outdate,
                returndate: post.returndate,
                status: post.status
            })}
            
            style={{ textDecoration: 'none', color: 'black' }}

            key={post._id}
            
            >
            <div className="libuserdetbktableclbm" >
                <div className={clbookDetails.bookid === post.bookid ? "libuserdetbktableclbmcontblue" : "libuserdetbktableclbmcont"}>
                    <div className='libdetbknameclbm'>
                        <p>{post.title}</p>
                    </div>
                    <div className='libdetbksurnameclbm'>
                        <p>{post.names}</p>
                    </div>
                    <div className='libdetbkgradeclbm'>
                        <p>{post.classgrade}</p>
                    </div>
                    <div className='libdetbkuseridclbm'>
                        <p>{(post.outdate).split("T")[0]}</p>
                    </div>
                    {(moment(currentdate).isAfter(post.returndate)) ?
                    <div className='libdetbkpasswordclbmred'>
                        <p>{(post.returndate).split("T")[0]}</p>
                    </div>
                    :
                    <div className='libdetbkpasswordclbm'>
                        <p>{(post.returndate).split("T")[0]}</p>
                    </div>
                    }
                    {post.status === "Approved" ?
                    <div className='libdetbkpassword2clbmgreen'>
                        <p>{post.status}</p>
                    </div>
                    :
                    <div className='libdetbkpassword2clbm'>
                        <p>{post.status}</p>
                    </div>
                    }
                </div>
            </div>
            </Link>
        )





    return (
        <>
        <div className="addlibmemberpageclbm">
            <div className="addlibmembersectclbm">
                <div className="addlibmemberboxclbm">
                    {showPopup && (
                        <div className="popupclbm">
                        <div className="popup-contentclbm">
                            <p>Are you sure you want delete this book!</p>
                            <button className="deletebuttonclbm" onClick={deleteonlbookyes}>Yes</button>
                            <button className="nodeletebtnclbm" onClick={closedeleteonlbkClick}>No</button>
                        </div>
                        </div>
                    )}
                    {showPopupscan && (
                        <div className="popupclbm">
                        <div className="popup-contentclbm">
                            <div id="qr-reader" style={{ width: '100%', height: '100%' }}></div>
                            <button className="deletebuttonclbm" onClick={closepopup}>Close</button>
                        </div>
                        </div>
                    )}
                    <h2>Manage</h2>
                    <button className='scanbookbtn' onClick={startscan}>Scan QR Code</button>
                    {showPopupnobook && (
                    <p className='booknotfoundclnm'>Book not found!!!</p>
                    )}
                    <div className='addlibmembertextboxclbm'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetailsclbm">
                                <input type="text" className="libuserbknamesclbm" name="libuserbknames" placeholder='Title'  value={clbookDetails.title} onChange={(e) => setClbookDetails({...clbookDetails, title: e.target.value})} readOnly/>
                                <input type="text" className="libuserbksurnamesclbm" name="libuserbksurname" placeholder='Author Name' value={clbookDetails.authorname} onChange={(e) => setClbookDetails({...clbookDetails, authorname: e.target.value})} readOnly/>
                                <input type="text" className="libuserbkgradeclbm" name="libuserbkgrade" placeholder='Category' value={clbookDetails.category} onChange={(e) => setClbookDetails({...clbookDetails, category: e.target.value})} readOnly/>
                                <input type="text" className="libuserbkgradeclbm" name="libuserbkgrade" placeholder='Name' value={clbookDetails.names} onChange={(e) => setClbookDetails({...clbookDetails, names: e.target.value})} readOnly/>
                                <input type="text" className="libuserbkgradeclbm" name="libuserbkgrade" placeholder='Surname' value={clbookDetails.surname} onChange={(e) => setClbookDetails({...clbookDetails, surname: e.target.value})} readOnly/>
                                <input type="text" className="libuserbkgradeclbm" name="libuserbkgrade" placeholder='Class' value={clbookDetails.classgrade} onChange={(e) => setClbookDetails({...clbookDetails, classgrade: e.target.value})} readOnly/>
                                
                                <DatePicker
                                    selected={clbookDetails.outdate}
                                    onChange={handleDateChange}
                                    className="libuserbkgradeclbmdate"
                                    name="libuserbkgrade"
                                    placeholderText="Out Date"
                                    dateFormat="yyyy-MM-dd" // Date format
                                    showYearDropdown // Show year dropdown in the date picker
                                    showMonthDropdown // Show month dropdown in the date picker
                                    dropdownMode="select" // Dropdown mode for year and month dropdowns
                                    autoComplete="off"
                                />

                                <DatePicker
                                    selected={clbookDetails.returndate}
                                    onChange={handlereturnDateChange}
                                    className="libuserbkgradeclbm"
                                    name="libuserbkgrade"
                                    placeholderText="Return Date"
                                    dateFormat="yyyy-MM-dd" // Date format yyyy-MM-dd'T'HH:mm:ss.SSSxxx
                                    showYearDropdown // Show year dropdown in the date picker
                                    showMonthDropdown // Show month dropdown in the date picker
                                    dropdownMode="select" // Dropdown mode for year and month dropdowns
                                    autoComplete="off"
                                />
                                
                                <select className="libuserbkgradeclbm" name="selectroles" value={clbookDetails.status} onChange={(e) => setClbookDetails({...clbookDetails, status: e.target.value})}>
                                    <option defaultValue>Status</option>
                                    <option value="Not Approved">Not Approved</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Returned">Returned</option>
                                    <option value="Lost">Lost</option>
                                </select>
                               
                                { (clbookDetails.bookid !== "")  &&
                                <>
                                <button className='addbtnclbm'>Edit</button>
                                <button className='clearbkbtnclbm' onClick={clear}>Clear</button>
                                <button className='editbkbtnclbm' onClick={openonlinedeletebkClick}>Delete</button>
                                </> 
                                }
                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectclbm">
                <div className="viewlibmemberboxclbm">
                    <h2>Books in Circulation</h2>
                    <div className="libuserbktableclbm">
                        <div className="libuserbktableclbmcont">
                            <div className='liblblbknameclbm'>
                                <p>Title</p>
                            </div>
                            <div className='liblblbksurnameclbm'>
                                <p>Name</p>
                            </div>
                            <div className='liblblbkgradeclbm'>
                                <p>Class</p>
                            </div>
                            <div className='liblblbkuseridclbm'>
                                <p>OutDate</p>
                            </div>
                            <div className='liblblbkpasswordclbm'>
                                <p>ReturnDate</p>
                            </div>
                            <div className='liblblbkpassword2clbm'>
                                <p>Status</p>
                            </div>
                        </div>
                        {getbookscard}
                        
                    </div>  
                    <div className="filterosectclbm">
                        <h2>Filter by:</h2>
                        <div className="filterboxclbm">
                            <div className='form-groupclbm'>
                                <span>Title:</span> 
                                <input className='bybkgrade' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-groupclbm'>
                                <span>Name:</span>
                                <input className='bybkname' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>  
                            <div className='form-groupclbm'>
                                <span>Class:</span>
                                <input className='bybkrole' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div>  
                            <div className='searchbkboxclbm'>
                                <input type="text" name="search" onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

    }
}
        
        
export default Circulatingbookp;
        