import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./revise.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


function ReviseTest(props) {

    const location = useLocation();

    const username = localStorage.getItem('theusername');

    const [localData,  setlocalData] = useState([]);
    const [resultsData,  setResultsData] = useState([]);
    

    const formnum = location.state.data.formnum;
    const termnum = location.state.data.termnum;
    const subject = location.state.data.subject;
    const testid = location.state.data.testid;

    console.log("subject writetest", subject)

    const [testqueData, setTestqueData] = useState({
        formnum: formnum,
        termnum: termnum,
        subject: subject,
        testid: testid
    });

    const [chtestans, setChtestans] = useState([]);
    const [marktest,  setMarktest] = useState([]);
    const [numberques,  setNumberques] = useState(null);

    /*const [testans, setTestans] = useState([]);
    const [marktest,  setMarktest] = useState([]);
    const [numberques,  setNumberques] = useState(null);
    const [tstname,  setTstname] = useState("");
    const [fnlmark,  setFnlmark] = useState();
    const [completed,  setCompleted] = useState('');*/

    /*const handleChange = (e, actualanswer) => {
        const useranswer = e.target.value;
        const quename = e.target.name;
        console.log("namee", quename)
        console.log("inputts", useranswer)
        console.log("actualanswer", actualanswer)
        setTestans({...testans, [quename]: useranswer});

        if (useranswer === actualanswer){
            setMarktest({...marktest, [quename]: 1});

        }else {
            setMarktest({...marktest, [quename]: 0});

        }
  
         
    }*/

    // Delete after confirmation of workin
    /*console.log("testanswer", testans)
    console.log("testmark", marktest)
    console.log("testmark length", Object.keys(marktest).length)
    console.log("testmark length convert", Object.keys(marktest))
    console.log("button off", numberques)
    console.log("answers array", Object.entries(testans))*/
    // Delete after confirmation of workin
  

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/assignmentquestions-filter', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ testqueData })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents writetest",data)
                if (data !== null ) {
                  //console.log("logging data", data[0].subtopics); data.subtest.length > 0
                  const contentcheck = data.subtest.map((post) => post.subject)
                  //console.log("mapping",contentcheck);
                  //console.log('data', data)
                  if (contentcheck !== null) {
                    setlocalData(data.subtest);
                  }else {
                    fetchContents();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
           
      
    }, []);

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/reviseassign-results', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ username, testqueData })
            })
              .then(response => response.json())
              .then(data => {
                console.log("assignment results contents db",data)
                if (data !== null) {
                  //console.log("logging data", data[0].subtopics); data.testassigncont.length > 0
                  const contentcheck = data.testassigncont.map((post) => post.term)
                  //console.log("mapping",contentcheck);
                  if (contentcheck !== null) {
                    setResultsData(data.testassigncont);
                  }else {
                    fetchContents();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
           
      
    }, []);

    useEffect(() => { 
        resultsData.map((post) =>
            <div className="revise-bufferB1" key={post._id}>
                {(post.form === testqueData.formnum && post.term === testqueData.termnum) &&
                    <div className="revisetestbuf">
                        {post.assignmentstsres.map((nextl) =>
                            <div className="revise-bufferb3" key={nextl._id}>
                                {(nextl.testid === testqueData.testid) &&
                                    //console.log("check here",nextl.answers)
                                    <div className="answerclbuffer">
                                        {nextl.answers.map((type, index) =>
                                            <div className="answerclbuffer2" key={index}>
                                                {setChtestans(type)}
                                                {setMarktest(nextl.mark)}
                                                {setNumberques(nextl.noques)}
                                                
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        )}
                        
                    </div>
                }    
            </div>
        )

    }, [testqueData.testid,[]])

    const g = "question1";

    console.log("check the answers here2g", typeof chtestans["question1"]);

    /*useEffect(() => { 
        localData.map((post) =>
            <div className="questions-bufferB1" key={post._id}>
            {post.testcont.map((type)=>
                <div className="questions-bufferb2" key={type._id}>
                    {type.testid === testqueData.testid &&
                    <div className="writetestquestionsbuf">
                        {setNumberques(type.noques)} 
                        {setTstname(type.testname)}

                    </div>
                    }    
                </div>
            )}
            </div>
        )

    }, [testqueData.testid, []])*/


    if (localData !== null) {

    const questionscard = localData.map((post) =>
        <div className="questions-cardB1" key={post._id}>
        {post.testcont.map((type)=>
            <div className="questions-cardb2" key={type._id}>
                {type.testid === testqueData.testid &&
                <div className="writetestquestions">
                    <p className="writetesttopic">{type.testname}</p>
                    <p className="writetesttopic">Mark <span className={((marktest/numberques)*100).toFixed(1) > 55 ? "writetesttopicC": "writetesttopicW"}>{((marktest/numberques)*100).toFixed(1)}%</span></p>
                    {type.testques.map((next, index)=>
                    <div className="testquestioncont" key={next._id}>
                        <p className="questionnumber">Question {index + 1}</p>
                        <div className="acttestquestion">
                            <p>{next.question}</p>
                        </div>
                        <div className="acttestanswer">
                            {next.anstype === "enter" ?
                            <label>{next.finditem} <input className={(chtestans['question'+(index+1)] === next.answer) ? "inputcorrect" : "inputwrong" } type="text" name={"question" + (index+1)} defaultValue={chtestans['question'+(index+1)]} readOnly/></label>
                            :
                            <div className="testoptionabcz">
                                <div className={(chtestans['question'+(index+1)] === next.answer) ? "optionitC" : "optionitW" }>
                                    <input type="radio" name={"question" + (index+1)} id={index + 'radio1'} value="A" checked={(chtestans['question'+(index+1)] === "A") ? true : false } readOnly/>
                                    <label htmlFor={index + 'radio1' }>A</label>
                                    <p>{next.options.map((newopt, index)=> index === 0 && newopt)}</p>   
                                </div>
                                <div className={(chtestans['question'+(index+1)] === next.answer) ? "optionitC" : "optionitW" }>
                                    <input type="radio" name={"question" + (index+1)} id={index + 'radio2'} value="B" checked={(chtestans['question'+(index+1)]=== "B") ? true : false} readOnly />
                                    <label htmlFor={index + 'radio2'}>B</label> 
                                    <p>{next.options.map((newopt, index)=> index === 1 && newopt)}</p>
                                </div>
                                <div className={(chtestans['question'+(index+1)] === next.answer) ? "optionitC" : "optionitW" }>
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio3'} value="C" checked={(chtestans['question'+(index+1)] === "C") ? true : false} readOnly />
                                    <label htmlFor={index + 'radio3'}>C</label>
                                    <p>{next.options.map((newopt, index)=> index === 2 && newopt)}</p>
                                </div>
                                <div className={(chtestans['question'+(index+1)] === next.answer) ? "optionitC" : "optionitW" }>
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio4'} value="D" checked={(chtestans['question'+(index+1)] === "D") ? true : false} readOnly/>
                                    <label htmlFor={index + 'radio4'}>D</label> 
                                    <p>{next.options.map((newopt, index)=> index === 3 && newopt)}</p>
                                </div>
                                <div className={(chtestans['question'+(index+1)] === next.answer) ? "optionitC" : "optionitW" }>
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio5'} value="E" checked={(chtestans['question'+(index+1)] === "E") ? true : false} readOnly/>
                                    <label htmlFor={index + 'radio5'}>E</label>
                                    <p>{next.options.map((newopt, index)=> index === 4 && newopt)}</p>
                                </div>

                            </div>
                            }
                        </div>
                    </div>
                    )}
                    
                </div>
                }    
            </div>
        )}
        </div>
    )



    

    return (
        <>
        <div className="revisetest-main">


            {(testqueData.testid === undefined)?

            <div className="writeteststart">
                <p>Select Subject And Check if there is a Test of Assignment Pending</p>
            </div>

            :

            <div className="formbuffer">
                <form className="formassignment">
                    {questionscard}
                </form>  
            </div>

            }

            

            
        </div>
        </>
      
    );
    }
    
}


  
  export default ReviseTest;