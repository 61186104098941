import React, { useState, useEffect } from "react";
import "./onlinegernethree.css";
import { useLocation, Link } from "react-router-dom";

const OnlineGernep = () => {
    const [localData,  setlocalData] = useState([]);

    const location = useLocation();

    const booktype = location.state.data.typebook;
    const categoryview = "animal story";
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcatgridbooks = '/getcatgridbooks';
    const getcatgridbooksurl = baseUrl + dynamicgetcatgridbooks;

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        // <a href="/onlinelibinfo">
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getcatgridbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ categoryview, booktype })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, [categoryview, booktype]);

if (localData !== null) {

    const getbooksgrid = localData.map((post, index) =>
        <div className="notimgridbufferthree" key={post._id}>
            {index < 6 &&
              <Link
                to = "/onlinelibinfo"
                state = {{
                data: {imglink: post.imglink, pdflink: post.pdflink, bio: post.authorbio,  bio2: post.authorbio2,bio3: post.authorbio3, authorname: post.authorname, title: post.title, typebook: booktype }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                }}
                style={{ textDecoration: 'none', color: 'black', padding: 0}}
              >
                <div className="notimgridthree" >
                    <div className="actualimgthree">
                        <img src={post.imglink} alt="bookimg" />
                        <p>{post.title}</p>
                        <p className="ntbktitlethree">{post.authorname}</p>
                    </div> 
                </div>
              </Link>
            }
        </div>
    
    )


  return (
    <>
        <div className="catgridmainthree">
            <div className="cattitlethree">
                <h2>Animal Story</h2>
                <div className="notablegridthree">
                    {getbooksgrid}
                </div>
            </div>  
        </div>
    
        
    </>
  );
}
};

export default OnlineGernep;