import React from "react";
import './homeabt.css';

const homeabt = () => {
    const homeabtimg = "https://ucarecdn.com/2e1d3cee-b6b7-472d-a6ca-17e5631bca81/-/preview/1000x951/";

    return (
        <>
            <div className="mainhomeabtcont">
                <div className="homeabtimgcont">
                    <img src={homeabtimg} alt="about masiye junior" />
                </div>

                <div className="homeabttextcont">
                    <div className="homeabtparacont">
                        <h2>Masiyephambili Junior School</h2>
                        
                        <p className="homeabtone">Established in 1990, Masiyephambili Junior School is a partnership among businesses and schools created to improve education in science, mathematics, and technology. Representatives from business and industry and staff of Bulawayo worked together in curriculum and facilities development for the school.</p>

                        <p className="homeabttwo">We offer a holistic, co-educational, full boarding experience, where we aim to bring out the best in our students in their academic, sporting and cultural pursuits. We are proud of the well-rounded, polite and responsible students who leave through the gates at the end of their Masiye careers.</p>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

export default homeabt;