import React from "react";
import "./academic.css";

/*
<div className="acpictitle">
                    <h1>Curriculum</h1>
                    <img src="https://ucarecdn.com/a56cfeab-0f48-4d60-be64-03f09dde4e90/-/preview/1000x374/" alt="Admissions" />
                </div>
 */


function Academic() {
    return (
        <>
            <div className="acwholepage">
                

                <div className="acinfogrid">
                    <div className="acgrid12">
                        <div className="acgrid1">
                            <h3>Schedule</h3>
                            <ul>
                                <li><a href="https://drive.google.com/uc?export=download&id=17zdzZUq595m5OrbRibdZ5rmfybabU-3I" download="Form1Timetable" >Grade 1</a></li>
                                <li><a href="#">Grade 2</a></li>
                                <li><a href="#">Grade 3</a></li>
                                <li><a href="#">Grade 4</a></li>
                                <li><a href="#">Grade 5</a></li>
                                <li><a href="#">Grade 6</a></li>
                                <li><a href="#">Grade 7</a></li>
                            </ul>
                        </div>

                        <div className="acgrid4">
                            <h3>Specialist Areas</h3>
                            <ul>
                                <li>Library</li>
                                <li>Guidance and Counseling</li>
                                <li>ICT</li>
                                <li>Music</li>
                                <li>Theatre and Dance</li>
                                <li>Agriculture</li>
                                <li>Physical Education</li>   
                            </ul>  
                        </div>

                    </div>
                    <div className="acgrid2">
                        <h3>Sports and Clubs</h3>
                        <ul>
                            <li>Rubgy</li>
                            <li>Soccer</li>
                            <li>Swimming</li>
                            <li>Hockey</li>
                            <li>Netball</li>
                            <li>Cricket</li>
                            <li>Athletics</li>
                            <li>Brownies</li>
                            <li>Scout</li>
                            <li>Spelling bee</li>
                            <li>Media Club</li>
                            <li>Times Table Rockstars</li>
                            <li>Public Speaking</li>
                            <li>Chess</li>
                        </ul>
                        
                    </div>
                    <div className="acgrid3">
                        <h3>Learning Areas</h3>
                        <ul>
                        <li>Mathematics</li>
                        <li>English</li>
                        <li>Ndebele</li>
                        <li>Social Sciences</li>
                        <li>Agriculture Science</li>
                        <li>Technology</li>
                        <li>Physical Education and Arts</li>
                        </ul>
                        
                    </div>
                    

                </div>

                

            </div>
            
        </>
    );
  }
  
  export default Academic;
  