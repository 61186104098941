import React from 'react';
import '../pagecomponents/thome/navbarte.css';
import Navbar from '../pagecomponents/thome/navbarte';
import Editoutline from '../pagecomponents/edoutline/edoutline';



//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Edoutline() {
    return (
        <>
            <Navbar />

            <Editoutline />
            
        </>
    )
}

export default Edoutline;