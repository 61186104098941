import React, { useState, useEffect } from 'react';
import {Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = () => {
    setIsTabVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isTabVisible;
};

const PrivateRoute = ({path, element, role}) => {

  //const location = useLocation();

  //const isTabActive = useIsTabActive();

  const [userRole, setUserRole] = useState(null);

  //const pagename = location?.pathname ?? '/';

  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicrequireAuth = '/requireAuth';
  const requireAuthurl = baseUrl + dynamicrequireAuth;

  
  /*const dynamicupdatecurrentpage = '/updatecurrentpage';
  const updatecurrentpageurl = baseUrl + dynamicupdatecurrentpage; 

  const dynamicupdateactivepage = '/updateactivepage';
  const updateactivepageurl = baseUrl + dynamicupdateactivepage;*/

  useEffect(() => {
        const fetchRoles = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch(requireAuthurl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)  
              setUserRole(data)
          });
          
        };
    
        fetchRoles();

        
  }, []);

  /*useEffect(() => {

    if (userRole !== null) {
      
    console.log(`your current page in private routes is ${pagename} and the user role is ${userRole.userid}`)

    const fetchContents = async () => {
    //await dispatch(getOutlines(parsedObj));
    await fetch(updatecurrentpageurl,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ pagename, userRole })
    })
      .then(response => response.json())
      .then(data => {
        //console.log("data contents fetched books db",data)
        //setlocalData(data);

        //setContentData(data);
        
      
    });
    
  };

  fetchContents();
}
 
    
 
 }, [pagename]);

 useEffect(() => {

  if (userRole !== null) {
      
    console.log(`your current page in private routes is ${isTabActive} and the user role is ${userRole.userid}`)

    const fetchContents = async () => {
    //await dispatch(getOutlines(parsedObj));
    await fetch(updateactivepageurl,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ isTabActive, userRole })
    })
      .then(response => response.json())
      .then(data => {
        //console.log("data contents fetched books db",data)
        //setlocalData(data);

        //setContentData(data);
        
      
    });
    
  };

  fetchContents();
  }

}, [isTabActive]);*/


  if (userRole === null) {
    // Loading or error state
    
  }

  if (userRole !== null) {
    console.log('checking role from the app', role)
    console.log('checking role from the app', userRole.message)

    /*if (!userRole) {
      // Redirect to login if not authenticated
      return <Navigate to="/login" />;
    }*/

    // Check if the user has the required role
    if (role.includes(userRole.message)) {
      // Redirect to unauthorized page or handle accordingly return <Navigate to="/portals"  state = {{data: {portalname: "Library" } }} />;
      console.log("going to addmember", path)
      return element ;
    }else if (userRole.message === 'Token tempared'){
      return <Navigate to="/portals" state = {{data: {portalname: "Admin" } }} />;
    }else if (userRole.message === 'No token'){
      return <Navigate to="/portals" state = {{data: {portalname: "Admin" } }} />;
    }else {
      return <Navigate to="/studentlibrarylogin" />;
    }

  
  }

  

  
};

export default PrivateRoute;
