import React from 'react';
import '../pagecomponents/performance/navbargu.css';
import Navbar from '../pagecomponents/performance/navbargu';
import '../pagecomponents/performance/perfomance.css';
import Perfomance from '../pagecomponents/performance/perfomance';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Performancep() {
    return (
        <>
            <Navbar />

            <Perfomance />


        </>
    )
}

export default Performancep;