import React, { useState, useEffect } from "react";
import "./phygerne4.css";
import { Link } from "react-router-dom";
//import useOnlinebookcat from "../../../../context/useOnlinebookcat";

const PhyGerne3p = () => {
    const [localData,  setlocalData] = useState([]);
    //const { booktype } =  useOnlinebookcat(); 

    const categoryview = "space";
    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetphycatgridbooks = '/getphycatgridbooks';
    const getphycatgridbooksurl = baseUrl + dynamicgetphycatgridbooks;

    const redirectinfoUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':3000/borrowbookinfo?bookId' : '/borrowbookinfo?bookId'}`;

    /*useEffect(() => {
        // You can perform additional actions here once documentType is set
        console.log('bookcategory is displaying:', booktype);
        
    }, [booktype]);*/

    //console.log('Initial booktype value:', booktype);

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        // <a href="/onlinelibinfo">
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getphycatgridbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ categoryview })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();     
      
    }, [categoryview]);

    const redirectToUrl = (url) => {
        window.location.href = `${redirectinfoUrl}=${url}`; // Redirect to the URL passed as parameter
    };

if (localData !== null) {

    const getbooksgrid = localData.map((post, index) =>
        <div className="notimgridbufferone" key={post._id}>
            {index < 6 &&
                <Link
                    //to = "/borrowbookinfo"
                    onClick={() => redirectToUrl(post.bookid)}
                    style={{ textDecoration: 'none', color: 'black', padding: 0}}
                >
                <div className="phy4notimgridone" >
                    <div className="phy4actualimgone">
                        <img src={post.imglink} alt="bookimg" />
                        <p>{post.title}</p>
                        <p className="phy4ntbktitleone">{post.authorname}</p>
                    </div> 
                </div>
                </Link>
            }
        </div>
    
    )


  return (
    <>
        <div className="phy4catgridmainone">
            <div className="phy4cattitleone">
                <h2>Space</h2>
                <div className="phy4notablegridone">
                    {getbooksgrid}
                </div>
            </div>  
        </div>
    
        
    </>
  );
 }
};

export default PhyGerne3p;