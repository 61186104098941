import React from 'react';
import '../pagecomponents/addbooks/navbarlibrian.css';
import Navbarlibrian from '../pagecomponents/addbooks/navbarlibrian.js';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';
import '../pagecomponents/dashboard/dashboard.css';
import Dashboardstats from '../pagecomponents/dashboard/dashboard.js';

import '../pagecomponents/dashboard/dashboardmenu.css';
import Dashboardmenu from '../pagecomponents/dashboard/dashboardmenu.js';

import '../pagecomponents/dashboard/detailsgraph.css';
import Detailsgraph from '../pagecomponents/dashboard/detailsgraph.js';

import '../pagecomponents/dashboard/doughtgraph.css';
import Doughtgraph from '../pagecomponents/dashboard/doughtgraph.js';




//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Dashboardlibadminp() {
    return (
        <>
            <Navbarlibrian />

            <Dashboardmenu />

            <Dashboardstats />

            <Detailsgraph />

            <Doughtgraph />
            
        </>
    )
}

export default Dashboardlibadminp;