import React from 'react';
import '../pagecomponents/library/onlineLib.css';
import Onlinelibsec from '../pagecomponents/library/onlineLib';

//intial build then rome to correct place

function Onlinelibp() {
    return (
        <>

            <Onlinelibsec />

            

        </>
    )
}

export default Onlinelibp;