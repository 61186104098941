import React from "react";
import './clubspara.css';

const Clubspara = () => {
    const homeabtimg = "https://ucarecdn.com/8dc62c32-addc-49bd-9036-f297394dcaaf/-/preview/1000x750/";

    return (
        <>
            <div className="mainclubsparacont">
                <div className="clubsparaimgcont">
                    <p className="clubsparathree">Masiyephambili Junior Clubs are like mini-adventures beyond the classroom, bursting with potential for young minds. These groups offer a chance for children to delve deeper into subjects they already love, like dinosaurs or coding. Maybe they're curious about something entirely new, like robotics or creative writing. Clubs provide a safe space to explore these interests with peers who share their enthusiasm. This exploration can spark a lifelong passion or simply lead to a fun and enriching experience.</p>

                    <p className="clubsparafour">But clubs aren't just about individual interests. They're also fantastic social hubs. In a club setting, children build friendships with classmates who share their curiosity. They learn to collaborate on projects, negotiate ideas, and celebrate each other's successes. These social interactions help them develop communication skills and build a sense of belonging within a supportive community.</p>
                </div>

                <div className="clubsparatextcont">
                    <div className="clubsparaparacont">
                        <p className="clubsparaone">Clubs can also be confidence boosters for school children. As they participate in activities, they gain new skills and knowledge. Whether it's mastering a coding language, composing a song, or presenting a science experiment, these achievements give them a sense of accomplishment. This newfound confidence spills over into other areas of their lives, encouraging them to take on challenges and embrace new opportunities.</p>

                        <p className="clubsparatwo">Finally, clubs can be a springboard for future passions and even careers. By exposing children to different fields like robotics, environmental science, or theater, clubs can spark a lifelong interest. This early exploration can shape their academic choices and even influence their career paths later in life. So, the next time your child shows interest in a club, remember, it's not just about having fun (although that's important too!). It's about opening doors to a world of possibilities.</p>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

export default Clubspara;