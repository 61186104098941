import React from "react";
import './lifeatmasiye.css';
import { Link } from 'react-router-dom';

const Lifeatmasiye = () => {
    const homeabtimg = "https://ucarecdn.com/418a8e1a-c96f-4fde-af9c-9d6015574091/-/preview/640x457/";
    const sportimg = "https://ucarecdn.com/745be8ef-b7ae-4205-a1f3-6f283d57617c/-/preview/1000x750/";
    const clubimg = "https://ucarecdn.com/6964c5f2-6995-4192-b5d6-e731e8cdd69c/-/preview/1000x750/";

    const handleClick = () => {
        window.scrollTo(0, 0); // Scroll to the top
    };

  return (
    <>
        <div className="lifeatmasiyecont">
            <h2 className="lifeatheader">Life at Masiye Junior</h2>

            <Link
                to = "/academics"
                onClick={handleClick}
                style={{ textDecoration: 'none', color: 'black', padding: 0}}
            >
            <div className="lifeatmasiyeone">
                <div className="lifeatmasiyeimgpic">
                    <img src={homeabtimg} alt="about masiye junior" />
                </div>
                <div className="lifeatmasiyetext">
                    <h2 className="lifeatmasiyetexthead">ACADEMICS</h2>
                    <p>We offer a Cambridge International education with tailored support for varying abilities, equipped classrooms, and campus-wide WiFi for research.</p>
                </div>
            </div>
            </Link>
            <Link
                to = "/sports"
                onClick={handleClick}
                state = {{
                    data: { sporttype: "all", sportsection: "gallery", sportcate: "latest" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                }}
                style={{ textDecoration: 'none', color: 'black', padding: 0}}
            >
            <div className="lifeatmasiyeone">
                <div className="lifeatmasiyeimgpic">
                    <img src={sportimg} alt="about masiye junior" />
                </div>
                <div className="lifeatmasiyetext">
                    <h2 className="lifeatmasiyetexthead">SPORT</h2>
                    <p>The school boasts world-class facilities and coaches, nurturing national representatives and professional athletes in various sports.</p>
                </div>
            </div>
            </Link>
            <Link
                to = "/clubs"
                onClick={handleClick}
                state = {{
                  data: { club: "all" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                }}
                style={{ textDecoration: 'none', color: 'black', padding: 0}}
            >
            <div className="lifeatmasiyeone">
                <div className="lifeatmasiyeimgpic">
                    <img src={clubimg} alt="about masiye junior" />
                </div>
                <div className="lifeatmasiyetext">
                    <h2 className="lifeatmasiyetexthead">CLUB AND SOCIETIES</h2>
                    <p>Our extra-curricular program includes over forty diverse clubs, focusing on performing arts and outdoor activities.</p>
                </div>
            </div>
            </Link>
        </div>
       
        
    </>
  );
};

export default Lifeatmasiye;