import React from "react";
import './missionstate.css';

const Missionstate = () => {
  return (
    <>
        <div className="missionstatemaincont">
            <div className="missionstatemission">
                <p>At Masiyephambili junior we aim to develop each child to their fullest potential, socially, culturally, academically, emotionally, equipping them with the necessary skills for life in a happy and friendly environment</p>
            </div>
        </div>
        
        
    </>
  );
};

export default Missionstate;