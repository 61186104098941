import React from 'react';
import '../pagecomponent/navbar/navbaradh1.css';
import Navbaradh1 from '../pagecomponent/navbar/navbaradh1.js';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';

import '../../../../libraryportal/pagecomponents/dashboard/dashboardmenu.css';
import Dashboardmenup from '../../../../libraryportal/pagecomponents/dashboard/dashboardmenu.js';

import '../../../../libraryportal/pagecomponents/dashboard/dashboard.css';
import Dashboardp from '../../../../libraryportal/pagecomponents/dashboard/dashboard.js';

import '../../../../libraryportal/pagecomponents/dashboard/detailsgraph.css';
import Detailsgraphdsp from '../../../../libraryportal/pagecomponents/dashboard/detailsgraph.js';

import '../../../../libraryportal/pagecomponents/dashboard/doughtgraph.css';
import Doughtgraphdsp from '../../../../libraryportal/pagecomponents/dashboard/doughtgraph.js';





//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Librarydashadh1() {
    return (
        <>
            <Navbaradh1 />

            <Dashboardmenup />

            <Dashboardp />

            <Detailsgraphdsp />

            <Doughtgraphdsp />

           
            
        </>
    )
}

export default Librarydashadh1;