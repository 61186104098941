import React from "react";
import './clubsstate.css';

const Clubsstatement = () => {
  return (
    <>
        <div className="clubsmnstatementcont">
            <div className="clubsthestatement">
                <h2>Clubs provide a space to explore interests, build friendships with like-minded peers, and develop essential life skills like teamwork and communication. </h2>
            </div>
        </div>
         
    </>
  );
};

export default Clubsstatement;