import React from 'react';

import Agricultureintro from '../pagecomp/agriculture/agricultureintro';
import '../pagecomp/agriculture/agricultureintro.css';

import Agricultureintroimg from '../pagecomp/agriculture/agricultureimgintro';
import '../pagecomp/agriculture/agricultureimgintro.css';

import Agriculturestatement from '../pagecomp/agriculture/agriculturestate';
import '../pagecomp/agriculture/agriculturestate.css';

import Agriculturepara from '../pagecomp/agriculture/agriculturepara';
import '../pagecomp/agriculture/agriculturepara.css';

import Agriculturemenu from '../pagecomp/agriculture/agriculturemenu';
import '../pagecomp/agriculture/agriculturemenu.css';

import Agricultureimggrid from '../pagecomp/agriculture/agriculturegrid';
import '../pagecomp/agriculture/agriculturegrid.css';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Agriculturepage() {
    return (
        <>
            <NavbarMs />

            <Agricultureintro />

            <Agricultureintroimg />

            <Agriculturestatement />

            <Agriculturepara />

            <Agriculturemenu />

            <Agricultureimggrid />

            <Footer />
        </>
    )
}

export default Agriculturepage;