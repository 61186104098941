import React from 'react';
import '../pagecomponents/phome/navbarp.css';
import Navbar from '../pagecomponents/phome/navbarp';
import '../pagecomponents/phome/sidebar.css';
import Sidebar from '../pagecomponents/phome/sidebar';
import LessOutline from '../pagecomponents/lessons/lessoutline';
import '../pagecomponents/lessons/lessoutline.css';
//import '../pagecomponents/phome/subject.css';
//import Subject from '../pagecomponents/phome/subject';

//intial build then rome to correct place
import '../pagecomponents/contents/content.css';
import Lesson from '../pagecomponents/lessons/lesson';

function Lessonp() {
    return (
        <>
            <Navbar />

            <LessOutline />

            <Lesson />

        </>
    )
}

export default Lessonp;