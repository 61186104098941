import React from 'react';
import './about.css';
import { Link } from 'react-router-dom';

/*
<div className="about-section">
        <h2>About Us</h2>
        <img src="https://ucarecdn.com/a56cfeab-0f48-4d60-be64-03f09dde4e90/-/preview/1000x374/" alt="placeholder" />
      </div>
*/

function About() {
  return (
    <div className="container">
      
      <div className="mission-school-profile">
        <div className="mission">
          <h3>Vision</h3>
          <p>To strive to develop a school that will remain a leader in the region in the provision of quality education</p>
        </div>
        <div className="school-profile">
          <h3>Mission Statement</h3>
          <p>To develop each child to their fullest potential, socially, culturally, academically, emotionally, equipping them with the necessary skills for life in a happy and friendly environment.</p>
        </div>
        <div className="mission">
          <h3>School Profile</h3>
          <p>Established in 1990, Masiyephambili Junior School is a partnership among businesses and schools created to improve education in science, mathematics, and technology. Representatives from business and industry and staff of Bulawayo Schools worked together in curriculum and facilities development for the school.</p>
        </div>
      </div>
      <div className="adminblock">
        <div className="adminsyd">
          <h2>Administration</h2>
          <button>Meet Our Administrative Team</button>
        </div>
        <div className="staffsyd">
          <h2>Staff Directory</h2>
          <button>View Our Staff Directory</button>
        </div>
        <div className="boarddsyd">
          <h2>Board of Directors</h2>
          <Link 
            to="/boardofdirectors" 
            //to="/adminteam" onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                           
            style={{ textDecoration: 'none', color: 'black' }}
          >
          <button>View Our Board of Directors</button>
          </Link>
        </div>
      </div>
      
      
    </div>
  );
}

export default About;
