import React from "react";
import './cultureintro.css';

const Cultureintro = () => {
  return (
    <>
        <div className="cultureintrocont">
            <div className="cultureintrostatement">
                <h2>Culture</h2>
            </div>
        </div>
         
    </>
  );
};

export default Cultureintro;