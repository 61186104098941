import React from 'react';

import Slogin from '../pagecomp/sloginp/slogin';
import '../pagecomp/sloginp/slogin.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function StudentPortal() {
    return (
        <>
            <NavbarMs />

            <Slogin />

            <Footer />
        </>
    )
}

export default StudentPortal;