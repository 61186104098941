import React from 'react';

import PTApa from '../pagecomp/PTA/pta';
import '../pagecomp/PTA/pta.css';

import PTAintro from '../pagecomp/PTA/ptaintro';
import '../pagecomp/PTA/ptaintro.css';

import Ptaintroimg from '../pagecomp/PTA/ptaintroimg';
import '../pagecomp/PTA/ptaintroimg.css';

import Ptamenu from '../pagecomp/PTA/ptamenu';
import '../pagecomp/PTA/ptamenu.css';


import PTAprodgrid from '../pagecomp/PTA/ptaprodgrid';
import '../pagecomp/PTA/ptaprodgrid.css';


//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function PTApage() {
    return (
        <>
            <NavbarMs />

            <PTAintro />

            <Ptaintroimg />

            <PTApa />

            <Ptamenu />

            <PTAprodgrid />


            <Footer />
        </>
    )
}

export default PTApage;