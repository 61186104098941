import React, { useState, useEffect } from 'react';
import './dashboardmenu.css';
import { Link } from 'react-router-dom';
import {Chart as ChartJS} from "chart.js/auto";
import {Bar, Doughtnut, Line} from "react-chartjs-2";
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Detailsgraphdsp() {
    //dashboardlibadminmenu
    const [localData,  setlocalData] = useState([]);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcirculationbooks = '/circulatingbooks';
    const getcirculationbooksurl = baseUrl + dynamicgetcirculationbooks;

    
    //const booktype = "physica

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getcirculationbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    if (localData !== null) {

    const categoryCounts = localData.reduce((acc, item) => {
      acc[item.category] = (acc[item.category] || 0) + 1;
      return acc;
    }, {});

    //const categories = Array.from(new Set(localData.map(item => item.category)));

    /*const categories = [];
    for (let item of localData) {
      if (!categories.includes(item.category)) {
        categories.push(item.category);
        if (categories.length === 10) break;
      }
    }*/

    // Sort the categories by their counts
  const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);

  // Take the first 10 categories just changed categories
  const topCategories = sortedCategories.slice(0, 10);


  return (
    <>
        <div className="detailsgraphadmin">
            <h2>Top 10 Genres in Circulation</h2> 
            <div className="customerCard">
                <Bar
                    data={{
                        labels: topCategories,
                        datasets: [
                            {
                                label: "Gerne",
                                data: topCategories.map(category => categoryCounts[category]),
                                backgroundColor: [
                                  "rgba(0, 255, 0, 0.5)"
                                ],
                                borderRadius: 5,
                            },
                        ]
                    }}
                />  
            </div>  
        </div>
    </>
  );
  }

    
}

export default Detailsgraphdsp;