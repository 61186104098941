const outlines = (outlines= {outlines:null, loading: false}, action) => {
    //console.log("logging data in posts.js",posts)

    switch (action.type){
        case 'UPDATE':
            return outlines.map((post)=> post._id === action.payload._id ? action.payload : post)
            
        case 'FETCH_ALL':
            return {loading: false, outlines: action.payload};
        case 'CREATE':
            return [...outlines, action.payload];
        default:
            return outlines;
    }
    
}

export default outlines;