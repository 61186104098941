import React, { useState } from "react";
import './ptaprodinfo.css';
import {  useLocation, Link } from 'react-router-dom';

const PTAproductinfo = () => {
    const [proddescrip, setProddescrip] = useState(false);
    const [returnrefund, setReturnrefund] = useState(false);
    const [shippinginfo, setShippinginfo] = useState(false);

    const location = useLocation();

    const productname = location.state.data.productname;
    const imglink = location.state.data.imglink;
    const price = location.state.data.price;
    const description = location.state.data.description;

    const toggleproddescrip = () => {
        setProddescrip(!proddescrip);
        setReturnrefund(false);
        setShippinginfo(false);
    };

    const togglereturnrefund = () => {
        setReturnrefund(!returnrefund);
        setProddescrip(false);
        setShippinginfo(false);
    };

    const toggleshippinginfo = () => {
        setShippinginfo(!shippinginfo);
        setProddescrip(false);
        setReturnrefund(false);
    };
  return (
    <>
         <div className="ptaprodinfomaincont">
            <div className="ptaprodinfoimgbox">
                <h3>PTA/{productname}</h3>
            </div>
            <div className="ptaprodinfoimg">
                <img className="Ptaprodinfoactualimg" alt="SAS" src={imglink} />
                <p className="Ptaprodinfoactualimgdescrip">{description}</p>
            </div>

            <div className="ptaprodinfodescrip">
                <h2 className="ptaprodinfoname">{productname}</h2>

                <p className="ptaprodinfoprice">${price}</p>

                <p className="ptaprodinfoquantity">Quantity</p>

                <input type="number" className="ptaprodinfoquantitynum" name="ptaprodinfoquantitynum" placeholder='0' />

                <input type="text" className="ptaprodinfoclientname" name="ptaprodinfoclientname" placeholder='Fullname' />

                <input type="text" className="ptaprodinfoclientname" name="ptaprodinfoclientname" placeholder='Phonenumber' />

                <button className="ptaprodinfobtn">Place Order</button>

                <div className="ptaprodinfodescriptionbox">
                    <div className="h3ptaprodinfoboxcont" onClick={toggleproddescrip}>
                        {proddescrip ?
                        <>
                        <h3 className="h3ptaprodinfobox">PRODUCT INFO</h3>
                        <h3 className="h3ptaprodinfoboxsign">-</h3>
                        </>
                        :
                        <>
                        <h3 className="h3ptaprodinfobox">PRODUCT INFO</h3>
                        <h3 className="h3ptaprodinfoboxsign">+</h3>
                        </>
                        }
                    </div>
                    {proddescrip &&
                    <div className="ptaprodinfostatebox">
                        <p className="ptaprodinfostatepara">{description}</p>
                    </div>
                    }
                </div>

                <div className="ptaprodreturnandrefundbox">
                    <div className="h3ptaprodreturnandrefundboxcont" onClick={togglereturnrefund}>
                        {returnrefund ?
                        <>
                        <h3 className="h3ptaprodreturnandrefundbox">RETURN & REFUND POLICY</h3>
                        <h3 className="h3ptaprodreturnandrefundboxsign">-</h3>
                        </>
                        :
                        <>
                        <h3 className="h3ptaprodreturnandrefundbox">RETURN & REFUND POLICY</h3>
                        <h3 className="h3ptaprodreturnandrefundboxsign">+</h3>
                        </>
                        }
                    </div>
                    {returnrefund &&
                    <div className="ptaprodreturnandrefundstatebox">
                        <p className="ptaprodreturnandrefundstatepara">We want you happy with your school uniform purchase!  Return any unworn, undamaged items with tags within 5 days of purchase for a full refund. Damaged or incorrect items can be exchanged at the PTA Shop.</p>
                    </div>
                    }
                </div>

                <div className="ptaprodshippinginfobox">
                    <div className="h3ptaprodshippinginfoboxcont" onClick={toggleshippinginfo}>
                        {shippinginfo ?
                        <>
                        <h3 className="h3ptaprodshippinginfobox">SHIPPING INFO</h3>
                        <h3 className="h3ptaprodshippinginfoboxsign">-</h3>
                        </>
                        :
                        <>
                        <h3 className="h3ptaprodshippinginfobox">SHIPPING INFO</h3>
                        <h3 className="h3ptaprodshippinginfoboxsign">+</h3>
                        </>
                        }
                    </div>
                    {shippinginfo &&
                    <div className="ptaprodshippinginfostatebox">
                        <p className="ptaprodshippinginfostatepara">Skip the delivery wait! To save on shipping costs and ensure a perfect fit, choose the convenient in-store pickup option at checkout. Simply select your items online or over the phone and we'll have your order ready for collection at the school shop within 3 business days. We'll send you a notification when your order is ready for pickup, so you can stop by at your convenience during our store hours. </p>
                    </div>
                    }
                </div>

                

            </div>
        </div>
         
    </>
  );
};

export default PTAproductinfo;