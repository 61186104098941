import { combineReducers } from 'redux';
import posts from './posts';
import users from './users';
import subjects from './subjects';
import outlines from './outlines';
import notes from './notes';

export default combineReducers({posts, users, subjects, outlines, notes});


