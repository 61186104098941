import React from 'react';
import '../pagecomponents/phome/navbarp.css';
import Navbar from '../pagecomponents/phome/navbarp';
import '../pagecomponents/library/library.css';
import Examsec from '../pagecomponents/library/exampaper';

//intial build then rome to correct place

function Exampapersp() {
    return (
        <>
            <Navbar />

            <Examsec />

            

        </>
    )
}

export default Exampapersp;