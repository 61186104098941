import React, { useState, useEffect} from "react";
import "./onlinecatblock.css";
import { useLocation, Link } from "react-router-dom";

const OnlineCatblockp = () => {
    const [localData,  setlocalData] = useState([]);

    const location = useLocation();

    const booktype = location.state.data.typebook;
    const categoryview =  location.state.data.categoryview;
    const catname =  location.state.data.catname; 
    var pagen = location.state.data.nxtpage;
    const searchactive = location.state.data.searchactive;
    const searchterm = location.state.data.searchterm;

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetspecbooks = '/getcatspecbooks';
    const getcatspecbooksurl = baseUrl + dynamicgetspecbooks;

    const dynamicsearchcatspecbooks = '/searchcatspecbooks';
    const searchcatspecbooksurl = baseUrl + dynamicsearchcatspecbooks;


    console.log("searchactive", searchactive)

    

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        // <a href="/onlinelibinfo">
        if (searchactive === "off"){

        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getcatspecbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ categoryview, booktype, pagen })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
        }else if (searchactive === "on") {
          //searchcatgridbooks
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchcatspecbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ booktype, searchterm, pagen })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();

        }
        
      
         
           
      
    }, [categoryview, booktype]);


if (localData !== null) {
    const getbooksgrid = localData.map((post, index) =>
        <div className="notimgridbufferblock" key={post._id}>
           
                <Link
                    to = "/onlinelibinfo"
                    state = {{
                    data: {imglink: post.imglink, pdflink: post.pdflink, bio: post.authorbio,  bio2: post.authorbio2,bio3: post.authorbio3, authorname: post.authorname, title: post.title, typebook: booktype }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                    }}
                    style={{ textDecoration: 'none', color: 'black', padding: 0}}
                >
                <div className="notimgridblock" >
                    <div className="actualimgblock">
                        <img src={post.imglink} alt="bookimg" />
                        <p>{post.title}</p>
                        <p className="ntbktitleblock">{post.authorname}</p>
                    </div> 
                </div>
                </Link>
            
        </div>
    
    )


  return (

    
    <>
        <div className="catgridmainblock">
            <div className="cattitleblock">
                <h2>{catname}</h2>
                <div className="notablegridblock">
                    {localData.length === 0 ?
                      <p>Book not available</p>
                      :
                      getbooksgrid
                    }
                </div>
            </div>  
        </div>
    
        
    </>
  );
};
};

export default OnlineCatblockp;