import React from "react";
import "./sidebar.css";

function Sidebar() {

   
  
    return (
      <>
      <div className="Sidebar-main">
        <div className="StuffTopic">
            <p>Stuff</p>
        </div>
        <div className="StuffOption">
            <a href="#">Courses</a>
        </div>
        <div className="AccountTopic">
            <p>Account</p>
        </div>
        <div className="AccountOption">
            <a href="#">Progress</a>
            <a href="#">Profile</a>
            <a href="#">Report</a>
        </div>
          
      </div>
      </>
    );
  }
  
  export default Sidebar;