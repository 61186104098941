import React from 'react';

import Clubsintro from '../pagecomp/clubs/clubsintro';
import '../pagecomp/clubs/clubsintro.css';

import Clubsintroimg from '../pagecomp/clubs/clubsimgintro';
import '../pagecomp/clubs/clubsimgintro.css';

import Clubsstatement from '../pagecomp/clubs/clubsstate';
import '../pagecomp/clubs/clubsstate.css';

import Clubspara from '../pagecomp/clubs/clubspara';
import '../pagecomp/clubs/clubspara.css';

import Clubsmenu from '../pagecomp/clubs/clubsmenu';
import '../pagecomp/clubs/clubsmenu.css';

import Clubsimggrid from '../pagecomp/clubs/clubsgrid';
import '../pagecomp/clubs/clubsgrid';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Clubspage() {
    return (
        <>
            <NavbarMs />

            <Clubsintro />

            <Clubsintroimg />

            <Clubsstatement />

            <Clubspara />

            <Clubsmenu />

            <Clubsimggrid />

            <Footer />
        </>
    )
}

export default Clubspage;