import React, { useState, useEffect } from "react";
import "./quiz.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";




function Quiz(props) {



    const location = useLocation();

   

    const [npdata, setNpdata] = useState('');
    
    

   
    const filterf = location.state.data.clicked;
    const subjectnm = location.state.data.topic;
    const outltopic = location.state.data.outltopic;
    const sub1t = location.state.data.sub1t;
    const lessopt = location.state.data.lessopt;
    const lock = location.state.data.lock;

    

    
    
    //const { state } = props.location;
    //console.log("sent data", props )
    
    

    const [contentData, setContentData] = useState({
        subjectnm: subjectnm,
        outltopic: outltopic
    });

    const [localData,  setlocalData] = useState([]);

    const [isShown, setIsShown] = useState(false);

    const [quenum, setQuenum] = useState(0);
    const [queimage, setQueimage] = useState("");
    const [optimage, setOptimage] = useState("");

    const [userenter, setUserenter] = useState(undefined);
    const [userradio, setUserradio] = useState("unchecked");

    const [btnEnabled, setBtnEnabled] = useState(false);

    const [chcorrect, setChcorrect] = useState("");

    const [questionData, setQuestionData] = useState();
    const [findit, setFindit] = useState();
    const [ansValue, setAnsValue] = useState();
    const [percentage, setPercentage] = useState("");

    //Options handle use states
    const [queType, setQueType] = useState();
    const [optionA, setOptionA] = useState();
    const [optionB, setOptionB] = useState();
    const [optionC, setOptionC] = useState();
    const [optionD, setOptionD] = useState();
    const [optionE, setOptionE] = useState();


    //Update database for quiz and test
    const [testdata, setTestdata] = useState({
      quizid: "",
      username: "",
      noque: 0,
      quizname: "",
      quizscore: 0
    });

    const [quizcount, setQuizcount] = useState(0);
    const [wrong, setWrong] = useState(false);

   


    console.log("data to be sent to next page", contentData.filterf)

    const letsgo = (a,e) => {
      e.preventDefault();
      setIsShown(current => !current)
    }

    const handleAnsSubmit = (e) => {
      e.preventDefault();

      if (queType === "enter"){
        const useranswer = e.target.useranswer.value;
        const actualanswer = e.target.actualanswer.value;
        if (actualanswer === useranswer){
          setUserenter(useranswer);
          if (wrong === false ){
            const quizscore = quizcount + 1;
            setQuizcount(quizscore);
          }else{
            setWrong(false)

          }

          setChcorrect(true)
          
        }else{
          setChcorrect(false)
          setWrong(true)
        }
      }else{
        const userradioanswer = e.target.radioB.value;
        const actualanswer = e.target.actualanswer.value;
        if (actualanswer === userradioanswer){
          if (wrong === false ){
            const quizscore = quizcount + 1;
            setQuizcount(quizscore);
          }else{
            setWrong(false)
          }
          setChcorrect(true)
        }else{
          setChcorrect(false)
          setWrong(true)
        }

      }
      
    }
    
    const handleChange = (e) => {
      const useranswer = e.target.value;
      console.log("userabc", quizcount)

      if (useranswer.length > 0){
        setBtnEnabled(true)
      }else{
        setBtnEnabled(false)
      }

      if (queType === "options"){
        setUserradio(useranswer)
        setChcorrect("");
      }
       
    }

    const Nextpage = (e) => {
      
      setBtnEnabled(false);
      setChcorrect("");
      setQuenum(quenum + 1);
      setAnsValue('');

      setUserenter("");
      setUserradio("uncheck");

    }

    const Summary = (e) => {
      e.preventDefault();

      const updatetestuser = async () => {
        //await dispatch(getOutlines(parsedObj));
        await fetch('http://localhost:5000/test-update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ testdata })
        })
          .then(response => response.json())
          .then(data => {
            console.log("data contents db",data)
            
        });
        
      };

      updatetestuser();

      setPercentage("show")
  
    }
 

    console.log("reloading")

    useEffect(() => {
      
      //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
        const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/lesson-filter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentData })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)
              if (data.outline.length > 0) {
                //console.log("logging data", data[0].subtopics);
                const contentcheck = data.outline.map((post) => post.title)
                console.log("mapping",contentcheck);
                if (contentcheck !== null) {
                  setlocalData(data.outline);
                }else {
                  fetchContents();
                }
                
                  //localStorage.setItem('array', JSON.stringify(data));
              }
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();
    
       
         
    
       }, []);

       console.log("localdata after db out",localData)

       function Startmessag() {
        return (localData.map((post) =>
          <div className="quiz-checkB10" key={post._id}>
            {post.subtopic.map((type, indexL)=>
            <div className="quiz-checkb20" key={type._id}>
              {type.subtopic2.map((enter)=>
                <div className="quiz-checkB20" key={enter._id}>
                  {enter.sub2lesstitle  === filterf &&
                    enter.lessonotes.map((close, index)=>
                      <div className="quizchecktopic0" key={close._id}>
                        {(index  === 0 && lessopt === undefined && indexL === lock && close.videoviewlink === "quiz") ||(lessopt  === close.sublesstitle && indexL === lock && close.videoviewlink === "quiz") || (lessopt === undefined && indexL === lock && close.videoviewlink === "quiz")?
                          <div className="quiz-startmain">
                            <div className="quizstartbox">
                              <h2>All set for the unit test?</h2>
                              <p className="firstp">Welcome to the unit test - where you get to test your skills for the entire unit</p>
                              <p className="Secondp">10 questions . 9 - 18 minutes</p>
                            </div>
                            <div className="startbutton">
                              <button className="startquizB" onClick={(e) => letsgo("start",e)}>Lets go</button>
                            </div>
                          </div>
                          :
                          null
                          
                        }
                        
                      </div>
                    )
                  }
                </div>
              )}
            </div>
            )}
          </div>
        ))

       }

 

      

    useEffect(() => {
      localData.map((post) =>
          <div className="quiz-checkB1" key={post._id}>
            {post.subtopic.map((type, indexL)=>
            <div className="quiz-checkb2" key={type._id}>
              {type.subtopic2.map((enter)=>
                <div className="quiz-checkB2" key={enter._id}>
                  {enter.sub2lesstitle  === filterf &&
                    enter.lessonotes.map((close, index)=>
                      <div className="quizchecktopic" key={close._id}>
                        {(index  === 0 && lessopt === undefined && indexL === lock && close.videoviewlink === "quiz") ||(lessopt  === close.sublesstitle && indexL === lock && close.videoviewlink === "quiz") || (lessopt === undefined && indexL === lock && close.videoviewlink === "quiz")?
                          close.quiz.map((newt, index)=>
                            <div className="quizcheckque1" key={newt._id}>
                              { (newt.quizNo === 1) && 
                              newt.quizQue.map((newt2, index)=>
                                <div className="quenumbuffer" key={index}>
                                  { (quenum === index) && 
                                  
                                  <div className="collect-data">
                                    {setQuestionData(newt2.question)}
                                    {setFindit(newt2.finditem)}
                                    {setAnsValue(newt2.answer)}
                                    {setQueType(newt2.anstype)}
                                    {setQueimage(newt2.queimage)}
                                    {setOptimage(newt2.optimage)}
                                    {setTestdata({quizname: close.sublesstitle, quizid: newt.quizid, noque: newt.noquizque, quizscore: quizcount, username: localStorage.getItem('theusername')})}
                                    { (newt2.anstype === "options") &&
                                      
                                      newt2.options.map((newt3, index)=>
                                        <div className="getopt" key={index}>
                                          {(index === 0)?
                                            setOptionA(newt3)
                                          :(index === 1)?
                                            setOptionB(newt3)
                                          :(index === 2)?
                                            setOptionC(newt3)
                                          :(index === 3)?
                                            setOptionD(newt3)
                                          :
                                            setOptionE(newt3)
                                          }
                                         
                                        </div>
                                      )
                                      
                                    }
                                  </div>
                                   }
                                </div>
                              )
                              }
                                
                            </div>
                          )
                          :
                          null
                          
                        }
                        
                      </div>
                    )
                  }
                </div>
              )}
            </div>
            )}
          </div>
        )


    }, [isShown, filterf, lessopt, localData, lock, quenum, quizcount, chcorrect, wrong])

    useEffect(() => { 
      if (userenter === "") {
        setUserenter(undefined);
      }else if (userradio === "uncheck"){
        setUserradio("")
      }

    }, [(userenter===''),(userradio === "uncheck")])

    if (localData !== null) {
      

    return (
      <>
      {(percentage === "show") ?
      <>
        <div className="mainpercentage">
          <div className="boxpercentage">
            <p className="summaryp">Summary</p>
            <p className="summcorr">Correct: {quizcount}</p>
            <p className="summwron">Wrong: {testdata.noque + 1 - quizcount}</p>
            <p className="summperc">Percentage: {(quizcount / (testdata.noque + 1)) * 100}%</p>
          </div>
        </div>
        <div className="startcheck">
        <button className='checkquizB' type="submit" >Continue</button>
        </div>
      </>
      :isShown ?
        
        <>
        <div className="quiz-main">
          <form onSubmit={handleAnsSubmit}>
            <div className="bigquestion-box">
              <div className="question-box">
                <div className="smallquestion-box">
                  <input type="hidden" name="actualanswer" value={ansValue}></input> 
                  <div className={(queimage !== "") ? "questionsentimg" : "questionsent"}>
                    <p>{questionData}</p>
                  </div>
                  {queimage !== "" &&
                    <div className="question-image">
                      <img src={queimage} alt="arraypic" />
                    </div>
                  }
                  {queType === "enter"?
                    <label>{findit}<input type="number" name="useranswer" value={userenter} onChange={handleChange}/></label>
                  :
                    <div className="optionabc">
                      <div className={(chcorrect === true) ? "optionitC" :(chcorrect === false) ? "optionitW" : "optionit" }>
                        <input type="radio" checked = {(userradio === "A")} name="radioB" id='radio1' value="A" onChange={handleChange}/>
                        <label htmlFor='radio1'>A</label> 
                        {optimage === "no" ?
                          <p>{optionA}</p>
                        :
                          <img src={optionA} alt="optionpic" />
                        }
                      </div>
                      <div className={(chcorrect === true) ? "optionitC" :(chcorrect === false) ? "optionitW" : "optionit" }>
                        <input type="radio" checked = {(userradio === "B")} name="radioB" id="radio2" value="B" onChange={handleChange}/>
                        <label htmlFor='radio2'>B</label> 
                        {optimage === "no" ?
                          <p>{optionB}</p>
                        :
                          <img src={optionB} alt="optionpic" />
                        }
                      </div>
                      <div className={(chcorrect === true) ? "optionitC" :(chcorrect === false) ? "optionitW" : "optionit" }>
                        <input type="radio" checked = {(userradio === "C")} name="radioB" id="radio3" value="C" onChange={handleChange}/>
                        <label htmlFor="radio3">C</label> 
                        {optimage === "no" ?
                          <p>{optionC}</p>
                        :
                          <img src={optionC} alt="optionpic" />
                        }
                      </div>
                      <div className={(chcorrect === true) ? "optionitC" :(chcorrect === false) ? "optionitW" : "optionit" }>
                        <input type="radio" checked = {(userradio === "D")} name="radioB" id="radio4" value="D" onChange={handleChange}/>
                        <label htmlFor="radio4">D </label>
                        {optimage === "no" ?
                          <p>{optionD}</p>
                        :
                          <img src={optionD} alt="optionpic" />
                        }
                      </div>
                      <div className={(chcorrect === true) ? "optionitC" :(chcorrect === false) ? "optionitW" : "optionit" }>
                        <input type="radio" checked = {(userradio === "E")} name="radioB" id="radio5" value="E" onChange={handleChange}/>
                        <label htmlFor="radio5">E</label>
                        {optimage === "no" ?
                          <p>{optionE}</p>
                        :
                          <img src={optionE} alt="optionpic" />
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="popupcontainer">
                {(chcorrect === true)?
                  <p className="correctp"><span>correct</span></p>
                :(chcorrect === false)?
                  <p className="wrongp"><span>wrong</span> try again</p> 
                :
                null
                }
              </div>
            </div>
            <div className="startcheck">
              {console.log("currentquestionno", quenum)}
              {console.log("numberofquestions", testdata.noque)}
              {(chcorrect === true)?
                (quenum === testdata.noque) ? 
                <button className='nextquestionB' onClick={(e) => Summary(e)} >Summary</button>
                :
                <button className='nextquestionB' onClick={(e) => Nextpage(e)} >Next Question</button>
                
              :
                <button className={btnEnabled === false ? 'checkquizBnotactive': 'checkquizB'} type="submit" disabled={btnEnabled === false} >Check</button>
              }
            </div>
          </form>
        </div>
        </>

      :
      < Startmessag/>
      }
      </>
      
    );
    }
}


  
export default Quiz;