import React from "react";
import './Footer.css';

const Footer = () => {
  return (
    <>
        <div className="footer-container">
        <div className="footer-item">
            <h3>Visit Us</h3>
            <p>
                2710 Cowdray Park
                <br></br>
                PO Luveve
                <br></br>
                Bulawayo
            </p>
            <br></br>
            <a href="#">Get Directions</a>
        </div>
        <div className="footer-item">
            <h3>Contact Us</h3>
            <p>Phone: 0771799999</p>
            <br></br>
            <a href="#">Email Us</a>
        </div>
        <div className="footer-item">
            <h3>Quick Links</h3>
            <ul>
            <li>
                <a href="#">About Us</a>
            </li>
            <li>
                <a href="#">Admissions</a>
            </li>
            <li>
                <a href="#">Student Portal</a>
            </li>
            <li>
                <a href="#">Parent's Place</a>
            </li>
            </ul>
        </div>
        <div className="footer-item">
            <h3>Social Media</h3>
            <ul className="social-media-icons">
            <li>
                <a href="#">
                <i className="fa fa-facebook"></i>
                </a>
            </li>
            <li>
                <a href="#">
                <i className="fa fa-twitter"></i>
                </a>
            </li>
            <li>
                <a href="#">
                <i className="fa fa-instagram"></i>
                </a>
            </li>
            </ul>
        </div>
       
        </div>
        <div className="powered"><h4>Power by this Company @2023</h4></div>
        
    </>
  );
};

export default Footer;
