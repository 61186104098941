import React, { useState } from 'react';
import './ptamenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Ptamenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

   
  return (
    <>
       <div className="ptamenu"> 
            <div className="ptamenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Shop Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Shop Menu  &#9776;</h2>
                }
                <div className={`ptamenugerneblock ${showGerne ? 'ptamenushow-gerne' : ''}`}>
                    <div className="ptamenuactualgerne">
                        <Link 
                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothestype: "all" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>All</p> 
                        </Link>
                        <Link 
                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothtype: "hats" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Hats</p> 
                        </Link>
                        <Link 
                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothtype: "trousers" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Trousers</p>
                        </Link>
                        <Link 
                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothestype: "scarf" } 
                            }}
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Scarf</p>
                        </Link>
                        
                        <>
                        <Link 
                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothestype: "shirts" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Shirts</p>
                        </Link>
                        <Link 
                             to="/pta" 
                             //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                             state = {{
                                 data: { clothestype: "track suit" } 
                             }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Track Suit</p> 
                        </Link>
                        <Link 

                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothestype: "swimming costume" } 
                            }}
                                                        
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Swimming Costumes</p>
                        </Link>
                        <Link 

                            to="/pta" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { clothestype: "bags" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Bags</p>
                        </Link>
                        </>
                        
                        <Link 
                            to="/pta" 

                            state = {{
                                data: { clothestype: "shorts" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Shorts</p>
                        </Link>
                        <Link 
                            to="/pta" 

                            state = {{
                                data: { clothestype: "jerseys" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Jerseys</p>
                        </Link>
                        <Link 
                            to="/pta" 

                            state = {{
                                data: { clothestype: "blazers" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Blazers</p>
                        </Link>
                        <Link 
                            to="/pta" 

                            state = {{
                                data: { clothestype: "tie" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Tie</p>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    </>
  );


    
}

export default Ptamenu;