import React from 'react';
import '../pagecomponents/addbooks/navbarlibrian.css';
import Navbar from '../pagecomponents/addbooks/navbarlibrian';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';
import '../pagecomponents/qrgenerate/qrgenerate.css';
import Qrgenerate from '../pagecomponents/qrgenerate/qrgenerate';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Qrgeneratelp() {
    return (
        <>
            <Navbar />

            <Qrgenerate />




        </>
    )
}

export default Qrgeneratelp;