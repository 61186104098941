import React from 'react';
import '../pagecomp/homep/Carasoule.css';
import Carasoule from '../pagecomp/homep/Carasoule';

import '../pagecomp/homep/statement.css';
import Statement from '../pagecomp/homep/statement';

import '../pagecomp/homep/learnmore.css';
import Learnmore from '../pagecomp/homep/learnmore';

import '../pagecomp/homep/homeabt.css';
import Homeabt from '../pagecomp/homep/homeabt';

import '../pagecomp/homep/whoarewe.css';
import Whoarewe from '../pagecomp/homep/whoarewe';

import '../pagecomp/homep/missionstate.css';
import Missionstate from '../pagecomp/homep/missionstate.js';

import '../pagecomp/homep/masiyevalue';
import Masiyevalue from '../pagecomp/homep/masiyevalue.js';

import '../pagecomp/homep/lifeatmasiye';
import Lifeatmasiye from '../pagecomp/homep/lifeatmasiye.js';

import '../pagecomp/homep/GridWithText.css';
import GridWithText from '../pagecomp/homep/GridWithText';

/*import '../pagecomp/homep/navbarm.css';
import NavbarM from '../pagecomp/homep/navbarm';*/

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';


function Home() {
    return (
        <>
           

            <NavbarMs />

            <Carasoule />

           
            <Statement />

            <Homeabt />

            <Learnmore />

            <Whoarewe />

            <Missionstate />

            <Lifeatmasiye />

            <GridWithText />

            <Masiyevalue />

            <Footer />
        </>
    )
}

export default Home;