import React from 'react';

import Cultureintro from '../pagecomp/culture/cultureintro';
import '../pagecomp/culture/cultureintro.css';

import Culturestatement from '../pagecomp/culture/culturestate';
import '../pagecomp/culture/culturestate.css';

import Cultureintroimg from '../pagecomp/culture/cultureimgintro';
import '../pagecomp/culture/cultureimgintro.css';

import Culturepara from '../pagecomp/culture/culturepara';
import '../pagecomp/culture/culturepara.css';

import Culturemenu from '../pagecomp/culture/culturemenu';
import '../pagecomp/culture/culturemenu';

import Cultureimggrid from '../pagecomp/culture/culturegrid';
import '../pagecomp/culture/culturegrid.css';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Culturepage() {
    return (
        <>
            <NavbarMs />

            <Cultureintro />

            <Cultureintroimg />

            <Culturestatement />

            <Culturepara />

            <Culturemenu />

            <Cultureimggrid />

            <Footer />
        </>
    )
}

export default Culturepage;