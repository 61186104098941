import React from 'react';
import '../pagecomponents/addbooks/navbarlibrian.css';
import Navbarlibrian from '../pagecomponents/addbooks/navbarlibrian.js';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';
import '../pagecomponents/addonlinebooks/addonlinebook.css';
import AddOnlineBooks from '../pagecomponents/addonlinebooks/addonlinebook';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Addonlinebooksp() {
    return (
        <>
            <Navbarlibrian />

            <AddOnlineBooks />




        </>
    )
}

export default Addonlinebooksp;