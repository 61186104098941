import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./content.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
//import { useDispatch, useSelector } from 'react-redux';
//import { getSubjects } from "../../../../actions/subjects";
import PdfViewer from "./pdfviewer"

/*

        <div className="pdf-card">
            < PdfViewer pdfUrl="https://drive.google.com/file/d/1Egm6n123XOxsB2kikhC0WMXe0YKz0MwJ/view?usp=sharing" />
        </div>


*/

function Content(props) {



    const location = useLocation();
    //var contentcard = "no data received";


   
    const filterf = location.state.data.filterf;
    const topic = location.state.data.topic;

    var indexstart = 0;
    
    
    //const { state } = props.location;
    console.log("sent data", props )
    console.log("sent location", location.state.data.filterf )
    console.log("sent topic", location.state.data.topic )

    const [contentData, setContentData] = useState({
        filterf: filterf,
        topic: topic
    });

    const [localData,  setlocalData] = useState([]);

    const [npdata, setNpdata] = useState('');

    

    console.log("data to be sent to next page", contentData.filterf)

    useEffect(() => {
      setNpdata(null)

      console.log('npdata', npdata)
      
        const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/content-filter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentData })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data.outline.length)
              if (data !== null) {
                //console.log("logging data", data[0].subtopics); data.outline.length
                const contentcheck = data.outline.map((post) => post.title)
                console.log("mapping",contentcheck);
                if (contentcheck !== null) {
                  setlocalData(data.outline);
                }else {
                  fetchContents();
                }
                
                  //localStorage.setItem('array', JSON.stringify(data));
              }
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();
    
       
         
    
       }, []);

       console.log("localdata after db",localData)

       if (localData !== null) { 
            console.log("entering working")

            const contentcard = localData.map((post) =>
            <div className="content-cardin1" key={post._id}>
                {post.subtopic.map((type)=>
                <div className="content-card" key={type._id}>
                   
                    <div className="content-cardtitle">
                        <p>{type.sub1title}{console.log("type.topic",type.sub1title)}</p>
                    </div>
                   
                    <div className="content-cardsubtop">
                        <ul>
                            {type.subtopic2.map((enter, indexsub2l)=>
                                <Link
                                to = "/Lesson"

                                state = {{
       
                                  data: {topic: contentData.filterf, clicked: enter.sub2lesstitle, outltopic: post.topic, sub1t: type.sub1title, sub2lessindex: indexsub2l, prevnextNum: null, sublesstitleIndex: indexstart, lock: type.lock  } // your data array of objects type.title 
                                }}
                                
                                style={{ textDecoration: 'none', color: 'black' }}

                                key={enter._id}
                                
                                >
                                
                                <li>{ enter.sub2lesstitle  !== "Quiz" && enter.sub2lesstitle !== "Test" ? enter.sub2lesstitle : null }</li>

                                </Link>
                                
                            )}
                        </ul>
                    </div>

                    <div className="content-cardbtn">
                        <button className="contentbutton">Continue</button>
                    </div>
               
                </div>
                
                )}
                
                

            </div>
            );
       

    

  
    return (
      <>
      <div className="Content-main">
        { contentcard }
      </div>
      </>
      
    );

  }
}


  
  export default Content;