import React from "react";
import './sportspara.css';

const Sportspara = () => {
    const homeabtimg = "https://ucarecdn.com/8dc62c32-addc-49bd-9036-f297394dcaaf/-/preview/1000x750/";

    return (
        <>
            <div className="mainsportsparacont">
                <div className="sportsparaimgcont">
                    <p className="sportsparathree">The world of sports is more than just winning and losing for children. It's a playground for building strong bodies and even stronger minds. They run, jump, throw, and kick, all while developing their cardiovascular health, strength, coordination, and agility. </p>

                    <p className="sportsparafour">But the impact of sports goes far beyond the physical. Sportsmanship is a valuable life skill honed on the field. Children learn to win with grace and lose with dignity, understanding that both are part of the game. They develop respect for their opponents, officials, and the rules of the game itself. This sense of fair play translates beautifully into other aspects of life, teaching valuable lessons about handling competition and overcoming challenges.</p>
                </div>

                <div className="sportsparatextcont">
                    <div className="sportsparaparacont">
                        <p className="sportsparaone">Teamwork is another cornerstone of sports, and children quickly discover the power of working together. They learn to communicate effectively, cooperate towards a common goal, and rely on their teammates for support. This collaborative spirit fosters a sense of belonging and builds strong social connections. </p>

                        <p className="sportsparatwo">Finally, sports offer a powerful platform for building self-esteem. As children learn new skills, achieve goals, and contribute to their team's success, their confidence flourishes. They experience the joy of accomplishment and develop a sense of perseverance, pushing themselves to improve and never giving up. </p>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

export default Sportspara;