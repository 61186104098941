import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/onlinelibrary/onlinelibinfor.css';
import Onlinelibinfor from '../pagecomponents/onlinelibrary/onlinelibinfor';

//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Onlinebookinfo() {
    return (
        <>
            <Libnavbar />

            <Onlinelibinfor />

        
        </>
    )
}

export default Onlinebookinfo;