import React from 'react';
import '../pagecomponents/thome/navbarte.css';
import Navbar from '../pagecomponents/thome/navbarte';
import TestAssignaddsec from '../pagecomponents/adtest/testassignmentst';



//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Addtest() {
    return (
        <>
            <Navbar />

            <TestAssignaddsec />
            
        </>
    )
}

export default Addtest;