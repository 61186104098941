import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./lessoutline.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
//import { useDispatch, useSelector } from 'react-redux';
//import { getSubjects } from "../../../../actions/subjects";
import PdfViewer from "./pdfviewer"
import VideoViewer from "./videoviewer"

/*

        <div className="pdf-card">
            < PdfViewer pdfUrl="https://drive.google.com/file/d/1Egm6n123XOxsB2kikhC0WMXe0YKz0MwJ/view?usp=sharing" />
        </div>


*/

function Lessoutline(props) {

    const [viewdata, setViewdata] = useState('');



    const location = useLocation();

    
    const filterf = location.state.data.clicked;
    const subjectnm = location.state.data.topic;
    const outltopic = location.state.data.outltopic;
    const sub1t = location.state.data.sub1t;
    const sub2lessindex = location.state.data.sub2lessindex;
    const lessopt = location.state.data.lessopt;
    const prevnextNum = location.state.data.prevnextNum;
    const sublesstitleIndex = location.state.data.sublesstitleIndex;
    const lock = location.state.data.lock;

    console.log("indexxxxxxxxxxxxxxL", lock)



    var filterf2 = 0;
    var NextVal = 0;
    var PrevVal = 0;
    

    

    
    
    //const { state } = props.location;
    //console.log("sent data", props )
    console.log("lessopt", location.state.data.lessopt )
    

    const [contentData, setContentData] = useState({
        subjectnm: subjectnm,
        outltopic: outltopic
    });
    
    const [localData,  setlocalData] = useState([]);


    console.log("data to be sent to next page", contentData.filterf)

    useEffect(() => {
        const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/lesson-filter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentData })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)
              if (data.outline.length > 0) {
                //console.log("logging data", data[0].subtopics);
                const contentcheck = data.outline.map((post) => post.title)
                console.log("mapping",contentcheck);
                if (contentcheck !== null) {
                  setlocalData(data.outline);
                }else {
                  fetchContents();
                }
                
                  //localStorage.setItem('array', JSON.stringify(data));
              }
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();
    
       
         
    
       }, []);

       console.log("localdata after db out",localData)


   
       

        if (localData !== null) {

            
                NextVal =  sub2lessindex + 1;
                PrevVal =  sub2lessindex - 1;
                

            const lessonoutMT = localData.map((post) => 
                <div className="maintopic" key={post._id}>
                    <Link
                     to = "/content"

                    state = {{
        
                        data: {filterf: subjectnm, topic: post.topic }  // your data array of objects type.title 
                    }}
                                    
                    style={{ textDecoration: 'none', color: 'black' }} 
                    >
                    <h1>{post.topic}</h1>
                    </Link>
                </div>
            )

            const lessonpressedT = localData.map((post) => 
                <div className="lessontopic" key={post._id}>
                    <p>{sub1t}</p>
                    {post.subtopic.map((type, index)=>
                    <div className="lessontopicH2" key={type._id}>
                        {index === lock ?
                        <div className="test">
                        {type.subtopic2.map((enter, index)=> 
                        <h2 key={enter._id}> {filterf === enter.sub2lesstitle && index === sub2lessindex ? "Lesson" + (sub2lessindex + 1) + ":" : null} {filterf === enter.sub2lesstitle && index === sub2lessindex ? enter.sub2lesstitle : null}</h2>
                        )}
                        {type.subtopic2.map((enter, indexsub2)=> 
                        <div className="buttonbuffer" key={enter._id}>

                                {console.log('indexxxxxxxxxx1',index)}
                            
                            
                                {indexsub2 === PrevVal ? 
                                <div className="buffferin1">
                                <Link
                                    to = "/lesson"

                                    state = {{
        
                                    data: {topic: contentData.subjectnm, outltopic: contentData.outltopic, sub1t: sub1t, sub2lessindex: PrevVal, clicked: enter.sub2lesstitle, lock: lock, numlessonnotes: enter.numlessonnotes, lessindex: 0, nextlesson: indexsub2 }  // your data array of objects type.title 
                                    }}
                                    
                                    style={{ textDecoration: 'none', color: 'black' }} 
                                >
                                <button className="prev">
                                    &#10094;
                                </button>
                                </Link>
                                </div>
                                :
                                null
                                }
                                
                                {indexsub2 === NextVal ? 
                                <div className="buffferin2">
                                <Link
                                    to = "/lesson"

                                    state = {{
        
                                    data: {topic: contentData.subjectnm, outltopic: contentData.outltopic, sub1t: sub1t, sub2lessindex: NextVal, clicked: enter.sub2lesstitle, lock: lock, numlessonnotes: enter.numlessonnotes, lessindex: 0, nextlesson: indexsub2 }  // your data array of objects type.title 
                                    }}
                                    
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <button className={NextVal === 1 ? 'new': 'next'}>
                                    &#10095;
                                </button>
                                </Link>
                                </div>
                                :
                                null
                                }
                            
                            
                               
                            

                        </div>
                        )}
                        </div>
                        :
                        null
                        }
                    </div>
                    )}
                    
                </div>
            )
           
                const lessonlistOT = localData.map((post) => 
                    <div className="mainoptB1" key={post._id}>
                        {post.subtopic.map((type)=> 
                        <div className="mainoptB2" key={type._id}>
                            {type.subtopic2.map((enter, indexsub2)=> 
                            <div className="mainoptB3" key={enter._id}>
                                {filterf === enter.sub2lesstitle && indexsub2 === sub2lessindex ?
                                <div className="subtopics">
                                    {enter.lessonotes.map((close, index)=>
                                    <Link
                                    to = "/lesson"

                                    state = {{
        
                                    data: {lessopt: close.sublesstitle, topic: contentData.subjectnm, outltopic: contentData.outltopic, sub1t: sub1t, sub2lessindex: sub2lessindex, clicked: filterf, lock: lock, quiznum: close.quizava, lessindex: index, nextlesson: indexsub2, numlessonnotes: enter.numlessonnotes }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                                    }}
                                    
                                    style={{ textDecoration: 'none', color: 'black' }}

                                    key={index}
                                    >
                                    {lessopt !== undefined ?
                                    <div className={lessopt === close.sublesstitle ? 'highlight-item': 'subtopic-item'} >
                                        <h3>{close.sublesstitle}</h3>   
                                    </div>
                                    :
                                    <div className={index === 0? 'highlight-item': 'subtopic-item'} >
                                        <h3>{close.sublesstitle}</h3>   
                                    </div>
                                    }
                                    </Link>
                                    )}
                                </div>
                                :
                                null
                                }
                            </div>
                            )}
                        </div>
                        )}
                    </div>
                )
    
                return (
                    <>
                    <div className="Lessoutline-main"> 
                        <div className="Lessoutline-card">
                            {lessonoutMT}

                            {lessonpressedT}

                            {lessonlistOT}

                        </div>
                        
                    </div>
                    </>
                
                );

            
        }
}


  
  export default Lessoutline;