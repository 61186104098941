import React from 'react';

import Sllogin from '../pagecomp/studlibLoginp/sllogin';
import '../pagecomp/studlibLoginp/sllogin.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function LibraryLoginPortal() {
    return (
        <>
            <NavbarMs />

            <Sllogin />

            <Footer />
        </>
    )
}

export default LibraryLoginPortal;