import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./onlineLib.css";

//import { useDispatch, useSelector } from 'react-redux';
//import { getSubjects } from "../../../../actions/subjects";
/*

        <div className="pdf-card">
            < PdfViewer pdfUrl="https://drive.google.com/file/d/1Egm6n123XOxsB2kikhC0WMXe0YKz0MwJ/view?usp=sharing" />
        </div>


*/

function OnlineLib(props) {

    

    return (
      <>
      <div className="OnlineLibrary-main">
        <div className="onlinelibsection">
            <p>Category Trending</p>
            <div className="bookimgblock">
                <div className="singleimgblock">
                    <div className="nowimage">
                        <img src="https://ia803000.us.archive.org/view_archive.php?archive=/3/items/m_covers_0008/m_covers_0008_46.tar&file=0008461797-M.jpg"/>
                        <script src="https://archive.org/details/mrisaacstaleofmo00crawrich/page/24/mode/2up?ref=ol&view=theater"></script>
                        
                        
                        <div class="ol_readapi_book" isbn="039471752X" lccn="75009828"></div>

                    </div>
                    <iframe title="searchbook" src="https://archive.org/details/psaltyskidsbible0000unse/page/4/mode/2up?view=theater"></iframe>
                    <div className="nowimage">

                    </div>
                    <div className="nowimage">

                    </div>
                    <div className="nowimage">

                    </div>
                    <div className="nowimage">

                    </div>
                    <div className="nowimage">

                    </div>
                </div>
            </div>
        </div>
      </div>
      

      </>
      
    );
    
}


  
export default OnlineLib;