import React, { useState, useEffect } from 'react';
import './dashboardmenu.css';
import { Link } from 'react-router-dom';
import {Chart as ChartJS} from "chart.js/auto";
import {Bar, Doughnut, Line} from "react-chartjs-2";
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Doughtgraphdsp() {
    //dashboardlibadminmenu
    const [localData,  setlocalData] = useState([]);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetcirculationbooks = '/getcirculationstats';
    const getcirculationbooksurl = baseUrl + dynamicgetcirculationbooks;

    
    //const booktype = "physica

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getcirculationbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    
  return (
    <>
        <div className="doughtgraphadmin">
            <h2>Circulation</h2> 
            <div className="doughtgraphCard">
                <Doughnut
                    data={{
                        labels: ["Approved", "Not Approved"],
                        datasets: [
                            {
                                label: "Gerne",
                                data: [localData.approvednum, localData.notapprovednum],
                                backgroundColor: [
                                  "rgba(0, 255, 0, 0.8)",
                                  "rgba(255, 0, 0, 0.8)"
                                ],
                                borderRadius: 5,
                            },
                        ]
                    }}
                />  
            </div>  
        </div>
    </>
  );

    
}

export default Doughtgraphdsp;