import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/landingimage/liblandingimg.css';
import Liblandingimage from '../pagecomponents/landingimage/liblandingimg';

import '../pagecomponents/introsection/introsection.css';
import Introsection from '../pagecomponents/introsection/introsection';

import '../pagecomponents/introsection/gotobutton.css';
import Gotobutton from '../pagecomponents/introsection/gotobutton';

import '../pagecomponents/introsection/recommsect.css';
import Recommsect from '../pagecomponents/introsection/recommsect';

import '../pagecomponents/categorygrid/catgrid.css';
import Categorysect from '../pagecomponents/categorygrid/catgrid';

import '../pagecomponents/categorygrid/librarynews.css';
import Librarynewssect from '../pagecomponents/categorygrid/librarynews';




//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function LibHome() {
    return (
        <>
            <Libnavbar />

            <Liblandingimage />

            <Gotobutton />

            <Recommsect />

            <Introsection />

            <Categorysect />

            <Librarynewssect />

        </>
    )
}

export default LibHome;