import React, { useState, useEffect } from "react";
import "./pdfviewer.css";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useLocation } from "react-router-dom";

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function Pdfviewer(props) {

  const location = useLocation();

  const filterf = location.state.data.clicked;
  const subjectnm = location.state.data.topic;
  const outltopic = location.state.data.outltopic;
  const sub1t = location.state.data.sub1t;
  const sub2lessindex = location.state.data.sub2lessindex;
  const lessopt = location.state.data.lessopt;

  const [contentData, setContentData] = useState({
    subjectnm: subjectnm,
    outltopic: outltopic
});

const [localData,  setlocalData] = useState([]);

console.log("data to be sent to next page", contentData.filterf)


useEffect(() => {
    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/lesson-filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ contentData })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data contents db",data)
          if (data.outline.length > 0) {
            //console.log("logging data", data[0].subtopics);
            const contentcheck = data.outline.map((post) => post.title)
            console.log("mapping",contentcheck);
            if (contentcheck !== null) {
              setlocalData(data.outline);
            }else {
              fetchContents();
            }
            
              //localStorage.setItem('array', JSON.stringify(data));
          }

          //setContentData(data);
          
        
      });
      
    };

    fetchContents();

   
     

   }, []);

   console.log("localdata after db out",localData)

  if (localData !== null) {
    const lessonpdfl = localData.map((post) =>
    <div className="mainpdfB1" key={post._id}>
      {post.subtopic.map((type)=> 
      <div className="mainpdfB2" key={type._id}>
        {type.subtopic2.map((enter, index)=> 
        <div className="mainpdfB3" key={enter._id}>
          {filterf === enter.sub2lesstitle && index === sub2lessindex ?
          <div className="mainpdfB4" key={enter._id}>
            {enter.lessonotes.map((close, index)=>
            <div className="pdf-contain" key={index}>
              {index  === 0 && lessopt === undefined ?
              <>
              <iframe title="pdfnotes" src={close.pdfviewlink}></iframe>
              </>
              :
              null
              }
              {lessopt  === close.sublesstitle ?
              <>
              <iframe title="pdfnotes" src={close.pdfviewlink}></iframe>
              </>
              :
              null
              }
              
            </div>
            )}
          </div>
          :
          null
          }       
        </div>
        )} 
      </div>
      )}
    </div> 

  )


    
    return (
        
      <div className="pdf-container">
        <h2>Notes</h2>
        {lessonpdfl}    
      </div>
        
    );
  }
}


  
export default Pdfviewer;
