import React, { useState } from 'react';
import './agriculturemenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Agriculturemenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

  
  return (
    <>
       <div className="agriculturemenu"> 
            <div className="agriculturemenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Agriculture Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Agriculture Menu  &#9776;</h2>
                }
                <div className={`agriculturemenugerneblock ${showGerne ? 'agriculturemenushow-gerne' : ''}`}>
                    <div className="agriculturemenuactualgerne">
                        <Link 
                            to="/agriculture" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { agriculture: "all" , abt: ""}  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>All</p> 
                        </Link>
                        <Link 
                            to="/agriculture" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { agriculture: "vegetables", abt: "The vibrant green leaves of lettuce and spinach peek out from a raised bed in the school garden." } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Vegetables</p> 
                        </Link>
                        <Link 
                            to="/agriculture" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { agriculture: "cabbage", abt: "In the school garden, rows of vibrant green kale leaves stand ready for students to harvest for a healthy lunch." } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Cabbage</p>
                        </Link>
                        
                    </div>

                </div>
            </div>
        </div>
    </>
  );
    
}

export default Agriculturemenu;