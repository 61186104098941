import React, { useState, useEffect } from 'react';
import './onlinebookstats.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function OnlineBookStatsp() {

    const [booksData,  setbooksData] = useState([]);
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    const [showvisualGerne, setShowvisualGerne] = useState(false);

    const togglevisualMenu = () => {
        setShowvisualGerne(!showvisualGerne);
    };

    const [showaudioGerne, setShowaudioGerne] = useState(false);

    const toggleaudioMenu = () => {
        setShowaudioGerne(!showaudioGerne);
    };


    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetphybookstats = '/getonlinebooks';
    const getphybookstatsstatsurl = baseUrl + dynamicgetphybookstats;


    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getphybookstatsstatsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setbooksData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);
   
    if (booksData !== null) {
        const numbooks = booksData.length;

        const categoryCounts = booksData.reduce((acc, item) => {
            acc[item.category] = (acc[item.category] || 0) + 1;
            return acc;
        }, {});

          // Sort the categories by their counts
        const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]);
      
        // Take the first 10 categories just changed categories
        const topCategories = sortedCategories.slice(0, 1);
        const countCategories = sortedCategories.length;
    return (
        <> 
        <div className="dashboardonlinebkstats">
        <div className="dashboardonlinebkstatsgerne">
                <h2>Online Books Dashboard</h2>
                
                <div className="dashboardonlinebkstatsonegerneblock">
                    <div className="dashboardonlinebkstatsactualgerne">
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardonlinebkstatsrobotnormal'>Total PDF Books
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => item.type === 'PDF').length}</span> 
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardonlinebkstatsrobotnormal'>Total Audio Books
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => item.type === 'Audio').length}</span>
                            </p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className='dashboardonlinebkstatsrobotnormal'>Total Visual Books
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => item.type === 'Video').length}</span>
                            </p>
                        </Link>
                       
                    </div>

                </div>
            </div>
        </div>


        <div className="dashboardonlinebkstats">
        <div className="dashboardonlinebkstatsgerne">
            {showGerne ?
                <h2 onClick={toggleMenu}>PDF Books in Genre  &times;</h2>
                :
                <h2 onClick={toggleMenu}>PDF Books in Genre  &#9776;</h2>
                }
               
                <div className={`dashboardonlinebkstatsgerneblock ${showGerne ? 'dashboardonlinebkstatsshow-gerne' : ''}`}>
                    <div className="dashboardonlinebkstatsactualgerne">
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'picture book' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'picture book' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Picture Book
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'picture book' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'early readers' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'early readers' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Early Readers
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'early readers' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'chapter books' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'chapter books' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Chapter Books
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'chapter books' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'fairy tales' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'fairy tales' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Fairy Tales
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'fairy tales' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'adventure' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'adventure' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Adventure
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'adventure' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'mystery' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'mystery' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Mystery
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'mystery' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'fantasy' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'fantasy' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Fantasy
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'fantasy' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'science fiction' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'science fiction' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Science Fiction
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'science fiction' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'historical' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'historical' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Historical
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'historical' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'horror' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'horror' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Horror
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'horror' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'biography' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'biography' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Biography
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'biography' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'poetry' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'poetry' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Poetry
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'poetry' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'animal story' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'animal story' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Animal Story
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'animal story' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'friendship' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'friendship' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Friendship
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'friendship' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'mathematics' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'mathematics' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Mathematics
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'mathematics' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'isndebele' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'isndebele' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Isndebele
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'isndebele' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'heritage' && item.type === "PDF")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'heritage' && item.type === "PDF")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Heritage
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'heritage' && item.type === "PDF")).length}</span> 
                            </p>
                        </Link>
                        
                        
                    
                       
                    </div>

                </div>
            </div>
        </div>



        <div className="dashboardonlinebkstats">
        <div className="dashboardonlinebkstatsgerne">
            {showvisualGerne ?
                <h2 onClick={togglevisualMenu}>Visual Books in Genre  &times;</h2>
                :
                <h2 onClick={togglevisualMenu}>Visual Books in Genre  &#9776;</h2>
                }
               
                <div className={`dashboardonlinebkstatsgerneblock ${showvisualGerne ? 'dashboardonlinebkstatsshow-gerne' : ''}`}>
                    <div className="dashboardonlinebkstatsactualgerne">
                    <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'picture book' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'picture book' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Picture Book
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'picture book' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'early readers' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'early readers' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Early Readers
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'early readers' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'chapter books' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'chapter books' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Chapter Books
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'chapter books' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'fairy tales' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'fairy tales' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Fairy Tales
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'fairy tales' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'adventure' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'adventure' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Adventure
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'adventure' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'mystery' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'mystery' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Mystery
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'mystery' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'fantasy' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'fantasy' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Fantasy
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'fantasy' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'science fiction' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'science fiction' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Science Fiction
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'science fiction' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'historical' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'historical' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Historical
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'historical' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'horror' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'horror' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Horror
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'horror' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'biography' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'biography' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Biography
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'biography' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'poetry' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'poetry' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Poetry
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'poetry' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'animal story' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'animal story' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Animal Story
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'animal story' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'friendship' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'friendship' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Friendship
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'friendship' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'mathematics' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'mathematics' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Mathematics
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'mathematics' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'isndebele' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'isndebele' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Isndebele
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'isndebele' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'heritage' && item.type === "Video")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'heritage' && item.type === "Video")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Heritage
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'heritage' && item.type === "Video")).length}</span> 
                            </p>
                        </Link>
             
                       
                    </div>

                </div>
            </div>
        </div>


        <div className="dashboardonlinebkstats">
        <div className="dashboardonlinebkstatsgerne">
            {showaudioGerne ?
                <h2 onClick={toggleaudioMenu}>Audio Books in Genre  &times;</h2>
                :
                <h2 onClick={toggleaudioMenu}>Audio Books in Genre  &#9776;</h2>
                }
               
                <div className={`dashboardonlinebkstatsgerneblock ${showaudioGerne ? 'dashboardonlinebkstatsshow-gerne' : ''}`}>
                    <div className="dashboardonlinebkstatsactualgerne">
                    <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'picture book' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'picture book' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Picture Book
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'picture book' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'early readers' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'early readers' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Early Readers
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'early readers' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'chapter books' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'chapter books' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Chapter Books
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'chapter books' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'fairy tales' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'fairy tales' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Fairy Tales
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'fairy tales' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'adventure' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'adventure' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Adventure
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'adventure' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'mystery' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'mystery' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Mystery
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'mystery' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'fantasy' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'fantasy' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Fantasy
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'fantasy' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'science fiction' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'science fiction' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Science Fiction
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'science fiction' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'historical' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'historical' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Historical
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'historical' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'horror' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'horror' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Horror
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'horror' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'biography' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'biography' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Biography
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'biography' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'poetry' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'poetry' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Poetry
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'poetry' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'animal story' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'animal story' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Animal Story
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'animal story' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'friendship' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'friendship' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Friendship
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'friendship' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'mathematics' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'mathematics' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Mathematics
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'mathematics' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'isndebele' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'isndebele' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Isndebele
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'isndebele' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
                        <Link 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={(booksData.filter(item => (item.category === 'heritage' && item.type === "Audio")).length) < 5 ? "dashboardonlinebkstatsrobotred" :(booksData.filter(item => (item.category === 'heritage' && item.type === "Audio")).length) < 10 ? "dashboardonlinebkstatsrobotorange" : "dashboardonlinebkstatsrobotnormal" }>Heritage
                                <br></br>
                                <span className="onlinebkstatsnumber">{booksData.filter(item => (item.category === 'heritage' && item.type === "Audio")).length}</span> 
                            </p>
                        </Link>
             
                       
                    </div>

                </div>
            </div>
        </div>


        </>
    );
    };

    
}
        
        
export default OnlineBookStatsp;