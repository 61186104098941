import React from "react";
import './agricultureintro.css';

const Agricultureintro = () => {
  return (
    <>
        <div className="agricultureintrocont">
            <div className="agricultureintrostatement">
                <h2>Agriculture</h2>
            </div>
        </div>
         
    </>
  );
};

export default Agricultureintro;