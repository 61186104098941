import React from 'react';

import Admission from '../pagecomp/admissionsp/admission';
import '../pagecomp/admissionsp/admission.css';

import Admissionintro from '../pagecomp/admissionsp/admissionintro';
import '../pagecomp/admissionsp/admissionintro.css';

import Admissionintroimg from '../pagecomp/admissionsp/admissionintroimg';
import '../pagecomp/admissionsp/admissionintroimg.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Admissions() {
    return (
        <>
            <NavbarMs />

            <Admissionintro />

            <Admissionintroimg />

            <Admission />

            <Footer />
        </>
    )
}

export default Admissions;