import React, { useEffect, useState } from "react";
import "./outline.css";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getOutlines } from "../../../../actions/outlines";

function Outline(props) {

  const dispatch = useDispatch();
  const location = useLocation();


  const form = location.state.data.form;
  const filterf = location.state.data.filterf;
  
 
  //const { state } = props.location;
  console.log("sent data", props )
  console.log("sent location", location.state.data.form )
  console.log("sent location", location.state.data.filterf )

  const [outlineData, setOutlineData] = useState({
    form: form,
    filterf: filterf


  });

    
  

  const dboutlines = useSelector((state) => state.outlines);

  useEffect(() => {
      dispatch(getOutlines());
  }, [dispatch]);

 

  

  const mpoutlines = dboutlines.outlines;

  //console.log('dbout',dboutlines);

  //console.log('mpout',mpoutlines);

  const [localData, setlocalData] = useState([]);

  /*useEffect(() => {
    const fetchOutlines = async () => {
      const response = await fetch('http://localhost:5000/outlines');
      const data = await response.json();
      
      if (data.length > 0) {
        setlocalData(data);
        //localStorage.setItem('array', JSON.stringify(data));
      }
    
    };

    fetchOutlines();
  }, []);

  /*useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setlocalData(JSON.parse(storedData));
      
    }
  }, []);

  console.log("see localdata2", localData)*/
  /*useEffect(() => {
    const fetchOutlines = async () => {
      const response = await fetch('http://localhost:5000/outlines');
      const data = await response.json();
        
      if (data.length > 0) {
        //console.log("logging data", data[0].subtopics);
        const outlinecheck = data.map((post) => post.subtopics)
        console.log(outlinecheck);
        if (outlinecheck !== null) {
          setlocalData(data);
        }else {
           fetchOutlines();
        }
        
          //localStorage.setItem('array', JSON.stringify(data));
      }
      
    };

    fetchOutlines();


    
   }, []);*/

   useEffect(() => {
    const fetchOutliness = async () => {
      //await dispatch(getOutlines(parsedObj));
      fetch('http://localhost:5000/outline-filter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ outlineData })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data from db",data)
          if (data !== null) {
            //console.log("logging data", data[0].subtopics); data.subjects.length > 0
            const subjectcheck = data.subjects.map((post) => post.title)
            console.log("mapping",subjectcheck);
            if (subjectcheck !== null) {
              setlocalData(data.subjects);
            }else {
              fetchOutliness();
            }
            
              //localStorage.setItem('array', JSON.stringify(data));
          }

          //setlocalData(data);
          
        
      });
      
    };

    fetchOutliness();

    /*
    fetch('http://localhost:5000/login-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userData })
      })
        .then(response => response.json())
        .then(data => {
          localStorage.setItem('token', data.token);
          //console.log("check token here",data.token);
          window.location.href = '/phome';
        
      });
      */
     console.log("localdata after db in",localData)

   }, []);

  
  
  
   console.log("localdata after db out",localData) //{console.log("thepostinfor", post)}
  


  if (localData !== null) { 

    const outlinecard = localData.map((post)=> 
      <div className="Outline-cards" key={post._id}> 
         {post.outline.map((type)=>
          <div className="Outline-card" key={type._id}>
            <div className="outline-name">
                <h2>{type.topic}</h2>
                <Link
                to = "/Content"

                state = {{
       
                  data: {filterf: post.title, topic: type.topic } // your data array of objects type.title 
                }}
                
                style={{ textDecoration: 'none', color: 'blue' }}
                
                >
                <p>{type.numsubtop} </p>
                </Link>
                
            </div>

            <div className="content-list">
              <ul>
                  {type.subtopics.map((enter, index)=>
                  <li key={index}>{enter}</li>
                  )}
              </ul>
            </div>
          </div>
          )} 
      </div>
    );
  
    return (
      <>
      <div className="Outline-main">
        <div className="Course-title">
            <h1>My Course Outline</h1>
        </div>
        {outlinecard}
        
      </div>
      </>
    );
  }

}
  
  export default Outline;