import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Adminlogin from "./adminlogin";
import Stafflogin from "./stafflogin";
import Librarylogin from "./librarylogin";
import PTAlogin from "./ptalogin";
import "./adminlogin.css";


function Portalcontrol(props) {

    const location = useLocation();

    //const [npdata, setNpdata] = useState('');location.state.data.portalname

    const portalname = location.state.data.portalname;
    
    return (
        <>
        <div className="Portalcon-main">
            {portalname  === "Admin" ?
                <Adminlogin />   
            : (portalname  === "Staff") ?
                <Stafflogin />        
            : (portalname  === "Library")?
                <Librarylogin />   
            : (portalname  === "PTA") ?
                <PTAlogin />
            :
                <Adminlogin />
            }

        </div>
        </>
      
    );
    
    
}


  
  export default Portalcontrol;