import React, {useState} from "react";
import './boarddirgrid.css';

const Boarddirteam = () => {

  const [seemore, setSeemore] = useState(false);
  const [adminid, setAdminid] = useState(-1);

  const images = [
    { id: 0, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/ipzndlovuweb.jpg", name: "Mr Israel Ndlovu", position: "Chairman", descrip:"Mr. Israel Ndlovu holds a Bachelor of Accountancy Degree from the then University of Rhodesia. He is a member of the Chartered Institute of Management Accountants and of ZIPFA. A keen sportsman, businessman and now into cattle ranching. Mr. Ndlovu is the Chairman of Masiyephambili School Trust. He is now a Financial Consultant." },
    { id: 1, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/drmsimanga.jpg", name: "Dr G. M. Msimanga", position: "Board Member", descrip:"Having studied medicine at the University of Crava in Romania, Dr Goodness Msimanga returned to Bulawayo and served her internship at Mpilo Hospital. Now in private practice Dr Msimanga is married with two sons" },
    { id: 2, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/toddmoyoweb.jpg", name: "Mr Toddy Moyo", position: "Treasurer", descrip:"A holder of a Bachelor of Accountancy Degree, Mr. Moyo is a Chartered Accountant by profession and a member of various professional and business bodies. He is married to Bongi with two sons and is currently the Chairman and Chief Executive Officer of Datlabs (Pvt) Ltd. He also serves as non executive chairman of National Foods Holdings and holds a number of board appointments in both listed and unlisted companies. He is also a trustee of various other institutions in the educational and health sectors. He has for many years been a treasure of the Masiyephambili School Trust."},
    { id: 3, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/tnsibandaweb.jpg", name: "Mr Thembinkosi Sibanda", position: "Board Member", descrip:"Better known as the brains behind Masiyephambili Junior School, Mr. Sibanda is married and has two sons. A Bachelor of Accountancy Graduate from the then University of Rhodesia, Themba is a member of the institute of chartered accountants of Zimbabwe and a partner in the Accounting firm of Schmullian, Knight and Sibanda. He is now into farming." },
    { id: 4, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/markncubeweb.jpg", name: "Mr Mark Ncube", position: "Board Member", descrip:"A retired chief archivist and consultant in records and Information Management, Mr. Mark Ncube holds a BA (Honours) degree, a Graduate certificate in Education (UZ), Diploma in Archives Studies (UCL) and Post Graduate Diploma in Public Management (NUST). He has partially complemented MBA Studies at NUST. Mr. Ncube has interest in farming and hunting and has taught and lectured at secondary schools and universities." },
    { id: 5, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/mmahlanguweb.jpg", name: "Mr Mordecai P Mahlangu", position: "Board Member", descrip:"A registered Attorney, Mordecai Mahlangu is a partner in the law firm of Gill, Godlonton & Gerrans in Harare. He holds a Bachelor of Law Honours Degree from the then University of Rhodesia. Married to Elsie with two children, Mr. Mahlangu also serves on the Board of Old Mutual Life Assurance Co. Ltd, Truworths, Reserve Bank of Zimbabwe, Cimas Medical Aid Society, Twenty First Century Building Contractors (Pvt) Ltd and is the former president of the Law Society of Zimbabwe." },
    { id: 6, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/EugeneMatikiti1.jpg", name: "Mr Uegene Matikiti", position: "Board Member", descrip:"Mr. Eugene V. Matikiti was chairman of the Masiyephambili School Trust from 1991-2000. He is currently working out of the country in Mozambique." },
    { id: 7, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/snkalaweb.jpg", name: "Mrs S Nkala", position: "Board Member", descrip:"Mrs. S Nkala is one of the founding board members of the Masiyephambili School Trust. She is currently out of the country on business."},
    { id: 8, src: "https://optsirvacc.sirv.com/masiyephambili/boarddir/msibandaweb.jpg", name: "Mr Marvellous Sibanda", position: "Board Member", descrip:"A holder of Bsc (Honours) in Engineering, (Manufacturing Operations Management), MBA, and CIS Mr. Marvellous Sibanda who is general manager of Gold Star Sugars in Harare is currently studying for a PhD in Business Management. He is a member of the Chartered Mechanical Engineers (Ceng), iMece UK (Institute of Mechanical Engineers UK), and an associate member of the Chartered Secretaries and Administrators." }
  ];

  //{ id: 4, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds3.JPG", gallery: "campus life", spid: 2 },
  //{ id: 5, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds4.JPG", gallery: "campus life", spid: 3 }

  const handleSeemore = (id) => {
    setSeemore(!seemore);
    setAdminid(id);
  };

  return (
    <>
        <div className="boarddirteamcont">
            <div className="boarddirteamhead">
                <div className="boarddirmainimgbox">
                  {images.map((image, index) => (
                    <div className="boarddirmainimgcont" key={image.id}>
                      <img src={image.src} alt={`Grid ${image.spid + 1}`} />
                      <p className="boarddirstaffname">{image.name}</p>
                      <p className="boarddirstaffname">{image.position}</p>
                      <p className="boarddirstaffseemore" onClick={() => handleSeemore(image.id)}>See More</p>
                      {(seemore && adminid === image.id) &&
                        <p className="boarddirstaffname">{image.descrip}</p>
                      }
                    </div>
                  ))} 
                </div>
               
            </div>
        </div>
         
    </>
  );
};

export default Boarddirteam;