import React, { useState } from 'react';
import './culturemenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Culturemenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

  
  return (
    <>
       <div className="culturemenu"> 
            <div className="culturemenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Culture Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Culture Menu  &#9776;</h2>
                }
                <div className={`culturemenugerneblock ${showGerne ? 'culturemenushow-gerne' : ''}`}>
                    <div className="culturemenuactualgerne">
                        <Link 
                            to="/culture" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { culture: "all" , abt: ""}  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>All</p> 
                        </Link>
                        <Link 
                            to="/culture" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { culture: "business indaba", abt: "Business indaba is about connecting business among the masiyephambili society" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Business Indaba</p> 
                        </Link>
                        <Link 
                            to="/culture" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { culture: "family fun day", abt: "Family fun day is about bulding relationships with children while having fun" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Family Fun Day</p>
                        </Link>
                        
                    </div>

                </div>
            </div>
        </div>
    </>
  );
    
}

export default Culturemenu;