import React from 'react';

import Boarddirintro from '../pagecomp/staff/boarddirectors/boarddirintro';
import '../pagecomp/staff/boarddirectors/boarddirintro.css';

import Boarddirteam from '../pagecomp/staff/boarddirectors/boarddirgrid';
import '../pagecomp/staff/boarddirectors/boarddirgrid.css';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Boardofdirectorspage() {
    return (
        <>
            <NavbarMs />

            <Boarddirintro />

            <Boarddirteam />

            <Footer />
        </>
    )
}

export default Boardofdirectorspage;