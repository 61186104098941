import React, { useState } from "react";
import "./onlinecategory.css";
import { useLocation, Link} from "react-router-dom";

const OnlineCategory = () => {
  const [searchterm,  setSearchterm] = useState([]);

  const location = useLocation();

  const booktype = location.state.data.typebook;
  const catname = "Search Results";
  const searchactive = "on";

  return (
    <>
       <div className="mainonlinelibsearchcat">
            <div className="onlinelibsearchbarcat">
                <input type="text" className="searchbarcat" placeholder="Search book by title" onChange={(e) => setSearchterm(e.target.value)}/>
                <Link 
                  to="/onlinecategory" 
                            
                  state = {{
                    data: {searchactive: searchactive, typebook: booktype, catname: catname, searchterm: searchterm, nxtpage: 1  }
                  }}
                  style={{ textDecoration: 'none', color: 'black' }}
                >     
                <button>Search</button>
                </Link>
            </div>
        </div>
    </>
  );
};

export default OnlineCategory;