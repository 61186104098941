import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./lesson.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
//import { useDispatch, useSelector } from 'react-redux';
//import { getSubjects } from "../../../../actions/subjects";
import PdfViewer from "./pdfviewer"
import VideoViewer from "./videoviewer"
import Quizviewer from "./quiz"


/*

        <div className="pdf-card">
            < PdfViewer pdfUrl="https://drive.google.com/file/d/1Egm6n123XOxsB2kikhC0WMXe0YKz0MwJ/view?usp=sharing" />
        </div>


*/

function Lesson(props) {



    const location = useLocation();

    const [npdata, setNpdata] = useState('');
    
    

   
    const filterf = location.state.data.clicked;
    const subjectnm = location.state.data.topic;
    const outltopic = location.state.data.outltopic;
    const sub1t = location.state.data.sub1t;
    const lessopt = location.state.data.lessopt;
    const lock = location.state.data.lock;
    const quiznum = location.state.data.quiznum;
    const sub2lessindex = location.state.data.sub2lessindex;
    const lessindex = location.state.data.lessindex;
    const nextlessindex = location.state.data.nextlesson;
    const numlessonnotes = location.state.data.numlessonnotes;

    

    
    
    //const { state } = props.location;
    console.log("sent data lessindex", lessindex)
    console.log("sent data numlessonnotes", numlessonnotes)
    
    

    const [contentData, setContentData] = useState({
        subjectnm: subjectnm,
        outltopic: outltopic
    });

    const [localData,  setlocalData] = useState([]);

    const [npquiz, setNpquiz] = useState('');

  

    console.log("the first", quiznum);

    





    console.log("data to be sent to next page", contentData.subjectnm)

    useEffect(() => {
      
      //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
      
        const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/lesson-filter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentData })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)
              if (data.outline.length > 0) {
                //console.log("logging data", data[0].subtopics);
                const contentcheck = data.outline.map((post) => post.title)
                console.log("mapping",contentcheck);
                if (contentcheck !== null) {
                  setlocalData(data.outline);
                }else {
                  fetchContents();
                }
                
                  //localStorage.setItem('array', JSON.stringify(data));
              }
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();
    
       
         
    
       }, []);

       console.log("localdata after db out",localData)

   

      useEffect(() => { 

        const quizcheck = localData.map((post) =>
          <div className="quiz-checkB1" key={post._id}>
            {post.subtopic.map((type, indexL)=>
            <div className="quiz-checkb2" key={type._id}>
              {type.subtopic2.map((enter)=>
                <div className="quiz-checkB2" key={enter._id}>
                  {enter.sub2lesstitle  === filterf &&
                    enter.lessonotes.map((close, index)=>
                      <div className="quizchecktopic" key={close._id}>
                        {(index  === 0 && lessopt === undefined && indexL === lock && close.videoviewlink === "quiz") ||(lessopt  === close.sublesstitle && indexL === lock && close.videoviewlink === "quiz") || (lessopt === undefined && indexL === lock && close.videoviewlink === "quiz")?
                          close.quiz.map((newt, index)=>
                            <div className="quizcheckque1" key={index}>
                              { newt.quizNo === 1 &&
                              newt.quizQue.map((newt2, index)=>
                                <div className="quizcheckque2" key={index}>
                                  {setNpquiz(newt2.question)}
                                </div>
                              )
                              }
                                
                            </div>
                          )
                          :
                          <div className="quizcheckque9" key={index}>
                            {setNpquiz("")}
                          </div>
                          
                        }
                        
                      </div>
                    )
                  }
                </div>
              )}
            </div>
            )}
          </div>
        )


      }, [quiznum, filterf, lessopt, localData, lock, [] ])

    
      
    if (localData !== null) {
      var nextless = lessindex + 1;
      var nexttopic = nextlessindex + 1;
      var NextVal =  sub2lessindex + 1;
      console.log("sub2lessindex",lessindex)
      console.log("next lesson",nextless);
      const lessoncard = localData.map((post) =>
        <div className="lesson-cardB1" key={post._id}>
          {post.subtopic.map((type, indexL)=>
          <div className="lesson-cardb2" key={type._id}>
            {type.subtopic2.map((enter)=>
              <div className="lesson-cardB2" key={enter._id}>
                {enter.sub2lesstitle  === filterf &&
                  enter.lessonotes.map((close, index)=>
                    <div className="lesoutsubtopic" key={index}>
                      {index  === 0 && lessopt === undefined && indexL === lock ?
                        <h2>{close.sublesstitle}</h2>
                        :
                        ''
                      }
                      {lessopt  === close.sublesstitle && indexL === lock ?
                        <h2>{close.sublesstitle}</h2>
                        :
                        null
                      }
                    </div>
                  )
                }
              </div>
            )}
          </div>
          )}
        </div>
      )

      const continuelesson = localData.map((post) =>
        <div className="continuelesson-cardB1" key={post._id}>
          {post.subtopic.map((type, indexL)=>
          <div className="continuelesson-cardb2" key={type._id}>
            {type.subtopic2.map((enter, indexsub2)=>
              <div className="continuelesson-cardB2" key={enter._id}>
                {enter.sub2lesstitle === filterf ?
                  enter.lessonotes.map((close, index)=>
                    <div className="continuelessbtnbuffer" key={index}>
                      {index === 1 && lessopt === undefined && indexL === lock ?
                        <div className="continuelessbtncont">
                        <Link
                          to = "/lesson"

                          state = {{
        
                          data: {lessopt: close.sublesstitle, topic: contentData.subjectnm, outltopic: contentData.outltopic, sub1t: sub1t, sub2lessindex: sub2lessindex, clicked: filterf, lock: lock, quiznum: close.quizava, lessindex: 1, nextlesson: indexsub2, numlessonnotes: enter.numlessonnotes}  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                          }}
                                    
                          style={{ textDecoration: 'none', color: 'black' }}

                        >
                          <button className='continuelessbtn'>Continue</button>
                          {console.log("current settings",indexsub2)}
                        </Link>
                        </div>
                      :(index === nextless && indexL === lock) ?
                        <div className="continuelessbtncont">
                        <Link
                          to = "/lesson"

                          state = {{
        
                          data: {lessopt: close.sublesstitle, topic: contentData.subjectnm, outltopic: contentData.outltopic, sub1t: sub1t, sub2lessindex: sub2lessindex, clicked: filterf, lock: lock, quiznum: close.quizava, lessindex: nextless, nextlesson: indexsub2, numlessonnotes: enter.numlessonnotes  }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                          }}
                                    
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          <button className='continuelessbtn'>Continue</button>
                        </Link>
                        </div>
                      :
                      null
                      }
                    </div>
                  )
                :
                enter.lessonotes.map((close, index)=>
                <div className="continuelessbtnbuffer" key={index}>
                {(indexsub2 === nexttopic) && (index === 0) && (type.lock === lock) && (lessindex === numlessonnotes) ?
                <div className="continuelessbtncont">
                <Link
                  to = "/lesson"

                  state = {{

                  data: {topic: contentData.subjectnm, outltopic: contentData.outltopic, sub1t: sub1t, sub2lessindex: NextVal, clicked: enter.sub2lesstitle, lock: lock, numlessonnotes: enter.numlessonnotes, lessindex: 0, nextlesson: indexsub2 }  // your data array of objects type
                  }}
                            
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <button className='continuelessbtn'>Next Lesson</button>
                  {console.log('check numlessonnotes',enter.numlessonnotes)}
                  {console.log('check lessindex',lessindex)}
                </Link>
                </div>
                :
                null
                }
                </div>
                )
              }

              </div>
            )}
          </div>
          )}
        </div>
      )

      
      console.log('straightquizcheck',quiznum)
      console.log('quizz',npquiz)
      console.log('lockkkk',lock)
      



    return (
      <>
      <div className="Lesson-main">

        { lessoncard }
        
        { npquiz === "" ?
        <div className="mainbigbuffer">
          <div className="lessvideocontainer">
            <VideoViewer />
          </div>
          <div className="lesspdfcontainer">
            <PdfViewer />
          </div>

          {continuelesson}

        </div>

        : 
        <Quizviewer />
        }
        
      </div>
      </>
      
    );
    }
}


  
  export default Lesson;