import React from 'react';
import '../pagecomponents/addbooks/navbarlibrian.css';
import Navbar from '../pagecomponents/addbooks/navbarlibrian';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';
import '../pagecomponents/addbooks/addbooks.css';
import AddBooks from '../pagecomponents/addbooks/addbooks';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Addmemberlp() {
    return (
        <>
           <Navbar />

            <AddBooks />




        </>
    )
}

export default Addmemberlp;