import React, { useState } from "react";
import "./librarynews.css";

const Catgridp = () => {
  return (
    <>
        <div className="librarynewsmain">
            <div className="libnewssect">
                <h3>What's New</h3>
                <div className="libnewsectone">
                    <div className="libimgsection">
                        <img src="https://optsirvacc.sirv.com/masiyephambili/gallery/spellingbee.jpg" alt="blackhistimg"/>
                    </div>
                    <div className="libparanewssect">
                        <div className="libnewsblock">
                            <p className="libnewsyear">2024</p>
                            <h2>Spelling Bee</h2>
                            <p className="libnewsyearmainpar">Celebrate Spelling bee winner with the Library! Learn more about our related collections, resources, programs and events.</p>
                            <i className="fas fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div className="libnewsectone2">
                    <div className="libimgsection2">
                        <img src="https://www.carnegielibrary.org/wp-content/uploads/2024/01/Celebration-of-Seeds_Seed-Swap_2024_1080x600.jpg" alt="blackhistimg"/>
                    </div>
                    <div className="libparanewssect2">
                        <div className="libnewsblock2">
                            <p className="libnewsyear2">Special Event</p>
                            <h2>CELEBRATION OF SEEDS: 12TH ANNUAL SEED SWAP</h2>
                            <p className="libnewsyearmainpar2">Kick off your growing season at the Library's annual Seed Swap! Enjoy workshops, creative activities and free seeds!</p>
                            <i className="fas fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div className="libnewsectone3">
                    <div className="libimgsection">
                        <img src="https://www.carnegielibrary.org/wp-content/uploads/2022/02/APM-20-1080x600-1.jpg" alt="blackhistimg"/>
                    </div>
                    <div className="libparanewssect">
                        <div className="libnewsblock">
                            <p className="libnewsyear">Special Event</p>
                            <h2>ANNUAL PUBLIC MEETING</h2>
                            <p className="libnewsyearmainpar">Join Board leaders and Library staff to learn about CLP's 2023 accomplishments and plans for 2024.</p>
                            <i className="fas fa-arrow-right"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
  );
};

export default Catgridp;