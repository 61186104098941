import React from "react";
import "./contact.css";

/*
<iframe
                        title="location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.5731715176325!2d-73.98588048460228!3d40.74820297933022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2590463f4302d%3A0x4c4e4f87d4b7c826!2sEmpire%20State%20Building!5e0!3m2!1sen!2sus!4v1645699031491!5m2!1sen!2sus"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
*/

function Contact() {
  return (
    <>
        <div className="contactpa">
        <   div className="contptitle">
                <h1>Contact Us</h1>
            </div>
            <div className="contact-area">
                <div className="continfoarea">
                    <h3>Address</h3>
                    <p>
                        We are located in Selbourne Park on 14th Street ChelmsFord.
                        <br></br>
                        <br></br>
                        <b>Address:</b>
                        <br></br>
                        Masiyephambili Junior School
                        <br></br>
                        P.O Box F.M 75
                        <br></br>
                        Pamona
                        <br></br>
                        Bulawayo
                        <br></br>
                        <br></br>
                        Phone: +263 29 2286196

                    </p>
                </div>
            </div>
            <div className="map-area">
                <div className="area">
                    <h3>Map</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14979.987284426128!2d28.621331538990997!3d-20.175857859143946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eb555d9ccea29e9%3A0xe3c76b07e163e798!2sMasiyephambili%20Primary%20School!5e0!3m2!1sen!2snl!4v1717041095667!5m2!1sen!2snl" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    
                </div>
            </div>
        </div>


        

        
    </>
  );
}

export default Contact;
