

const posts = (posts= {posts:null, loading: false}, action) => {
    //console.log("logging data in posts.js",posts)

    switch (action.type){
        case 'UPDATE':
            return posts.map((post)=> post._id === action.payload._id ? action.payload : post)
            
        case 'GETALL':
            return {loading: false, posts:action.payload};
        case 'CREATER':
            return [...posts, action.payload];
        default:
            return posts;
    }
    
}

export default posts;