import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/borrowbooks/phybcategories.css';
import Physicalcatg from '../pagecomponents/borrowbooks/phybcategories';

import '../pagecomponents/borrowbooks/phygerne.css';
import Phygerneone from '../pagecomponents/borrowbooks/phygerne';

import '../pagecomponents/borrowbooks/phygerne2.css';
import Phygernetwo from '../pagecomponents/borrowbooks/phygerne2';

import '../pagecomponents/borrowbooks/findphybook.css';
import Findphybook from '../pagecomponents/borrowbooks/findphybook';

import '../pagecomponents/borrowbooks/phygerne3.css';
import Phygernethree from '../pagecomponents/borrowbooks/phygerne3';

import '../pagecomponents/borrowbooks/phygerne4.css';
import Phygernefour from '../pagecomponents/borrowbooks/phygerne4';

import '../pagecomponents/borrowbooks/scanqrcode.css';
import Scanbook from '../pagecomponents/borrowbooks/scanqrcode';


function Borrowbookp() {
    //console.log("rerendring")
    return (
        <>
            <Libnavbar />

            <Physicalcatg />

            <Scanbook />

            <Phygerneone />

            <Phygernetwo />

            <Findphybook />

            <Phygernethree />

            <Phygernefour />

            
        </>
    )
}

export default Borrowbookp;