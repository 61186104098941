import React from "react";
import './galleryintro.css';

const Galleryintro = () => {
  return (
    <>
        <div className="galleryintrocont">
            <div className="galleryintrostatement">
                <h2>Gallery</h2>
            </div>
        </div>
         
    </>
  );
};

export default Galleryintro;