import React, { useState, useEffect} from "react";
import "./phycatblock.css";
import { useLocation, Link } from "react-router-dom";

const PhyCatblockp = () => {
    const [localData,  setlocalData] = useState([]);

    const location = useLocation();

    
    const categoryview =  location.state.data.categoryview;
    const catname =  location.state.data.catname; 
    var pagen = location.state.data.nxtpage;
    const searchactive = location.state.data.searchactive;
    const searchterm = location.state.data.searchterm;

    

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetphycatspecbooks = '/getphycatspecbooks';
    const getphycatspecbooksurl = baseUrl + dynamicgetphycatspecbooks;

    const dynamicsearchphycatspecbooks = '/searchphycatspecbooks';
    const searchphycatspecbooksurl = baseUrl + dynamicsearchphycatspecbooks;

    const redirectinfoUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':3000/borrowbookinfo?bookId' : '/borrowbookinfo?bookId'}`;


    console.log("searchactive", searchactive)

    

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        // <a href="/onlinelibinfo">
        if (searchactive === "off"){

        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getphycatspecbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ categoryview, pagen })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
        }else if (searchactive === "on") {
          //searchcatgridbooks
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchphycatspecbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ searchterm, pagen })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();

        }
        
      
         
           
      
    }, [categoryview, searchterm]);

    /*const redirectToUrl = (e, url) => {
        e.preventDefault();
        window.location.href = `${redirectinfoUrl}=${url}`; // Redirect to the URL passed as parameter
    };*/

    function redirectToUrl (e, url) {
      e.preventDefault();
      window.location.href = `${redirectinfoUrl}=${url}`; // Redirect to the URL passed as parameter
      
    }


if (localData !== null) {
    const getbooksgrid = localData.map((post, index) =>
        <div className="notimgridbufferblock" key={post._id}>
           
                <Link
                    //to = "/borrowbookinfo"
                    onClick={(e) => redirectToUrl(e, post.bookid)}
                    style={{ textDecoration: 'none', color: 'black', padding: 0}}
                >
                <div className="notimgridblock" >
                    <div className="actualimgblock">
                        <img src={post.imglink} alt="bookimg" />
                        <p>{post.title}</p>
                        <p className="ntbktitleblock">{post.authorname}</p>
                    </div> 
                </div>
                </Link>
            
        </div>
    
    )


  return (

    
    <>
        <div className="catgridmainblock">
            <div className="cattitleblock">
                <h2>{catname}</h2>
                <div className="notablegridblock">
                    {localData.length === 0 ?
                    <p>Book not available</p>
                    :
                    getbooksgrid
                    }
                </div>
            </div>  
        </div>
    
        
    </>
  );
};
};

export default PhyCatblockp;