import React from 'react';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/sports/sportsintro.css';
import Sportsintro from '../pagecomp/sports/sportsintro';

import '../pagecomp/sports/sportsintroimg.css';
import Sportsintroimg from '../pagecomp/sports/sportsintroimg';

import '../pagecomp/sports/sportspara.css';
import Sportspara from '../pagecomp/sports/sportspara';

import '../pagecomp/sports/sports.css';
import Sportspagegrid from '../pagecomp/sports/sports';

import '../pagecomp/sports/sportsstate.css';
import Sportsstatement from '../pagecomp/sports/sportsstate';

import '../pagecomp/sports/sportsmenu.css';
import Sportsmenu from '../pagecomp/sports/sportsmenu';

import '../pagecomp/sports/sportsgrid.css';
import Sportsimggrid from '../pagecomp/sports/sportsgrid';


import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Sportsp() {
    return (
        <>
            <NavbarMs />

            <Sportsintro />

            <Sportsintroimg />

            <Sportsstatement />

            <Sportspara />

            <Sportspagegrid />

            <Sportsmenu />

            <Sportsimggrid />

            <Footer />
        </>
    )
}

export default Sportsp;