import React from 'react';

import Adminintro from '../pagecomp/staff/adminteam/adminintro';
import '../pagecomp/staff/adminteam/adminintro.css';

import Adminteam from '../pagecomp/staff/adminteam/adminteam';
import '../pagecomp/staff/adminteam/adminteam.css';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Adminteampage() {
    return (
        <>
            <NavbarMs />

            <Adminintro />

            <Adminteam />

            <Footer />
        </>
    )
}

export default Adminteampage;