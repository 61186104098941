import React, { useState } from 'react';
import './majoreventsmenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Majoreventsmenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

  
  return (
    <>
       <div className="majoreventsmenu"> 
            <div className="majoreventsmenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Major Events Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Major Events Menu  &#9776;</h2>
                }
                <div className={`majoreventsmenugerneblock ${showGerne ? 'majoreventsmenushow-gerne' : ''}`}>
                    <div className="majoreventsmenuactualgerne">
                        <Link 
                            to="/majorevents" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { majorevents: "all" , abt: ""}  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>All</p> 
                        </Link>
                        <Link 
                            to="/majorevents" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { majorevents: "business indaba", abt: "Business indaba is about connecting business among the masiyephambili society" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Business Indaba</p> 
                        </Link>
                        <Link 
                            to="/majorevents" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { majorevents: "family fun day", abt: "Family fun day is about bulding relationships with children while having fun" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Family Fun Day</p>
                        </Link>
                        
                    </div>

                </div>
            </div>
        </div>
    </>
  );
    
}

export default Majoreventsmenu;