import React from "react";
import './majoreventsintro.css';

const Majoreventsintro = () => {
  return (
    <>
        <div className="majoreventsintrocont">
            <div className="majoreventsintrostatement">
                <h2>Major Events</h2>
            </div>
        </div>
         
    </>
  );
};

export default Majoreventsintro;