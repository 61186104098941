import React from 'react';

import Contact from '../pagecomp/contactp/contact';
import '../pagecomp/contactp/contact.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Contactus() {
    return (
        <>
            <NavbarMs />

            <Contact />

            <Footer />
        </>
    )
}

export default Contactus;