import React from "react";
import './boarddirintro.css';

const Boarddirintro = () => {
  return (
    <>
        <div className="boarddirintrocont">
            <div className="boarddirintrostatement">
                <h2>Board of Directors</h2>
            </div>
        </div>
         
    </>
  );
};

export default Boarddirintro;