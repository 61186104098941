const users = (users = {users:null, loading: false}, action) => {

    switch (action.type){
        case 'FETCH_ALL':
            return {loading: false, users:action.payload};
        case 'CREATE':
            return [...users, action.payload];
        default:
            return users;
    }
    
}

export default users;