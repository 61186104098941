import React from "react";
import './agriculturestate.css';

const Agriculturestatement = () => {
  return (
    <>
        <div className="agriculturemnstatementcont">
            <div className="agriculturethestatement">
                <h2>Agriculture is like magic! It takes tiny seeds and sunshine and turns them into the yummy fruits and veggies you love for lunch!</h2>
            </div>
        </div>
         
    </>
  );
};

export default Agriculturestatement;