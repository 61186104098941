import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./library.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import ExamResults from "./examres";
import ExamPages from "./exampages";
import Exampdf from "./examviewer";
//import { useDispatch, useSelector } from 'react-redux';
//import { getSubjects } from "../../../../actions/subjects";
/*

        <div className="pdf-card">
            < PdfViewer pdfUrl="https://drive.google.com/file/d/1Egm6n123XOxsB2kikhC0WMXe0YKz0MwJ/view?usp=sharing" />
        </div>


*/

function Exam(props) {

    const location = useLocation();

    const papert = location.state.data.papertype;
    const booksub = location.state.data.booksubject;
    const formnumber = location.state.data.formnum;
    const searchwor = location.state.data.keywordserch;
    const viewpdf = location.state.data.bookview;

    

    const [contentData, setContentData] = useState({
        papert: papert,
        booksub: booksub,
        formnumber: formnumber,
        searchword: searchwor,
        viewpdf: viewpdf
    });


    const [userfData,  setuserfData] = useState([]);

    const username = localStorage.getItem('theusername');

    const [searchword,  setSearchword] = useState([]);

    const handleSearch = (e) => {
      e.preventDefault();
      const searchvalue = e.target.value;
      setSearchword(searchvalue);
      console.log("word being searched",searchvalue)
       
    }

    useEffect(() => {

        const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/user-det', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username })
          })
            .then(response => response.json())
            .then(data => {
              //console.log("data contents userform db",data.form)
              setuserfData(data.form)
              
            
          });
          
        };
    
        fetchContents();
    
      }, []);

    return (
      <>
      <div className="Library-main">
        <div className="categories-main">
            <div className="categories-container">
                <p className="categoriestopic">Categories</p>
                <Link
                to = "/Exampapers"

                onClick={() => window.location.reload() }

                state = {{
        
                data: {papertype: "exam", booksubject: "maths", formnum: userfData, nxtpage: 1} }}
                                    
                style={{ textDecoration: 'none', color: 'black' }}

                > 
                <p className="catoptions">Maths</p>
                </Link>
                <Link
                to = "/Exampapers"

                onClick={() => window.location.reload() }

                state = {{
        
                data: {papertype: "exam", booksubject: "physics", formnum: userfData, nxtpage: 1} }}
                                    
                style={{ textDecoration: 'none', color: 'black' }}

                > 
                <p className="catoptions">Physics</p>
                </Link>
                <p className="catoptions">Science</p>
                <p className="catoptions">Biology</p>
                <p className="catoptions">Chemistry</p>
            </div>
        </div>

        { viewpdf !== "yes" ? 

        <div className="search-main">
            <div className="search-contanier">
                <form className="form-inline">
                    <input type="text" id="header-search" placeholder="Search for books" onChange={handleSearch}/>
                    <Link
                      to = "/Exampapers"

                      onClick={() => window.location.reload() }

                      state = {{
              
                      data: {papertype: "exam", keywordserch: searchword, nxtpage: 1} }}
                                          
                      style={{ textDecoration: 'none', color: 'black' }}

                    > 
                    <button className="searchbutton">Search</button>
                    </Link>
                </form>
            </div>
        </div>
        :
        null
        }
      </div>

      { viewpdf !== "yes" ? 

      <div className="bufferbookpdf">

      <ExamResults />

      <ExamPages />

      </div>

      :

      <Exampdf />

      }

      </>
      
    );
    
}


  
  export default Exam;