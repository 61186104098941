import React from "react";
import './pta.css';

const PTApa = () => {
  return (
    <>
         <div className="ptamnstatementcont">
            <div className="ptathestatement">
                <h2>PTA creates a bridge between parents and teachers, fostering a supportive environment for student success.</h2>
            </div>
        </div>
         
    </>
  );
};

export default PTApa;