import React from "react";
import './admissionintroimg.css';

const Admissionintroimg = () => {
  return (
    <>
        <div className="admissioninimgcont">
            <div className="admissioninimg">
                <img src="https://optsirvacc.sirv.com/masiyephambili/gallery/adminblockmain.JPG" alt="placeholder" />
            </div>
        </div>
         
    </>
  );
};

export default Admissionintroimg;