import React, {useState, useEffect} from "react";
import './culturegrid.css';
import { useLocation, Link } from "react-router-dom";

const Cultureimggrid = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sportlength, setSportlength] = useState(0);

    const images = [
        { id: 0, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/culture.jpg", culture: "business indaba", spid: 0 },
        
    ];

    const location = useLocation();
    //location.state.data.sporttype;   <iframe title='silwanevid' src={video1}></iframe>

    const sportstype = location.state.data.culture;
    const aboutevent = location.state.data.abt;

    useEffect(() => {
      
        const filteredImages = images.filter(
          (image) => image.culture === sportstype
        );
        setSportlength(filteredImages.length);
  
    }, [sportstype, images]);

    const handleImageClick = (index) => {
      setCurrentIndex(index);
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };
  
    const handlePrev = () => {
      const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNext = () => {
      const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };

    const handlePrevSport = () => {
      const newIndex = currentIndex === 0 ? sportlength - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNextSport = () => {
      const newIndex = currentIndex === sportlength - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };


    return (
      <>
          <div className="cultureimggridcont">
              <p className="cultureabout">{aboutevent}</p>
              {isOpen && (
                <div className="popupculture">
                  {images.map((image, index) => (
                    <div className="cultureimgbuffer" key={image.id}>
                      {(image.culture === sportstype && image.spid === currentIndex) ?
                      <>
                        <img src={image.src} alt={`culture ${currentIndex}`} />
                        <button className="culturearrow prev" onClick={handlePrevSport}>
                          &#10094;
                        </button>
                        <button className="culturearrow next" onClick={handleNextSport}>
                          &#10095;
                        </button>
                        <button className="cultureclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      :(sportstype === "all" && index === currentIndex) &&
                      <>
                        <img src={image.src} alt={`culture ${currentIndex}`} />
                        <button className="culturearrow prev" onClick={handlePrev}>
                          &#10094;
                        </button>
                        <button className="culturearrow next" onClick={handleNext}>
                          &#10095;
                        </button>
                        <button className="cultureclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      }
                    </div>
                  ))}
                </div>
              )}
              <div className="cultureimggridsize">
                <div className="cultureimggrid">
                  {images.map((image, index) => (
                          <div className="cultureimgcont" key={image.id}>
                              {image.culture === sportstype ?
                                <img src={image.src} alt={`Grid ${image.spid + 1}`}  onClick={() => handleImageClick(image.spid)}/>
                                : sportstype === "all" &&
                                <img src={image.src} alt={`Grid ${image.id + 1}`} onClick={() => handleImageClick(image.id)} />
                              }
                          </div>
                  ))} 
                </div>
              </div>
          </div>
           
      </>
    );
  };
  
export default Cultureimggrid;