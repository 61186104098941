import React from "react";
import './statement.css';

const Statement = () => {
  return (
    <>
        <div className="mnstatementcont">
            <div className="thestatement">
                <h2>A school built on imagination, perseverance and sustainability.</h2>
            </div>
        </div>
         
    </>
  );
};

export default Statement;
