import React, { useState } from 'react';
import "./adminlogin.css";
//import { useSelector } from 'react-redux';
import axios from 'axios';
//import { createUser } from '../../../actions/users';

function PTAlogin() {
  // get data from database thru app.js
  /*const users = useSelector ((state) => state.users);
  console.log("check token here",users);*/

 
  

  const [userData, setUserData] = useState({
    username: '',
    password: '',
    portal: ["pta"]

  });

  const [errorMessage, setErrorMessage] = useState('');

  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicpart = '/librarylogin';
  const fullUrl = baseUrl + dynamicpart;


  
  
  // user dispatch to send data to the database thru the handlesubmit

  
    const handleSubmit = async (event) => {
      event.preventDefault();

      try {
        axios.defaults.withCredentials = true;
  
        const response = await axios.post(fullUrl, {
          userData
        });

       
        if (response.status === 200) {
          // The server has set a user cookie upon successful login
          // You can handle the user login and redirect the user as needed.
          console.log('Login successful');
          setErrorMessage('');
          window.location.href = '/addptaproduct';
        }

  
      } catch (error) {
        console.error('Login failed:', error);
        setErrorMessage(error.response.data.message);
      }
    
  

      

      

      
      
    };
  
    return (
      <div className='containerLog'>
        <div className="formpagead">
            <h2>PTA</h2>
            <form onSubmit={handleSubmit}>
                <div>
                <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder='Username'
                    value={userData.username}
                    onChange={(e) => setUserData({ ...userData, username: e.target.value})}
                />
                </div>
                <div>
                <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder='Password'
                    value={userData.password}
                    onChange={(e) => setUserData({ ...userData, password: e.target.value})}
                />
                </div>
                <button type="submit">Login</button>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </form>
        </div>
      </div>
    );
  }
  
  export default PTAlogin;
  