import React from "react";
import './admissionintro.css';

const Admissionintro = () => {
  return (
    <>
        <div className="admissionintrocont">
            <div className="admissionintrostatement">
                <h2>Admission</h2>
            </div>
        </div>
         
    </>
  );
};

export default Admissionintro;