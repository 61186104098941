import * as api from '../api';

//Action creators
export const getOutlines = (findf) => async (dispatch) => {
    try {
        //console.log('actions outlines', returnf)
        const { data } = await api.fetchOutlines(findf);

        dispatch({ type: 'FETCH_ALL', payload: data });
        
    } catch (error) {
        console.log(error.message);
        
    }
}

export const createOutline = (post) => async (dispatch) => {
    try {
        const { data } = await api.createOutline(post);

        dispatch({ type: 'CREATE', payload: data})
    } catch (error) {
        console.log(error);
        
    }
}

export const updateOutline = (id, post) => async (dispatch) => {
    try {
        const { data } =await api.updateOutline(id, post);

        dispatch({ type: 'UPDATE', payload: data });
    } catch (error) {

        console.log(error);
        
    }
}
