import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/onlinelibrary/onlinelibrary.css';
import Onlinelibhome from '../pagecomponents/onlinelibrary/onilnelibrary';

import '../pagecomponents/onlinelibrary/onlinegerne.css';
import Onlinelibgerneone from '../pagecomponents/onlinelibrary/onlinegerne';

import '../pagecomponents/onlinelibrary/onlinegernetwo.css';
import Onlinelibgernetwo from '../pagecomponents/onlinelibrary/onlinegernetwo';

import '../pagecomponents/onlinelibrary/onlinelibsearch.css';
import Onlinelibsearch from '../pagecomponents/onlinelibrary/onlinelibsearch';

import '../pagecomponents/onlinelibrary/onlinegernethree.css';
import Onlinelibgernethree from '../pagecomponents/onlinelibrary/onlinegernethree';

import '../pagecomponents/onlinelibrary/onlinegernefour.css';
import Onlinelibgernefour from '../pagecomponents/onlinelibrary/onlinegernefour';




//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Onlinelibrayp() {
    console.log("rerendring")
    return (
        <>
            <Libnavbar />

            <Onlinelibhome />

            <Onlinelibgerneone />

            <Onlinelibgernetwo />

            <Onlinelibsearch />

            <Onlinelibgernethree />

            <Onlinelibgernefour />

        </>
    )
}

export default Onlinelibrayp;