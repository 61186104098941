import React, { useState } from "react";
import "./introsection.css";

const Introsectionp = () => {
  return (
    <>
        <div className="introall">
            <div className="introhometext">
                <p>Books & More</p>   
            </div>
            <div className="browsesection">
                <div className="browseTopic">
                    <h2>BROWSE THE COLLECTIONS</h2>
                    <div className="browsepara">
                        <p>The Library offers thousands of items to borrow, from books, DVDs, CDs, music scores and magazines to downloadable books, music, movies and more. All you need is your library card for access!</p>
                    </div>
                        
                </div>
            </div>
        </div>
    
        
    </>
  );
};

export default Introsectionp;