import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./edsubject.css";
import { useDispatch, useSelector } from 'react-redux';
import { getSubjects } from "../../../../actions/subjects";

function Edsubject() {

  const [localData, setlocalData] = useState(null);

  const [userfData,  setuserfData] = useState([]);

  const [subDescrip, setsubDescrip] = useState({
    coursename: "",
    description: "",
    numlessons: "",
    id: "",
    formnum: ""
  });

  const username = localStorage.getItem('theusername');

  /*const dispatch = useDispatch();
    
  useEffect(() => {
      dispatch(getSubjects());
  }, [dispatch]);*/

  const dbsubjects = useSelector((state) => state.subjects);
  const mpsubjects = dbsubjects.subjects;

  //console.log("receving from db", mpsubjects);
    const editsubject = (e, title, descript, numls, id, form) => {

        e.preventDefault()
        setsubDescrip({...subDescrip, coursename: title, description: descript, numlessons: numls, id:id, formnum: form });

    }

    const handleEditSubDescrip = (e) => {
      e.preventDefault();

  
      //dispatch(createEvent(eventData));

      const Editsubjectdescr = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/editsubjectdescription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ subDescrip })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)
              if (data.length > 0) {
                  //console.log("logging data", data[0].subtopics);
                  const contentcheck = data.map((post) => post.subjects)
                  //console.log("mapping",contentcheck);
                  //console.log('data', data)
                  if (contentcheck !== null) {
                    setlocalData(data);
                  }else {
                    Editsubjectdescr();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
              
          });
          
      };
    
      Editsubjectdescr();
        
      clear();
      
  }

  const clear = () => {

    setsubDescrip({...subDescrip, coursename: "", description: "", numlessons: "", id:"" });
}


  /*useEffect(() => {

    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/user-det', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      })
        .then(response => response.json())
        .then(data => {
          //console.log("data contents userform db",data.form)
          setuserfData(data.form)
          
        
      });
      
    };

    fetchContents();

  }, []);*/

  useEffect(() => {
    const fetchSubjects = async () => {

      await fetch('http://localhost:5000/displaysubject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      })
        .then(response => response.json())
        .then(data => {
          console.log("data from db type",typeof(data))
        
            //console.log("logging data", data[0].subtopics); data.subjects.length > 0
            setlocalData(data);
            const objarray = Object.entries(data).map(([key, value]) =>  value );
            setuserfData(objarray[1]);



            /*const storedData = data.getItem('array');  setuserfData
            if (storedData) {
              setlocalData(JSON.parse(storedData));


              
            }*/
            /*const subjectcheck = data.subjects.map((post) => post.title)
            console.log('subjectcheck console logging',subjectcheck);
            if (subjectcheck !== null) {
              setlocalData(data);
            }else {
              fetchSubjects();
            }*/
          
          

          //setlocalData(data);

          
          
        
      });

      
    };

    fetchSubjects();
   }, []);

   /*useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setlocalData(JSON.parse(storedData));
      
    }
  }, []);*/


  /*useEffect(() => {
    const fetchSubjects = async () => {
      const response = await fetch('http://localhost:5000/subjects');
      const data = await response.json();
      
      if (data.length > 0) {
        //setPostData(data);
        localStorage.setItem('array', JSON.stringify(data));
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setlocalData(JSON.parse(storedData));
      
    }
  }, []);

  console.log("see localdata", localData)*/

  /*const gotooutline = (event, title) => {
    event.preventDefault();
    console.log("receiving id", title);

    <Link
      to={{
        pathname: "/spoutline",
        state: title // your data array of objects onClick={(event) => gotooutline(event, type.title) }
      }}
    />
   
    
  }*/
 


  

  

  //<h2>Form 1 Mathematics</h2>
  //              <p>Form one mathematics is an introduction to basic algebra, graphs, equations, pathagarus theorem </p>
  //const objarray = Object.entries(localData).map(([key, value]) =>  value );

  //console.log("data return block", objarray)

     //const obj2array = localData.map((post) => post)

    //console.log("data return block out", obj2array.subjects)

   

 
  

  if ( localData !== null) { 
    
    console.log("inside useEffect block", userfData)
  
    
    
    const subjectcard = localData.subjects.map((type) =>
      <div className="testcont" key={type._id}>
      
      
      <div className="edSubject-card"  >
         <Link

         to = "/editoutline"

         state = {{
       
          data: {form: userfData, filterf: type.title} // your data array of objects type.title 
        }}

        style={{ textDecoration: 'none', color: 'black' }}

        >
        <div className="edSubject-name" >
          <p>Course</p>
        </div>
        </Link>
        <Link

        onClick={e => editsubject(e, type.title, type.descrip, type.numlesson, type._id, userfData )}
        
        style={{ textDecoration: 'none', color: 'black' }}
       
        >
        <div className="edSubject-descrip" >
          <h2>{type.title}</h2>
          <p>{type.descrip}</p>
        </div>
        <div className="edLesson-number" >
        <p>{type.numlesson}</p>
        </div>
        </Link>
      </div> 
      
       
      </div>
    );

    




  
    return (
      <>
      <div className="edSubject-main">
        {subjectcard} 
      </div>

      <div className="subjeditsect-main">
        <div className="subjeditsect-box">
            <p className="subjeditsect-boxpara">Edit Description</p>
            <form onSubmit={handleEditSubDescrip}>
                <div className='subjetedname'> 
                    <input type="text" name="subjetedname" placeholder="Course Name" value={subDescrip.coursename} onChange={(e) => setsubDescrip({ ...subDescrip, coursename: e.target.value})} />
                </div>
                <div className='subjetedname'> 
                    <input type="text" name="subjetedname" placeholder="Description" value={subDescrip.description}  onChange={(e) => setsubDescrip({ ...subDescrip, description: e.target.value})}/>
                </div>
                <div className='subjetedname'> 
                    <input type="text" name="subjetedname" placeholder="Number of lessons" value={subDescrip.numlessons}  onChange={(e) => setsubDescrip({ ...subDescrip, numlessons: e.target.value})}/>
                </div>
                <button className="submitbuttonsubjetedname" type="submit">Edit</button>
            </form>

        </div>
      </div>

      </>
      
    );
  }

}
  
  export default Edsubject;