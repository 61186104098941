import React, { useState, useEffect } from "react";
import "./bookpages.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";




function Bookpages(props) {



    const location = useLocation();

    const papert = location.state.data.papertype;
    const booksub = location.state.data.booksubject;
    const formnumber = location.state.data.formnum;
    const numboksperpage = 4;
    const pagen = location.state.data.nxtpage;
    const searchword = location.state.data.keywordserch;
    

    const [contentData, setContentData] = useState({
        papert: papert,
        booksub: booksub,
        formnumber: formnumber,
        searchword: searchword
    });

    const [numlocalData,  setnumlocalData] = useState([]);
    const [localData,  setlocalData] = useState([]);

    const [currentPage,  setcurrentPage] = useState({
        pagen: pagen
    });

    const [lowpagerlimit,  setlowpagelimit] = useState(1);
    const [uppagelimit,  setuppagelimit] = useState(3);

    /*const gotopage = (page,e) => {
        setcurrentPage(page)
        window.location.reload()
    
    }
    {(e) => gotopage(index+1,e)}
    */

     

   


    useEffect(() => {
        
        const fetchBooks = async () => {
            
        await fetch('http://localhost:5000/book-filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contentData })
        })
        .then(response => response.json())
        .then(data => {
            console.log("data contents db",data.length)
            
            if (data.length > 0) {
        
                if (data.length !== null) {
                    setnumlocalData(data.length);
                    setlocalData(data);
                }else {
                    fetchBooks();
                }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
            }
      
                //setContentData(data);
                
              
        });
            
        };
      
        fetchBooks();
        
       
    }, []);

    useEffect(() => {

       
        var upperpagelimit = currentPage.pagen + 2;
        var lowerpagelimit = currentPage.pagen - 4; 
        setlowpagelimit(lowerpagelimit);
        setuppagelimit(upperpagelimit );

        console.log('upperpagelimit', upperpagelimit)
        console.log('lowerpagelimit', lowerpagelimit)
        
        
        
        
       
    }, [currentPage, currentPage.pagen, []]);



    if (numlocalData !== null && uppagelimit !== null){

        var numofpag = numlocalData/numboksperpage;
        var numofpages = Math.ceil(numofpag);

        console.log("the number of page are", numofpages)

        const pagescard = localData.map((post, index) =>
            <div className="books-cardB1" key={post._id}> 
                <div className="pagenumbers">
                    { (index > lowpagerlimit && index < uppagelimit && index < numofpages) &&
                    <Link
                    to = "/Library"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: searchword, nxtpage: index+1} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p className={(currentPage.pagen === index + 1) ? 'clickedpage': 'thepage'}>{index+1}</p>
                    </Link>
                    }
                </div>
                
            </div>
        )

        const pagebuttonscard = localData.map((post, index) =>
            <div className="books-cardB1" key={post._id}> 
                {index === currentPage.pagen &&
                <div className="bookpages-bottons">
                    <Link
                    to = "/Library"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: searchword, nxtpage: 1} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>First</p>
                    </Link>
                    <Link
                    to = "/Library"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: searchword, nxtpage: index-1} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>Prev</p>
                    </Link>
                    <Link
                    to = "/Library"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: searchword, nxtpage: index+1} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>Next</p>
                    </Link>
                    <Link
                    to = "/Library"

                    onClick={() => window.location.reload() }

                    state = {{
            
                    data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: searchword, nxtpage: numofpages} }}
                                        
                    style={{ textDecoration: 'none', color: 'black' }}

                    >
                    <p>Last</p>
                    </Link>
                </div> 
                }    
            </div>
        )


        

    
    
      

    return (
        <>
        <div className="bookpages-main">
            <div className="bookpages-cont">
                <p>Pages:</p>
                { pagescard }
                
            </div> 
             { pagebuttonscard }  
        </div>
        </> 
      
    );
    }
    
}


  
export default Bookpages;