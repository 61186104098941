import React, { useState } from "react";
import "./findphybook.css";
import { Link} from "react-router-dom";

const OnlineLibsearch = () => {
  const [searchterm,  setSearchterm] = useState([]);

  const catname = "Search Results";
  const searchactive = "on";

  return (
    <>
       <div className="phymainonlinelibsearch">
            <div className="phyonlinelibsearchbar">
                <h2>Find a book</h2>
                <input type="text" className="physearchbar" placeholder="Search book by title" onChange={(e) => setSearchterm(e.target.value)} />
                <Link 
                  to="/phycategory" 
                            
                  state = {{
                    data: {searchactive: searchactive, catname: catname, searchterm: searchterm, nxtpage: 1  }
                  }}
                  style={{ textDecoration: 'none', color: 'black' }}
                >      
                <button>Search</button>
                </Link>
            </div>
        </div>
    </>
  );
};

export default OnlineLibsearch;