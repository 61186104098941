import React from "react";
import './masiyevalue.css';

const Masiyevalue = () => {
  return (
    <>
        <div className="masiyevaluemaincont">
            <h2>Explore Masiye Junior School and witness how we shape educated, ethical citizens ready to embrace life's challenges.</h2>
        </div>
        
    </>
  );
};

export default Masiyevalue;