import * as api from '../api';

//Action creators
export const getPosts = () => async (dispatch) => {
    try {
        const { data } = await api.fetchPosts();

        dispatch({ type: 'GETALL', payload: data });
        
    } catch (error) {
        console.log(error);
        
    }
}

export const createPost = (post) => async (dispatch) => {
    try {
        const { data } = await api.createPost(post);

        dispatch({ type: 'CREATER', payload: data})
    } catch (error) {
        console.log(error);
        
    }
}

export const updatePost = (id, post) => async (dispatch) => {
    try {
        const { data } =await api.updatePost(id, post);

        dispatch({ type: 'UPDATE', payload: data });
    } catch (error) {

        console.log(error);
        
    }
}
