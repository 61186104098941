import React, { useState, useEffect } from 'react';
//import CurrentPage from './hooks/currentpage';
import PrivateRoute from './components/PrivateRoutes/PrivateRoutes'

//import { EventEmitter } from 'events';





//import cors from 'cors';

/*import { useEffect, useState } from 'react';*/
//import Navbar from './components/Navbar';
//import Carasoule from './components/Carasoule';
//import ImageGrid from './components/ImageGrid';
//import ImageGrid from './components/editwebpagecomp/edithomep/ImageGrid';
//import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Aboutus from './components/pages/Aboutus';
import Adminteampage from './components/pages/Adminteam';
import Boardofdirectorspage from './components/pages/Boardofdirectors';
import Contactus from './components/pages/Contactus';
import Admissions from './components/pages/Admissions';
import Academics from './components/pages/Academics';
import Sports from './components/pages/Sports';
import PTApage from './components/pages/PTA';
import PTAproductpage from './components/pages/PTAPRODUCTPAGE';
import StudentPortal from './components/pages/Student-Portal';
import Gallerypage from './components/pages/Gallery';
import Clubspage from './components/pages/Clubs';
import Agriculturepage from './components/pages/Agriculture';
import Majoreventspage from './components/pages/Majorevents';
import ParentSpace from './components/pages/Parent-Space';
import Portals from './components/pages/Portals';

import Ehome from './components/editwebpages/Ehome';


//Importing pages for the students portal below here
import Phome from './components/studentportal/pages/phome';
import SPoutline from './components/studentportal/pages/spoutline';
import Pcontent from './components/studentportal/pages/pcontent';
import Plesson from './components/studentportal/pages/plesson';
import Plibrary from './components/studentportal/pages/plibrary';
import PExampapers from './components/studentportal/pages/pExampapers';
import PTestAssignment from './components/studentportal/pages/ptestassignment';
import PMarkedtstAssignment from './components/studentportal/pages/pmarkedtstassignment';
import POnlinelibrary from "./components/studentportal/pages/ponlinelib"


//Importing pages for the Teachers portal below here
import Addtest from './components/teacherportal/pages/Addtest';
import Addstart from './components/teacherportal/pages/Addstart';
import Editsubjects from './components/teacherportal/pages/Edsubjects';
import Editoutline from './components/teacherportal/pages/Edoutline';


//Importing pages for the Parents portal below here
import Performancegu from './components/parentsportal/pages/perfomance';

//Importing pages for the Teachers Library portal below here
import Addlibbooks from './components/libraryportal/pages/Addbooks';
import AddOnlinelibbooks from './components/libraryportal/pages/Addonlinebooks';
import Qrgeneratecode from './components/libraryportal/pages/Qrgenerate';
import BooksinCirculation from './components/libraryportal/pages/Circulation';
import Dashboardlibadim from './components/libraryportal/pages/Dashboard';
import Circulationhistory from './components/libraryportal/pages/Circulationhistory';
import Studentstatslib from './components/libraryportal/pages/Studentstats';
import Studentstatuspage from './components/libraryportal/pages/Studentstatus';
import PhyBookstatslib from './components/libraryportal/pages/Phybookstat';
import OnlineBookstatslib from './components/libraryportal/pages/Onlinebookstats';


//Importing pages for the Adimin portal below here
import Addlibmember from './components/libraryportal/pages/Addmember';
import Adh1librarydash from './components/adminportal/pagecomponent/adminh1/pages/librarydash';


//Importing pages for the Main Library portal below here Students Library Portal
import LibStudPortal from "./components/pages/LibraryLoginPortal"
import LibStudHome from './components/studentlibraryportal/pages/libhome';
import OnlineLibHome from './components/studentlibraryportal/pages/onllnelibrary';
import OnlineLibInfor from './components/studentlibraryportal/pages/onlinebookinfo';
import OnlineLibdisplay from './components/studentlibraryportal/pages/onlinedisplay';
import OnlineCategory from './components/studentlibraryportal/pages/onlinecategory';
import Borrowbook from './components/studentlibraryportal/pages/borrowbook';
import Borrowbookinfo from './components/studentlibraryportal/pages/borrowbookinfo';
import PhysicalCategory from './components/studentlibraryportal/pages/physicalcategory';

//Importing pages for the Main Library portal below here PTA Portal
import Addptaproductp from './components/PTA/pages/Addptaproduct';
import Culturepage from './components/pages/Culture';




//import { getPosts } from './actions/posts';
//import { getUser } from './actions/users';

/*
                <Routes>
                    <Route path='/' exact element={<Catlogue currentId={currentId} setCurrentId={setCurrentId} />} />
                </Routes>
                 <Carasoule />

                <ImageGrid />

                <GridWithText />
                 <PrivateRoute path='/addmember' exact element={<Addlibmember /> } />
*/
//const emitter = new EventEmitter(); // Create an event emitter

/*const useCurrentPage = () => {
    const [currentPage, setCurrentPage] = useState(window.location.pathname);

    const handleLocationChange = () => {
        setCurrentPage(window.location.pathname);
    };

    const handleHashChange = () => {
        setCurrentPage(window.location.pathname);
    };

    useEffect (() => {
       
        window.addEventListener("popstate", handleLocationChange);
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return currentPage;
}*/





const App = () => {

    //const [currentId, setCurrentId] = useState(null);

   /* const [authenticated, setAuthenticated] = useState(false);

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(getPosts());
        //console.log("dispatch",dispatch)
    }, [dispatch]);*/

    /*useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);*/

   

  /*useEffect(() => {
        // Check if the user is authenticated by making a request to the server
        console.log("running the authenticated")
        const fetchContents = async () => {
            axios.defaults.withCredentials = true;
            await axios.get('http://localhost:5000/requireAuth')
            .then((response) => {
                setAuthenticated(true);
                if (response.data.message === "library"){
                    setAuthenticated(true);
                    console.log("see response here",response.data.message)
                    console.log("authenticated")
                }
            })
            .catch((error) => {
                setAuthenticated(false);
                console.log(error)
                console.log(error.response.data.message);
                console.log("error")
            });
        };
        fetchContents();
    }, [authenticated, []]);


    console.log("see authenticated",authenticated)*/

  /*useEffect(() => {

    try {
        axios.defaults.withCredentials = true;

        const response = axios.get('http://localhost:5000/requireAuth');

    
        if (response.status === 200) {
        // The server has set a user cookie upon successful login
        // You can handle the user login and redirect the user as needed.
        console.log('Login successful');
        setAuthenticated(true);
        }
        <Route path='/about-us' element={(authenticated) ? <Aboutus /> : <Navigate to="/portals" state={{data: {portalname: "Library"} }} /> } />


    } catch (error) {
        console.error('Login failed:', error);
        setAuthenticated(false);
    } 
    }, []);
    
    <Route path='/addmember' exact element={(authenticated === "library") ? <Addlibmember /> : <Navigate to="/portals" state={{data: {portalname: "Library"} }} />} />
     <Routes>
                    <Route path="/addmember" element={<PrivateRoute component={<Addlibmember/>}/>} />
                </Routes>


                <Routes>
                    <Route path='/' exact element={<Home />} />
                </Routes> 

                <Route path='/addonlinebooks' exact element={<AddOnlinelibbooks />} /> 

                <Route path='*' exact element={<PrivateRoute path="/addmember" element={<Addlibmember />} role={'library'} />} />
    */
      /*        <Route path='*' exact element={
                        <>
                        <PrivateRoute path="/dashboardlibadim" element={<Dashboardlibadim />} role={['library']} />
                        <PrivateRoute path="/addmember" element={<Addlibmember />} role={['library']} />
                        <PrivateRoute path="/addonlinebooks" element={<AddOnlinelibbooks />} role={['library']}/>
                        <PrivateRoute path="/addbooks" element={<Addlibbooks />} role={['library']}/>
                        <PrivateRoute path="/circulation" element={<BooksinCirculation />} role={['library']}/> 


                        <PrivateRoute path="/libraydashadh1" element={<Adh1librarydash />} role={['adminh1']}/>
                        </>
                    }
                    />
                    */
   
    //const [currentPage, setCurrentPage] = useState(window.location.pathname);
    
    return(
        <>
            <Router>

                
                
                <Routes>
                    <Route path='/' exact element={<Home />} />
               
                    <Route path="/about-us" element={<Aboutus />} />
                
                    <Route path='/contact-us' exact element={<Contactus />} />
                
                    <Route path='/admissions' exact element={<Admissions />} />
                
                    <Route path='/academics' exact element={<Academics />} />

                    <Route path='/sports' exact element={<Sports />} />

                    <Route path='/pta' exact element={<PTApage />} />

                    <Route path='/gallery' exact element={<Gallerypage />} />

                    <Route path='/majorevents' exact element={<Majoreventspage />} />

                    <Route path='/clubs' exact element={<Clubspage />} />

                    <Route path='/culture' exact element={<Culturepage />} />

                    <Route path='/agriculture' exact element={<Agriculturepage />} />

                    <Route path='/adminteam' exact element={<Adminteampage />} />

                    <Route path='/boardofdirectors' exact element={<Boardofdirectorspage />} />

                    <Route path='/ptaproductpage' exact element={<PTAproductpage />} />
              
                    <Route path='/studentportal' exact element={<StudentPortal />} />
                
                    <Route path='/phome' exact element={<Phome />} />
               
                    <Route path='/parents-space' exact element={<ParentSpace />} />
                
                    <Route path='/portals' exact element={<Portals /> } />
                
                    <Route path='/ehome' exact element={<Ehome />} />
               
                    <Route path='/Outline' exact element={<SPoutline />} />
                
                    <Route path='/Content' exact element={<Pcontent />} />
                
                    <Route path='/Lesson' exact element={<Plesson />} />
                
                    <Route path='/Library' exact element={<Plibrary />} />
               
                    <Route path='/Exampapers' exact element={<PExampapers />} />
                
                    <Route path='/TestAssignment' exact element={<PTestAssignment />} />
                
                    <Route path='/MarkedTestAssignment' exact element={<PMarkedtstAssignment />} />
                
                    <Route path='/addtest' exact element={<Addtest />} />
                
                    <Route path='/addstart' exact element={<Addstart />} />
                
                    <Route path='/editsubjects' exact element={<Editsubjects />} />
                
                    <Route path='/editoutline' exact element={<Editoutline />} />
                
                    <Route path='/performancegu' exact element={<Performancegu />} />
                      
                    <Route path='/qrgenerate' exact element={<Qrgeneratecode />} /> 

                

                    <Route path='/dashboardlibadim' exact element={<PrivateRoute  element={<Dashboardlibadim />} role={['library']} />}/>
                    <Route path='/addmember' exact element={<PrivateRoute element={<Addlibmember />} role={['library']} />}/>
                    <Route path='/addonlinebooks' exact element={ <PrivateRoute element={<AddOnlinelibbooks />} role={['library']}/>}/>
                    <Route path='/addbooks' exact element={<PrivateRoute element={<Addlibbooks />} role={['library']}/>}/>
                    <Route path='/circulation' exact element={<PrivateRoute element={<BooksinCirculation />} role={['library']}/> }/>


                    <Route path='/libraydashadh1' exact element={<PrivateRoute element={<Adh1librarydash />} role={['adminh1']}/>}/>
                

                    


                    <Route path='/onlinelibrary' exact element={<POnlinelibrary />} />

                    <Route path='/studentlibrarylogin' exact element={<LibStudPortal />} />

                    <Route path='/libraryhome' exact element={<PrivateRoute element={<LibStudHome />} role={['library','adminh1','student']}/>} /> 

                    <Route path='/onlinelibcat' exact element={<PrivateRoute element={<OnlineLibHome />} role={['library','adminh1','student']}/>} /> 

                    <Route path='/onlinelibinfo' exact element={<PrivateRoute element={<OnlineLibInfor />} role={['library','adminh1','student']}/>} />

                    <Route path='/onlinedisplay' exact element={<PrivateRoute element={<OnlineLibdisplay />} role={['library','adminh1','student']}/>} />

                    <Route path='/onlinecategory' exact element={<PrivateRoute element={<OnlineCategory />} role={['library','adminh1','student']}/>} /> 

                    <Route path='/borrowbook' exact element={<PrivateRoute element={<Borrowbook />} role={['library','adminh1','student']}/>} /> 

                    <Route path='/borrowbookinfo' exact element={<PrivateRoute element={<Borrowbookinfo />} role={['library','adminh1','student']}/>} /> 

                    <Route path='/phycategory' exact element={<PrivateRoute element={<PhysicalCategory />} role={['library','adminh1','student']}/>} />

                    

                    <Route path='/onlinebookstats' exact element={<PrivateRoute element={<OnlineBookstatslib />} role={['library','adminh1']}/>}/>

                    <Route path='/phybookstats' exact element={<PrivateRoute element={<PhyBookstatslib />} role={['library','adminh1']}/>}/> 

                    <Route path='/studentsstatslib' exact element={<PrivateRoute element={<Studentstatslib />} role={['library','adminh1']}/>}/>   

                    <Route path='/studentsstatus' exact element={<PrivateRoute element={<Studentstatuspage />} role={['library','adminh1']}/>}/>   

                    <Route path='/circulationhistory' exact element={<PrivateRoute element={<Circulationhistory />} role={['library','adminh1']}/>}/>   

                    <Route path='/addptaproduct' exact element={<PrivateRoute element={<Addptaproductp />} role={['pta']}/>}/>  
                    
                   
                </Routes>

                



                

                

                 
            </Router>
            
            
        </>
    );
}

export default App;