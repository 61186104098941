import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
import './Carasoule.css';
//import { Button } from './Button';
//import { NavLink } from "react-router-dom";

/*const images = [
  "https://images.pexels.com/photos/764681/pexels-photo-764681.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/1306497/pexels-photo-1306497.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/809119/pexels-photo-809119.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  };

  const goToPrevSlide = () => {
    const prevIndex = (currentIndex + images.length - 1) % images.length;
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        <div className="slide-container">
          <img className="slide" src={images[currentIndex]} alt="" />
        </div>
        <button className="prev" onClick={goToPrevSlide}>
          &#10094;
        </button>
        <button className="next" onClick={goToNextSlide}>
          &#10095;
        </button>
      </div>
    </div>

     { id: 1, src: "http://www.masiyejunior.org/images/sampledata/Clubs-2016/IMG_0160.jpg" },
  { id: 2, src: "http://www.masiyejunior.org/images/sampledata/Clubs-2016/IMG_0025.jpg" },
  { id: 3, src: "http://www.masiyejunior.org/images/sampledata/Clubs-2016/IMG_0028.jpg" }
  );

  <button className="prev" onClick={goToPrevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={goToNextSlide}>
        &#10095;
      </button>

      https://ucarecdn.com/01613926-5e66-4b48-981c-1e97367b291f/-/preview/640x457/

      "https://ucarecdn.com/3b63bd71-e8ad-4d2d-b8b0-34036bd20acb/-/preview/1000x750/" },
  { id: 2, src: "https://ucarecdn.com/dc750a1d-fe91-411a-9391-0c7121333b34/-/preview/1000x666/" },
  { id: 3, src: "https://ucarecdn.com/2e1d3cee-b6b7-472d-a6ca-17e5631bca81/-/preview/1000x951/" }
};*/



const images = [
  { id: 1, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/IMG_8917.jpg" },
  { id: 2, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/IMG_8915.jpg" },
  { id: 3, src: "https://ucarecdn.com/2e1d3cee-b6b7-472d-a6ca-17e5631bca81/-/preview/1000x951/" }
];

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  const goToPrevSlide = () => {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
  };

  const goToNextSlide = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <div
          key={image.id}
          className={`slide${index === activeIndex ? ' active' : ''}`}
          style={{ transform: `translateX(${(index - activeIndex) * 100}%)` }}
        >
          <img src={image.src} alt={`Slide ${index + 1}`} />
        </div>
      ))}
      <div className="carouseltextinfo">
        <h1>Masiyephambili Junior - Let's Go Forward</h1>
        <p>Our leading independent, co-educational primary school strives to bring out the exceptional in all our students.</p>
      </div>
      
    </div>
  );
};
  
  export default Carousel;
  
  