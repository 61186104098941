import React from 'react';
import '../pagecomponents/phome/navbarp.css';
import Navbar from '../pagecomponents/phome/navbarp';
import '../pagecomponents/library/library.css';
import Librarysec from '../pagecomponents/library/library';

//intial build then rome to correct place

function Libraryp() {
    return (
        <>
            <Navbar />

            <Librarysec />

            

        </>
    )
}

export default Libraryp;