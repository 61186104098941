import React from "react";
import './sportsintroimg.css';

const Sportsintroimg = () => {
  return (
    <>
        <div className="sportsinimgcont">
            <div className="sportsinimg">
                <img src="https://ucarecdn.com/745be8ef-b7ae-4205-a1f3-6f283d57617c/-/preview/1000x750/" alt="placeholder" />
            </div>
        </div>
         
    </>
  );
};

export default Sportsintroimg;