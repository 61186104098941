import React from "react";
import './cultureimgintro.css';

const Cultureintroimg = () => {
  return (
    <>
        <div className="cultureinimgcont">
            <div className="cultureinimg">
                <img src="https://optsirvacc.sirv.com/masiyephambili/sports/chessclub.jpg" alt="placeholder" />
            </div>
        </div>
         
    </>
  );
};

export default Cultureintroimg;