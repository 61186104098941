import React from "react";
import './sports.css';

const Sportspagegrid = () => {
    return (
      <>
          <div className="sportspagegridcont">
              <div className="sportspagegrid">
                  <h2>OFFERED SPORTS ARE:</h2>
              </div>
          </div>
           
      </>
    );
  };
  
  export default Sportspagegrid;