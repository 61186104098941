import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/onlinelibrary/onlinedisplay.css';
import Onlinedisplay from '../pagecomponents/onlinelibrary/onlinedisplay';

//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Onlinebookdisplay() {
    return (
        <>
            <Libnavbar />

            <Onlinedisplay />

        
        </>
    )
}

export default Onlinebookdisplay;