import React from 'react';
import '../pagecomponents/navbar/libnavbar.css';
import Libnavbar from '../pagecomponents/navbar/libnavbar';

import '../pagecomponents/onlinelibrary/onlinecategory.css';
import OnlineCategory from '../pagecomponents/onlinelibrary/onlinecategory';

import '../pagecomponents/onlinelibrary/onlinelibrary.css';
import Onlinelibhome from '../pagecomponents/onlinelibrary/onilnelibrary';

import '../pagecomponents/onlinelibrary/onlinecatblock.css';
import Onlinecatblock from '../pagecomponents/onlinelibrary/onlinecatblock';

import '../pagecomponents/onlinelibrary/catpages.css';
import Onlinecatpages from '../pagecomponents/onlinelibrary/catpages';




//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline';

function Onlinecategoryp() {
    return (
        <>
            <Libnavbar />

            <OnlineCategory />

            <Onlinelibhome />

            <Onlinecatblock />

            <Onlinecatpages />

        </>
    )
}

export default Onlinecategoryp;