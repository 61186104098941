import React, {useState} from "react";
import './adminteam.css';

const Adminteam = () => {

  const [seemore, setSeemore] = useState(false);
  const [adminid, setAdminid] = useState(-1);

  const images = [
    { id: 0, src: "https://optsirvacc.sirv.com/masiyephambili/hall.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 1, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/adminblock.jpg", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 2, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community."},
    { id: 3, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds2.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 4, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds3.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 5, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds4.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 6, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/adminblock.jpg", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 7, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community."},
    { id: 8, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds2.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 9, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds3.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." },
    { id: 10, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds4.JPG", name: "Mr Sibanda", position: "Headmaster", descrip:"With over 20 years of experience as a school headmaster, I possess a proven track record of providing visionary leadership and fostering a positive learning environment. My strengths lie in curriculum development, instructional leadership, and building strong relationships with students, staff, and parents. I am a skilled communicator and adept at motivating and inspiring all members of the school community." }
   
  ];

  //{ id: 4, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds3.JPG", gallery: "campus life", spid: 2 },
  //{ id: 5, src: "https://optsirvacc.sirv.com/masiyephambili/gallery/builds4.JPG", gallery: "campus life", spid: 3 }

  const handleSeemore = (id) => {
    setSeemore(!seemore);
    setAdminid(id);
  };

  return (
    <>
        <div className="adminteamcont">
            <div className="adminteamhead">
                <div className="adminmainimgbox">
                  {images.map((image, index) => (
                    <div className="admimmainimgcont" key={image.id}>
                      <img src={image.src} alt={`Grid ${image.spid + 1}`} />
                      <p className="adminstaffname">{image.name}</p>
                      <p className="adminstaffname">{image.position}</p>
                      <p className="adminstaffseemore" onClick={() => handleSeemore(image.id)}>See More</p>
                      {(seemore && adminid === image.id) &&
                        <p className="adminstaffname">{image.descrip}</p>
                      }
                    </div>
                  ))} 
                </div>
               
            </div>
        </div>
         
    </>
  );
};

export default Adminteam;