import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./testassignment.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import ReviseTest from "./revise";


function Markedtstassignment(props) {

    const location = useLocation();

    const username = localStorage.getItem('theusername');

    const formnum = location.state.data.formnum;
    const termnum = location.state.data.termnum;

    console.log("formnum", formnum)
    console.log("termnum", termnum)

    const [testqueData, setTestqueData] = useState({
        formnum: formnum,
        termnum: termnum
    });

    const [localData,  setlocalData] = useState([]);

   
   //Categories options for opening and closing
    const [clmaths,  setclmaths] = useState(false);
    const [clphysics,  setclphysics] = useState(false);
    const [clenglish,  setclenglish] = useState(false);


    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/checkassign-results', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ username, testqueData })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                if (data !== null) {
                  //console.log("logging data", data[0].subtopics);
                  const contentcheck = data.testassigncont.map((post) => post.term)
                  //console.log("mapping",contentcheck);
                  if (contentcheck !== null) {
                    setlocalData(data.testassigncont);
                  }else {
                    fetchContents();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
           
      
    }, []);


    if (localData !== null) {

        const mathematicscard = localData.map((post) =>
            <div className="assignment-cardB1" key={post._id}>
            {post.assignmentstsres.map((type)=>
                <div className="assignment-cardb2" key={type._id}>
                    {type.subject  === "mathematics" &&
                        <Link
                        to = "/MarkedTestAssignment"

                        onClick={() => window.location.reload() }
                        
                        state = {{
                        
                        data: {formnum: testqueData.formnum, termnum: testqueData.termnum, subject: "mathematics", testid: type.testid} // your data array of objects type.title 
                        }}
                
                        style={{ textDecoration: 'none', color: 'black' }}
                        >
                        <div className="testdropdowncenter">
                            <p>{type.testname}</p>
                        </div>
                        </Link>
                    } 
                </div>
            )}
            </div>
        )
    

    return (
        <>
        <div className="Test-main">
            <div className="testcatsec">
                <div className="testopt">
                    <p className="subjectstopic">Subjects</p>
                    <div className="testdropdown">
                        <p onClick={() => setclmaths((prev) => !prev)} >Mathematics <i className={clmaths === true ? "icondown fa fa-play" : "iconup fa fa-play"}></i></p>
                        {clmaths === true &&
                        <div className="testdropdowncontent">
                            {mathematicscard}
                        </div>
                        }
                    </div>
                    <div className="testdropdown">
                        <p onClick={() => setclphysics((prev) => !prev)} >Physics <i className={clphysics === true ? "icondown fa fa-play" : "iconup fa fa-play"}></i></p>
                        {clphysics === true &&
                        <div className="testdropdowncontent">
                            <div className="testdropdowncenter">
                                <p>Assignment 1</p>
                                <p>Assignment 2</p>
                                <p>Assignment 3</p>
                                <p>Test 1</p>
                                <p>Test 2</p>
                                <p>Test 3</p>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="testdropdown">
                        <p onClick={() => setclenglish((prev) => !prev)} >English <i className={clenglish === true ? "icondown fa fa-play" : "iconup fa fa-play"}></i></p>
                        {clenglish === true &&
                        <div className="testdropdowncontent">
                            <div className="testdropdowncenter">
                                <p>Assignment 1</p>
                                <p>Assignment 2</p>
                                <p>Assignment 3</p>
                                <p>Test 1</p>
                                <p>Test 2</p>
                                <p>Test 3</p>
                            </div>
                        </div>
                        }
                    </div>


                </div>
            </div>
            <ReviseTest />
        </div>
        </>
      
    );
    }
    
}


  
  export default  Markedtstassignment;