import React from "react";
import './Footer.css';

//<a href="#">Email Us</a>

const Footer = () => {
  return (
    <>
        <div className="footer-containerh">
        <div className="footer-itemh">
            <h3>Visit Us</h3>
            <p>
                Masiyephambili Junior School
                <br></br>
                PO Box  F.M 75
                <br></br>
                Famona
                <br></br>
                Bulawayo
            </p>
            <br></br>
            <a href="/contact-us">Get Directions</a>
        </div>
        <div className="footer-itemh">
            <h3>Contact Us</h3>
            <p>Phone: +263 29 2286196</p>
            <br></br>
            
        </div>
        <div className="footer-itemh">
            <h3>Quick Links</h3>
            <ul>
            <li>
                <a href="/about-us">About Us</a>
            </li>
            <li>
                <a href="/admissions">Admissions</a>
            </li>
            <li>
                <a href="/studentportal">Student Portal</a>
            </li>
            <li>
                <a href="/parents-space">Parent's Place</a>
            </li>
            </ul>
        </div>
        <div className="footer-itemh">
            <h3>Social Media</h3>
            <ul className="social-media-iconsh">
            <li>
                <a href="https://www.facebook.com/groups/MasiyeJunior/">
                <i className="fa fa-facebook"></i>
                </a>
            </li>
            <li>
                <a href="https://x.com/masiyephambili">
                <i className="fa fa-twitter"></i>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/masiyejunior249?utm_source=qr&igsh=MXN3cmh0aGR4Zmx3ZQ==">
                <i className="fa fa-instagram"></i>
                </a>
            </li>
            <li>
                <a href="https://www.tiktok.com/@masiyejunior24?_t=8nu4GKlbebe&_r=1">
                <i className="fa-brands fa-tiktok"></i>
                </a>
            </li>
            </ul>
        </div>
       
        </div>
        <div className="poweredh"><h4>Power by OdinTech @2024</h4></div>
        
    </>
  );
};

export default Footer;
