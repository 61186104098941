import React from 'react';

import About from '../pagecomp/aboutusp/about';
import '../pagecomp/aboutusp/about.css';

import Aboutusintro from '../pagecomp/aboutusp/aboutusintro';
import '../pagecomp/aboutusp/aboutusintro.css';

import Aboutusintroimg from '../pagecomp/aboutusp/aboutusintroimg';
import '../pagecomp/aboutusp/aboutusintroimg.css';

import Aboutpprofile from '../pagecomp/aboutusp/abtpprofile';
import '../pagecomp/aboutusp/abtpprofile.css';

import Headcomp from '../pagecomp/aboutusp/headcomp';
import '../pagecomp/aboutusp/headcomp.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function Aboutus() {
    return (
        <>
            <NavbarMs />

            <Aboutusintro />

            <Aboutusintroimg />

            <Aboutpprofile />

            <About />

            <Footer />
        </>
    )
}

export default Aboutus;