import React, { useState, useEffect } from 'react';
import './addptaproduct.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Addptaproduct() {
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkrole, setCheckrole] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [localData,  setlocalData] = useState([]);
    const [filterby, setfilterby] = useState("");
    const [showPopup, setShowPopup] = useState(false);
  

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetptaproducts = '/getptaproducts'; 
    const getptaproductsurl = baseUrl + dynamicgetptaproducts;

    const dynamicsearchbooks = '/searchbooks';
    const searchbooksurl = baseUrl + dynamicsearchbooks;

    const dynamicaddptaproducts = '/addptaproducts';
    const addptaproductsurl = baseUrl + dynamicaddptaproducts;

    const dynamiceditptaproduct = '/editptaproducts';
    const editptaproductsurl = baseUrl + dynamiceditptaproduct;

    const dynamicdeleteptaproducts = '/deleteptaproducts';
    const deleteptaproductsurl = baseUrl + dynamicdeleteptaproducts;



    //---------------          /editbooks


    const [bookDetails, setbookDetails] = useState({
        title: "",
        authorname: "",
        illustrator: "",
        bioinfor: "",
        bioinfor2: "",
        bioinfor3: "",
        isbn: "",
        imglink: "",
        category: "",
        year: "",
        publisher: "",
        edition: "",
        numcopies : "",
        bookid: "",
        editcheck: "off"
    });

    const [addptaprod, setAddptaprod] = useState ({
      productname: "",
      price: "",
      description: "",
      imglink: "",
      category: "",
      stocknum: 0,
      productid: "",
      editcheck: "off"

    });

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getptaproductsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookDetails })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);


    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("author")
    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("name")
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("category")
    
    }

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleAddSubmit(e) {
        e.preventDefault();
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(addptaproductsurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ addptaprod }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            //setaddPopup(true);
            clear();
            
            
        };
    
        Addquestion();
    
    }

    const clear = () => {

        setAddptaprod({ ...addptaprod, 
            productname: "",
            price: "",
            description: "",
            imglink: "",
            category: "",
            productid: "",
            stocknum: 0,
            productid: "",
            editcheck: "off"
        });
        
        //setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    function edit (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(editptaproductsurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ addptaprod }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();
       
    }


    function deletebookyes (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(deleteptaproductsurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ addptaprod }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();

        setShowPopup(false);
       
    }


    function opendeletebkClick (e) {
        e.preventDefault();
        setShowPopup(true);
    };

    
    function closedeletebookClick () {
        setShowPopup(false);

    };
   

    if (localData !== null) {


        const getbookscard = localData.map((post) =>
            <Link
                            
            onClick={(e) => setAddptaprod({...addptaprod,
                productname: post.productname,
                price: post.price,
                description: post.description,
                imglink: post.imglink,
                productid: post.productid,
                category: post.category,
                stocknum: post.stocknum,
                editcheck: "on"
            })}
            
            style={{ textDecoration: 'none', color: 'black' }}

            key={post._id}
            
            >
            <div className="libuserdetbktableaddptaprod" >
                <div className={addptaprod.productid === post.productid ? "libuserdetbktableaddptaprodcontblue" : "libuserdetbktableaddptaprodcont"}>
                    <div className='libdetbknameaddptaprod'>
                        <p>{post.productid}</p>
                    </div>
                    <div className='libdetbksurnameaddptaprod'>
                        <p>{post.productname}</p>
                    </div>
                    <div className='libdetbkgradeaddptaprod'>
                        <p>{post.category}</p>
                    </div>
                    <div className='libdetbkuseridaddptaprod'>
                        <p>{post.price}</p>
                    </div>
                    <div className='libdetbkpasswordaddptaprod'>
                        <p>{post.stocknum}</p>
                    </div>
                </div>
            </div>
            </Link>
        )





    return (
        <>
        <div className="addlibmemberpageaddptaprod">
            <div className="addlibmembersectaddptaprod">
                <div className="addlibmemberboxaddptaprod">
                    {showPopup && (
                        <div className="popupaddptaprod">
                        <div className="popup-contentaddptaprod">
                            <p>Are you sure you want delete this Product!</p>
                            <button className="deletebuttonaddptaprod" onClick={deletebookyes}>Yes</button>
                            <button className="nodeletebtnaddptaprod" onClick={closedeletebookClick}>No</button>
                        </div>
                        </div>
                    )}
                    
                    <h2>Add Products</h2>
                    <div className='addlibmembertextboxaddptaprod'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetailsaddptaprod">
                                <input type="text" className="libuserbknamesaddptaprod" name="libuserbknames" placeholder='Product Name'  value={addptaprod.productname} onChange={(e) => setAddptaprod({...addptaprod, productname: e.target.value})} />
                                <input type="text" className="libuserbksurnamesaddptaprod" name="libuserbksurname" placeholder='Price' value={addptaprod.price} onChange={(e) => setAddptaprod({...addptaprod, price: e.target.value})} />
                                <textarea className="libdetbktextarearaddptaprod" name="libuserbkgrade" placeholder='Description' maxLength="600" value={addptaprod.description} onChange={(e) => setAddptaprod({...addptaprod, description: e.target.value})}> </textarea>
                                <input type="text" className="libuserbkgradeaddptaprod" name="libuserbkgrade" placeholder='Image Link' value={addptaprod.imglink} onChange={(e) => setAddptaprod({...addptaprod, imglink: e.target.value})} />
                                <select className="libuserbkgradeaddptaprod" name="selectroles" value={addptaprod.category} onChange={(e) => setAddptaprod({...addptaprod, category: e.target.value})}>
                                    <option defaultValue>Category/Genre</option>
                                    <option value="hats">Hats</option>
                                    <option value="trousers">Trousers</option>
                                    <option value="scarf">Scarf</option>
                                    <option value="shirts">Shirts</option>
                                    <option value="track suit">Track suit</option>
                                    <option value="swimming costumes">Swimming costumes</option>
                                    <option value="bags">Bags</option>
                                    <option value="shorts">Shorts</option>
                                    <option value="jerseys">Jerseys</option>
                                    <option value="blazers">Blazers</option>
                                    <option value="tie">Tie</option>
                                </select>
                                <input type="text" className="libuserbkgradeaddptaprod" name="libuserbkgrade" placeholder='Stock Number' value={addptaprod.stocknum} onChange={(e) => setAddptaprod({...addptaprod, stocknum: e.target.value})} />
                                
                                { addptaprod.editcheck === "off" ? 
                                <button className='addbtnaddptaprod'>Add</button>
                                :
                                <>
                                <button className='clearbkbtnaddptaprod' onClick={clear}>Clear</button>
                                <button className='editbkbtnaddptaprod' onClick={edit}>Edit</button>
                                <button className='editbkbtnaddptaprod' onClick={opendeletebkClick}>Delete</button>
                                </>
                                }
                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectaddptaprod">
                <div className="viewlibmemberboxaddptaprod">
                    <h2>Library Books</h2>
                    <div className="libuserbktableaddptaprod">
                        <div className="libuserbktableaddptaprodcont">
                            <div className='liblblbknameaddptaprod'>
                                <p>ID</p>
                            </div>
                            <div className='liblblbksurnameaddptaprod'>
                                <p>Product Name</p>
                            </div>
                            <div className='liblblbkgradeaddptaprod'>
                                <p>Category</p>
                            </div>
                            <div className='liblblbkuseridaddptaprod'>
                                <p>Price</p>
                            </div>
                            <div className='liblblbkpasswordaddptaprod'>
                                <p>Stock</p>
                            </div>
                        </div>
                        {getbookscard}
                        
                    </div>  
                    <div className="filterosectaddptaprod">
                        <h2>Filter by:</h2>
                        <div className="filterboxaddptaprod">
                            <div className='form-groupaddptaprod '>
                                <span>Name:</span> 
                                <input className='bybkgradeptaprod' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-groupaddptaprod '>
                                <span>Category:</span>
                                <input className='bybknameptaprod' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>  
                            <div className='form-groupaddptaprod '>
                                <span>Price:</span>
                                <input className='bybkroleptaprod' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div>  
                            <div className='searchbkboxaddptaprod'>
                                <input type="text" name="search" onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

    }
}
        
        
export default Addptaproduct;
        