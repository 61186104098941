import React, {useState, useEffect} from 'react';
import '../pagecomponents/addbooks/navbarlibrian.css';
import Navbarlibrian from '../pagecomponents/addbooks/navbarlibrian.js';
//import '../pagecomponents/performance/perfomance.css';
//import Perfomance from '../pagecomponents/performance/perfomance';
import '../pagecomponents/circulationhist/circhistory.css';
import CirculatingBooksHistory from '../pagecomponents/circulationhist/circhistory.js';

import '../pagecomponents/dashboard/dashboardmenu.css';
import Dashboardmenu from '../pagecomponents/dashboard/dashboardmenu.js';

import '../../adminportal/pagecomponent/adminh1/pagecomponent/navbar/navbaradh1.js';
import ADH1navbar from '../../adminportal/pagecomponent/adminh1/pagecomponent/navbar/navbaradh1.js';


//import '../pagecomponents/circulationhist/circhistorydb.css';
//import CirculatingHistoryDb from '../pagecomponents/circulationhist/circhistorydb.js';


//intial build then rome to correct place
//import '../pagecomponents/outline/outline.css';
//import Outline from '../pagecomponents/outline/outline'; 

function Circulatingbookhistorylap() {
    const [userRole, setUserRole] = useState(null);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicrequireAuth = '/requireAuth';
    const requireAuthurl = baseUrl + dynamicrequireAuth;

    useEffect(() => {
            const fetchRoles = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(requireAuthurl, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json'
                },
                credentials: 'include',
                
            })
                .then(response => response.json())
                .then(data => {
                console.log("data contents db",data)  
                setUserRole(data)
            });
            
            };
        
            fetchRoles();

            
    }, []);

    if (userRole !== null) {
    return (
        <>
            {(userRole.message === "library") ?

            <Navbarlibrian />

            :

            <ADH1navbar />

            }

            <Dashboardmenu />

            <CirculatingBooksHistory />

           



        </>
    )
    }
}

export default Circulatingbookhistorylap;