import React from "react";
import './culturepara.css';

const Culturepara = () => {
    const homeabtimg = "https://ucarecdn.com/8dc62c32-addc-49bd-9036-f297394dcaaf/-/preview/1000x750/";

    return (
        <>
            <div className="maincultureparacont">
                <div className="cultureparaimgcont">
                    <p className="cultureparathree">Culture is like a child's magic paintbrush, coloring their world with vibrant traditions, languages, and stories. It shapes their sense of who they are, connecting them to a rich heritage and family history. Through cultural celebrations and folktales, children learn about their roots, fostering a sense of pride and belonging. This strong foundation gives them the confidence to explore the world around them.</p>

                    <p className="cultureparafour">Culture also acts as a bridge to other experiences. By learning about different cultural practices, music, and foods, children develop empathy and respect for others. They discover that there are many ways of doing things, and that these differences are something to celebrate. This early exposure breaks down stereotypes and fosters a sense of global citizenship.</p>
                </div>

                <div className="cultureparatextcont">
                    <div className="cultureparaparacont">
                        <p className="cultureparaone">Imagine a classroom buzzing with curiosity as children explore Diwali lights or learn a traditional Maori dance. Cultural exploration ignites a spark of wonder in young minds. It encourages them to ask questions, delve deeper into the world's diversity, and appreciate the creativity and ingenuity of different cultures. This curiosity fuels their lifelong love of learning and understanding.</p>

                        <p className="cultureparatwo">Finally, culture is a seed that can blossom into future passions. A child captivated by Japanese calligraphy might develop a lifelong love for languages. The rhythm of African drumming could spark a musical interest. Cultural experiences plant these seeds of inspiration, encouraging children to explore their own creativity and find their unique place in the world's rich tapestry.</p>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

export default Culturepara;