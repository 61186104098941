import React, {useState, useEffect} from 'react';
import './ImageGrid.css';
import FileBase from 'react-file-base64';
import { useDispatch,  useSelector } from 'react-redux';
//import { Link } from 'react-router-dom';

import { createPost } from '../../../actions/posts';
import { updatePost } from '../../../actions/posts';
//import { getPosts } from '../../../actions/posts';



//Importing images
/*import SAS from '../../img/SAS.png';
import BAS from '../../img/BAS.jpg';
import Adventure from '../../img/adventure.jpg';
import Accesss from '../../img/access.jpg';
import Tour from '../../img/tour.jpg';
import Welcome from '../../img/welcome.png';
import Grad from '../../img/grad.jpg';
import Senior from '../../img/senior.jpg';*/

const ImageGrid = () => {

  //const [postData, setPostData] = useState({ creator: '', title:'', message:'', selectedFile: ''});

  //const posts = useSelector((state) => state.posts);


  const dispatch = useDispatch();

  const posts = useSelector((state) => state.posts);

  const [postData, setPostData] = useState({ creator: '', title:'', message:'', selectedFile: ''});

  const [localData, setlocalData] = useState([]);

  const [found, setfound] = useState({ creator: '', title:'', message:'', selectedFile: ''});
  

  /*useEffect(() => {
    console.log("lenght",postData.users);
    if (postData.posts) {
      localStorage.setItem('array', JSON.stringify(postData));
    }else {
      //console.log("localstorage",localStorage.getItem('array'))
      
    }
     
    
    
  }, [postData]);

  useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setPostData(JSON.parse(storedData));
    }
  }, []);


  //const local = localStorage.getItem('array');
  console.log("local",postData.posts);
  console.log("posts",posts);*/


  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch('http://localhost:5000/posts');
      const data = await response.json();
      
      if (data.length > 0) {
        setPostData(data);
        localStorage.setItem('array', JSON.stringify(data));
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setlocalData(JSON.parse(storedData));
      
    }
  }, []);


  //console.log(localData);
  

  const handleSubmit = (e) => {
    e.preventDefault();

    //dispatch(createPost(postData));
    console.log("inside button id", found._id)
    
    if (found._id) {
      dispatch(updatePost(found._id, found))
      const updatedlocaldata= localData.map((ldata) => {
        if (ldata._id === found._id){  
          return { ...ldata, ...found };
        }else {
          return ldata;
        }
        
      })
      localStorage.setItem("array", JSON.stringify(updatedlocaldata));
      setlocalData(updatedlocaldata);

    }
    clear();


    

  }

  //Sophomore Akiera Sanchez Celebrated by Post & Courier {postData.map(item => (console.log("show item", item)))}

  const clear = () => {
    found._id = null;
    setfound({ creator: '', title:'', message:'', selectedFile: ''});

  }

  const editimagegridhomep = (event, id) => {
    event.preventDefault();
    console.log("receiving id", localData);
   
    const foundi = localData.find(obj => {
      return obj._id === id;
    });

    if (foundi) {
      setfound(foundi);
    }
  }

  console.log("Edit this element",found);

  
    if (localData.length > 0) {

    return (
      
      <>
        <div className='titlehI'><h2>Welcome Announcement & News</h2></div>
        <div className="grid-containerI">
          <div className="grid-itemI">
            <img alt="SAS" src={localData[0].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[0]._id) }> { localData[0].title } </a>
          </div>
          <div className="grid-itemI">
            <img alt="BAS" src={localData[1].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[1]._id) }>{ localData[1].title }</a>
          </div>
          <div className="grid-itemI">
            <img alt="Adventure" src={localData[2].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[2]._id) }>{ localData[2].title }</a>
          </div>
          <div className="grid-itemI">
            <img alt="Access" src={localData[3].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[3]._id) }>{ localData[3].title }</a>

          </div>
          <div className="grid-itemI">
            <img alt="tour" src={localData[4].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[4]._id) }>{ localData[4].title }</a>
          </div>
          <div className="grid-itemI">
            <img alt="Welcome" src={localData[5].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[5]._id) }>{ localData[5].title }</a>
          </div>
          <div className="grid-itemI">
            <img alt="Grad" src={localData[6].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[6]._id) }>{ localData[6].title }</a>
          </div>
          <div className="grid-itemI">
            <img alt="Senior" src={localData[7].selectedFile} />
            <a href="/" onClick={(event) => editimagegridhomep(event, localData[7]._id) }>{ localData[7].title }</a>
          </div>
        </div>
        <div className="editsectionhome">
          <form onSubmit={handleSubmit}>
            <div className="editformhome">
              <h2>{found._id ? "Click on element to edit" : "Creating"}</h2>
              <div className='creatoredithome'>
    
                <input type="text" name="creator"  placeholder="Creator" value={found ? found.creator : "Creator"} onChange={(e) => setfound({ ...found, creator: e.target.value})} />
              </div>
              <div className='titledithome'>
                
                <input type="text" name="title" placeholder="Title" value={found ? found.title : "Title"} onChange={(e) => setfound({ ...found, title: e.target.value})} />
              </div>
              <div className='detediithome'>
                
                <textarea name="message" placeholder="Article" value={found ? found.message : "Article"} onChange={(e) => setfound({ ...found, message: e.target.value})}></textarea>
              </div>
              <div className='edithomepics'>
                <FileBase type="file" multiple={false} onDone={({base64}) => setfound({ ...found, selectedFile: base64 })} />
              </div>
              <button className="submitbuttonhomep" type="submit">Submit</button>
             
              <button className="clearbuttonhomep" onClick={clear}>Clear</button>


            </div>
          </form>
        </div>
      
      </>
      
    );
    }
  };
  
  export default ImageGrid;
 