import React, {useState, useEffect} from "react";
import './sportsgrid.css';
import { useLocation, Link } from "react-router-dom";

const Sportsimggrid = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenblog, setIsOpenblog] = useState(false);
  const [currentIndex
    , setCurrentIndex] = useState(0);
  const [sportlength, setSportlength] = useState(0);
  const [localData,  setlocalData] = useState([]);


  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicpart = '/getblogstories';
  const fullUrl = baseUrl + dynamicpart;

  // width="100%" height="50px" frameborder="0" allowfullscreen

    const images = [
        { id: 0, src: "https://ucarecdn.com/745be8ef-b7ae-4205-a1f3-6f283d57617c/-/preview/1000x750/", sport: "soccer", spid: 0, mediatype:"image" },
        { id: 1, src: "https://ucarecdn.com/60aa7bad-7be7-43cf-aa0d-bd41f37a20b6/-/preview/1000x750/", sport: "soccer", spid: 1, mediatype:"image" },
        { id: 2, src: "https://ucarecdn.com/1f7ee406-0846-4dbe-ae4f-a9b989470d51/-/preview/1000x750/", sport: "soccer", spid: 2, mediatype:"image"},
        { id: 3, src: "https://ucarecdn.com/53bcd384-de8c-4593-82bd-02e0c425f9f7/-/preview/1000x666/", sport: "hockey", spid: 0, mediatype:"image" },
        { id: 4, src: "https://ucarecdn.com/f4d3f882-2dd0-4610-8f11-60e3ac20022f/-/preview/1000x666/", sport: "athletics", spid: 0, mediatype:"image" },
        { id: 5, src: "https://ucarecdn.com/b76b7142-039e-49a3-9f73-46af1e31289b/-/preview/1000x666/", sport: "athletics", spid: 1, mediatype:"image" },
        { id: 6, src: "https://ucarecdn.com/f600ce47-9149-4920-b57c-fb0aa0449298/-/preview/1000x666/", sport: "athletics", spid: 2, mediatype:"image" },
        { id: 7, src: "https://ucarecdn.com/1d0bcd78-a696-4967-adb0-a62987b0a262/-/preview/1000x666/", sport: "shot put", spid: 0, mediatype:"image" },
        { id: 8, src: "https://www.youtube.com/embed/-nOpiB_WtYY", sport: "athletics", spid: 3, mediatype:"video" },
        { id: 9, src: "https://ucarecdn.com/122d68cd-8d59-4d42-b161-cc1d52649374/-/preview/1000x666/", sport: "high jump", spid: 0, mediatype:"image" },
        { id: 10, src: "https://ucarecdn.com/edc163c4-f808-4fea-9b7f-1d1196014524/-/preview/1000x666/", sport: "high jump", spid: 1, mediatype:"image" },
        { id: 11, src: "https://ucarecdn.com/1532f213-7d7b-4288-89b6-7d86905e73d4/-/preview/1000x666/", sport: "high jump", spid: 2, mediatype:"image" },
        { id: 12, src: "https://ucarecdn.com/450f0a2c-4b46-407b-a545-62e1d6a93d1e/-/preview/1000x666/", sport: "juveline", spid: 0, mediatype:"image" },
        { id: 13, src: "https://ucarecdn.com/c8895e8e-56e4-4545-b8d7-924df4c44078/-/preview/1000x666/", sport: "juveline", spid: 1, mediatype:"image" },
        { id: 14, src: "https://ucarecdn.com/126972ee-d82a-45ec-8f5b-bba8ae6cadce/-/preview/1000x666/", sport: "cross country", spid: 0, mediatype:"image" },
        { id: 15, src: "https://ucarecdn.com/b35342f7-89f2-4a22-8b94-bdcad20c65f2/-/preview/1000x666/", sport: "cross country", spid: 1, mediatype:"image" },
        { id: 16, src: "https://ucarecdn.com/feb2e427-7f25-49d8-afc3-6b4c210abacd/-/preview/1000x666/", sport: "cross country", spid: 2, mediatype:"image" },
        { id: 17, src: "https://ucarecdn.com/60aa7bad-7be7-43cf-aa0d-bd41f37a20b6/-/preview/1000x750/", sport: "soccer", spid: 3, mediatype:"image" }
        
        
    ];

    const location = useLocation();

    const sportstype = location.state.data.sporttype;
    const sportsection = location.state.data.sportsection;
    const sportcate = location.state.data.sportcate;
    const currentYear = location.state.data.year;



    useEffect(() => {
      
      //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
        const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch(fullUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({sportcate, currentYear})
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents writetest",data)
              setlocalData(data);
              //////if (data.length > 0) {
                //console.log("logging data", data[0].subtopics);
                //const contentcheck = data.subtest.map((post) => post.subject)
                //console.log("mapping",contentcheck);
                ///////////console.log('data sports blog section', data)
                //////////console.log('data sports blog section length', data.length)
                setlocalData(data);
                /*if (data.length > 0) {
                  setlocalData(data);
                }else if (data.length === 0) {
                  setlocalData(data);
                }else {
                  fetchContents();
                }*/
                
                  //localStorage.setItem('array', JSON.stringify(data));
              ///////////}
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();
        
    
    }, [sportcate, currentYear]);



    useEffect(() => {
      
        const filteredImages = images.filter(
          (image) => image.sport === sportstype
        );
        setSportlength(filteredImages.length);
  
    }, [sportstype, images]);

    const handleImageClick = (index) => {
      setCurrentIndex(index);
      setIsOpen(true);
    };

    const handleClose = () => {
      setIsOpen(false);
    };
  
    const handlePrev = () => {
      const newIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNext = () => {
      const newIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };

    const handlePrevSport = () => {
      const newIndex = currentIndex === 0 ? sportlength - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
  
    const handleNextSport = () => {
      const newIndex = currentIndex === sportlength - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };


    if (localData !== null) {

      const announcementcard = localData.map((post, index) =>
          <div className="announcement-cardB1" key={post._id}>
            {(index < 8) &&
            <div className="grid-item">
              <img alt="SAS" src={post.imglink} />
              <a href="#">{post.title}</a>
            </div>
            }
          </div>
      )


    return (
      <>
          <div className="sportsimggridcont">
              {isOpen && (
                <div className="popupsports">
                  {images.map((image, index) => (
                    <div className="sportsimgbuffer" key={image.id}>
                      {(image.sport === sportstype && image.spid === currentIndex) ?
                      <>
                        {image.mediatype === "image" ?
                        <img src={image.src} alt={`Sports ${currentIndex}`} />
                        :
                        <iframe src={image.src} title={`Sports ${currentIndex}`}  frameborder="0"  allowFullScreen></iframe>
                        }
                        <button className="sportsarrow prev" onClick={handlePrevSport}>
                          &#10094;
                        </button>
                        <button className="sportsarrow next" onClick={handleNextSport} >
                          &#10095;
                        </button>
                        <button className="sportsclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      :(sportstype === "all" && index === currentIndex) &&
                      <>
                        {image.mediatype === "image" ?
                        <img src={image.src} alt={`Sports ${currentIndex}`} />
                        :
                        <iframe src={image.src} title={`Sports ${currentIndex}`}  frameborder="0"  allowFullScreen></iframe>
                        }
                        <button className="sportsarrow prev" onClick={handlePrev}>
                          &#10094;
                        </button>
                        <button className="sportsarrow next" onClick={handleNext}>
                          &#10095;
                        </button>
                        <button className="sportsclose" onClick={handleClose}>
                          &times;
                        </button>
                      </>
                      }
                    </div>
                  ))}
                </div>
              )}
              {isOpenblog &&
                <div className="popupblog">
                  <div className="">

                  </div>
                </div>
              }
              <div className="sportsimggridsize">
                {sportsection === "gallery" ?
                <div className="sportsimggrid">
                  {images.map((image, index) => (
                          <div className="sportimgcont" key={image.id}>
                              {(image.sport === sportstype && image.mediatype === "image" )?
                                <img src={image.src} alt={`Grid ${image.spid + 1}`}  onClick={() => handleImageClick(image.spid)}/>
                                : (sportstype === "all" && image.mediatype === "image") ?
                                <img src={image.src} alt={`Grid ${image.id + 1}`} onClick={() => handleImageClick(image.id)} />
                                :(image.sport === sportstype && image.mediatype === "video")?
                                <iframe src={image.src} title={`Grid ${image.spid + 1}`} onClick={() => handleImageClick(image.spid)}  frameborder="0"  allowFullScreen></iframe>
                                : (sportstype === "all" && image.mediatype === "video") &&
                                <iframe src={image.src} title={`Grid ${image.id + 1}`} onClick={() => handleImageClick(image.id)}  frameborder="0"  allowFullScreen></iframe>
                              }
                          </div>
                  ))} 
                </div>
                :
                <div className="grid-container">
                  {announcementcard}  
                </div>
                }
              </div>
          </div>
           
      </>
    );

  };
  };
  
export default Sportsimggrid;