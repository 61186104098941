import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./perfomance.css";
import { useDispatch, useSelector } from 'react-redux';
import { getSubjects } from "../../../../actions/subjects";

function Guperfomance() {

  const [localData, setlocalData] = useState([]);

  const [userfData,  setuserfData] = useState([]);

  const [subDescrip, setsubDescrip] = useState({
    coursename: "",
    description: "",
    numlessons: "",
    id: "",
    formnum: ""
  });

  const username = localStorage.getItem('theusername');

  const dispatch = useDispatch();
    
  useEffect(() => {
      dispatch(getSubjects());
  }, [dispatch]);

  const dbsubjects = useSelector((state) => state.subjects);
  const mpsubjects = dbsubjects.subjects;

  //console.log("receving from db", mpsubjects);
    const editsubject = (e, title, descript, numls, id, form) => {

        e.preventDefault()
        setsubDescrip({...subDescrip, coursename: title, description: descript, numlessons: numls, id:id, formnum: form });

    }

    const handleEditSubDescrip = (e) => {
      e.preventDefault();

  
      //dispatch(createEvent(eventData));

      const Editsubjectdescr = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch('http://localhost:5000/editsubjectdescription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ subDescrip })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)
              if (data.length > 0) {
                  //console.log("logging data", data[0].subtopics);
                  const contentcheck = data.map((post) => post.subjects)
                  //console.log("mapping",contentcheck);
                  //console.log('data', data)
                  if (contentcheck !== null) {
                    setlocalData(data);
                  }else {
                    Editsubjectdescr();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
              
          });
          
      };
    
      Editsubjectdescr();
        
      clear();
      
  }

  const clear = () => {

    setsubDescrip({...subDescrip, coursename: "", description: "", numlessons: "", id:"" });
}


  useEffect(() => {

    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch('http://localhost:5000/user-det', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      })
        .then(response => response.json())
        .then(data => {
          //console.log("data contents userform db",data.form)
          setuserfData(data.form)
          
        
      });
      
    };

    fetchContents();

  }, []);

  useEffect(() => {
      
    //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
    
      const fetchContents = async () => {
        //await dispatch(getOutlines(parsedObj));
        await fetch('http://localhost:5000/getchlidsperformance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userfData })
        })
          .then(response => response.json())
          .then(data => {
            console.log("data contents db",data)
            if (data.testassigncont.length > 0) {
              //console.log("logging data", data[0].subtopics);
              const contentcheck = data.testassigncont.map((post) => post.form)
              console.log("mapping",contentcheck);
              if (contentcheck !== null) {
                setlocalData(data.testassigncont);
              }else {
                fetchContents();
              }
              
                //localStorage.setItem('array', JSON.stringify(data));
            }
  
            //setContentData(data);
            
          
        });
        
      };
  
      fetchContents();
  
     
       
  
     }, []);


  /*useEffect(() => {
    const fetchSubjects = async () => {
      const response = await fetch('http://localhost:5000/subjects');
      const data = await response.json();
      
      if (data.length > 0) {
        //setPostData(data);
        localStorage.setItem('array', JSON.stringify(data));
      }
    };

    fetchSubjects();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('array');
    if (storedData) {
      setlocalData(JSON.parse(storedData));
      
    }
  }, []);

  console.log("see localdata", localData)*/

  /*const gotooutline = (event, title) => {
    event.preventDefault();
    console.log("receiving id", title);

    <Link
      to={{
        pathname: "/spoutline",
        state: title // your data array of objects onClick={(event) => gotooutline(event, type.title) }
      }}
    />
   
    
  }*/
 


  

  

  //<h2>Form 1 Mathematics</h2>
  //              <p>Form one mathematics is an introduction to basic algebra, graphs, equations, pathagarus theorem </p>


  if (localData !== null) { 
    console.log("see",localData);


    const subjectassignres = localData.map((post) =>
        <div className="lesson-cardB1" key={post._id}>
          {post.assignmentstsres.map((type, indexL)=>
           <div className="infor-cont" key = {type._id}>
                <div className="subnameres">
                    <p>{type.subject}</p>
                </div>
                <div className="testnameres">
                    <p>{type.testname}</p>
                </div>
                <div className="marknameres">
                    <p className={type.percent>50 ? "marknamerescorr" : "marknamereswron" }>{type.percent}</p>
                </div>
            </div>
          )}
        </div>
      )

    

    





  
    return (
      <>
      <div className="PerSubject-main">
        <div className="subject-tablemain">
            <div className="topic-cont">
                <div className="subnametopic">
                    <p>Subject</p>
                </div>
                <div className="testnametopic">
                    <p>Name</p>
                </div>
                <div className="marknametopic">
                    <p>Mark %</p>
                </div>
            </div>

            {subjectassignres}

        </div>
      </div>

      <div className="parsubjsect-main">
            <div className="subjectsprsec">
                <div className="testoptpr">
                    <p className="subjecttopic">Subjects</p>
                    <div className="subjnm">
                        <p>Mathematics</p>
                    </div>
                    <div className="subjnm">
                        <p>Physics</p>   
                    </div>
                    <div className="subjnm">
                        <p>English</p>
                    </div>


                </div>
            </div>
      </div>

      </>
      
    );
  }

}
  
  export default Guperfomance;