import React, { useState, useEffect } from "react";
import "./bookviewer.css";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';


function Examviewer(props) {

  const location = useLocation();

  const papert = location.state.data.papertype;
  const booksub = location.state.data.booksubject;
  const formnumber = location.state.data.formnum;
  const carpage  = location.state.data.nxtpage;
  const searchword = location.state.data.keywordserch;
  const bookurl = location.state.data.bookurl;

  console.log("checking if  papert", papert)
  console.log("checking if booksub", booksub)
  console.log("checking if formnumber", formnumber)
  console.log("checking if carpage", carpage)
  console.log("checking if searchword", searchword)
  console.log("checking if  bookurl",  bookurl)

  const [contentData, setContentData] = useState({
    papert: papert,
    booksub: booksub,
    formnumber: formnumber,
    searchword: searchword,
    carpage: carpage,
    bookurl: bookurl
});


    
    return (
        
      <div className="bookview-container">
        <p>Book</p>
        <div className="booksectionv" >
          <iframe title="searchbook" src={contentData.bookurl}></iframe>
        </div>
        <div className="backbutton" >
        <Link
          to = "/Exampapers"

          onClick={() => window.location.reload() }

          state = {{data: {papertype: contentData.papert, booksubject: contentData.booksub, formnum: contentData.formnumber, keywordserch: contentData.searchword, nxtpage: contentData.carpage} }}
                                            
          style={{ textDecoration: 'none', color: 'black' }}

          >
          <button>Back</button>
          </Link>
        </div>  
      </div>
        
    );
  
}


  
export default Examviewer;