import React, { useState, useEffect } from 'react';
import './addbooks.css';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Addbookspage() {
    const[checkgrade, setCheckgrade] = useState(false);
    const[checkrole, setCheckrole] = useState(false);
    const[checkname, setCheckname] = useState(false);
    const[searchterm, setSearchterm] = useState("");
    const [localData,  setlocalData] = useState([]);
    const [filterby, setfilterby] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showQRcode, setShowQrcode] = useState(false);
    const [qrCodeDataURL, setQRCodeDataURL] = useState(null);


    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetbooks = '/getbooks';
    const getbooksurl = baseUrl + dynamicgetbooks;

    const dynamicsearchbooks = '/searchbooks';
    const searchbooksurl = baseUrl + dynamicsearchbooks;

    const dynamicaddbooks = '/addbooks';
    const addbooksurl = baseUrl + dynamicaddbooks;

    const dynamiceditbooks = '/editbooks';
    const editbooksurl = baseUrl + dynamiceditbooks;

    const dynamicdeletebooks = '/deletebooks';
    const deletebookssurl = baseUrl + dynamicdeletebooks;



    //---------------          /editbooks


    const [bookDetails, setbookDetails] = useState({
        title: "",
        authorname: "",
        illustrator: "",
        bioinfor: "",
        bioinfor2: "",
        bioinfor3: "",
        isbn: "",
        imglink: "",
        category: "",
        year: "",
        publisher: "",
        edition: "",
        numcopies : "",
        bookid: "",
        qrcode: "",
        editcheck: "off"
    });

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getbooksurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ bookDetails })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, []);

    function handlegradechange(e) {
        setCheckgrade(!checkgrade)
        setCheckname(false)
        setCheckrole(false)
        setfilterby("author")
    }

    function handlenamechange(e) {
        setCheckname(!checkname)
        setCheckgrade(false)
        setCheckrole(false)
        setfilterby("name")
    }

    function handlerolechange(e) {
        setCheckrole(!checkrole)
        setCheckgrade(false)
        setCheckname(false)
        setfilterby("category")
    
    }

    function handleSearchSubmit(e) {
        e.preventDefault();

        const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
           
            await fetch(searchbooksurl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ filterby, searchterm })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
    }

    function handleAddSubmit(e) {
        e.preventDefault();
        console.log(bookDetails)
        const Addquestion = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(addbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setmemberDetails({ ...memberDetails, memberid: data.var3, password: data.var1});
      
                //setContentData(data);
                
              
            });

            //setaddPopup(true);
            clear();
            
            
        };
    
        Addquestion();
    
    }

    const clear = () => {

        setbookDetails({ ...bookDetails, 
        title: "",
        authorname: "",
        illustrator: "",
        bioinfor: "",
        bioinfor2: "",
        bioinfor3: "",
        isbn: "",
        imglink: "",
        category: "",
        year: "",
        publisher: "",
        edition: "",
        numcopies : "",
        bookid: "",
        qrcode: "",
        editcheck: "off"});
        
        //setquestionDetails({...questionDetails, question: '', optA:'', optB:'', optC: '', optD : '', optE : '', answer: ''});
    }

    function edit (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(editbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();
       
    }


    function deletebookyes (e) {
        e.preventDefault();

        const Editmemberdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(deletebookssurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Editmemberdet();

        setShowPopup(false);
       
    }

    function duplicate (e) {
        e.preventDefault();

        const Duplicatebookdet = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(addbooksurl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ bookDetails }),
            
            })
            .then(response => response.json())
              .then(data => {
                //console.log("data contents db all here",data)
                console.log("data contents db all password",data)
                setlocalData(data);
                //setContentData(data);
                
              
            });

            clear();
            
            
        };
    
        Duplicatebookdet();
       
    }

    function opendeletebkClick (e) {
        e.preventDefault();
        setShowPopup(true);
    };

    
    function closedeletebookClick () {
        setShowPopup(false);

    };


    function genqrcode (e) {
        e.preventDefault();

        const generateQRCode = async () => {
            try {
              const testurl =`https://masiye-101-learning-plat-2e668f09f411.herokuapp.com/borrowbookinfo?bookId=${bookDetails.bookid}`;
              //http://localhost:3000/borrowbookinfo?bookId=${bookDetails.bookid}
              const qrCodeText = JSON.stringify(testurl);
              const qrCodeDataURL = await QRCode.toDataURL(qrCodeText);
              setQRCodeDataURL(qrCodeDataURL);
            } catch (error) {
              console.error(error);
            }
          };
      
        generateQRCode();

        setShowQrcode(true);
       
    }

    function closeqrcodepopClick () {
        setShowQrcode(false);

    };


    if (localData !== null) {

        const downloadQRCode = () => {
            if (qrCodeDataURL) {
              const downloadLink = document.createElement('a');
              downloadLink.href = qrCodeDataURL;
              downloadLink.download = `${bookDetails.title.replace(/ /g, '_')}_QR.png`;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }
        };

        const getbookscard = localData.map((post) =>
            <Link
                            
            onClick={(e) => setbookDetails({...bookDetails,
                title: post.title,
                authorname: post.authorname,
                illustrator: post.illustrator,
                bioinfor: post.authorbio,
                bioinfor2: post.authorbio2,
                bioinfor3: post.authorbio3,
                isbn: post.isbn,
                imglink: post.imglink,
                category: post.category,
                year: post.year,
                publisher: post.publisher,
                edition: post.edition,
                numcopies : post.numcopies,
                bookid: post.bookid,
                qrcode: post.qrcode,
                editcheck: "on"
            })}
            
            style={{ textDecoration: 'none', color: 'black' }}

            key={post._id}
            
            >
            <div className="libuserdetbktableaddbk" >
                <div className={bookDetails.bookid === post.bookid ? "libuserdetbktableaddbookcontblue" : "libuserdetbktableaddbookcont"}>
                    <div className='libdetbknameaddbk'>
                        <p>{post.bookid}</p>
                    </div>
                    <div className='libdetbksurnameaddbk'>
                        <p>{post.authorname}</p>
                    </div>
                    <div className='libdetbkgradeaddbk'>
                        <p>{post.title}</p>
                    </div>
                    <div className='libdetbkuseridaddbk'>
                        <p>{post.publisher}</p>
                    </div>
                    <div className='libdetbkpasswordaddbk'>
                        <p>{post.year}</p>
                    </div>
                </div>
            </div>
            </Link>
        )





    return (
        <>
        <div className="addlibmemberpageaddbk">
            <div className="addlibmembersectaddbk">
                <div className="addlibmemberboxaddbk">
                    {showPopup && (
                        <div className="popupaddbk">
                        <div className="popup-contentaddbk">
                            <p>Are you sure you want delete this book!</p>
                            <button className="deletebuttonaddbk" onClick={deletebookyes}>Yes</button>
                            <button className="nodeletebtnaddbk" onClick={closedeletebookClick}>No</button>
                        </div>
                        </div>
                    )}
                    {showQRcode && (
                        <div className="popupaddbk">
                        <div className="popup-contentaddbk">
                            <h1>Book QR Code</h1>
                            <div className='popqrcontainer'>
                            {qrCodeDataURL && <img src={qrCodeDataURL} alt="Book QR Code" />}
                            </div>
                            <button className="deletebuttonaddbk" onClick={downloadQRCode}>Download</button>
                            <button className="nodeletebtnaddbk" onClick={closeqrcodepopClick}>Close</button>
                        </div>
                        </div>
                    )}
                    <h2>Add Books</h2>
                    <div className='addlibmembertextboxaddbk'>
                        <form onSubmit={handleAddSubmit}>
                            <div className="libuserdetails">
                                <input type="text" className="libuserbknamesaddbk" name="libuserbknames" placeholder='Title'  value={bookDetails.title} onChange={(e) => setbookDetails({...bookDetails, title: e.target.value})} />
                                <input type="text" className="libuserbksurnamesaddbk" name="libuserbksurname" placeholder='Author Name' value={bookDetails.authorname} onChange={(e) => setbookDetails({...bookDetails, authorname: e.target.value})} />
                                <input type="text" className="libuserbksurnamesaddbk" name="libuserbksurname" placeholder='illustrator' value={bookDetails.illustrator} onChange={(e) => setbookDetails({...bookDetails, illustrator: e.target.value})} />
                                <textarea className="libdetbktextarearaddbk" name="libuserbkgrade" placeholder='Biographical Information' maxLength="600" value={bookDetails.bioinfor} onChange={(e) => setbookDetails({...bookDetails, bioinfor: e.target.value})}> </textarea>
                                <textarea className="libdetbktextarearaddbk" name="libuserbkgrade" placeholder='Biographical Infor Paragraph 1' maxLength="600" value={bookDetails.bioinfor2} onChange={(e) => setbookDetails({...bookDetails, bioinfor2: e.target.value})} > </textarea>
                                <textarea className="libdetbktextarearaddbk" name="libuserbkgrade" placeholder='Biographical Infor Paragraph 2' maxLength="600" value={bookDetails.bioinfor3} onChange={(e) => setbookDetails({...bookDetails, bioinfor3: e.target.value})} > </textarea>
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='ISBN' value={bookDetails.isbn} onChange={(e) => setbookDetails({...bookDetails, isbn: e.target.value})} />
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='Cover Page Img Link' value={bookDetails.imglink} onChange={(e) => setbookDetails({...bookDetails, imglink: e.target.value})} />
                                <select className="libuserbkgradeaddbk" name="selectroles" value={bookDetails.category} onChange={(e) => setbookDetails({...bookDetails, category: e.target.value})}>
                                    <option defaultValue>Category/Genre</option>
                                    <option value="arts">Arts</option>
                                    <option value="music">Music</option>
                                    <option value="atlases">Atlases</option>
                                    <option value="dinosaurs">Dinosaurs</option>
                                    <option value="plants">Plants</option>
                                    <option value="natural sciences">Natural Sciences</option>
                                    <option value="space">Space</option>
                                    <option value="magazine and journals">Magazines and Journals</option>
                                    <option value="mammals">Mammals</option>
                                    <option value="biography">Biography</option>
                                    <option value="reptiles">Reptiles</option>
                                    <option value="insects">Insects</option>
                                    <option value="fish">Fish</option>
                                    <option value="geography">Geography</option>
                                    <option value="isndebele">IsiNdebele</option>
                                    <option value="science">Science</option>
                                    <option value="mathematics">Mathematics</option>
                                    <option value="history">History</option>
                                    <option value="home economics">Home Economics</option>
                                    <option value="english">English</option>
                                    <option value="general knowledge">General Knowledge</option>
                                    <option value="miscellaneous">Miscellaneous</option>
                                    <option value="historical fantasy">Historical Fantasy</option>
                                    <option value="mystery">Mystery</option>
                                    <option value="science fiction">Science Fiction</option>
                                    <option value="horror">Horror</option>
                                    <option value="fiction">Fiction</option>
                                    <option value="adventure fiction">Adventure Fiction</option>
                                    <option value="classics">Classics</option>
                                    <option value="humor">Humor</option>
                                    <option value="poetry">Poetry</option>
                                    <option value="fairy tale">Fairy Tale</option>
                                    <option value="mythology">Mythology</option>
                                    <option value="adult fiction">Adult Fiction</option>
                                    <option value="picture book">Picture Book</option>
                                    <option value="fantasy">Fantasy</option>
                                    <option value="sports">Sports</option>
                                    <option value="realistic fiction">Realistic Fiction</option>
                                    <option value="easy to read">Easy to read</option>
                                </select>
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='Publication Year' value={bookDetails.year} onChange={(e) => setbookDetails({...bookDetails, year: e.target.value})} />
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='Publisher' value={bookDetails.publisher} onChange={(e) => setbookDetails({...bookDetails, publisher: e.target.value})} />
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='Edition' value={bookDetails.edition} onChange={(e) => setbookDetails({...bookDetails, edition: e.target.value})} />
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='Number of copies' value={bookDetails.numcopies} onChange={(e) => setbookDetails({...bookDetails, numcopies: e.target.value})} />
                                <input type="text" className="libuserbkgradeaddbk" name="libuserbkgrade" placeholder='QR CODE' value={bookDetails.qrcode} onChange={(e) => setbookDetails({...bookDetails, qrcode: e.target.value})} />
                                
                                { bookDetails.editcheck === "off" ? 
                                <button className='addbtnaddbk'>Add</button>
                                :
                                <>
                                <button className='clearbkbtnaddbk' onClick={clear}>Clear</button>
                                <button className='editbkbtnaddbk' onClick={edit}>Edit</button>
                                <button className='editbkbtnaddbk' onClick={opendeletebkClick}>Delete</button>
                                <button className='editbkbtnaddbk' onClick={genqrcode}>Qrcode</button>
                                <button className='editbkbtnaddbk' onClick={duplicate}>Duplicate</button>
                                </>
                                }
                               
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <div className="viewlibmembersectaddbk">
                <div className="viewlibmemberboxaddbk">
                    <h2>Library Books</h2>
                    <div className="libuserbktableaddbk">
                        <div className="libuserbktableaddbkcont">
                            <div className='liblblbknameaddbk'>
                                <p>ID</p>
                            </div>
                            <div className='liblblbksurnameaddbk'>
                                <p>Author</p>
                            </div>
                            <div className='liblblbkgradeaddbk'>
                                <p>Title</p>
                            </div>
                            <div className='liblblbkuseridaddbk'>
                                <p>Publisher</p>
                            </div>
                            <div className='liblblbkpasswordaddbk'>
                                <p>Year</p>
                            </div>
                        </div>
                        {getbookscard}
                        
                    </div>  
                    <div className="filterosectaddbk">
                        <h2>Filter by:</h2>
                        <div className="filterboxaddbk">
                            <div className='form-groupaddbk '>
                                <span>Author:</span> 
                                <input className='bybkgradebk' name="bygrade" checked={checkgrade} type="checkbox" onChange={handlegradechange} />
                            </div>
                            <div className='form-groupaddbk '>
                                <span>Title:</span>
                                <input className='bybknamebk' name="byname" checked={checkname} type="checkbox" onChange={handlenamechange} />
                            </div>  
                            <div className='form-groupaddbk '>
                                <span>Genre:</span>
                                <input className='bybkrolebk' name="byrole" checked={checkrole} type="checkbox" onChange={handlerolechange} />
                            </div>  
                            <div className='searchbkboxaddbk'>
                                <input type="text" name="search" onChange={(e) => setSearchterm(e.target.value)} />
                                <button onClick={handleSearchSubmit}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        </>
    );

    }
}
        
        
export default Addbookspage;
        