import React, {useState, useEffect} from "react";
import './ptaprodgrid.css';
import { useLocation,  Link } from 'react-router-dom';

const PTAprodgrid = () => {

    const [localData,  setlocalData] = useState([]);

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamicgetptaproducts = '/getcatptaproducts'; //getcatptaproducts
    const getptaproductsurl = baseUrl + dynamicgetptaproducts;

    const location = useLocation();

    const clothestype = location.state.data.clothestype;
   

    const [addptaprod, setAddptaprod] = useState ({
        productname: "",
        price: "",
        description: "",
        imglink: "",
        category: "",
        stocknum: 0,
        productid: "",
        editcheck: "off"
  
    });
  

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
        
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch(getptaproductsurl,{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ addptaprod, clothestype })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents fetched books db",data)
                setlocalData(data);
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
      
         
           
      
    }, [clothestype]);


if (localData !== null) {

    const getproductsscard = localData.map((post) =>
        <div className="ptaproditem"  key={post._id}>
            <img className="Ptaprodimg" alt="SAS" src={post.imglink} />

            <div className="ptaproddetails">
                <p className="ptaprodname">{post.productname}</p>

                <p className="ptaprodprice">${post.price}</p>
                <Link 
                    to="/ptaproductpage" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                    state = {{
                        data: { productname: post.productname, imglink: post.imglink, price: post.price, description: post.description }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                    }}
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <button className="ptaorderbtn">Order Now</button>
                </Link>
            </div>
        </div>
    )


  return (
    <>
        <div className="ptaprodgridcont">
            <div className="ptaprodgridin">
                {getproductsscard}   
            </div>
        </div>
         
    </>
  );
};
};

export default PTAprodgrid;