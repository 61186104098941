import React, { useState, useEffect } from 'react';
import './sportsmenu.css';
import { useLocation, Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Sportsmenu() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);
    const [showBlogsubmenu, setBlogsubmenu] = useState({
        clicksubmenu: ""
    });

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    const toggleSubmenu = (clickedsubmenu) => {
        if (showBlogsubmenu.clicksubmenu === clickedsubmenu) {
            setBlogsubmenu({ ...showBlogsubmenu, 
                clicksubmenu: ""
            });
        }else {
            setBlogsubmenu({ ...showBlogsubmenu, 
                clicksubmenu: clickedsubmenu
            });

        }
        
    };

    //const booktype = "physical";

    const location = useLocation();

    const sportsection = location.state.data.sportsection;
    const sporttype = location.state.data.sporttype;
    const sportcate = location.state.data.sportcate;
    const currentYear = location.state.data.year;

    /*
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "latest", sportsection: "blog" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                        <p className={sportsection === "blog" ? "sportsmainpblog" : ""}>Blog</p> 
                        </Link>




    */



  return (
    <>
       <div className="sportsmenu"> 
            <div className="sportsmenugerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Sports Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Sports Menu  &#9776;</h2>
                }
                <div className={showGerne ? "sportsmenumainstart" : "sportsmenumainstartoff"}>
                    <div className="sportsmenumainstartbox">
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "all", sportsection: "gallery" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                        <p className={sportsection === "gallery" ? "sportsmainpgallery" : ""}>Gallery</p> 
                        </Link>
                        









                    </div>

                </div>
                <div className={`sportsmenugerneblock ${showGerne ? 'sportsmenushow-gerne' : ''}`}>
                    {sportsection==="gallery" ?
                    <div className="sportsmenuactualgerne">
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "all", sportsection: "gallery" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "all" ? "sportspgalleryall" : "" }>All</p> 
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "tennis", sportsection: "gallery" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "tennis" ? "sportspgallerytennis" : "" }>Tennis</p> 
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "cricket", sportsection: "gallery" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "cricket" ? "sportspgallerytennis" : "" }>Cricket</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "cross country", sportsection: "gallery" } 
                            }}
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "cross country" ? "sportspgallerytennis" : "" }>Cross Country</p>
                        </Link>
                        
                        <>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "golf", sportsection: "gallery" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "golf" ? "sportspgallerytennis" : "" }>Golf</p>
                        </Link>
                        <Link 
                             to="/sports" 
                             //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                             state = {{
                                 data: { sporttype: "hockey", sportsection: "gallery" } 
                             }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "hockey" ? "sportspgallerytennis" : "" }>Hockey</p> 
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "rugby", sportsection: "gallery" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "rugby" ? "sportspgallerytennis" : "" }>Rugby</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "netball", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "netball" ? "sportspgallerytennis" : "" }>Netball</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "soccer", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "soccer" ? "sportspgallerytennis" : "" }>Soccer</p>
                        </Link>
                        </>
                        
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "swimming", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "swimming" ? "sportspgallerytennis" : "" }>Swimming</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "athletics", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "athletics" ? "sportspgallerytennis" : "" }>Athletics</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "juveline", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "juveline" ? "sportspgallerytennis" : "" }>Juveline</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "high jump", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "high jump" ? "sportspgallerytennis" : "" }>High Jump</p>
                        </Link>
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sporttype: "shot put", sportsection: "gallery" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sporttype === "shot put" ? "sportspgallerytennis" : "" }>Shot Put</p>
                        </Link>
                    </div>
                    :
                    <div className="sportsmenuactualgerne">
                        <Link 
                            to="/sports" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "latest", sportsection: "blog", year: "2024" }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "latest" ? "sportspgalleryall" : "" }>Latest</p> 
                        </Link>
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("tennis")}}
                            state = {{
                                data: { sportcate: "tennis", sportsection: "blog", year: "2024" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "tennis" ? "sportspgallerytennis" : "" }>Tennis</p> 
                        </Link>
                            {showBlogsubmenu.clicksubmenu === "tennis" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "tennis", sportsection: "blog", year: "2021" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "tennis"&& currentYear ==="2021") ? "sportspgallerytennis" : "" }>2021</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "tennis", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "tennis"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "tennis", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "tennis"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "tennis", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "tennis"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                            }                             
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("cricket")}}
                            state = {{
                                data: { sportcate: "cricket", sportsection: "blog", year: "2024" } 
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "cricket" ? "sportspgallerytennis" : "" }>Cricket</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "cricket" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "cricket", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "cricket"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "cricket", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "cricket"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "cricket", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "cricket"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        }    
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("cross country")}}
                            state = {{
                                data: { sportcate: "cross country", sportsection: "blog", year: "2024" } 
                            }}
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "cross country" ? "sportspgallerytennis" : "" }>Cross Country</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "cross country" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "cross country", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "cross country"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "cross country", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "cross country"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "cross country", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "cross country"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        
                        <>
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("golf")}}
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "golf", sportsection: "blog", year: "2024" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "golf" ? "sportspgallerytennis" : "" }>Golf</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "golf" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "golf", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "golf"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "golf", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "golf"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "golf", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "golf"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                             to="/sports" 
                             onClick={() => {toggleSubmenu("hockey")}}
                             //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                             state = {{
                                 data: { sportcate: "hockey", sportsection: "blog", year: "2024" } 
                             }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "hockey" ? "sportspgallerytennis" : "" }>Hockey</p> 
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "hockey" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "hockey", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "hockey"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "hockey", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "hockey"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "hockey", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "hockey"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("rugby")}}
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "rugby", sportsection: "blog", year: "2024" } 
                            }}

                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "rugby" ? "sportspgallerytennis" : "" }>Rugby</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "rugby" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "rugby", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "rugby"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "rugby", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "rugby"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "rugby", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "rugby"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("netball")}}
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "netball", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "netball" ? "sportspgallerytennis" : "" }>Netball</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "netball" &&   
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "netball", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "netball"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "netball", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "netball"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "netball", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "netball"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports"
                            onClick={() => {toggleSubmenu("soccer")}} 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "soccer", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "soccer" ? "sportspgallerytennis" : "" }>Soccer</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "soccer" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "soccer", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "soccer"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "soccer", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "soccer"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "soccer", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "soccer"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        </>
                        
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("swimming")}}
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "swimming", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "swimming" ? "sportspgallerytennis" : "" }>Swimming</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "swimming" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "swimming", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "swimming"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "swimming", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "swimming"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "swimming", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "swimming"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("athletics")}}
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "athletics", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "athletics" ? "sportspgallerytennis" : "" }>Athletics</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "athletics" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "athletics", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "athletics"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "athletics", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "athletics"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "athletics", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "athletics"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports"
                            onClick={() => {toggleSubmenu("juveline")}} 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "juveline", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "juveline" ? "sportspgallerytennis" : "" }>Juveline</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "juveline" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "juveline", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "juveline"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "juveline", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "juveline"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "juveline", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "juveline"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports" 
                            onClick={() => {toggleSubmenu("high jump")}}
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "high jump", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "high jump" ? "sportspgallerytennis" : "" }>High Jump</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "high jump" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "high jump", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "high jump"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "high jump", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "high jump"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "high jump", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "high jump"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                        <Link 
                            to="/sports"
                            onClick={() => {toggleSubmenu("shot put")}} 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { sportcate: "shot put", sportsection: "blog", year: "2024" } 
                            }}
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p className={sportcate === "shot put" ? "sportspgallerytennis" : "" }>Shot Put</p>
                        </Link>
                        {showBlogsubmenu.clicksubmenu === "shot put" &&
                            <div className="blogsubtennis">
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "shot put", sportsection: "blog", year: "2022" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "shot put"&& currentYear ==="2022") ? "sportspgallerytennis" : "" }>2022</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "shot put", sportsection: "blog", year: "2023" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "shot put"&& currentYear ==="2023") ? "sportspgallerytennis" : "" }>2023</p>
                                </Link>
                                <Link 
                                    to="/sports" 
                                    state = {{
                                        data: { sportcate: "shot put", sportsection: "blog", year: "2024" } 
                                    }}
                                    style={{ textDecoration: 'none', color: 'black' }}
                                >
                                <p className={(sportcate === "shot put"&& currentYear ==="2024") ? "sportspgallerytennis" : "" }>2024</p>
                                </Link>
                            </div>  
                        } 
                    </div>
                    }

                </div>
            </div>
        </div>
    </>
  );


    
}

export default Sportsmenu;