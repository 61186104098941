import React, { useState } from 'react';
import "./sllogin.css";
import axios from 'axios';


function SLiblogin() {
    // get data from database thru app.js
    /*const users = useSelector ((state) => state.users);
    console.log("check token here",users);*/
  

    const [userData, setUserData] = useState({
      username: '',
      password: '',
      portal: ["library", 'student']
  
    });
  
    const [errorMessage, setErrorMessage] = useState('');

    const loc = window.location;
    const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
    const dynamiclibrarylogin = '/librarylogin';
    const libraryloginurl = baseUrl + dynamiclibrarylogin;
  
    
    
    // user dispatch to send data to the database thru the handlesubmit
  
      //const [username, setUsername] = useState('');
      //const [password, setPassword] = useState('');
    
      /*const handleUsernameChange = (event) => {
        setUsername(event.target.value);
      };
    
      const handlePasswordChange = (event) => {
        setPassword(event.target.value);
      };*/
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        //console.log(`Username: ${userData.username}, Password: ${userData.password}`);
        //console.log(dispatch(getUser()));
        /*dispatch(getUser(userData))
        .then(data => {
          //localStorage.setItem('token', data.token);
          console.log("check token here",data)
          console.log("check token",data.token)
        });*/
  
  
        //dispatch(getUser(userData)); <label htmlFor="username">Username:</label>
  
        /*fetch('http://localhost:5000/librarylogin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
         
          body: JSON.stringify({ userData })
        })
          .then(response => response.json())
          .then(data => {
            localStorage.setItem('token', data.token);
            //console.log("check token here",data.token);
            
            //window.location.href = '/addmember';
          
        });*/
  
  
        try {
          axios.defaults.withCredentials = true;
    
          const response = await axios.post(libraryloginurl, {
            userData
          });
  
         
          if (response.status === 200) {
            // The server has set a user cookie upon successful login
            // You can handle the user login and redirect the user as needed.
            console.log('Login successful');
            setErrorMessage('');
            window.location.href = '/libraryhome';
          }
  
    
        } catch (error) {
          console.error('Login failed:', error);
          setErrorMessage(error.response.data.message);
        }
      
    
  
        
  
        
  
        
        
      };
    
      return (
        <div className='containerLogslp'>
          <div className="formpageadslp">
              <h2>Library Portal</h2>
              <form onSubmit={handleSubmit}>
                  <div>
                  
                  <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Username"
                      value={userData.username}
                      onChange={(e) => setUserData({ ...userData, username: e.target.value})}
                  />
                  </div>
                  <div>
                  
                  <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={userData.password}
                      onChange={(e) => setUserData({ ...userData, password: e.target.value})}
                  />
                  </div>
                  <button type="submit">Login</button>
                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </form>
          </div>
        </div>
      );
    }
    
export default SLiblogin;
    
  
