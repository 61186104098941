import React from 'react';

import Plogins from '../pagecomp/plogins/plogin';
import '../pagecomp/plogins/plogin.css';

//import '../pagecomp/homep/navbarm.css';
//import NavbarM from '../pagecomp/homep/navbarm';

import '../pagecomp/homep/navbarms.css';
import NavbarMs from '../pagecomp/homep/navbarms';

import '../pagecomp/homep/Footer.css';
import Footer from '../pagecomp/homep/Footer';



function ParentSpace() {
    return (
        <>
            <NavbarMs />

            <Plogins />

            <Footer />
        </>
    )
}

export default ParentSpace;