import React, { useState, useEffect } from "react";
import "./phybookinfo.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


const Phylibinfor = () => {


  ///////////////Fix bookid get

  const [showMore, setShowMore] = useState(false);
  const [localData,  setlocalData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [bookStatus, setBookStatus] = useState(null);
  
  const toggleDescription = () => {
    setShowMore(!showMore);
  };


  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicgetbooks = '/getphybookinfo';
  const getbookinfourl = baseUrl + dynamicgetbooks;

  const dynamicborrowbooks = '/borrowbook';
  const borrowbooksurl = baseUrl + dynamicborrowbooks;

  const dynamicrequireAuth = '/requireAuth';
  const requireAuthurl = baseUrl + dynamicrequireAuth;

  const location = useLocation();

  const urlnew =  window.location.href;

  // Parse the URL to get the query parameters
  const queryString = urlnew.split('?')[1];

  // Parse the query string to get the variable after the '=' sign
  const queryParams = new URLSearchParams(queryString);
  const bookId = queryParams.get('bookId');

  const firstNineLetters = bookId.substring(0, 9);

  console.log("your bookid is", firstNineLetters);

  const imglink = "image"; //location.state.data.imglink;
  //const pdflink = location.state.data.pdflink;
  const bio = "when to the website"; //location.state.data.bio;
  const bio2 = "when to the website great"; //location.state.data.bio2;
  const bio3 = "when to the website great cool"; //location.state.data.bio3;
  const authorname = "author name" //location.state.data.authorname;
  const title = "Book One"; //location.state.data.title;
  //const booktype = location.state.data.typebook;

  useEffect(() => {
    const fetchRoles = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch(requireAuthurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        
      })
        .then(response => response.json())
        .then(data => {
          console.log("data contents db",data.userid)  
          setUserId(data.userid)
      });
      
    };

    fetchRoles();

    
}, []);


  useEffect(() => {
      
    //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.quiznum )
    // <a href="/onlinelibinfo">
    
      const fetchContents = async () => {
        //await dispatch(getOutlines(parsedObj));
        await fetch(getbookinfourl,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ firstNineLetters })
        })
          .then(response => response.json())
          .then(data => {
            console.log("data contents fetched books db",data)
            setlocalData(data);
  
            //setContentData(data);
            
          
        });
        
      };
  
      fetchContents();     
  
  }, [firstNineLetters]);


  function borrowbook (e, book) {
    e.preventDefault();

    const fetchContents = async () => {
      //await dispatch(getOutlines(parsedObj));
      await fetch(borrowbooksurl,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ book, userId }) 
      })
        .then(response => response.json())
        .then(data => {
          console.log("data books books db",data) 
          setBookStatus(data);
          
          //setlocalData(data);

          //setContentData(data);
          
        
      });
      
    };

    fetchContents(); 
    
    
  }


if (localData !== null) {

  return (
    <>
       <div className="mainphylibinfo">         
            <div className="phybookdet" >
                <div className="phybookinfobox">
                    <img src={localData.imglink} alt="phybookinfo" />
                </div>
                <div className="phybookinfodetails">
                    <h2>{localData.title}</h2>
                    <p className="phyauthornametag">{localData.authorname}</p>
                    <Link 
                      to="/onlinedisplay"
        
                      style={{ textDecoration: 'none', color: 'black', padding: 0}}
                    >
                      <button className="phybreadbtn" onClick={(e) => borrowbook(e, localData.bookid)} >Borrow</button>
                      {bookStatus ? <p className="bookstatusinfo">{bookStatus}</p> : ""}
                    </Link>
                    <p className="phybifordesc">{localData.authorbio}</p>
                    <div className={`phyreadmoreparablock ${(showMore && localData.authorbio2 !=="") ? 'phylshow-descrip' : ''}`}>
                      <p className="phyreadmorepara1">{localData.authorbio2}</p>
                      <p className="phyreadmorepara2">{localData.authorbio3}</p>
                      <p className="phybreadless" onClick={toggleDescription}>Read Less</p>
                    </div>
                    {(showMore) ?
                    ""
                    : (localData.authorbio2 !== "") ?
                    <p className="phybreadmore" onClick={toggleDescription}>Read More</p>
                    :
                    ""
                    }
                </div>
            </div>
        </div>
    </>
  );
}
};


export default Phylibinfor;