import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./writetest.css";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";


function WriteTest(props) {

    const location = useLocation();

    const username = localStorage.getItem('theusername');

    const [localData,  setlocalData] = useState([]);
    

    const formnum = location.state.data.formnum;
    const termnum = location.state.data.termnum;
    const subject = location.state.data.subject;
    const testid = location.state.data.testid;

    console.log("subject writetest", subject)

    const [testqueData, setTestqueData] = useState({
        formnum: formnum,
        termnum: termnum,
        subject: subject,
        testid: testid
    });

    const [testans, setTestans] = useState([]);
    const [marktest,  setMarktest] = useState([]);
    const [numberques,  setNumberques] = useState(null);
    const [tstname,  setTstname] = useState("");
    const [fnlmark,  setFnlmark] = useState();
    const [completed,  setCompleted] = useState('');

    const handleChange = (e, actualanswer) => {
        const useranswer = e.target.value;
        const quename = e.target.name;
        console.log("namee", quename)
        console.log("inputts", useranswer)
        console.log("actualanswer", actualanswer)
        setTestans({...testans, [quename]: useranswer});

        if (useranswer === actualanswer){
            setMarktest({...marktest, [quename]: 1});

        }else {
            setMarktest({...marktest, [quename]: 0});

        }
  
         
    }

    // Delete after confirmation of workin testqueData
    console.log("testanswer", testqueData)
    console.log("testanswer", testans)
    console.log("testmark", marktest)
    console.log("testmark length", Object.keys(marktest).length)
    console.log("testmark length convert", Object.keys(marktest))
    console.log("button off", numberques)
    console.log("answers array", Object.entries(testans))
    // Delete after confirmation of workin

   

    const handleAnsSubmit = (e) => {
        e.preventDefault();

        const updatetestuser = async () => {
            //await dispatch(getOutlines(parsedObj));  username, testqueData.testid, tstname, fnlmark , testans
            await fetch('http://localhost:5000/assignmenttst-update', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ username, tstname, numberques, fnlmark , testans, testqueData })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents db",data)
                
            });
            
          };
    
        updatetestuser();
    
        setCompleted("done")
        
    }
  

    useEffect(() => {
      
        //console.log("clicked option seeeeeeeeeeeeeent", location.state.data.clicked )
          const fetchContents = async () => {
            //await dispatch(getOutlines(parsedObj));
            await fetch('http://localhost:5000/assignmentquestions-filter', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ testqueData })
            })
              .then(response => response.json())
              .then(data => {
                console.log("data contents writetest",data)
                if (data !== null) {
                  //console.log("logging data", data[0].subtopics); data.subtest.length > 0 && 
                  const contentcheck = data.subtest.map((post) => post.subject)
                  console.log(" contents writetest mapping",contentcheck);
                  //console.log('data', data)
                  if (contentcheck !== null) {
                    setlocalData(data.subtest);
                  }else {
                    fetchContents();
                  }
                  
                    //localStorage.setItem('array', JSON.stringify(data));
                }
      
                //setContentData(data);
                
              
            });
            
          };
      
          fetchContents();
           
      
    }, []);

    useEffect(() => { 
        localData.map((post) =>
            <div className="questions-bufferB1" key={post._id}>
            {post.testcont.map((type)=>
                <div className="questions-bufferb2" key={type._id}>
                    {type.testid === testqueData.testid &&
                    <div className="writetestquestionsbuf">
                        {setNumberques(type.noques)} 
                        {setTstname(type.testname)}

                    </div>
                    }    
                </div>
            )}
            </div>
        )

    }, [testqueData.testid, []])


    useEffect(() => {  
        const initialValue = 0;
        const finalmark = Object.values(marktest).reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
        console.log("testmark finalmark", finalmark)
        setFnlmark(finalmark);

    }, [marktest])


    if (localData !== null) {

    const questionscard = localData.map((post) =>
        <div className="questions-cardB1" key={post._id}>
        {post.testcont.map((type)=>
            <div className="questions-cardb2" key={type._id}>
                {type.testid === testqueData.testid &&
                <div className="writetestquestions">
                    <p className="writetesttopic">{type.testname}</p>
                    {type.testques.map((next, index)=>
                    <div className="testquestioncont" key={next._id}>
                        <p className="questionnumber">Question {index + 1}</p>
                        <div className="acttestquestion">
                            <p>{next.question}</p>
                        </div>
                        <div className="acttestanswerr">
                            {next.anstype === "enter" ?
                            <label>{next.finditem} <input type="text" name={"question" + (index+1)} onChange={(e) => handleChange(e, next.answer)} /></label>
                            :
                            <div className="testoptionabc">
                                <div className="checkoptionr">
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio1'} value="A" onChange={(e) => handleChange(e, next.answer)} />
                                    <label htmlFor={index + 'radio1' }>A</label>
                                    <p>{next.options.map((newopt, index)=> index === 0 && newopt)}</p>   
                                </div>
                                <div className="checkoptionr">
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio2'} value="B" onChange={(e) => handleChange(e, next.answer)} />
                                    <label htmlFor={index + 'radio2'}>B</label> 
                                    <p>{next.options.map((newopt, index)=> index === 1 && newopt)}</p>
                                </div>
                                <div className="checkoptionr">
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio3'} value="C" onChange={(e) => handleChange(e, next.answer)} />
                                    <label htmlFor={index + 'radio3'}>C</label>
                                    <p>{next.options.map((newopt, index)=> index === 2 && newopt)}</p>
                                </div>
                                <div className="checkoptionr">
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio4'} value="D" onChange={(e) => handleChange(e, next.answer)} />
                                    <label htmlFor={index + 'radio4'}>D</label> 
                                    <p>{next.options.map((newopt, index)=> index === 3 && newopt)}</p>
                                </div>
                                <div className="checkoptionr">
                                    <input type="radio"  name={"question" + (index+1)} id={index + 'radio5'} value="E" onChange={(e) => handleChange(e, next.answer)} />
                                    <label htmlFor={index + 'radio5'}>E</label>
                                    <p>{next.options.map((newopt, index)=> index === 4 && newopt)}</p>
                                </div>

                            </div>
                            }
                        </div>
                    </div>
                    )}
                    
                </div>
                }    
            </div>
        )}
        </div>
    )



    

    return (
        <>
        <div className="writetest-main">


            {(completed === "done") ? 

            <div className="writeteststart">
                <p>Your Test has been submitted Thank You</p>
            </div>

            :(testqueData.testid === undefined)?

            <div className="writeteststart">
                <p>Select Subject And Check if there is a Test of Assignment Pending</p>
            </div>

            :

            <div className="formbuffer">
                <form className="formassignment" onSubmit={handleAnsSubmit}>
                    {questionscard}

                    <div className="submitbtn">
                        {numberques !== null &&
                        <button className={(numberques + 1) === (Object.keys(marktest).length) ? 'btnactive': 'btnnotactive'} type="submit" disabled={ (numberques + 1) !== (Object.keys(marktest).length)}>Submit</button>
                        }
                    </div>
                </form>  

                
            </div>

            }

            

            
        </div>
        </>
      
    );
    }
    
}


  
  export default WriteTest;