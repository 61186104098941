import React, { useState, useEffect } from 'react';
import './dashboardmenu.css';
import { Link } from 'react-router-dom';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
        
function Dashboardmenup() {
    //dashboardlibadminmenu
    const [showGerne, setShowGerne] = useState(false);

    const toggleMenu = () => {
        setShowGerne(!showGerne);
    };

    //const booktype = "physical";

    const [userRole, setUserRole] = useState(null);

  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicrequireAuth = '/requireAuth';
  const requireAuthurl = baseUrl + dynamicrequireAuth;

  useEffect(() => {
        const fetchRoles = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch(requireAuthurl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include',
            
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents db",data)  
              setUserRole(data)
          });
          
        };
    
        fetchRoles();

        
  }, []);

    

  if (userRole !== null) {

  return (
    <>
       <div className="dashboardlibadminmenu">
            <div className="dashboardlibadmingerne">
                {showGerne ?
                <h2 onClick={toggleMenu}>Dashboard Menu  &times;</h2>
                :
                <h2 onClick={toggleMenu}>Dashboard Menu  &#9776;</h2>
                }
                <div className={`dashboardlibadmingerneblock ${showGerne ? 'dashboardlibadminshow-gerne' : ''}`}>
                    <div className="dashboardlibadminactualgerne">
                        <Link 
                            to="/onlinebookstats" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "arts" ,catname: "Arts", nxtpage: 1, searchactive: "off"  }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Online Books</p> 
                        </Link>
                        <Link 
                            to="/phybookstats" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                            state = {{
                                data: { categoryview: "music" ,catname: "Music", nxtpage: 1, searchactive: "off"   }  // your data array of objects type.title {setIntquiz({quiznumc: close.quizava})}
                            }}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Physical Books</p>
                        </Link>
                        <Link 
                            to="/studentsstatslib" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Students Stats</p>
                        </Link>
                        {(userRole.message === "library" &&
                        <>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Quiz</p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Book Summary</p> 
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Circulation</p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Lost Books</p>
                        </Link>
                        <Link 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Reserved Books</p>
                        </Link>
                        <Link 
                            to="/studentsstatus" 
                            //onClick={() => {if (window.location.href === 'http://localhost:3000/onlinecategory') {window.location.reload()} }}
                    
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Student Status</p>
                        </Link>
                        </>
                        )}
                        <Link 
                            to="/circulationhistory" 
                            
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <p>Borrow History</p>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    </>
  );
}

    
}

export default Dashboardmenup;